import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'ar',
  messages: {
    'en': {
      welcome: 'welcome asd',
      name:"Name",
      privacy_inforamtion:'This information is confidential and used only to register in Biban2024',
      all_right_1 :'Organized by',
      all_right_2 :'Small and Medium Enterprises General Authority  "Monshaat"',
      lang:'EN',
      welcome_to_registrate:'Welcome to register for Biban 2024',
      step1:'Step one',
      coming_soon:'Coming Soon',
      welcome_text:'In the “Biban Forum”, the first destination for the party sector and entrepreneurs, organized by the General Authority for Small-Scale Enterprises, where entrepreneurs meet over a period of 5 days, with rich establishments that highlight community awareness, the growth of the local economy, youth on investment opportunities, facilitating work and creating a work environment And raise and raise in the business sector to achieve the goals of the Kingdom\'s Vision 2030.',
      You_will_find_it_in_Biban:'You will find in Biban Forum',
      Training_workshops:'Training workshops',
      Global_speakers: 'Local and international speakers',
      Specialized_experts:'Specialized experts',
      Promising_investment_opportunities:'Promising investment opportunities',
      Competitive_business_environment:'Competitive business environment',
      Educational_initiatives:'Educational initiatives',
      Project_financing_opportunities:'Project financing opportunities',
      month:'Months',
      days:'Days',
      hours:'Hours',
      basic_registration:'Basic registration',
      legal_name:'Legal Name (Company name)',
      website:'Website',
      email:'Email',
      nationality: 'Nationality',
      select_nationality:'Select nationality',
      cr_number:'CR Number',
      cr_numbergover:'Entity code',
      logo:'Logo',
      image_text:'Press to add file, it accept PNG, JPG',
      industry:'Industry',
      remeber_me_late:'To receive an email when registration is open,\n please enter your e-mail address',
      reminde_me:'Remind me',
      select_industry:'Select industry',
      company_investment_phase:'Company investment phase',
      select_key_investment:'Select key investment',
      objective_key_question:'What is your key objectives for attending/participating in Biban Forum?',
      select_key_objective:'Select key objective',
      please_enter_your_objective:'Please enter your objective',
      step2:'Step two',
      owner_name:'Contact name',
      contact_info:'Contact information',
      phone:'Phone',
      step3:'Step three',
      team_member:'Team member',
      job:'Job',
      add_member:'Add member',
      name:'Name',
      add:'Add',
      cancel:'Cancel',
      registration_completed:'Registration form received',
      registration_failed:"Registration failed",
      see_you_at_biban:'See you at Biban 2024',
      title:'Title',
      first_name:'First name',
      last_name:'Last name',
      personal_photo:'Personal photo',
      id_pass_iqama:'ID Number / Passport / Residence',
      bio:'Bio',
      select_country_of:'Select country of residence',
      country_of:'Country of residence',
      email_address:'Email address',
      socail_media:'Socail media',
      role:'Role',
      job_role:'Job role',
      career_info:'Career info',
      do_you_have:'Do you have valid visa?',
      yes:'Yes',
      no:'No',
      have_another_nationality:'Have another Nationality?',
      do_you_want:'Do you want monshaat to process a visa?',
      are_you_gcc:'Are you residing in one of the Gulf countries?',
      visa_requirement:'Visa requirement',
      step4:'Step four',
      passport_number:'Passport number',
      passport_type:'Passport type',
      issuance_country:'Issuance country',
      pass_issue_date:'Passport issue date',
      date_of_birth:'Date of birth',
      place_of_birth:'Place of birth',
      place_of_issue:'Place of issue',
      pass_expire_date:'Passport expiry date',
      religion:'Religion',
      gender:'Gender',
      image_condition:'It should be white background and formal uniform',
      recant_nationality:'Nationality',
      prev_nationality:'Another nationality',
      social_situation:'Social situation',
      single:'Single',
      married:'Married',
      divorced:'Divorced',
      address:'Address',
      expected_entry_date:'Expected entry date',
      entry_number:'Enterance number',
      length_of_stay:'Length of stay',
      contact_point:'Contact point',
      umrah_option:'Do you want to perform Umrah?',
      have_you_travel:'Have you ever traveled to any other country in the past five years?',
      contact_name:'Contact name',
      country:'Country',
      company_name:'Company name',
      id_number:'ID number',
      iqama:'Iqama number',
      enter_role:'Please enter your role',
      enter_industary:'Please enter your industry',
      as_exh:'as exhibitor',
      as_media:'as media',
      as_speaker:'as speaker',
      proccessing_reg:'We will be processing your request.We will contact you soon',
      as_exh_staff:'as exhibitor staff',
      as_guest:'as guest',
      as_media_staff:'as media staff',
      loading:'Loading Please Wait',
      link_expire:'The link was expired or it is not valid',
      official_email:'Official email',
      as_vip:'as VIP',
      category:'Category',
      phone_required:"Phone is required",
      phone_not_valid:"Phone number not valid",
      as_vip_staff:"as VIP companion",
      country_comming_from:"Country coming from",
      duplicated_email:'Duplicated email',
      minutes:'Minutes',
      email_is_success:"Email has been successfully sent",
      emai_is_uniqe:"You have been set a reminder before",
      part_of_participation:"Part of participation",
      headquarter_country:'Headquarter country',
      toke_not_valid:'Token not valid',
      dose_your_brand:"Does your brand have potential franchise agreements?",
      filed_is_required:"Field is required",
      beneficiaries:"Beneficiaries",
      beneficiaries_from_guidance:"Beneficiaries from guidance and consultation sessions",
      about_biban:"Biban 2024",
      footer_par_biban_p1:"Biban 2024 is one of the most important tools for Saudi Arabia’s accelerated development in SMEs and entrepreneurship. Biban Forum contributes to creating a catalytic environment in partnership with public and private sector stakeholders",
      about_biban_p1:"Biban 2024 is one of the most important tools for Saudi Arabia’s accelerated development in SMEs and entrepreneurship. Biban Forum contributes to creating a catalytic environment in partnership with public and private sector stakeholders, which has resulted in the Kingdom’s remarkable progress in the Global Entrepreneurship Monitor report, going from 41st place in 2018 to fourth place in 2022. This increase is in line with Saudi Arabia’s Vision 2030, which gives a prominent role to SME growth.",
      about_biban_p2:"Biban Forum was launched to enhance the Kingdom’s position as a first-choice destination for national and international entrepreneurs and to promote communication between SME owners and entrepreneurs locally, regionally and internationally. Transmitting the Kingdom’s ambition to the world, Biban Forum encourages local and international SME owners and entrepreneurs to share innovative ideas and experiences, and to find the best solutions to tackle different growth challenges.",
      about_biban_p3:"Biban Forum receives stakeholders from around the world and facilitates access to different services and initiatives that help SME owners and entrepreneurs promote business growth and embrace a culture of innovation. This allows entrepreneurs and business owners to develop new ideas which in turn benefit the domestic and international economy, while connecting them with investors and financing sources.",
      download_pdf_link:"For more, click to download Biban Forum file",
      about_sbutitle_1:"The added value of Biban Forum event",
      about_sbutitle_2:"Regional & local incentives for entrepreneurship:",
      about_sbutitle_3:"International competitions:",
      about_sbutitle_4:"Increase awareness of the Gulf region & Arab world:",
      about_p1:"Build a regional & international leadership network promote communication between investors, entrepreneurs and local, regional and global leaders, host- ing the most important and prominent speakers and entrepreneurs, and attracting the largest investors in the region and the world.",
      about_p2:"Supporting international start-ups: special zones, economic support, developing packages for pilot proj- ects (financial, logistical and R&D support).",
      about_p3:"International Awards for Innovation-Based Startups - World Entrepreneurship Cup Qualifiers (EWC).",
      about_p4:"Access to great and exciting markets, investment opportunities and growth in venture capital, busi- ness opportunities and leading companies.",
      door_name_1:"Star Door",
      door_desc_1:"Business Start Journey",
      door_name_2:"Enablement and Opportunities Door",
      door_desc_2:"Supporting & Enabling",
      door_name_3:"Grwoth and Sustainability Door",
      door_desc_3:"Business Development & Prosperity",
      door_name_4:"Funding and Investment Door",
      door_desc_4:"Opportunities  &  Solutions",
      door_name_5:"E-commerce Door",
      door_desc_5:"Digital Business Technology",
      door_name_6:"Market Door",
      door_desc_6:"Services & Products",
      door_name_7:"Innovation Door",
      door_desc_7:"Creativity  &  Development",
      door_name_8:"Startups Door",
      door_desc_8:"Promising Entrepreneurial Projects",
      door_name_9:"Franchise Door",
      door_desc_9:"Brands Expansion",
      door_name_10:"Monsha at Stage",
      titletext:"Thursday,March 9 to Monday, March 13",
      text2:"In Riyadh Front Exhibition & Convention center",
      entrepreneurship_environment:"Entrepreneurship Environment in Saudi Arabia",
      entrepreneurship_text:"Saudi Arabia’s entrepreneurial environment benefits from programs, services and initiatives that enhance entrepreneurial growth and competitiveness, stimulating a culture of entrepreneurship by supporting ambitious entrepreneurs to follow their ideas and create a business. It is also focused on spreading a culture of innovation and attracting entrepreneurial ideas, which in turn benefits the domestic and international economy.",
      facts:"Facts",
      facts_title:"The Kingdom is the world’s first in the following indicators:",
      facts_1:"Ease of starting a business.",
      facts_2:"Promising opportunities for starting a project in my region.",
      facts_3:"Personal knowledge and skills to start a business.",
      economic_summary:"Economic summary",
      economic_summary_1:"The government has invested about $70 billion to develop economic & smart cities.",
      economic_summary_2:"FDI inflows reached US$7.854 billion by the end of the second quarter in 2022.",
      economic_summary_3:"With US$10,320 million and a share of 65.6%, eCommerce generated increasing digital revenues in 2021.",
      economic_summary_4:"eCommerce revenues expected to have an annual average growth of 18.6% by 2025.",
      economic_summary_5:"The total FinTech transaction value is forecast to grow by 100% between 2021 and 2025.",
      plz_try_again:"Please try again later",
      invalid_token:"Sorry this token is invalid or expired",
      at_least_one_member:"You have to add at least one mumber",
      some_email_are_duplicated:"Emails are duplicated",
      ar_legal_name:"Arabic ar legal name",
      max_team_memebr:"Capacitance of exhibitor personal",
      male:"Male",
      female:"Female",
      fostering_tangible_opportunities:"Global Destination for Opportunities",
      biban_23:"Biban 2024",
      biban_add_value:"BIBAN 2024, The global added value",
      build_network:"Build a regional & international leadership network",
      build_network_p:"Promote communication between investors, entrepreneurs and local, regional and global leaders, hosting the most important and prominent speakers and entrepreneurs, and attracting the largest investors in the region and the world.",
      build_a_regional:"Regional & local incentives for entrepreneurship:",
      build_a_regional_p:"Supporting international start-ups: special zones, economic support, developing packages for pilot projects (financial, logistical and R&D support).",
      inter_com:"International competitions:",
      inter_com_p:"International Awards for Innovation-Based Startups - World Entrepreneurship Cup Qualifiers (EWC).",
      increase_awar:"Increase awareness of the Gulf region & Arab world:",
      increase_awar_p:"Access to great and exciting markets, investment opportunities and growth in venture capital, busi- ness opportunities and leading companies.",
      biban_in_number:"Biban Forum in numbers",
      number_p2:"Participation of international and local franchise brands",
      number_p3:"Exhibitors of local and international entrepreneurs",
      number_p4:"Beneficiaries from more than 330 workshops",
      number_p5:"Beneficiaries from guidance and consultation sessions",
      number_p6:"Entrepreneurs participating at the Biban Forum Talks",
      number_p7:"Enablement party",
      number_p8:"International and local speakers",
      biban_doors:"Biban Forum doors",
      door_n_1:"Launching Door",
      door_d_1:"Business Start Journey",
      door_n_2:"Empowerment Door",
      door_d_2:"Supporting & Enabling",
      door_n_3:"Innovation Door",
      door_d_3:"Creativity & Development",
      door_n_4:"Growth Door",
      door_d_4:"Business Development & Prosperity",
      door_n_5:"E-commerce Door",
      door_d_5:"Digital Business Technology",
      door_n_6:"Investment & Funding Door",
      door_d_6:"Opportunities & Solutions",
      door_n_7:"Market Door",
      door_d_7:"Services & Products",
      door_n_8:"Franchise Door",
      door_d_8:"Brands Expansion",
      door_n_9:"Startup Door",
      door_d_9:"Promising Entrepreneurial Projects",
      ewc_title:"Entrepreneurship World Cup (EWC)",
      ewc_p1:"The Entrepreneurship World Cup is the first and largest competition of its kind in the world, with participants from more than 200 countries.",
      ewc_p2:"Saudi Arabia hosts the final qualifiers of the competition, the closing ceremony and the distribution alongside the participation of the best 25 entrepreneurs who will qualify for the finals in Riyadh as part of the Biban 2024 calendar.",
      ewc_t_1:"Participating countries",
      ewc_t_2:"Finalists in Biban 2024",
      ewc_t_3:"Qualified Enterprises through intensive workshops",
      ewc_t_4:"Financial Prizes",
      web_title:"BIBAN 2024 Global Destination for Opportunities",
      brief:"Brief",
      ex_person:"Exhibitor Personal",
      download_app:"Download App",
      contact_us:"Contact Us",
      doors:"Doors",
      sponsers:"Sponsers",
      live_streaming:"Live Streaming",
      exhibitors:"Exhibitors",
      calendar:"Agenda",
      error_in_felids:"There are some empty fields or wrong data",
      legal_name_req:"Legal Name is required",
      logo_is_req:"Logo is required",
      official_email_is_req:"Official email is required",
      cr_10:"CR is Invalid , should be 10 numbers",
      industary_is_req:"Industary is required",
      iqama_10:"Iqama Number should 10 digits only",
      id_10:"ID Number should 10 digits only",
      close:"Close",
      photo_specs:"Photo specs",
      photo_specs_title:"The specifications for a Saudi Arabia passport photo are as follows:",
      photo_specs_li_1: "Required photo size: 4.5 cm in height and 3.5 cm in width",
      photo_specs_li_2: "Colored pictures are necessary. Not black and white.",
      photo_specs_li_3: "Head position: keep your head straight and face forward",
      photo_specs_li_4: "Must not be older than 6 months",
      photo_specs_li_5: "Background: white",
      photo_specs_li_6: "Smile: no",
      photo_specs_li_7: "Eyes: clearly visible",
      photo_specs_li_8: "Blurry photos are rejected",
      photo_specs_li_9: "Glasses: acceptable under certain conditions",
      photo_specs_li_10:"Headwear: allowed for religious purposes",
      photo_specs_li_11:"Resolution (pixels): at least 600 dpi",
      photo_specs_li_12:"Dress code: clothes must be in contrast with the background. Do not wear white clothes",
      photo_specs_li_13:"Beard: permitted if it represents your everyday likeness",
      cr_5:"Entity code is Invalid , should be 5 numbers or letters",
      linked_in:"Linked_in",
      twitter:"Twitter",
      facebook:"Facebook",
      instagram:"Instagram",
      snapchat:"Snapchat",
      phone_is_required:"Phone is required",
      missing_data:"Data is Missing! Please Use the Link sent to your Email!",
      more_about_biban:"More about Biban Forum",
      d_1:"Sunday",
      d_2:"Monday",
      d_3:"Tuesday",
      d_4:"Wednesday",
      d_5:"Thursday",
      d_6:"Friday",
      d_7:"Saturday",
      m_1: "January",
      m_2: "February",
      m_3: "March",
      m_4: "April",
      m_5: "May",
      m_6: "June",
      m_7: "July",
      m_8: "August",
      m_9: "September",
      m_10:"October",
      m_11:"November",
      m_12:"December",
      local_speakers:"Local Speakers",
      international_speakers:"International Speakers",
      sponser:"Sponser",
      your_question:"Your question...",
      send_msg:"Send Message",
      send_msg_new_contact:"Send",
      call_center:"Call Center",
      speakers:"Speakers",
      time_of_event:"Thursday 9 March 2024 - Monday 13 March 2024",
      location_of_event:"Riyadh Front Exhibitions and Convention Center",
      welcome_phrase:"Global Destination for Opportunities",
      registration_for_biban:"Registration for Biban Forum event",
      download_biban_app_for_android:"Download Biban Forum app for android",
      download_biban_app_for_ios:"Download Biban Forum app for ios",
      biban_map:"Biban Forum Map",
      see_more:"See more",
      more_local_speakers:"More local speakers",
      more_international_speakers:"More international speakers",
      more_doors:"More doors",
      more_exhibitors:"More exhibitors",
      text_number_2:"Beneficiary of more than 255 workshops",
      text_number_6:"Local and international exhibitors of  business leaders",
      about_home_title:"Global Destination for Opportunities",
      about_home_1:"Welcome to Biban Forum, Saudi Arabia's biggest SME and Entrepreneurship event. This is where the private and public sectors come together, under one roof, to share extraordinary ideas and profound experiences revolving around business and entrepreneurship.",
      about_home_2:"Here, we showcase innovations and breakthroughs, host talks and panel discussions, and hold workshops and competitions that inspire growth and embrace the culture of innovation.",
      about_home_3:"It’s a space that encourages dialogue and collaboration, filled to the brim with the most influential names in the industry (and from all over the world, no less). It’s where aspiring business minds come to their own, where existing businesses find investors, and where the greatest and latest business developments are revealed to the world.",
      about_home_4:"Join us and explore our Nation’s thriving economic landscape; fertile land from which bright ideas and bold businesses grow and prosper, influencing a new generation of ambitious SMEs and start-ups. It's an opportunity to establish meaningful, long-lasting relationships with industry leaders and decision-makers, learn new skills and gain valuable insight, and engage in high-power meetings, business accelerators, and incubators. In short, we’re Global Destination for Opportunities. Biban Forum is where you capitalize on them. ",
      partners:"Partners",
      leading_businesses:"Entrepreneurship World Cup",
      leading_dictionary:"The leading dictionary",
      usage_policy:"Usage policy",
      ewc_title_2:"Expected impact results ",
      ewc_list_1:"Attracting pioneering and innovative projects that serve the Kingdom",
      ewc_list_2:"Stimulating local investments and attracting foreign investments",
      ewc_list_3:"Highlighting the Kingdom’s promising opportunities",
      ewc_list_4:"Applying entrepreneurial projects from around the world to solve national challenges",
      ewc_list_5:"Enabling partners and supporting entities so they may achieve their goals",
      ewc_list_6:"Accumulating over $25,000 (as incentives & in services) from competition partners",
      message_sent:"Your message has send!",
      message_not_sent:"Faild to sent your message please try again later",
      mobile_phone:"Mobile phone",
      telephone:"Telephone",
      qualification:"Qualification",
      work_place:"Work place",
      job_title:"Job title",
      do_you_have_facebook:"Do you have facebook",
      do_you_have_twitter:"Do you have twitter",
      search:"Search",
      personalphoto:"Personal Photo",
      okay:"OK",
      popup_sending_toallstaff:"An invitation will be send to all added team members. Kindly ensure the completion of their registration.",
      invalid_email:"Invalid email",
      duplicated_phone:'Phone number duplicated',
      muslim:"Muslim",
      not_muslim:"Not Muslim",
      high_resolution:"Upload Logo in high resolution",
      add_new_companion:"Add new companion",
      add_new_member:"Add new team member",
      companion:"Companion Persons",
      id_10_digit:"Id number should be 10 digit starting by 1",
      id_20_digit:"Please enter a correct Iqama number",
      id_25_digit:"Please enter a correct passport number",
      enter_only_number:"You should enter numbers only",
      title_is_required:'Title is required',
      first_name_is_required:'First name is required',
      last_name_is_required:'Last name is required',
      gender_is_required:'Gender is required',
      email_is_required:'Email is required',
      nationality_is_required:'Nationality is required',
      countryofresidence_is_required:'Country of residence is required',
      category_is_required:'Category is required',
      legal_name_is_required:'Legal name is required',
      contactname_is_required:'Contact name is required',
      c_coming_from_is_required:"Country coming from is required",
      personal_image_is_required:'Personal image is required',
      passport_image_is_required:'Passport image is required',
      headquarter_is_required:'headquarter is required',
      full_name_is_required:"Full name is required",
      place_of_birth_is_required:"Place of birth is required",
      date_of_birth_is_required:"Date of birth is required",
      passport_type_is_required:"Passport type is required",
      passport_expiry_is_required:"Passport expiry is required",
      qualification_is_required:"Qualification is required",
      work_palce_is_required:"Work place is required",
      job_tilte_is_required:"Job tilte is required",
      address_is_required:"Address is required",
      company_name_is_required:"Company name is required",
      facebook_is_required:"Facebook is required",
      twitter_is_required:"Twitter is required",
      role_is_required:"Role is required",
      message:"Message",
      more:"More",
      home:"Home",
      ewc_hoempage_second_title:'Expected impact results',
      describeyourslefas:'You describe yourself as',
      visitor_thank:"Thank you for your registration in Biban 2024,",
      badge_sending:"Your E-badge has been sent to your email.",
      regards:"Thank You.",
      email_confirm:"Email confirmation",
      welcome_to_registrate_visitor:"Welcome to Biban 2024 registration",
      badge_email:"The badge will send to this email",
      full_name_as_id:"Enter full name as ID card",
      full_name_as_iqama:"Enter full name as Iqama",
      full_name_as_passport:'Full name as passport',
      passportphoto:"Passport Photo",
      IDphoto:"ID photo",
      iqamaphoto:"Iqama photo",
      photo_is_required:"Photo is required",
      full_name:"Full name",
      invalid_id:"Please enter a correct ID number",
      email_hint_1:"Please use your pesonal email",
      email_hint_1_1:"Please use your personal email.",
      why:"Why?",
      email_hint_2:"To ensure you receive your E-badge.",
      email_hint_2_2:"To ensure receiving the e-Badge.",
      email_dose_not_match:"Emails do not match",
      name_as_passport:"Name should be identical to passport",
      flight_ticket:"Flight ticket",
      choose_option:"kindly choose one of the options or provide us with the date, time and destination you prefer in the below text box",
      departing_flight:"Departing Flight",
      returning_flight:"Returning Flight",
      from:"From",
      to:"To",
      class:"Class",
      dear_guest:"Dear Guest,",
      wirte_your_message:"Kindly write your message in the text below",
      your_message:"Your Message",
      other:"Other",
      submit:"Submit",
      choosen_option:"Thank you for choose option",
      receiving_ticket:"You will be receiving your ticket shortly",
      thank_message:"Thank you for you message",
      thank_message_side:"Thank you",
      agent_call:"OUr agent will contact you soon",
      must_select:"Please you have to select one option",
      option:"Option",
      invalid_token_forbadge:"Invalid Link. Please open the Link again from your Email",
      next:"Next",
      back:"Back",
      media_company:"Media company",
      media_company_is_required:"Media company is required",
      country_code:"Country code",
      phone_number:"Phone number",
      download_badge:"Download badge",
      share:"Share",
      your_selected_option:"Your already choose an option before",
      trip_list:"Trips list",
      trip_choose_option:"Please select all desire events to attend",
      trip:"Trip",
      par:"Main Partiners",
      all_partners:"All partners",
      message_cannot_be_empty:"Please enter message text",
      add_to_calendar:"Add to calendar",
      no_trips:"Sorry, all side event are full please Try again later",
      id_expiry_date:"ID expiry date",
      iqama_expiry_date:"Iqama expiry date",
      id_expiry_is_required:"ID expiry is required",
      iqama_expiry_is_required:"Iqama expiry is required",
      job_title_is_required:"Job title is required",
      work_place_is_required:"work place is required",
      faq:"FAQ",
      sorry_no_result:"Sorry there is no result",
      visit_saudi:"Visit Saudi",
      first_day:"First day",
      second_day:"Second day",
      third_day:"Third day",
      fourth_day:"Fourth day",
      fifth_day:"Fifth day",
      march:"March",
      enablers:"Supporting Entities",
      activites:"Activites",
      general_event:"General events",
      faq:"FAQ",
      oppurtuinites:"Opportunities",
      supporting_centers:"Monshaat supporting center",
      monshaat:"About Monshaat",
      workshops:"Workshops",
      media_center:"Media centers",
      training:"Training",
      follow_us:"Follow Us",
      no_session:"There is no session now please try again later",
      session:"Session",
      topics:"Topics",
      opportunities:"Opportunities",
      kind:"Kind",
      more_details:"More details",
      conditions:"Conditions",
      all:"All",
      local:"Local",
      international:"International",
      sorry_no_result:"Sorry, there is no result",
      calling_numbers:"Calling Number",
      inside_ksa:"Inside KSA",
      outside_ksa:"Outside KSA",
      register_now:'Register now',
      registrate_now:"Register",
      web_agenda_title:'Agenda',
      filiter_by_industary:"Filiter by industry",
      tag_lang:"EN",
      about_biban_page_title:"Monsha'at’s programs, services, and initiatives",
      about_biban_page_description:"Monsha'at is constantly working to fulfill the needs of all entrepreneurs, both male and female. It aims to create a promising environment that facilitates opportunities where people can grow and prosper, be it through support services or business opportunities, thus nourishing the entire sector and its many subcultures and departments.",
      flight_opt_1:"The flight schedule options:",
      flight_opt_2:"In case, you have issues; please mention it in the “other” tab and we will contact you.",
      media_center_title:"Media center for Biban 2024",
      news:"News",
      hour:"Hour",
      workshop:"Workshop",
      seminars:"Seminars",
      seminar:"Seminar",
      send:"Send",
      smePage:{
        mainTitle:"About SMEs Support Center",
        firstSectionText:'An area that provides international and local consultancy and guidance, both general and specialized services, involving the world’s leading experts and businesspeople. It will include: ',
        firstSectionBoxTitle1:"630",
        firstSectionBoxText1:"business support sessions",
        firstSectionBoxTitle2:"2100",
        firstSectionBoxText2:"counseling sessions",
        firstSectionBoxTitle3:"630",
        firstSectionBoxText3:"guidance sessions",

        secondSectionTitle:"Business support",
        secondSectionBox1Title:"Activities and attractions",
        secondSectionBox1Definition:"A mechanism that identifies customer needs. Based on the outcome of the analysis, visitors are redirected to the service center and/or other support services.",
        secondSectionBox2Title:"Consulting",
        secondSectionBox2Definition:"Daily sessions led by specialized consultants, each of whom is formally trained and competent, with commercial experience from which they draw valuable insight and answers for questions from audience members.",
        secondSectionBox3Title:"Guidance",
        secondSectionBox3Definition:"Sessions featuring successful businesspeople with experience and expertise from different fields, thus facilitating for the exchange of knowledge from businessperson to another",

        section3Title: "How to book sessions",
        section3Step1Title:"Scan the QR code",
        section3Step2Title:"Book an appointment on the day of",
        section3Step2Subtitle: "(the QR code will display available time slots)",
        section3Step3Title: "Receive a reminder 15 minutes before the appointment",
        section3Step4Title: "Show up ten minutes early to meet the expert",

        subjects:"Subjects",
        definition: "Definition"
      },
      please_select_atleast_one_trip:"Please selecte atleast one trip",
      confirm:"Confrim",
      trip_time_conflict:"Sorry, you can't choose this time due to time conflict",
      i_agree:"I herby certify that I am above 14 years of age.",
      locationaddress_badge:"Riyadh - Kingdom of Saudi Arabia \n Riyadh Front Exhibition and Convention Center \n 9 - 13 March 2024 \n 4 - 11 PM",
      gallery:"Gallery",
      guides:"Guide",
      selected_side_event:"Selected sied-event",
      monday:"Monday",
      tuesday:"Tuesday",
      wednesday:"Wednesday",
      thursday:"Thursday",
      friday:"Friday",
      saturday:"Saturday",
      sunday:"Sunday",
      discover_biban:"Discover Biban",
      recommendations:"Recommendations",
      you_can_visit:"You can visit",
      skip:"Skip",
      do_you_want_to_stat_again:"Do you want to stat again?",
      write_your_answer:"Please write your answer here...",
      survey_basic_info:"Basic Info",
      survey_thank:"Thank you for fill the survey",
      survey_failed:"Sorry submiting survey failed please try again",
      guidelines:"Guidelines",
      door:"Door",
      opp_industary:"Industries",
      opp_target:"Target Groups",
      filter_search_by:"Filter search by",
      do_you_want_to_fill:"Would you like to provide us with your personal information?",
      news_item:"News",
      filter_btn:"Filter",
      clear_btn:"Clear",
      biban_map_1:"Biban Map",
      download:"Download",
      app_download_btn:"Download BIBAN app on",
      discover_biban_1:"Decide your journey",
      live_ewc:"EWC",
      live_main_stage:"Main Stage",
      sorry_no_streaming_now:"Sorry there is no streaming now",
      sorry_something_wrong:"Sorry there is somthing wrong please try again later",
      certificate_downloaded_successfully:"certificate downloaded successfully",
  },




    'ar': {
      locationaddress_badge:"الرياض - المملكة العربية السعودية \n واجهة الرياض للمعارض والمؤتمرات \n 9 - 13 مارس 2024 \n 4 - 11 مساءً",
      thank_message_side:"شكرا لكم",
        web_agenda_title:'جدول الاعمال',
      invalid_token_forbadge:'الرابط غير صحيح ، يرجى فتح الرابط مرة أخرى من بريدك الإلكتروني',
      describeyourslefas:'اصنف نفسي كـ',
      ewc_hoempage_second_title:'الأثر المستهدف لكأس العالم لريادة الأعمال',
      welcome_to_registrate_visitor:'مرحبا بك في التسجيل في بيبان 2024',
      role_is_required:"الرجاء إدخال المنصب ",
      twitter_is_required:"الرجاء إدخال اسم التويتر ",
      facebook_is_required:"الرجاء إدخال اسم الفيسبوك ",
      company_name_is_required:"الرجاء إدخال اسم الشركة",
      address_is_required:"الرجاء إدخال العنوان",
      job_tilte_is_required:"الرجاء إدخال المسمى الوظيفي",
      title_is_required:'الرجاء إختيار اللقب',
      first_name_is_required:'الرجاء إدخال الإسم الأول',
      last_name_is_required:'الرجاء إدخال الإسم الثاني',
      gender_is_required:'الرجاء إختيار النوع',
      email_is_required:'الرجاء إدخال البريد الإلكتروني',
      nationality_is_required:'الرجاء إختيار الجنسية',
      countryofresidence_is_required:'الرجاء إختيار بلد الإقامة',
      category_is_required:'الرجاء إختيار الفئة',
      legal_name_is_required:'الرجاء إدخال الإسم القانوني',
      contactname_is_required:'الرجاء إدخال إسم المسؤول',
      c_coming_from_is_required:"الرجاء إدخال البلد القادم منه",
      personal_image_is_required:'الرجاء تحميل الصورة الشخصية',
      passport_image_is_required:'الرجاء تحميل صورة جواز السفر',
      headquarter_is_required:'الرجاء إدخال بلد المقر الرئيسي',
      full_name_is_required:"الرجاء إدخال الإسم الكامل",
      place_of_birth_is_required:"الرجاء إدخال مكان الولادة",
      date_of_birth_is_required:"الرجاء إدخال تاريخ الولادة",
      passport_type_is_required:"الرجاء إدخال نوع جواز السفر",
      passport_expiry_is_required:"الرجاء إدخال تاريخ إنتهاء جواز السفر",
      qualification_is_required:"الرجاء إدخال المؤهلات العلمية",
      work_palce_is_required:"الرجاء إدخال مكان العمل",
      personalphoto:"الصورة الشخصية",
      welcome: 'asd مرحبا',
      privacy_inforamtion:'هذه المعلومات سرية وتستخدم فقط للتسجيل في بيبان 2024 ',
      all_right_1:'بتنظيم من',
      all_right_2:'الهيئة العامة للمنشآت الصغيرة والمتوسطة "منشآت"',
      lang:'AR',
      welcome_to_registrate:'مرحباً بك في التسجيل بيبان 2024',
      step1:'الخطوة الأولى',
      coming_soon:'إنتظرونا قريباً',
      welcome_text:'في "بيبان" الوجهة الأولى لقطاع المنشآت الصغيرة والمتوسطة ورواد الأعمال، والذي تنظمه الهيئة العامة للمنشآت الصغيرة والمتوسطة "منشآت"، حيث يلتقي رواد الأعمال على مدار 5 أيام، بتجارب ثريّة تسلّط الضوء على الوعي المجتمعي بأهمية نمو الاقتصاد المحلي لتشجيع الشباب على الفرص الاستثمارية، وتسهيل عمل المنشآت وخلق بيئة تنافسية بينهم، ورفع المساهمة في قطاع الأعمال تحقيقاً لأهداف رؤية المملكة 2030.',
      You_will_find_it_in_Biban:'ستجدون في بيبان ',
      Training_workshops:'ورش تدريبية',
      Global_speakers:'متحدثين محليين وعالميين',
      Specialized_experts:'خبراء مختصيّن',
      Promising_investment_opportunities:'فرص استثمارية واعدة',
      Competitive_business_environment:'بيئة أعمال تنافسية',
      Educational_initiatives:'مبادرات تثقيفية',
      Project_financing_opportunities:'فرص لتمويل المشاريع',
      month:'أشهر',
      days:'أيام',
      hours:'ساعات',
      basic_registration:'المعلومات الأساسية',
      legal_name:'الاسم القانوني (اسم الشركة)',
      website:'الموقع الإلكتروني',
      email:'البريد الإلكتروني',
      nationality: 'الجنسية',
      select_nationality:' الرجاء إختيار الجنسية',
      cr_number:'السجل التجاري',
      cr_numbergover:'رمز الجهة',
      logo:'شعار',
      image_text:'الرجاء النقر لإضافة صورة, تنسيقات الملفات المدعومة PNG JPG ',
      industry:'المجال',
      remeber_me_late:'للتذكير بموعد التسجيل يرجى إدخال البريد الإلكتروني',
      reminde_me:'ذكرني',
      select_industry:'الرجاء إختيار المجال',
      company_investment_phase:'مرحلة استثمار الشركة',
      select_key_investment:'الرجاء اختيار حجم الإستثمار',
      objective_key_question:'ما هي أهدافك الرئيسية للحضور / المشاركة في بيبان؟',
      select_key_objective:'الرجاء إختيار الهدف',
      please_enter_your_objective:'الرجاء إدخال الهدف',
      step2:'الخطوة الثانية',
      owner_name:'اسم مسؤول التواصل',
      contact_info:'معلومات التواصل',
      phone:'الهاتف',
      step3:'الخطوة الثالثة',
      team_member:'أعضاء الفريق',
      job:'المنصب',
      add_member:'إضافة عارض جديد',
      name:'الاسم',
      add:'إضافة',
      cancel:'إلغاء',
      registration_completed:'تم استلام المعلومات',
      registration_failed:"لقد فشلت عملية التسجيل",
      see_you_at_biban:'نراك في بيبان 2024',
      title:'اللقب',
      first_name:'الاسم الأول',
      last_name:'اسم العائلة',
      personal_photo:'الصورة الشخصية',
      id_pass_iqama:'رقم الهوية \ رقم جواز السفر \رقم الإقامة',
      bio:'نبذة تعريفية',
      select_country_of:'الرجاء إختيار بلد الإقامة',
      country_of: 'بلد الإقامة',
      email_address:'البريد الإلكتروني',
      socail_media:'وسائل التواصل الإجتماعي',
      job_role:'المنصب',
      career_info:'المعلومات المهنية',
      do_you_have:'هل لديك تأشير صالحة؟',
      yes:' نعم',
      no:'كلا',
      okay:'حسناً',
      popup_sending_toallstaff :'سيتم إرسال دعوات لجميع أعضاء الفريق المضافين، نأمل منكم المتابعة معهم لإستكمال تسجيلهم.',
      have_another_nationality:'هل لديك جنسية اخرى',
      do_you_want:'هل ترغب في قيام منشآت بإصدار تأشيرة لك؟',
      are_you_gcc:'هل أنت مقيم في دول الخليج؟',
      visa_requirement:'متطلبات التأشيرة',
      step4:'الخطوة الرابعة',
      passport_type:'نوع جواز السفر',
      issuance_country:'بلد الإصدار',
      pass_issue_date:'تاريخ الإصدار',
      date_of_birth:'تاريخ الميلاد',
      place_of_birth:'مكان الولادة',
      place_of_issue:'مكان الإصدار',
      pass_expire_date:'تاريخ إنتهاء جواز السفر',
      religion:'الديانة',
      gender:'الجنس',
      image_condition:'يجب أن تكون الصورة لها خلفية بيضاء وبالزي الرسمي ',
      recant_nationality:'الجنسية الحالية',
      prev_nationality:'الجنسية الاخرى',
      social_situation:'الحالة الإجتماعية',
      single:'أعزب',
      married:'متزوج',
      divorced:'مطلق',
      address:'العنوان',
      expected_entry_date:'التاريخ المتوقع لدخول المملكة',
      entry_number:'عدد مرات الدخول',
      length_of_stay:'مدة الإقامة',
      contact_point:'جهة الوصل',
      umrah_option:'‏هل ترغب في أداء مناسك العمرة؟',
      have_you_travel:'هل سبق لك السفر إلى أي دولة أخرى في الخمس سنوات الماضية؟',
      contact_name:'اسم الشخص المسؤول',
      country:'البلد',
      company_name:'إسم الشركة',
      id_number:'رقم الهوية',
      passport_number:'رقم الجواز',
      iqama:'رقم الإقامة',
      enter_role:'الرجاء إدخال المنصب',
      enter_industary:'الرجاء إدخال المجال',
      as_exh:'كشركة عارضة',
      as_media:'كجهة إعلامية',
      as_speaker:' كمتحدث',
      proccessing_reg:'طلبك تحت المتابعة سوف نتواصل معك قريباً',
      as_exh_staff:'كعضو للشركة العارضة',
      as_guest:'كضيف',
      as_media_staff:'كعضو لوسيلة الإعلام',
      loading:'جاري التحميل الرجاء الإنتظار',
      role:'المنصب',
      link_expire:'لفد إنتهت صلاحية الرابط أو الرابط غير صحيح',
      official_email:'البريد الإلكتروني الرسمي',
      as_vip:'كشخصية مهمة',

      category:'الفئة',
      phone_required:"الرجاء إدخل رقم الهاتف",
      phone_not_valid:"رقم الهاتف غير صحيح",
      as_vip_staff:"كمرافق لشخصية مهمة",
      country_comming_from:"البلد القادم منه",

      duplicated_email:'لا يمكنك تكرار البريد الالكتروني',
      minutes:'دقائق',
      email_is_success:"لقد تم إرسال البريد الإلكتروني بنجاح",
      emai_is_uniqe:"لقد قمت بالستجيل من قبل",
      part_of_participation:"مجال المشاركة",
      headquarter_country:'بلد المقر الرئيسي',
      toke_not_valid:'الرمز غير صالح الرجاء المحاولة لاحقاً',

      dose_your_brand:"هل يوجد لدى علامتكم التجارية اتفاقيات امتياز محتملة؟",
      filed_is_required:"الرجاء إدخال المعلومات",
      beneficiaries:"عدد المستفدين",
      beneficiaries_from_guidance:"مستفيد من جلسات الاستشارات والإرشاد",
      about_biban:"عن بيبان",
      footer_par_biban_p1:"يُعد بيبان من أهم أدوات التطور المتسارع الذي تشهده المملكة العربية السعودية في قطاع المنشآت الصغيرة والمتوسطة وريادة الأعمال، وساهم بخلق بيئة محفزة بالشراكة مع جميع الجهات الممكنة والداعمة من القطاعين العام والخاص.",
      about_biban_p1:"يُعد بيبان من أهم أدوات التطور المتسارع الذي تشهده المملكة العربية السعودية في قطاع المنشآت الصغيرة والمتوسطة وريادة الأعمال، وساهم بخلق بيئة محفزة بالشراكة مع جميع الجهات الممكنة والداعمة من القطاعين العام والخاص؛ أثمرت بتقدم المملكة في تقرير مرصد ريادة الأعمال الدولي من المرتبة الواحدة والأربعون في عام 2018 إلى المرتبة الرابعة في عام 2022، وجاءت هذه القفزة النوعية بما يتوافق مع رؤية السعودية 2030 التي أولت اهتماماً كبيراً لهذا القطاع الحيوي المهم.",
      about_biban_p2:"وتعزيزًا لمكانة المملكة كوجهة وخيار أول لرواد الأعمال إقليمياً ودولياً؛ تنطلق فعالية بيبان من المملكة إلى العالم لتعزيز التواصل بين أصحاب المنشآت الصغيرة والمتوسطة ورواد الأعمال من جميع أنحاء العالم، وحثهم على تبادل الأفكار المبتكرة والخبرات، وأفضل الحلول لمواجهة التحديات.",
      about_biban_p3:"ويحتضن بيبان الجهات الُممكنة والداعمة من مختلف أنحاء العالم لتسهيل وصول أصحاب المنشآت الصغيرة والمتوسطة ورواد الأعمال إلى خدماتهم ومبادراتهم والاستفادة منها لتعزيز نمو أعمالهم، وتحفيزهم على تبني ثقافة الابتكار للخروج بأفكار ريادية ذات مردود إيجابي على الاقتصاد المحلي والدولي، مع ربط أصحاب الأفكار ورواد الأعمال مع المستثمرين والجهات التمويلية.",
      download_pdf_link:"للمزيد اضغط لتحميل ملف بيبان",
      about_sbutitle_1:"القيمة المضافة لفعالية بيبان",
      about_sbutitle_2:"حوافز إقليمية ومحلية لريادة الأعمال",
      about_sbutitle_3:"مسابقات عالمية",
      about_sbutitle_4:"رفع الوعي بأهمية منطقة الخليج والعالم العربي",
      about_p1:"بناء شبكة ريادية إقليمية وعالمية تعزيز التواصل بين المستثمرين ورواد الأعمال والمُمكنين محلياً وإقليمياً وعالمياً واستضافة أهم وأبرز المتحدثينورواد الأعمال، وجذب أكبر المستثمرين في المنطقة والعالم.",
      about_p2:"دعم الشركات الدولية الناشئة: مناطق خاصة، دعم اقتصادي، وباقات استضافة للمشاريع الريادية )دعم مالي ولوجستي ودعم في البحث والتطوير(.",
      about_p3:"جوائز دولية للشركات الناشئة القائمة على الابتكار - التصفيات النهائية لكأس العالم لريادة الأعمال )EWC(.",
      about_p4:"الدخول لأسواق كبيرة وواعدة، فرص استثمارية ونمو في رأس المال الجريء، وفرص تجارية ومشاريع عملاقة.",
      door_name_1:"باب الإنطلاقة",
      door_desc_1:"رحلة بدأ الأعمال",
      door_name_2:"باب النمووالإستدامة",
      door_desc_2:"تطوير وإزدهار الأعمال",
      door_name_3:"باب التمكين والفرص",
      door_desc_3:"جهات داعمة وممكنة",
      door_name_4:"باب الإستثمار والحلول التمويلية",
      door_desc_4:"فرص وحلول",
      door_name_5:"باب السوق",
      door_desc_5:"خدمات ومنتجات",
      door_name_6:"باب التجارة الإلكترونية",
      door_desc_6:"تقنيات الأعمال الرقمية",
      door_name_7:"باب الإبتكار",
      door_desc_7:"الإبداع والتطوير",
      door_name_8:"باب الإمتياز التجاري",
      door_desc_8:"توسع العلامة التجارية",
      door_name_9:"باب الشركات الناشئة",
      door_desc_9:"مشاريع ريادية واعدة",
      door_name_10:"جناح منشآت",
      titletext:"الخميس 9 مارس حتى 13 مارس",
      text2:"في واجهة الرياض- إكسبو،",
      entrepreneurship_environment:"بيئة ريادة الأعمال في المملكة العربية السعودية",
      entrepreneurship_text:"ترتكز البيئة المحفزة لريادة الأعمال في المملكة العربية السعودية على تصميم برامج وخدمات ومبادرات تعزز نمو مشاريع رواد الأعمال وقدرتها التنافسية، وتحفيز ثقافة ريادة الأعمال عبر دعم رواد الأعمال الطموحين لتحقيق زيادة في معدلات تأسيس الشركات الجديدة، إلى جانب التركيز على نشر ثقافة الابتكار وجذب أفكار ريادية ذات مردود إيجابي على الاقتصاد المحلي والدولي.",
      facts:"حقائق",
      facts_title:"المملكة الأولى عالمياً في المؤشرات التالية:",
      facts_1:"سهولة البدء في العمل التجاري.",
      facts_2:"الفرص الواعدة لبداية المشروع في منطقتي.",
      facts_3:"امتلاك المعرفة والمهارات الشخصية للبدء في الأعمال.",
      economic_summary:"نبذة اقتصادية",
      economic_summary_1:"استثمرت حكومة المملكة حوالي 70 مليار دولار لتطوير مدن اقتصادية وذكية.",
      economic_summary_2:"بلغت صافي تدفقات الاستثمار الأجنبي المباشر 7.854 مليار حتى نهاية الربع الثاني من 2022",
      economic_summary_3:"بإجمالي وصل إلى 10,32 مليار دولار أمريكي وحصة 65.6٪، حققت التجارة الإلكترونية أعلى مستوى للإيرادات الرقمية في عام 2021.",
      economic_summary_4:"من المتوقع أن تحقق إيرادات التجارة الإلكترونية متوسط نمو بنسبة 18.6٪ بحلول عام 2025.",
      economic_summary_5:"من المتوقع أن ينمو إجمالي قيمة المعاملات بالتقنية المالية بنسبة 100٪ خلال الفترة 2021 - 2025.",
      plz_try_again:"الرجاء إعادة المحاولة لاحقاً",
      invalid_token:"عذراً هذا الرمز غير صحيح او منتهي الصلاحية",
      at_least_one_member:"يجب إضافة شخص واحد على الأقل",
      some_email_are_duplicated:"البريد الإلكتروني متكرر",
      ar_legal_name:'الاسم القانوني بالعربي (اسم الشركة)',
      max_team_memebr:"عدد الأشخاص العارضين",
      male:"ذكر",
      female:"أنثى",
      fostering_tangible_opportunities:"وجهة عالمية للفرص",
      biban_23:" بيبان 2024",
      biban_add_value:"القيمة المضافة لفعالية بيبان",
      build_network:"",
      build_network_p:"بناء شبكة ريادية إقليمية وعالمية لتعزيز التواصل بين المستثمرين ورواد الأعمال والمُمكنين محلياً وإقليمياً وعالمياً واستضافة أهم وأبرز المتحدثين ورواد الأعمال، وجذب أكبر المستثمرين في المنطقة والعالم.",
      build_a_regional:"حوافز إقليمية ومحلية لريادة الأعمال",
      build_a_regional_p:"دعم الشركات الدولية الناشئة: مناطق خاصة، دعم اقتصادي، وباقات استضافة للمشاريع الريادية (دعم مالي ولوجستي ودعم في البحث والتطوير).",
      inter_com:"مسابقات عالمية",
      inter_com_p:"جوائز دولية للشركات الناشئة القائمة على الابتكار - التصفيات النهائية لكأس العالم لريادة الأعمال (EWC).",
      increase_awar:"رفع الوعي بأهمية منطقة الخليج والعالم العربي",
      increase_awar_p:"الدخول لأسواق كبيرة وواعدة، فرص استثمارية ونمو في رأس المال الجريء، وفرص تجارية ومشاريع عملاقة.",
      biban_in_number:" بيبان في أرقام",
      number_p2:"مشاركة من علامات تجارية للامتياز محلية وعالمية",
      number_p3:"عارضاً من رواد الأعمال المحليين و العالميين",
      number_p4:"مستفيد من أكثر من 300 ورشة عمل",
      number_p5:"مستفيد من جلسات الاستشارات و الارشاد",
      number_p6:"مشاركاً من رواد الأعمال في بيبان Talks",
      number_p7:"جهة ممكنة",
      number_p8:"متحدث عالمي ومحلي في المؤتمر",
      biban_doors:"أبواب بيبان",
      door_n_1:"باب الانطلاقة",
      door_d_1:"رحلة بدء الأعمال",
      door_n_2:"باب التمكين والفرص",
      door_d_2:"جهات داعمة وممكنة",
      door_n_3:"باب الابتكار",
      door_d_3:"الابداع والتطوير",
      door_n_4:"باب النمو",
      door_d_4:"تطوير وازدهار الأعمال",
      door_n_5:"باب التجارة الالكترونية",
      door_d_5:"تقنيات الأعمال الرقمية",
      door_n_6:"باب الاستثمار والتمويل",
      door_d_6:"فرص و حلول",
      door_n_7:"باب السوق",
      door_d_7:"خدمات و منتجات",
      door_n_8:"باب الامتياز التجاري",
      door_d_8:"توسع العلامات التجارية",
      door_n_9:"باب الشراكات الناشئة",
      door_d_9:"مشاريع ريادية واعدة",
      ewc_title:"كأس العالم لريادة الأعمال",
      ewc_p1:"تُعد مسابقة كأس العالم لريادة الأعمال أول وأكبر بطولة من نوعها على مستوى العالم، حيث يشارك فيها رواد أعمال ينتمون إلى أكثر من 200 دولة.",
      ewc_p2:"تستضيف المملكة العربية السعودية التصفيات النهائية للمسابقة والحفل الختامي وتوزيع الجوائز بمشاركة أفضل 25 رائد ورائدة أعمال سيتأهلون للنهائيات في الرياض ضمن فعاليات بيبان 2024",
      web_title:"بيبان 2024 وجهة عالمية للفرص",
      brief:"نبذة تعريفية ",
      ex_person:"العارض",
      download_app:"حمل التطبيق",
      contact_us:"تواصل معنا",
      doors:"الأبواب",
      sponsers:"الرعاة",
      live_streaming:"البث المباشر",
      exhibitors:"العارضون",
      calendar:"البرنامج",
      error_in_felids:"هنالك بعض الخانات الفارغة او التي تحتوي على معلومات خاطئة",
      legal_name_req:"الرجاء إدخال الايم القانوني",
      logo_is_req:"الرجاء إدخال اللوغو",
      official_email_is_req:"الرجاء إدخال البريد الإلكتروني",
      cr_10:"الرمز خاطئ يجب أن يتكون من عشرة أرقام",
      industary_is_req:"الرجاء إدخال المجال",
      iqama_10:"رقم الإقامة خاطئ يجب أن يتكون من عشرة أرقام",
      id_10:"رقم الهوية خاطئ يجب أن يتكون من عشرة أرقام",
      close:"إغلاق",
      photo_specs:"مواصفات الصورة",
      photo_specs_title:"مواصفات صورة جواز السفر السعودي هي كالتالي:",
      photo_specs_li_1: " حجم الصورة المطلوب: 4.5 سم في الارتفاع و 3.5 سم في العرض",
      photo_specs_li_2: " الصور الملونة ضرورية. ليس أبيض وأسود.",
      photo_specs_li_3: " وضع الرأس: حافظ على رأسك مستقيماً ووجهك للأمام",
      photo_specs_li_4: " يجب ألا يزيد عمره عن 6 أشهر",
      photo_specs_li_5: " الخلفية: أبيض",
      photo_specs_li_6: " ابتسم: لا",
      photo_specs_li_7: " العيون: مرئية بوضوح",
      photo_specs_li_8: " تم رفض الصور الباهتة",
      photo_specs_li_9: " النظارات: مقبولة في ظل ظروف معينة",
      photo_specs_li_10:" أغطية الرأس: مسموح بها للأغراض الدينية",
      photo_specs_li_11:" الدقة (بكسل): 600 نقطة في البوصة على الأقل",
      photo_specs_li_12:" قواعد اللباس: يجب أن تكون الملابس متناقضة مع الخلفية. لا ترتدي ملابس بيضاء",
      photo_specs_li_13:" اللحية: مسموح بها إذا كانت تمثل شبهك اليومي",
      cr_5:"رمز الجهة غير صالح ، يجب أن يتكون من 5 أرقام أو حروف",
      linked_in:"لينكدإن",
      twitter:"تويتر",
      facebook:"فيسبوك",
      instagram:"إنستاغرام",
      snapchat:"سناب شات",
      phone_is_required:"الرجاء إدخال رقم الهاتف",
      missing_data:"بعض المعلومات ناقصة! الرجاء إستخدام الرابط المرسل في البريد الإلكتروني",
      more_about_biban:"للمزيد عن بيبان",
      d_1:"الأحد",
      d_2:"الاثنين",
      d_3:"الثلاثاء",
      d_4:"الأربعاء",
      d_5:"الخميس",
      d_6:"الجمعة",
      d_7:"السبت",
      m_1: "كانون الثاني",
      m_2: "شباط",
      m_3: "مارس",
      m_4: "نيسان",
      m_5: "أيار",
      m_6: "حزيرات",
      m_7: "تموز",
      m_8: "آب",
      m_9: "أيلول",
      m_10:"تشرين أول",
      m_11:"تشرين ثاني",
      m_12:"كانون أول",
      local_speakers:"المتحدثون المحليون",
      international_speakers:"المتحدثون العالمين",
      sponser:"الراعي",
      your_question:"سؤالك ...",
      send_msg:"أرسل رسالة",
      send_msg_new_contact:"أرسل",
      call_center:"مركز الاتصال",
      speakers:"المتحدثون",
      time_of_event:"الخميس 9 مارس 2024 - الإثنين 13 مارس 2024 ",
      location_of_event:"واجهة الرياض  للمعارض والمؤتمرات",
      welcome_phrase:"وجهة عالمية للفرص",
      registration_for_biban:"التسجيل لفعالية بيبان",
      download_biban_app_for_android:"حمل تطبيق بيبان للأندرويد",
      download_biban_app_for_ios:"حمل تطبيق بيبان للأيفون",
      biban_map:"خريطة فعالية بيان",
      see_more:"إنقر للمزيد",
      more_local_speakers:"المزيد من المتحدثين المحلين",
      more_international_speakers:"المزيد من المتحدثين العالمين",
      more_doors:"المزيد من الأبواب",
      more_exhibitors:"المزيد من العارضين",
      text_number_2:"مستفيد من أكثر من 255 ورشة عمل",
      text_number_6:"عارضاً من رواد الأعمال المحليين والعالميين",
      about_home_title:" بيبان..  وجهة عالمية للفرص",
      about_home_1:"أهلًا بكم في بيبان 2024 التي تلبي طموحاتكم وتواكب تطلعاتكم عبر تعزيز التواصل بين أصحاب المنشآت الصغيرة والمتوسطة ورواد الأعمال من جميع أنحاء العالم لحثهم على تبادل الأفكار المبتكرة والخبرات، وتقديم أفضل الحلول لمواجهة التحديات.",
      about_home_2:"وتجمع الفعالية جميع الجهات المُمكنة والداعمة من مختلف أنحاء العالم لتحفيزهم على تقديم خدماتهم المتخصصة في تعزيز نمو وازدهار قطاع المنشآت الصغيرة والمتوسطة وريادة الأعمال، كما تجمع مستثمرين وجهات تمويلية تحت سقف واحد لتسهيل الوصول إلى التمويل المناسب لأصحاب المنشآت الصغيرة والمتوسطة ومشاريع رواد الأعمال القائمة.",
      about_home_4:"وتثري بيبان 2024 قدرات أصحاب المنشآت الصغيرة والمتوسطة ورواد الأعمال وتصقل مهاراتهم عبر منظومة برامج متخصصة في رفع الكفاءات الإدارية والمالية والفنية، إلى جانب تحفيز أصحاب الأفكار على إطلاق مشاريعهم، وتوفير بيئة محفزة تتيح لرواد الأعمال الاطلاع على آخر المستجدات عالمياً مع حثهم على تبني التقنية وثقافة الابتكار لجذب أفكار ريادية ذات مردود إيجابي على الاقتصاد المحلي والدولي.",
      about_home_5:"كن جزءً من هذا الحدث الذي يوفر فرصة كبيرة للتعرف على خطوات إطلاق مشروع تجاري وتطويره وإدارته من جميع النواحي وفق أفضل الممارسات العالمية، والاستفادة من تجارب واستشارات خبراء محليين ودوليين لتحسين نمو وكفاءة الأعمال.",
      partners:"الشركاء",
      leading_businesses:"كأس العالم لريادة الأعمال",
      leading_dictionary:"المعجم الريادي",
      usage_policy:"سياسة الإستخدام",
      ewc_title_2:"الأثر المستهدف لكأس العالم لريادة الأعمال",
      ewc_list_1:"استقطاب المشاريع الابتكارية الريادية التي تخدم توجهات المملكة.",
      ewc_list_2:"تحفيز الاستثمار المحلي في المملكة، وجذب الاستثمارات الأجنبية.",
      ewc_list_3:"تسليط الضوء على الفرص الواعدة المتاحة في المملكة.",
      ewc_list_4:"إشراك المشاريع الريادية من شتى أنحاء العالم في حل التحديات الوطنية.",
      ewc_list_5:"تمكين الشركاء والجهات الداعمة من تحقيق أهدافهم.",
      ewc_list_6:"الحصول على أكثر من 25,000 دولار في شكل حوافز وخدمات من شركاء المسابقة.",
      message_sent:"لقد تم إرسال رسالتك بنجاح",
      message_not_sent:"لقد فشل إرسال الرسالة الرجاء إعادة المحاولة لاحقاً",
      mobile_phone:"رقم الجوال",
      telephone:"رقم الهاتف الأرضي",
      qualification:"المؤهل العلمي",
      work_place:"مكان العمل",
      job_title:"المسمى الوظيفي",
      do_you_have_facebook:"هل لديك حساب فيسبوك",
      do_you_have_twitter:"هل لديك حساب تويتر",
      search:"بحث",
      invalid_email:"البريد الإلكتروني غير صحيح",
      duplicated_phone:'رقم الهاتف مكرر',
      muslim:"مسلم",
      not_muslim:"غير مسلم",
      high_resolution:"الرجاء تحميل الصورة بجودة عالية",
      add_new_companion:"إضافة مرافق جديد",
      add_new_member:"إضافة شخص للفريق",
      companion:"الأشخاص المرافقون",
      id_10_digit:"رقم الهوية يجب أن يكون 10 أرفام ويبدأ بالرقم 1",
      id_20_digit:"الرجاء ادخال رقم الاقامة الصحيح",
      id_25_digit:"الرجاء إدخال رقم جواز السفر الصحيح",
      enter_only_number:"يجب أن تدخل أرقاماً فقط",
      message:"الرسالة",
      more:"للمزيد",
      home:"الرئيسية",
      visitor_thank:"شكراً لتسجيلكم في بيبان 2024",
      badge_sending:"لقد تم إرسال البطاقة الرقمية لبريدك الإلكتروني.",
      regards:"مع بالغ الشكر والتقدير.",
      name:"الاسم",
      email_confirm:"تأكيد البريد الإلكتروني",
      badge_email:"سيتم إرسال بطاقة الحضور إلى البريد المدخل",
      full_name_as_id:"الاسم الكامل كما في الهوية الوطنية",
      full_name_as_iqama:"الاسم الكامل كما في بطاقة الإقامة",
      full_name_as_passport:'الإسم الكامل كما في جواز السفر',
      passportphoto:"صورة من جواز السفر",
      IDphoto:"صورة من الهوية الوطنية",
      iqamaphoto:"صورة من بطاقة الإقامة",
      photo_is_required:"الرجاء تحميل الصورة",
      full_name:"الاسم الكامل",
      invalid_id:"الرجاء إدخال رقم الهوية الصحيح",
      email_hint_1:"الرجاء عدم إستخدام البريد الإلكتروني لجهة عملك.",
      email_hint_1_1:"الرجاء استخدام البريد الالكتروني الشخصي.",
      why:"لماذا؟",
      email_hint_2:"للحصول على البطاقة الرقمية.",
      email_hint_2_2:"لتأكيد الحصول على البطاقة الرقمية.",
      email_dose_not_match:"البريد الإلكتروني غير متطابق",
      name_as_passport:"يرجى إدخال الاسم المطابق لجواز السفر.",
      flight_ticket:"تذكرة الطيران",
      choose_option:"يرجى اختيار أحد الخيارات أو تزويدنا بالتاريخ والوقت والوجهة التي تفضلها في مربع النص أدناه",
      departing_flight:"المغادرة",
      returning_flight:"العودة",
      from:"من",
      to:"إلى",
      class:"الفئة",
      dear_guest:"عزيزي الضيف،",
      wirte_your_message:"يرجى كتابة رسالتك في النص أدناه",
      your_message:"رسالتك",
      other:"أخرى",
      submit:"إرسال",
      choosen_option:"شكراً لاختيارك الخيار",
      receiving_ticket:"سوف تتلقى تذكرتك قريباً",
      thank_message:"شكراً لرسالتك",
      agent_call:"سيتصل بك وكيلنا قريباً",
      must_select:"الرجاء إختيار الخيار الذي يناسبك",
      option:"الخيار",
      next:"التالي",
      back:"رجوع",
      media_company:"الجهة الإعلامية",
      media_company_is_required:"الرجاء إدخال الجهة الإعلامية",
      country_code:"رمز الدولة",
      phone_number:"رقم الهاتف",
      download_badge:"تحميل البطاقة",
      share:"مشاركة",
      your_selected_option:"لقد قمت بالإختيار سابقاً",
      trip_list:"قائمة الرحلات",
      trip_choose_option:"فضلاً اختيار جميع الفعاليات التي ترغب بحضورها",
      trip:"الرحلة",
      par:"الشركاء الرئيسيين",
      all_partners:"جميع الشركاء",
      message_cannot_be_empty:"الرجاء إدخال نص الرسالة",
      add_to_calendar:"إضافة إلى التقويم",
      no_trips:"عذراً جميع الرحلات ممتلئة الرجاء المحاولة لاحقاً",
      id_expiry_date:"تاريخ إنتهاء الهوية",
      iqama_expiry_date:"تاريخ إنتهاء الإقامة",
      id_expiry_is_required:"الرجاء إدخال تاريخ إنتهاء الهوية",
      iqama_expiry_is_required:"الرجاء إدخال تاريخ إنتهاء الإ‘قامة",
      job_title_is_required:"الرجاء إدخال المهنة",
      work_place_is_required:"الرجاء إدخال مكان العمل",
      faq:"الأسئلة الشائعة",
      sorry_no_result:"عذراً لا توجد نتائج",
      visit_saudi:"زيارة السعودية",
      first_day:"اليوم الأول",
      second_day:"اليوم الثاني",
      third_day:"اليوم الثالث",
      fourth_day:"اليوم الرابع",
      fifth_day:"اليوم الخامس",
      march:"مارس",
      enablers:"الجهات الممكنة",
      activites:"الأنشطة",
      general_event:"الفعاليات العامة",
      faq:"الأسئلة الشائعة",
      oppurtuinites:"الفرص",
      supporting_centers:"مركز دعم المنشآت",
      monshaat:"عن منشآت",
      workshops:"ورش العمل",
      media_center:"المركز الإعلامي",
      training:"التدريب",
      follow_us:"تابعنا",
      no_session:"لا يوجد جلسات حالياً الرجاء المحاولة لاحقاً",
      session:"جلسة",
      topics:"المحاور",
      opportunities:"الفرص",
      kind:"النوع",
      more_details:"للمزيد من المعلومات",
      conditions:"الشروط",
      all:"الجميع",
      local:"محلي",
      international:"دولي",
      sorry_no_result:"عذراً لا توجد نتائج",
      calling_numbers:"أرقام الإتصال",
      inside_ksa:"من داخل المملكة",
      outside_ksa:"من خارج المملكة",
      register_now:'سجل الآن',
    registrate_now:"سجل",
      filiter_by_industary:"تصفية حسب المجال",
      tag_lang:"عربي",
      about_biban_page_title:"نبذة عن أبرز برامج وخدمات ومبادرات \"منشآت\"",
      about_biban_page_description:"نعمل باستمرار على تصميم برامج وخدمات ومبادرات متخصصة تلبي احتياجات المنشآت المتناهية الصغر والصغيرة والمتوسطة بجميع مراحلها وأنشطتها الاقتصادية، وأخرى تحفز ثقافة ريادة الأعمال، وتدعم رواد الأعمال الطموحين لتحقيق زيادة في معدلات تأسيس الشركات الجديدة، ومنها على سبيل المثال لا الحصر",
      flight_opt_1:"جدول الرحلات المتاحة:",
      flight_opt_2:"في حال وجود أي ملاحظات، يرجى ذكرها في خانة  'أخرى' وسيتم التواصل معك.",
      media_center_title:"المركز الإعلامي لملتقى بيبان 2024",
      news:"الأخبار",
      hour:"ساعة",
      workshop:"ورشة عمل",
      seminars:"ندوات",
      seminar:"ندوة",
      send:"أرسل",

      smePage:{
        mainTitle:"مركز دعم المنشآت",
        firstSectionText:'منطقة تقدم خدمتي الاستشارات والإرشاد الدولية والمحلية العامة والتخصصية مع خبراء ورجال الأعمال حيث توفر المنطقة:',
        firstSectionBoxTitle1:"630",
        firstSectionBoxText1:"جلسة في دعم الأعمال",
        firstSectionBoxTitle2:"2100",
        firstSectionBoxText2:"جلسة في الاستشارات ",
        firstSectionBoxTitle3:"630",
        firstSectionBoxText3:"جلسة في الإرشاد ",

        secondSectionTitle:"الخدمة",
        secondSectionBox1Title:"دعم الأعمال ",
        secondSectionBox1Definition:" يتم من خلال دعم الأعمال دراسة احتياج العميل ومن ثم تحويله بناءً عليها إلى خدمات المراكز المباشرة أو الخدمات الداعمة الأخرى ",
        secondSectionBox2Title:"الاستشارات",
        secondSectionBox2Definition:"جلسات ثنائية يومية مع خبراء استشاريين متخصصين من ذوي الكفاءة العلمية والخبرة التجارية لتقديم الاستشارات والإجابة على استفسارات رواد الأعمال وأصحاب المنشآت الصغيرة والمتوسطة",
        secondSectionBox3Title:"إرشاد",
        secondSectionBox3Definition:"جلسة ثنائية لنقل التجربة الناجحة والخبرات من رجال الأعمال الناجحين إلى المستفيدين في نفس المجال التجاري، وتقديم الدعم والتوجيه المهني والشخصي والفني على المدى القصير والبعيد",

        section3Title: "آلية حجز المواعيد",
        section3Step1Title:"مسح الباركود الموجود على الشاشات أو المكعبات  ",
        section3Step2Title:"حجز موعد في نفس اليوم",
        section3Step2Subtitle: "(عن طريق الباركود المتواجد على الشاشات بالأوقات المتاحة )",
        section3Step3Title: "رسالة تذكير قبل الموعد ب 15 دقيقة",
        section3Step4Title: "حضور الجلسة قبل موعدها بعشر دقائق لمقابلة الخبير ",

        definition: "تعريفها",
        subjects:"مجالاتها",
      },
      please_select_atleast_one_trip:"الرجاء إختيار رحلة واحدة على الأقل",
      confirm:"تأكيد",
      trip_time_conflict:"عذراً، لا يمكنك إختيار هذه الوقت بسبب تعارض الوقت مع رحلة أخرى",
      i_agree:"أقر بأن عمري لا يقل عن ١٤ سنة.",
      gallery:"المكتبة المرئية",
      guides:"الأدلة",
      selected_side_event:"الفعاليات المختارة",
      monday:"الإثنين",
      tuesday:"الثلاثاء",
      wednesday:"الأربعاء",
      thursday:"الخميس",
      friday:"الجمعة",
      saturday:"السبت",
      sunday:"الأحد",
      discover_biban:"إكتشف بيبان",
      recommendations:"التوصيات",
      you_can_visit:"يمكنك زيارة",
      skip:"تخطى",
      do_you_want_to_stat_again:"هل تريد البدء مرة اخرى؟",
      write_your_answer:"الرجاء كتابك إجابتك هنا",
      survey_basic_info:"المعلومات الأساسية",
      survey_thank:"شكراً لك لتعبئة الإستمارة",
      survey_failed:"عذراً لقد فشل المحاولة الرجاء إعادة المحاولة",
      guidelines:"الأدلة",
      door:"الباب",
      opp_industary:"القطاعات المستهدفة",
      opp_target:"الفئات المستهدفة",
      filter_search_by:"تصفية البحث حسب",
      do_you_want_to_fill:"هل ترغب في تزويدنا بمعلوماتك الشخصية؟",
      news_item:"خبر",
      filter_btn:"تصفية البحث",
      clear_btn:"مسح الكل",
      biban_map_1:"خريطة بيبان",
      download:"تحميل",
      app_download_btn:"حمل تطبيق بيبان على",
      discover_biban_1:"حدد مسار رحلتك",
      live_ewc:"EWC",
      live_main_stage:"المسرح الرئيسي",
      sorry_no_streaming_now:"عذراً لا يوجد بث مباشر الآن",
      sorry_something_wrong:"عذراً هنالك خطأ ما الرجاء المحاولة لاحقاً",
      certificate_downloaded_successfully:"تم تحميل الشهادة بنجاح",
    },
  },
})
export default i18n
