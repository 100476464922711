<template>
<div class="mt100 c mb50">
  <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
    <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
    <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }}  {{ $t('as_guest') }}</div>
    <div class="mt10 l w100">
      <div class="bg-prograss-bar prograss-bar">
        <div class="prograss-bar ov ra w100 g_nm bg_orange"></div>
      </div>
      <div class="mt10 c-blue">{{ $t('step3') }}</div>
      <div class="mt10 b">{{ $t('basic_registration') }} *</div>
        <form class="pv10" @submit.prevent="submit_form">
          <!-- START INDUSTARY INDUSTARY -->
          <!-- <div class="mt15">
            <label class="label_fields">{{ $t('industry') }} *</label>
            <Select2 :disabled="view_only_all == 1" v-model="form.industry_list" :options="industries_list" @change="industry_change"/>
            <div class="validateindustry validation_warning"></div>
          </div> -->
          <!-- CLOSE INDUSTARY -->
          <div class="mt15">
            <label class="label_fields">{{ $t('role') }} *</label>
            <Select2 :disabled="view_only_all == 1" v-model="form.role" :options="role_list" />
            <div class="validaterole validation_warning"></div>
          </div>
          <div class="mt15">
            <label class="label_fields">{{ $t('company_name') }} *</label>
            <input  :disabled="view_only_all == 1" class="input_fields" v-model="form.company_name" v-bind:placeholder="$t('company_name')" type="text"/>
              <div class="validation_company_name validation_warning"></div>
          </div>
          <!-- START FACEBOOK -->
          <div class="mt15">
              <label class="label_fields">{{$t('do_you_have_facebook')}}</label>
              <br>
              <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveFacebook"  value="1">{{ $t('yes') }}
              <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveFacebook" value="0">{{ $t('no') }}
          </div>
          <div v-if="HaveFacebook == '1'" class="mt10">
            <input :disabled="view_only_all == 1" class="sm ph10 input_fields" v-model="form.facebook" v-bind:placeholder="$t('facebook')" type="text" />
            <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue mdi mdi-facebook"></span></div>
              <div class="validate_facebook validation_warning"></div>
          </div>
          <!-- CLOSE FACEBOOK -->
          <!-- START TWITTER -->
          <div class="mt15">
              <label class="label_fields">{{$t('do_you_have_twitter')}}</label>
              <br>
              <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveTwitter"  value="1">{{ $t('yes') }}
              <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveTwitter" value="0">{{ $t('no') }}
              <div class="validateGender validation_warning"></div>
          </div>
          <div v-if="HaveTwitter == '1'" class="mt10">
            <input :disabled="view_only_all == 1" class="sm ph10 input_fields" v-model="form.twitter" v-bind:placeholder="$t('twitter')" type="text" />
            <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue icon-x-twitter"></span></div>
              <div class="validate_twitter validation_warning"></div>
          </div>
          <!-- CLOSE TWITTER -->
          <div class="mt20 r">
            <button v-if="$store.state.is_view_only == 0" @click="submit" class="next_btn ph20 ml20">
              <div v-if="disabled_submit" class="mt10 mb10 ml5 mr5 rotatingLoader" ></div>
              <span v-if="!disabled_submit" class="cw lh40 fs18" >{{$t('next')}}</span>
              <!-- <span v-if="$i18n.locale == 'en' && !disabled_submit" class="cw mdi mdi-arrow-right lh40 fs30"></span> -->
              <!-- <span v-if="$i18n.locale == 'ar' && !disabled_submit" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
            </button>
          </div>
        </form>
      </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'guest3',
  data(){
    return {
      errors:0,
      disabled_submit:false,
      // industries_list:[],
      // industry_other: false,
      role_other: false,
      view_only_all: 0,
      HaveFacebook:0,
      HaveTwitter:0,
      form:{
          role: "",
          company_name: "",
          facebook:'',
          twitter:'',
      }
  }
},
methods:{
  validatedata(formname,validatefieldclass, text) {
    if (formname == "" || formname == null || formname == 0) {
      $(validatefieldclass).html(text);
      $(validatefieldclass).slideDown();
      this.errors = 1;
    }
    else{
      $(validatefieldclass).slideUp();
    }
  },
  submit() {
    if(this.disabled_submit == false){
      $('.next_btn').addClass('disabled_btn');
      this.disabled_submit=true;
      this.errors = 0;
      this.$emit("submit", this.form);
      this.validatedata(this.form.company_name,".validation_company_name", this.$t('company_name_is_required'));
      if(this.HaveFacebook == '1'){
        this.validatedata(this.form.facebook,".validate_facebook", this.$t('facebook_is_required'));
      }
      if(this.HaveTwitter == '1'){
        this.validatedata(this.form.twitter,".validate_twitter", this.$t('twitter_is_required'));
      }
      if (this.form.role == "" || this.form.role == null || this.form.role == undefined) {
          $(".validaterole").html(this.$t('filed_is_required'));
          $(".validaterole").slideDown();
          this.errors = 1;
      } else {
          $(".validaterole").slideUp();
      }
      if(this.errors == 0){
        if(this.$store.state.normal_guest.twitter){}else{
          this.$store.state.normal_guest.twitter=null;
        }
        if(this.$store.state.normal_guest.facebook){}else{
          this.$store.state.normal_guest.facebook=null;
        }
        this.$store.state.normal_guest.role=this.form.role;
        this.$store.state.normal_guest.company_name = this.form.company_name;
        this.$store.state.normal_guest.role=this.form.role;
        this.$store.state.visa.type_of_user ="guest";
        this.$store.state.visa.user_token_key =this.$store.state.normal_guest.token_key;
        this.$store.state.visa.nationality = this.$store.state.normal_guest.nationality;
        this.$store.state.visa.country_of_residence =this.$store.state.normal_guest.country_of_residence,
        this.$store.state.visa.userID =this.$store.state.normal_guest.id;
        this.$store.state.visa.gender =this.$store.state.normal_guest.gender;
        var res = this.$store.state.no_visa_countries.indexOf(this.$store.state.normal_guest.nationality);
        if( this.$store.state.normal_guest.country_of_residence == 194 || res != -1){
          this.axios.post(this.$store.state.baseURL + "update-guest", {
            first_name: this.$store.state.normal_guest.first_name,
            last_name: this.$store.state.normal_guest.last_name,
            full_name: this.$store.state.normal_guest.full_name,
            passport_type: this.$store.state.normal_guest.passport_type,
            passport_expiry_date: this.$store.state.normal_guest.passport_expiry_date,
            place_of_birth: this.$store.state.normal_guest.place_of_birth,
            date_of_birth: this.$store.state.normal_guest.date_of_birth,
            qualification: this.$store.state.normal_guest.qualification,
            work_place: this.$store.state.normal_guest.work_place,
            telephone: this.$store.state.normal_guest.telephone,
            job_title: this.$store.state.normal_guest.job_title,
            address: this.$store.state.normal_guest.address,
            facebook: this.$store.state.normal_guest.facebook,
            twitter: this.$store.state.normal_guest.twitter,
            personal_photo: this.$store.state.normal_guest.personal_photo,
            passport_image: this.$store.state.normal_guest.passport_image,
            title: this.$store.state.normal_guest.title,
            email: this.$store.state.normal_guest.emailaddress,
            nationality: this.$store.state.normal_guest.nationality,
            country_of_residence: this.$store.state.normal_guest.country_of_residence,
            id_pass_iqama: this.$store.state.normal_guest.id_pass_iqama,
            gender: this.$store.state.normal_guest.gender,
            phone: this.$store.state.normal_guest.phonenumber,
            job: this.$store.state.normal_guest.role_list,
            company_name: this.$store.state.normal_guest.company_name,
            // industry: this.$store.state.normal_guest.industry_list,
            token_key: this.$store.state.normal_guest.token_key,
            status:"",
            visa_status: "not required",
            flight_status: "not required",
            hotel_status: "not required",
          }).then((response) => {
            $('.next_btn').removeClass('disabled_btn');
            this.disabled_submit=false;
            this.$router.push({ path: "/registration/thankyou" });
            }).catch((e) => {});
        }
        else{
            this.axios.post(this.$store.state.baseURL + "update-guest", {
              first_name: this.$store.state.normal_guest.first_name,
              last_name: this.$store.state.normal_guest.last_name,
              full_name: this.$store.state.normal_guest.full_name,
              passport_type: this.$store.state.normal_guest.passport_type,
              passport_expiry_date: this.$store.state.normal_guest.passport_expiry_date,
              place_of_birth: this.$store.state.normal_guest.place_of_birth,
              date_of_birth: this.$store.state.normal_guest.date_of_birth,
              qualification: this.$store.state.normal_guest.qualification,
              work_place: this.$store.state.normal_guest.work_place,
              telephone: this.$store.state.normal_guest.telephone,
              job_title: this.$store.state.normal_guest.job_title,
              address: this.$store.state.normal_guest.address,
              facebook: this.$store.state.normal_guest.facebook,
              twitter: this.$store.state.normal_guest.twitter,
              personal_photo: this.$store.state.normal_guest.twitter,
              passport_image: this.$store.state.normal_guest.twitter,
              title: this.$store.state.normal_guest.title,
              email: this.$store.state.normal_guest.emailaddress,
              nationality: this.$store.state.normal_guest.nationality,
              country_of_residence: this.$store.state.normal_guest.country_of_residence,
              id_pass_iqama: this.$store.state.normal_guest.id_pass_iqama,
              gender: this.$store.state.normal_guest.gender,
              phone: this.$store.state.normal_guest.phonenumber,
              job: this.$store.state.normal_guest.role_list,
              company_name: this.$store.state.normal_guest.company_name,
              // industry: this.$store.state.normal_guest.industry_list,
              token_key: this.$store.state.normal_guest.token_key,
              status:"",
              visa_status: "new",
              flight_status: "new",
              hotel_status: "new",
            }).then((response) => {
              $('.next_btn').removeClass('disabled_btn');
              this.disabled_submit=false;
              if (response.data.data.visa_status != "new") {
                  this.$router.push({ path: "/registration/thankyou" });
              } else {
                  this.$router.push({ path: "/registration/visa/step1" });
              }
            }).catch((e) => {});
        }
      }
      else{
      $('.alert_error').removeClass('h');
      setTimeout(function () {
          $('.alert_error').addClass("h");;
      }, 5000);
      $('.next_btn').removeClass('disabled_btn');
      this.disabled_submit=false;
      }
    }
  },
},

  computed:{
    role_list(){
      if(this.$i18n.locale == 'ar'){
        return this.$store.state.ar_role_list
      }
      return this.$store.state.en_role_list
    }
  },

created() {
  this.$store.state.loader =1;
  if(this.$store.state.normal_guest.token_key == null || this.$store.state.normal_guest.token_key == ""){
    this.$router.push({ path: '/registration/invalid-token' });
  }
  if(this.$store.state.is_view_only == 1){
    this.view_only_all=1;
  }
  // this.form.industry_list=this.$store.state.normal_guest.industry;
  // this.form.industry_list=this.$store.state.normal_guest.industry;
  // this.form.industry=this.$store.state.normal_guest.industry;
  this.form.role=this.$store.state.normal_guest.job;
  this.form.company_name=this.$store.state.normal_guest.company_name;
  var objectresponsecuritycheck=this.$store.state.normal_guest.datasecurity;
    if(objectresponsecuritycheck != null){
      this.form.facebook = objectresponsecuritycheck.facebook;
      this.form.twitter = objectresponsecuritycheck.twitter;
    }
    if(this.form.facebook){
      this.HaveFacebook = 1;
    }
    if(this.form.twitter){
      this.HaveTwitter = 1;
    }
  // this.axios.post(this.$store.state.baseURL + "getindustries", {}).then((response) => {
  // var xobjectrespons = response.data.data;
  // xobjectrespons.forEach(element => {
  //     var x ={
  //         id: element.id,
  //         text: element.title
  //     }
  //     this.industries_list.push(x);
  // });
  // }).catch((e) => {
  //     console.log(e);
  // });
  this.$store.state.loader =0;
},
}
</script>
