<template>
  <div class="fs14 flexi big ph0 oh ltr" style="min-height: 100vh">
    <div v-if="$store.state.loader" class="pf ov bgb40 overlaydiv" style="z-index: 999999999" >
      <div class="cc">
        <div class="fs20 b cw mb20">{{ $t("loading") }}</div>
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <div class="ov pf ba pv5 sh20 bgw ltr elevation-0" id="header_weblayout" style="z-index: 999999999" >
      <div class="pf ov ba h alert_error" style="z-index: 999999999999">
        <div class="mt20 g_nm sh20 bgw ph15 pv8 round2x" style="border: 2px solid red" >
          {{ $t("error_in_felids") }}
        </div>
      </div>
      <div class=" pf ov ba h alert_trip_error" style="z-index:999999999999">
        <div class="mt20 g_nm sh20 bgw ph15 pv8 round2x" style="border:2px solid red">
          {{$t('please_select_atleast_one_trip')}}
        </div>
      </div>
      <div class=" pf ov ba h alert_trip_conflict" style="z-index:999999999999">
        <div class="mt20 g_nm sh20 bgw ph15 pv8 round2x" style="border:2px solid red">
          {{$t('trip_time_conflict')}}
        </div>
      </div>
      <div class="flexi big ph50 md_ph5 m_ph15 c">
<!--        <tbl_nm style="direction:ltr !important">-->
        <tbl_nm  class="nav-wrapper">
          <cl class=" pt5" style="width: 80px; height:70px; text-align:left !important;">
            <router-link :to="{ name: 'NewHomeWebsite' }">
              <img class="pointer" src="../assets/img/Biban-logo-10.svg" alt="profile_image" style="width: 80px; height:70px">
            </router-link>
          </cl>
          <cl class="m_h fs0 c c_navy ltr">
            <div class="g_nm vm"><div class="web-nav-bar-item nav_about_biban_div ph8 fs14 md_fs13" :class="[($i18n.locale == 'ar') ?  'bl_blue_nav' : 'br_blue_nav' ]"    @click="goto('about_biban')" >{{ $t("about_biban") }}</div></div>
            <div v-if="$store.state.config_list.display_main_page_doors_section == 'show'" class="g_nm vm"><div class="web-nav-bar-item nav_door_div ph10 fs14 md_fs13" :class="[$route.name.includes('door') ? 'active': '' ,($i18n.locale == 'ar') ?  'bl_blue_nav' : 'br_blue_nav' ]" @click="goto('door')" >{{ $t("doors") }}</div></div>
            <div v-if="$store.state.config_list.display_main_page_speakers_section == 'show'" class="g_nm vm"><div class="web-nav-bar-item nav_speaker_div ph10 fs14 md_fs13" :class="[$route.name.includes('NewSpeakersWeb') ? 'active': '' ,($i18n.locale == 'ar') ?  'bl_blue_nav' : 'br_blue_nav' ]" @click="goto('speaker')" >{{ $t("speakers") }}</div></div>
            <div v-if="$store.state.config_list.display_main_page_agenda_section == 'show'" class="g_nm vm"><div class="web-nav-bar-item nav_calendar_div ph10 fs14 md_fs13" :class="[$route.name.includes('agenda') ? 'active': '' ,($i18n.locale == 'ar') ?  'bl_blue_nav' : 'br_blue_nav' ]" @click="goto('calendar')" >{{ $t("calendar") }}</div></div>
            <div v-if="$store.state.config_list.display_main_page_partners_section == 'show'" class="g_nm vm"><div class="web-nav-bar-item nav_partner_div ph10 fs14 md_fs13" :class="[$route.name.includes('partner') ? 'active': '' ,($i18n.locale == 'ar') ?  'bl_blue_nav' : 'br_blue_nav' ]" @click="goto('partner')" >{{ $t("partners") }}</div></div>
            <div v-if="$store.state.config_list.display_main_page_exhibitors_section == 'show'" class="g_nm vm"><div class="web-nav-bar-item nav_exhibitor_div ph10 fs14 md_fs13" :class="[$route.name.includes('NewExhibitorsWeb') ? 'active': '' ,($i18n.locale == 'ar') ?  'bl_blue_nav' : 'br_blue_nav' ]" @click="goto('exhibitor')" >{{ $t("exhibitors") }}</div></div>
            <div v-if="$store.state.config_list.display_main_page_ewc_section == 'show'" class="g_nm vm"><div class=" web-nav-bar-item nav_ewc_div ph10 fs14 md_fs13 bl_blue_nav" :class="[$route.name.includes('NewEwc') ? 'active': '' ,]" @click="goto('ewc')" >{{ $t("leading_businesses") }}</div></div>
            <div v-if=" $store.state.config_list.display_main_page_live_streaming_section == 'show' " class="g_nm vm"><div class="web-nav-bar-item nav_live_streaming_div ph10 fs14 md_fs13 bl_blue_nav" :class="[$route.name.includes('live_streaming') ? 'active': '' ,($i18n.locale == 'ar') ?  'br_blue_nav' : 'bl_blue_nav' ]" @click="goto('live_streaming')" >{{ $t("live_streaming") }}</div></div>
<!--            <div   class="g_nm vm">-->
<!--&lt;!&ndash;              <router-link :to="{ name: 'NewSMEWeb'}"><div class="web-nav-bar-item nav_live_streaming_div ph8 fs14 md_fs13 bl_blue_nav">{{$t('supporting_centers')}}</div></router-link>&ndash;&gt;-->

<!--            </div>-->
            <div   class="g_nm vm">
              <router-link :to="{ name: 'MediaCenter'}" ><div class="web-nav-bar-item nav_live_streaming_div ph8 fs14 md_fs13 bl_blue_nav">{{$t('media_center')}}</div></router-link>
            </div>
            <div   class="g_nm vm">
              <router-link :to="{ name: 'about-monshaat'}" ><div class="web-nav-bar-item nav_live_streaming_div ph8 fs14 md_fs13 bl_blue_nav">{{$t('monshaat')}}</div></router-link>
            </div>
            <div class="g_nm vm">
              <router-link :to="{ name: 'faq'}" ><div class="web-nav-bar-item nav_live_streaming_div ph8 fs14 md_fs13 bl_blue_nav">{{$t('faq')}}</div></router-link>
            </div>
            <div v-if="$i18n.locale == 'en'" class="g_nm vm"><div class="web-nav-bar-item nav_visit_saudi_div ph8 fs14 md_fs13 bl_blue_nav" :class="[$route.name.includes('visit-saudi') ? 'active': '' , ]"><router-link :to="{ name: 'visit-saudi' }">Visit Saudi</router-link></div></div>

<!--           <div class="ph2 m_h g_nm vm">-->
<!--              <router-link :to="{ name: 'VisitorStep1' }">-->
<!--                <button class="pv4 pointer  register_btn pointer ph10 nw cw fs15">-->
<!--                  <span class="blink ">{{$t('register_now')}}</span>-->
<!--                </button>-->
<!--              </router-link>-->
<!--            </div>-->
          </cl>
          <!-- <cl class="colSep"></cl> -->
          <cl class="min_w_nav nw" style="text-align:right;">
            <div class="g_nm  ph5 vm" >
              <span class="pointer m_h mdi mdi-menu c_navy sub_menu_btn fs30"></span>
            </div>
            <div class="g_nm vm ph2 c_navy fs30 b">
              <div class="g_nm pointer lang-main-div" @click="showLangDiv()" >
                <tbl_nm style="margin-top:9px;">
                  <cl v-if="$route.fullPath != '/visit-saudi'" class="vm  mdi mdi-web c_navy fs18 pointer"></cl>
                  <cl class="vm nav-gray fs13">{{ $t("tag_lang") }}</cl>
                  <cl v-if="$route.fullPath != '/visit-saudi'" class="vm mt2 mdi arrow-span mdi-chevron-down fs20 c-nav-gray"></cl>
                </tbl_nm>
                <!-- <span v-if="$route.fullPath != '/visit-saudi'" class="vm ml1 mr1 mdi mdi-web c_navy fs20 pointer"></span>
                <span class="vm ph2 nav-gray fs16">{{ $t("tag_lang") }}</span>
                <span v-if="$route.fullPath != '/visit-saudi'" class="vm mt2 mdi arrow-span mdi-chevron-down fs20 c-nav-gray" ></span> -->
              </div>
              <div class="ov lang-select ba brdr round2x pv4 c bgw sh20 mt50" hidden>
                <div class="fs16 bb pointer" @click="changeLocale('en')">
                  EN
                </div>
                <div class="fs16 pointer" @click="changeLocale('ar')">عربي</div>
              </div>
              <!-- <span class="vm ph2 nav-gray fs20 pointer" @click="changeLocale()">{{ $t("lang") }}</span> -->
            </div>
            <div class="g_nm vm">
              <span class="pointer h m_sb vm lh40 mdi mdi-menu c_navy menu_btn  fs30" style="height:40px"></span>
              <!-- <span class="pointer h m_h mdi mdi-menu c_navy sub_menu_btn  fs30" style="height:40px"></span> -->
              <div class="ov ba mt50 brdr_blue sh20 nw h menu_div c bgw oya scroll_h  navbarDropDown " style="max-height:300px;">
                <div class="menu_nav_item nav_about_biban_div pv10 ph20 pointer bb_blue" @click="goto('about_biban')" >{{ $t("about_biban") }}</div>
                <div v-if="$store.state.config_list.display_main_page_doors_section == 'show'" class="h m_sb menu_nav_item nav_door_div pv10 ph20 pointer bb_blue" :class="{ active: $route.name.includes('door') }" @click="goto('door')" >{{ $t("doors") }}</div>
                <div v-if=" $store.state.config_list.display_main_page_speakers_section == 'show' " class="h m_sb menu_nav_item nav_speaker_div pv10 ph20 pointer bb_blue" :class="{ active: $route.name.includes('NewSpeakersWeb') }" @click="goto('speaker')" >{{ $t("speakers") }}</div>
                <div v-if="$store.state.config_list.display_main_page_agenda_section == 'show'" class="h m_sb menu_nav_item nav_calendar_div pv10 ph20 pointer bb_blue" :class="{ active: $route.name.includes('agenda') }" @click="goto('calendar')" >{{ $t("calendar") }}</div>
                <div v-if=" $store.state.config_list.display_main_page_partners_section == 'show' " class="h m_sb menu_nav_item nav_partner_div pv10 ph20 pointer bb_blue" :class="{ active: $route.name.includes('partner') }" @click="goto('partner')" >{{ $t("partners") }}</div>
                <div v-if=" $store.state.config_list.display_main_page_exhibitors_section == 'show' " class="h m_sb menu_nav_item nav_exhibitor_div pv10 ph20 pointer bb_blue" :class="{ active: $route.name.includes('NewExhibitorsWeb') }" @click="goto('exhibitor')" >{{ $t("exhibitors") }}</div>
                <div v-if="$store.state.config_list.display_main_page_ewc_section == 'show'" class="h m_sb menu_nav_item nav_ewc_div pv10 ph20 pointer bb_blue" :class="{ active: $route.name.includes('ecw') }" @click="goto('NewEwc')" >{{ $t("leading_businesses") }}</div>
                <div v-if=" $store.state.config_list.display_main_page_live_streaming_section == 'show' " class="h m_sb menu_nav_item nav_live_streaming_div pv10 ph20 pointer" :class="{ active: $route.name.includes('live_streaming') }" @click="goto('#live_streaming')" >{{ $t("live_streaming") }}</div>
                <div v-if="$i18n.locale == 'en'" class="h m_sb menu_nav_item nav_visit_saudi_div pv10 ph20 bb_blue" :class="{ active: $route.name.includes('visit-saudi') }" ><router-link :to="{ name: 'visit-saudi' }">Visit Saudi</router-link></div>
                <router-link :to="{ name: 'MediaCenter'}" ><div class="menu_nav_item  pointer pv10 ph20 bb_blue">{{$t('media_center')}}</div></router-link>
<!--                <router-link :to="{ name: 'workshops'}" ><div class="menu_nav_item  pointer pv10 ph20 bb_blue">{{$t('workshops')}}</div></router-link>-->
<!--                <router-link :to="{ name: 'Opportunities'}" ><div class="menu_nav_item  pointer pv10 ph20 bb_blue">{{$t('oppurtuinites')}}</div></router-link>-->
<!--                    <router-link :to="{ name: 'NewSMEWeb'}"><div class="menu_nav_item pointer pv10 ph20 bb_blue">{{$t('supporting_centers')}}</div></router-link>-->
                <router-link :to="{ name: 'about-monshaat'}"><div class="menu_nav_item  pointer pv10 ph20 bb_blue">{{$t('monshaat')}}</div></router-link>
                <router-link :to="{ name: 'faq'}"><div class="menu_nav_item  pointer pv10 ph20 bb_blue">{{$t('faq')}}</div></router-link>
<!--               <router-link :to="{ name: 'VisitorStep1' }"><div class="menu_nav_item  pointer pv10 ph20 bb_blue">{{$t('register_now')}}</div></router-link>-->
                <!-- <div class="menu_nav_item  pointer pv10 ph20 bb_blue">{{$t('supporting_centers')}}</div> -->
                <div class="fs0 mt8 pv10 bb_blue">
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://twitter.com/BibanGlobal?s=20&t=AiJHRuSZaIsyEIgQGqASRQ" target="_blank">
                      <div class="bg_navy" style="padding:12px; border-radius:4px;"><span class="cc icon-x-twitter cw" style="line-height:24px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.facebook.com/profile.php?id=100088582235109" target="_blank">
                      <div class="bg_navy" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-facebook cw" style="line-height:24px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.youtube.com/@bibanglobal8184/featured" target="_blank">
                      <div class="bg_navy" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-youtube cw" style="line-height:24px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.instagram.com/bibanglobal/" target="_blank">
                      <div class="bg_navy" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-instagram cw" style="line-height:24px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.linkedin.com/company/bibanglobal/" target="_blank">
                      <div class="bg_navy" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-linkedin cw" style="line-height:24px;"></span></div>
                    </a>
                  </div>
                </div>
                <div class="pv10 c pointer">
                  <button :name="$t('contact_us')" class="contact_us round2x pointer c-nav-gray pv2 ph4" style="border-color:#212C5C !important;" @click="goto('map_contact')" >
                    {{ $t("contact_us") }}
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="fs20 cb">{{$route.name}}</div> -->
<!--            <div v-if="!$route.name.includes('SideEvent')" class="ph2 m_h g_nm vm">-->
<!--             <router-link :to="{ name: 'VisitorStep1' }">-->
<!--                <button class="pv4 pointer round register_btn pointer ph10 nw cw fs15">-->
<!--                    <span class="blink ">{{$t('register_now')}}</span>-->
<!--                </button>-->
<!--              </router-link>-->
<!--            </div>-->


          </cl>
        </tbl_nm>
        <div class="ch c h ba sub_menu_div pv30 g_nm mt6 bgw sh20 pv20 ph20 m_ph10">
          <tbl>
<!--            <cl class="w70 vt">-->
<!--              <tbl>-->
<!--&lt;!&ndash;                <cl class="w50">&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link :to="{ name: 'MediaCenter'}" ><div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('media_center')}}</div></router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="bb h m_sb mt2"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <span :to="{ name: 'workshops'}" ><div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('workshops')}}</div></span>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="bb h m_sb mt2"></div>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  <span :to="{ name: 'Opportunities'}" ><div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('oppurtuinites')}}</div></span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="bb h m_sb mt2"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                </cl>&ndash;&gt;-->
<!--                <cl class="w50">-->
<!--                  &lt;!&ndash; <div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('supporting_centers')}}</div>-->
<!--                  <div class="bb h m_sb mt2"></div> &ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link :to="{ name: 'NewSMEWeb'}"><div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('supporting_centers')}}</div></router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="bb h m_sb mt2"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link :to="{ name: 'about-monshaat'}"><div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('monshaat')}}</div></router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="bb h m_sb mt2"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link :to="{ name: 'faq'}" ><div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('faq')}}</div></router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="bb h m_sb mt2"></div>&ndash;&gt;-->

<!--                </cl>-->
<!--              </tbl>-->
<!--            </cl>-->
<!--            <cl class="colSep m_h"></cl>-->
            <cl class="w30 nw vb m_mt4">
              <button :name="$t('contact_us')" class="contact_us round2x pointer c-nav-gray pv2 ph4" style="border-color:#212C5C !important; " @click="goto('map_contact')" >
                {{ $t("contact_us") }}
              </button>
              <div class="fs0 mt8">
                <div class="g_nm ph2 vm fs18">
                  <a href="https://twitter.com/BibanGlobal?s=20&t=AiJHRuSZaIsyEIgQGqASRQ" target="_blank">
                    <div class="bg_navy" style="padding:12px; border-radius:4px;"><span class="cc icon-x-twitter cw" style="line-height:24px;"></span></div>
                  </a>
                </div>
                <div class="g_nm ph2 vm fs18">
                  <a href="https://www.facebook.com/profile.php?id=100088582235109" target="_blank">
                    <div class="bg_navy" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-facebook cw" style="line-height:24px;"></span></div>
                  </a>
                </div>
                <div class="g_nm ph2 vm fs18">
                  <a href="https://www.youtube.com/@bibanglobal8184/featured" target="_blank">
                    <div class="bg_navy" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-youtube cw" style="line-height:24px;"></span></div>
                  </a>
                </div>
                <div class="g_nm ph2 vm fs18">
                  <a href="https://www.instagram.com/bibanglobal/" target="_blank">
                    <div class="bg_navy" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-instagram cw" style="line-height:24px;"></span></div>
                  </a>
                </div>
                <div class="g_nm ph2 vm fs18">
                  <a href="https://www.linkedin.com/company/bibanglobal/" target="_blank">
                    <div class="bg_navy" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-linkedin cw" style="line-height:24px;"></span></div>
                  </a>
                </div>
              </div>
            </cl>
          </tbl>
        </div>
      </div>
    </div>
    <router-view class=""></router-view>
    <div class="bg_gray cb" id="footer_weblayout">
      <div class="flexi big ph80 md_ph50 m_ph20 m_m_ph10 pv10 web_c_dark_grey">
        <tbl>
          <cl class="vt l">
            <tbl>
              <cl class="w60 vt">
                <p class="fs20 b c_navy">{{ getStaticContent("footer_main_title") }}</p>
                <p class="">{{ getStaticContent("footer_main_paragraph") }}</p>
              </cl>
              <cl class="colSep20"></cl>
              <cl class="w40 vt">
                <div class="mt10 c_navy">{{ getStaticContent("footer_contact_title") }}</div>
<!--                <p>{{ $t('location_of_event') }}</p>-->
                <div class="mt5">{{ getStaticContent("footer_email") }}</div>
                <div class="mt5">
                  <span>{{$t('inside_ksa')}}</span><div class="ph4 g_nm"></div><span>{{ getStaticContent("footer_local_call_number") }}</span>
                </div>
<!--                <div class="mt20">-->
<!--                  <p class="c_navy">{{$t('calling_numbers')}}</p>-->
<!--                  <span>{{$t('inside_ksa')}}</span><div class="ph4 g_nm"></div><span>{{ getStaticContent("footer_local_call_number") }}</span>-->
<!--                </div>-->
<!--                <div class="">-->
<!--                  <span>{{$t('outside_ksa')}}</span><div class="ph4 g_nm"></div><span>{{ getStaticContent("footer_international_call_number")}}</span>-->
<!--                </div>-->
              </cl>
            </tbl>
            <div class="fs0 m_mt10">
              <div class="g_nm vt ">
                <p class="fs14 c_navy">{{$t('follow_us')}}</p>
                <div class="mt5 l">
                  <div class="g_nm ph2 vm fs22">
                    <a  href="https://twitter.com/BibanGlobal?s=20&t=AiJHRuSZaIsyEIgQGqASRQ" target="_blank">
                      <div class="brdr" style="padding:17px; border-radius:4px;"><span class="cc icon-x-twitter" style="line-height:36px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs22">
                    <a  href="https://www.facebook.com/profile.php?id=100088582235109" target="_blank">
                      <div class="brdr" style="padding:17px; border-radius:4px;"><span class="cc mdi mdi-facebook" style="line-height:36px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs22">
                    <a  href="https://www.youtube.com/@bibanglobal8184/featured" target="_blank">
                      <div class="brdr" style="padding:17px; border-radius:4px;"><span class="cc mdi mdi-youtube" style="line-height:36px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs22">
                    <a  href="https://www.instagram.com/bibanglobal/" target="_blank">
                      <div class="brdr" style="padding:17px; border-radius:4px;"><span class="cc mdi mdi-instagram" style="line-height:36px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs22">
                    <a  href="https://www.linkedin.com/company/bibanglobal/" target="_blank">
                      <div class="brdr" style="padding:17px; border-radius:4px;"><span class="cc mdi mdi-linkedin" style="line-height:36px;"></span></div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="g_nm ph10"></div>
<!--              <div class="g_nm m_m_w100 m_m_mt10 vt">-->
<!--                <p class="fs14 c_navy">{{$t('download_app')}}</p>-->
<!--                &lt;!&ndash; <img class="g_nm mt5" style="height:26px;" src="@/assets/img/web2/google_paly.svg"/> &ndash;&gt;-->
<!--                <a href="https://apps.apple.com/lb/app/biban23/id1660225379" target="_blank"> <img class="g_nm mt5 round2x" :class="[($i18n.locale == 'ar') ?  'mrs10' : 'mls10' ]" style="height:40px;" src="@/assets/img/web2/apple_btn.png"/></a>-->
<!--                <a href="https://play.google.com/store/apps/details?id=sa.bibanglobal.app" target="_blank"><img class="g_nm mt5 round2x" :class="[($i18n.locale == 'ar') ?  'mrs10' : 'mls10' ]" style="height:40px;" src="@/assets/img/web2/google_play_btn.png"/></a>-->
<!--                <a href="https://appgallery.huawei.com/app/C107795771" target="_blank"><img class="g_nm mt5 round2x" :class="[($i18n.locale == 'ar') ?  'mrs10' : 'mls10' ]" style="height:40px;" src="@/assets/img/web2/huawei_btn.png"/></a>-->
<!--              </div>-->
            </div>
          </cl>
          <cl class="vt colSep"></cl>
          <cl class="vt m_l fs14 pb100 m_pb0" style="width:100px;">
            <div class="m_sib m_w50 ">
            <router-link :to="{ name: 'NewHomeWebsite' }">
              <div class="con g_nm p50" :style="{backgroundImage:'url(' + require('@/assets/img/Biban-logo-10.svg') + ')',}"></div>
            </router-link>
            </div>
            <div class="ov ta m_pr m_sib m_w50 r" style="max-height:80px">
              <a href="https://www.monshaat.gov.sa" target="_blank"><div class="con g_nm p50" :style="{backgroundImage:'url(' + require('@/assets/img/monshaat_logo-01.svg') + ')',}"></div></a>
            </div>
          </cl>
        </tbl>
      </div>
      <div class="pt1 mt10 bgb30"></div>
        <div class="fs14 p10 vm g_nm web_c_dark_grey">
          <span>{{ getStaticContent("footer_powered_by_text") }}</span>
          {{ new Date().getFullYear() }}
        </div>
    </div>
  </div>
</template>
<script>
// import $ from "jquery";
export default {
  name: "Mainlayout",
  data() {
    return {
      showLangBool: 0,
      footer_list: null,
    };
  },
  mounted() {
    $(".menu_btn").click(function () {
      $(".menu_div").toggleClass("h");
      $(".sub_menu_div").addClass('h')
    });
    $(".menu_nav_item").click(function () {
      $(".menu_div").toggleClass("h");
    });
    $(".sub_menu_btn").click(function () {
      $(".sub_menu_div").toggleClass("h");
      $(".menu_div").addClass('h')
    });
    $(".sub_menu_nav_item").click(function () {
      $(".sub_menu_div").toggleClass("h");
    });
  },

  created() {
    var lang = this.$route.query.lang;
    if (lang == "" || lang == null) {
    }
    else{
      if(lang == 'en'){
        this.changeLocale('en');
      }
    }

    this.axios
      .get(this.$store.state.baseURL + "static_content/configuration", {})
      .then((response) => {
        var data = response.data.data;
        var x;
        data.forEach((element) => {
          x = element.short_code;
          this.$store.state.config_list[x] = element.body;
        });
      })
      .catch((e) => {});
      if(this.$route.fullPath == '/visit-saudi'){
        this.setenglish()
      }
      if (this.$i18n.locale == "ar") {
        this.axios
          .get(this.$store.state.baseURL + "static_content/footer?lang=ar", {})
          .then((response) => {
            this.footer_list = response.data.data;
          })
          .catch((e) => {});
      } else {
        this.getdata(this.$i18n.locale);
      }
      $(document).click(function(e) {
        var container_menu = $(".menu_div");
        var container_sub = $(".sub_menu_div");
        var container_lang = $(".lang-select");
        var lang_btn = $(".lang-main-div");
        var sub_btn =$('.sub_menu_btn')
        var main_btn =$('.menu_btn')
        if (!container_menu.is(event.target) && !main_btn.is(event.target)) {
              $(".menu_div").addClass('h');
            // }
        }
        if (!container_sub.is(event.target) && !sub_btn.is(event.target)) {
            $(".sub_menu_div").addClass('h')
          // }
        }
        var clickedOn = $(e.target);
        if(clickedOn.parents().andSelf().is(container_lang) || !clickedOn.parents().andSelf().is(lang_btn)){
          $(".arrow-span").removeClass("mdi-chevron-up");
          $(".lang-select").slideUp()
        }
    });

    //   $(document).click(function() {
    //     var container_menu = $(".menu_div");
    //     // var container_sub = $(".sub_menu_div");
    //     // var sub_btn =$('.sub_menu_btn')
    //     var main_btn =$('.menu_btn')
    //     // if (!container_menu.is(event.target) && !container_menu.has(event.target).length) {
    //     if (!container_menu.is(event.target) && !main_btn.is(event.target)) {
    //         // if($(".menu_div").hasClass('h')){
    //           $(".menu_div").addClass('h');
    //         // }
    //     }
    //     // if (!container_sub.is(event.target) && !sub_btn.is(event.target)) {
    //     //   // if($(".sub_menu_div").hasClass('h')){
    //     //     $(".sub_menu_div").addClass('h')
    //     //   // }
    //     // }
    // });
  },
  watch: {
    "$i18n.locale": function (newVal) {
      this.getdata(newVal);
    },
  },
  methods: {
    getdata: function (lang) {
      // GET FOOTER
      this.axios
        .get(
          this.$store.state.baseURL + "static_content/footer?lang=" + lang,
          {}
        )
        .then((response) => {
          this.footer_list = response.data.data;
        })
        .catch((e) => {});
    },
    getStaticContent(key) {
      if (this.footer_list) {
        return this.footer_list.find((item) => item.short_code == key)?.body;
      }
      return "";
    },
    showLangDiv() {
      if(this.$route.fullPath == '/visit-saudi'){
      }
      else{
        if ($(".arrow-span").hasClass("mdi-chevron-up")) {
          $(".arrow-span").removeClass("mdi-chevron-up");
          $(".lang-select").slideUp();
        }
        else {
          $(".arrow-span").addClass("mdi-chevron-up");
          $(".lang-select").slideDown();
        }
      }
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      $("html").attr("lang", "ar");
      $(".arrow-span").removeClass("mdi-chevron-up");
      $(".lang-select").slideUp();
      if (locale == "ar") {
        $("head").append(
          '<link rel="stylesheet" href="/rtl_style.css" class="ar_style">'
        );
        // console.log("add");
        $("#title").html("بيبان 2024 وجهة عالمية للفرص");
      } else {
        $("html").attr("lang", "en");
        $(".ar_style").remove();
        $("#title").html("BIBAN 2024 Global Destination for Opportunities");
      }
    },
    // changeLocale() {
    //   if(this.$i18n.locale == "ar"){
    //     this.$i18n.locale="en";
    //     $("html").attr("lang", "en");
    //     $(".ar_style").remove();
    //     $("#title").html("BIBAN 2023 Global Destination for Opportunities");
    //   }
    //   else{
    //     this.$i18n.locale="ar";
    //     $("head").append(
    //       '<link rel="stylesheet" href="/rtl_style.css" class="ar_style">'
    //     );
    //     $("html").attr("lang", "ar");
    //     $("#title").html("بيبان 2023 وجهة عالمية للفرص");
    //   }
    // },
    setenglish(){
      this.$i18n.locale="en";
        $("html").attr("lang", "en");
        $(".ar_style").remove();
        $("#title").html("BIBAN 2024 Global Destination for Opportunities");
    },
    goto(id) {
      if (this.$route.fullPath == "/") {
        var x = $("#" + id).offset().top - 70;
        $("html, body").animate(
          {
            scrollTop: x,
          },
          500
        );
      } else {
        var xname = "/";
        var idid = id.replace(/\s/g, "");
        this.$router.push({ path: xname, query: { t: idid } });
      }
    },
  },
};
</script>
<style scoped>
.register_btn{
  background-color: #274191;
  border:1px solid white;
}
.register_btn:hover{
  font-weight: bold;
}

@media all and (max-width:768px){
  .sub_menu_div{
    display: none !important;
  }
  }
  @media all and (min-width:768px){
  .menu_div{
    display: none !important;
  }
  }
</style>
