<template>
<div v-if="loader" class="pf ov bgb40 overlaydiv z2000" >
    <div class="cc">
        <div class="fs20 b cw mb20">{{$t('link_expire')}}</div>
    </div>
</div>
</template>
<script>
export default {
    name: 'LinkNotAvailable',
    data() {
        return {
        loader:true,
        }
    },
}
</script>
