<template>
  <div class="c mt150">
    <div class="mt100 m_mt0 g_nm ph50 bgw round3x sh20">
      <div class="ov ba p50 con ch tx_50 ty__50" :style="{backgroundImage: 'url('+require('@/assets/img/visaimg.png')+')'}" style="top:-50px"></div>
      <div class="b mt60 ">{{ $t('do_you_have') }}</div>
      <div class="mt20 mb20 c">
        <button @click="gotofunc(0)" class="prev_btn pv5 ph30">{{ $t('no')}}</button>
        <button @click="gotofunc(1)" class="next_btn pv5 ph30 cw ml20">{{ $t('yes')}}</button>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'guestStep4',
  data() {
    return {

    }
  },
  methods: {
    gotofunc(goto) {
      if(goto ==1){
        this.axios.post(this.$store.state.baseURL + "update-visa-status", {
          type_of_user:this.$store.state.visa.type_of_user,
          user_token_key: this.$store.state.visa.user_token_key,
          visa_status: "has visa",
          flight_status: "has flight",
          hotel_status: "has hotel",
        }).then((response) => {
          this.$router.push({path: '/registration/thankyou'});
        }).catch((e) => {
          // console.log(e);
        });
      }else{
        this.$router.push({path: '/registration/visa/step2'});
      }

    }
  },
}
</script>
