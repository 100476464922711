<template>
<div class="mt100 c mb100">
  <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
    <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
    <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }} {{ $t('as_exh_staff') }}</div>
    <div class="mt10 l w100">
      <div class="bg-prograss-bar prograss-bar">
        <div class="prograss-bar ov ra w33 g_nm bg_orange"></div>
      </div>
      <div class="mt10 c-blue">{{ $t('step2') }}</div>
      <div class="mt10 b">{{ $t('basic_registration') }}</div>
      <form class="pv10" @submit.prevent="submit_form">
        <!-- START TITLE -->
        <div class="mt15">
          <label class="label_fields">{{ $t('title') }} *</label>
          <Select2 :disabled="view_only_all == 1" v-model="form.title" :options="title_list"/>
          <div class="validateTitle validation_warning"></div>
        </div>
        <!-- CLOSE TITLE -->
        <!-- START NAME -->
        <tbl>
          <cl class="vt w50">
            <div class="mt15">
              <label class="label_fields">{{ $t('first_name') }} *</label>
              <input :disabled="view_only_all == 1" class="input_fields" v-model="form.first_name" v-bind:placeholder="$t('first_name')" type="text"/>
              <div class="validateFirstname validation_warning"></div>
            </div>
          </cl>
          <cl class="colSep pv10"></cl>
          <cl class="vt w50">
            <div class="mt15">
              <label class="label_fields">{{ $t('last_name') }} *</label>
              <input :disabled="view_only_all == 1" class="input_fields" v-model="form.last_name" v-bind:placeholder="$t('last_name')" type="text"/>
              <div class="validateLastname validation_warning"></div>
            </div>
            </cl>
        </tbl>
        <!-- CLOSE NAME -->
        <tbl>
          <cl class="vt w50">
            <div class="mt15">
              <label class="label_fields">{{ $t('nationality') }} *</label>
              <Select2 :disabled="view_only_all == 1" v-model="form.nationality" :options="nationality_list" />
              <div class="validate_Nation validation_warning"></div>
            </div>
          </cl>
          <cl class="colSep"></cl>
          <cl class="vt w50">
            <div class="mt15">
              <label class="label_fields">{{ $t('country_of') }} *</label>
              <Select2 :disabled="view_only_all == 1" v-model="form.country_of_residence"  class="ltr" :options="countries_list" />
            </div>
            <div class="validateCountryOfResidence validation_warning"></div>
          </cl>
        </tbl>
        <!-- START FULL NAME -->
        <div class="mt15">
          <label v-if="$store.state.no_visa_countries.indexOf(form.nationality) != -1" class="label_fields">{{$t('full_name_as_id')}} *</label>
            <label v-else-if="form.country_of_residence == 194" class="label_fields">{{$t('full_name_as_iqama')}} *</label>
            <label v-else class="label_fields">{{$t('full_name_as_passport')}} *</label>
          <input :disabled="view_only_all == 1" class="input_fields" v-model="form.full_name" type="text"/>
          <div class="validateFullName validation_warning"></div>
        </div>
        <!-- CLOSE FULL NAME -->
        <div class="mt15">
          <label class="label_fields">{{$t('gender')}} *</label>
          <br>
          <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="form.gender"  value="male">{{ $t('male') }}
          <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="form.gender" value="female">{{ $t('female') }}
          <div class="validateGender validation_warning"></div>
        </div>
        <!-- START ID -->
        <div class="mt15">
          <label v-if="form.nationality ==194 || $store.state.no_visa_countries.indexOf(form.nationality) != -1" class="label_fields">{{$t('id_number')}} *</label>
          <label v-else-if="form.country_of_residence == 194 && $store.state.no_visa_countries.indexOf(form.nationality) == -1" class="label_fields">{{$t('iqama')}} *</label>
          <label v-else class="label_fields">{{$t('passport_number')}} *</label>
          <input v-if="form.nationality ==194" :disabled="view_only_all == 1" class="input_fields" v-model="form.id_pass_iqama" type="text" maxlength="10"/>
          <input v-else-if="form.country_of_residence == 194 && $store.state.no_visa_countries.indexOf(form.nationality) == -1" :disabled="view_only_all == 1" class="input_fields" v-model="form.id_pass_iqama" type="text" maxlength="10"/>
          <input v-else :disabled="view_only_all == 1" class="input_fields" v-model="form.id_pass_iqama" type="text" maxlength="10"/>
          <div class="validation_id_pass_iqama validation_warning"></div>
        </div>
        <!-- <div class="mt15" v-if="(form.nationality) != 194 && form.country_of_residence != 194"> -->
        <div v-if="$store.state.no_visa_countries.indexOf(form.nationality) != -1 || form.country_of_residence == 194"></div>
        <div v-else class="mt15">
          <label class="label_fields">{{$t('passport_type')}} *</label>
          <Select2 :disabled="view_only_all == 1" v-model="form.passport_type" :options="passport_type"/>
          <div class="validatePassportType validation_warning"></div>
        </div>
        <tbl>
          <cl class="vt w50">
            <div class="mt15">
              <label class="label_fields">{{$t('place_of_birth')}} *</label>
              <Select2 :disabled="view_only_all == 1" v-model="form.place_of_birth" :options="countries_list"/>
              <div class="validatePlaceOfBirth validation_warning"></div>
            </div>
          </cl>
          <cl class="colSep pv10"></cl>
          <cl class="vt w50">
            <div class="mt15">
              <label class="label_fields">{{$t('date_of_birth')}} *</label>
              <input class="input_fields" placeholder="" v-model="form.date_of_birth" max="2009-03-01" type="date"/>
              <div class="validateDateOfdBirth validation_warning"></div>
            </div>
          </cl>
        </tbl>
        <div class="fs12 c mt20">{{ $t('privacy_inforamtion') }}</div>
        <div class="mt20 r">
          <button @click="submit" class="next_btn ph20 ml20">
            <span class="cw lh40 fs18" >{{$t('next')}}</span>
            <!-- <span v-if="$i18n.locale == 'en'" class="cw mdi mdi-arrow-right lh40 fs30"></span>
            <span v-if="$i18n.locale == 'ar'" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

</template>
<script>
export default {
  name: 'Step2View',
  data(){
    return {
      type : this.$store.state.exhtype,
      en_countries_list:[],
      ar_countries_list:[],
      en_nationality_list:[],
      ar_nationality_list:[],
      errors:0,
      view_only_all: 0,
      form:{
        title:'',
        first_name:'',
        last_name:'',
        nationality:'',
        country_of_residence:'',
        id_pass_iqama:'',
        gender:'',
        full_name:'',
        passport_type:'',
        place_of_birth:'',
        date_of_birth:'',
      }
    }
  },
  created(){
    if(this.$store.state.exh_guest_token_key == null || this.$store.state.exh_guest_token_key == ""){
      this.$router.push({ path: '/registration/failed' });
    }
    this.$store.state.loader=1;
    this.axios.post(this.$store.state.baseURL+'getcountries', {
    }).then(response => {
      var objectrespons=response.data.data;
      objectrespons.forEach(element => {
          var ar_x ={
              id: element.id,
              text: element.country_arNationality
          }
          var en_x ={
              id: element.id,
              text: element.country_enNationality
          }
          var ar_y ={
              id: element.id,
              text: element.country_arName
          }
          var en_y ={
              id: element.id,
              text: element.country_enName
          }
          this.ar_nationality_list.push(ar_x);
          this.en_nationality_list.push(en_x);
          this.ar_countries_list.push(ar_y);
          this.en_countries_list.push(en_y);
      });
      this.$store.state.loader=0;
    })
    .catch(e => {})
    if(this.$store.state.is_view_only == 1){
      this.view_only_all=1;
    }
    var objectresponsecuritycheck=this.$store.state.saved_guest_data.datasecurity;
    if(objectresponsecuritycheck == null){
    }
    else{
      this.form.full_name=objectresponsecuritycheck.full_name;
      this.form.passport_type=objectresponsecuritycheck.passport_type;
      this.form.place_of_birth=objectresponsecuritycheck.place_of_birth;
      this.form.date_of_birth=objectresponsecuritycheck.date_of_birth;
    }
    this.form.id_pass_iqama=this.$store.state.saved_guest_data.id_pass_iqama;
    this.form.title=this.$store.state.saved_guest_data.title;
    this.form.first_name=this.$store.state.saved_guest_data.first_name;
    this.form.last_name=this.$store.state.saved_guest_data.last_name;
    this.form.gender=this.$store.state.saved_guest_data.gender;
    this.form.country_of_residence=this.$store.state.saved_guest_data.country_of_residence;
    this.form.nationality=this.$store.state.saved_guest_data.nationality;
    if(this.$store.state.is_view_only == 1){
      this.view_only_all=1;
    }
  },
  computed:{
    countries_list(){
      if(this.$i18n.locale == 'ar'){
        return this.ar_countries_list
      }
      return this.en_countries_list
    },
    nationality_list(){
      if(this.$i18n.locale == 'ar'){
        return this.ar_nationality_list
      }
      return this.en_nationality_list
    },
    title_list(){
      if(this.$i18n.locale == 'ar'){
            return this.$store.state.ar_title_list
        }
        return this.$store.state.en_title_list
    },
    passport_type(){
      if(this.$i18n.locale == 'ar'){
          return this.$store.state.ar_passport_type;
      }
      return this.$store.state.en_passport_type;
    }
  },
  methods:{
    validatedata(formname,validatefieldclass, text) {
      if (formname == "" || formname == null || formname == 0) {
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        this.errors = 1;
        }
      else {
        $(validatefieldclass).slideUp();
      }
    },
    validateSAID(id) {
      id = id.trim();
      if (isNaN(parseInt(id))) {
        return -1;
      }
      if (id.length !== 10) {
        return -1;
      }
      var type = id.substr(0, 1);
      if (type !== '2' && type !== '1') {
        return -1;
      }
      var sum = 0;
      for (var i = 0; i < 10; i++) {
        if (i % 2 === 0) {
          var ZFOdd = String('00' + String(Number(id.substr(i, 1)) * 2)).slice(-2);
          sum += Number(ZFOdd.substr(0, 1)) + Number(ZFOdd.substr(1, 1));
        }
        else {
          sum += Number(id.substr(i, 1));
        }
      }
      return (sum % 10 !== 0) ? -1 : type;
    },
    submit(){
      this.errors=0;
      this.$emit('submit', this.form);
      this.validatedata(this.form.title,".validateTitle", this.$t('title_is_required'));
      this.validatedata(this.form.first_name,".validateFirstname", this.$t('first_name_is_required'));
      this.validatedata(this.form.last_name,".validateLastname", this.$t('last_name_is_required'));
      this.validatedata(this.form.full_name,".validateFullName", this.$t('full_name_is_required'));
      this.validatedata(this.form.nationality,".validate_Nation", this.$t('nationality_is_required'));
      this.validatedata(this.form.country_of_residence,".validateCountryOfResidence", this.$t('countryofresidence_is_required'));
      this.validatedata(this.form.gender,".validateGender", this.$t('gender_is_required'));
      this.validatedata(this.form.place_of_birth,".validatePlaceOfBirth", this.$t('place_of_birth_is_required'));
      this.validatedata(this.form.date_of_birth,".validateDateOfdBirth", this.$t('date_of_birth_is_required'));
      var res = this.$store.state.no_visa_countries.indexOf(this.form.nationality);
      if(res == -1 && this.form.country_of_residence !=194){
          this.validatedata(this.form.passport_type,".validatePassportType", this.$t('filed_is_required'));
      }
      if (this.form.id_pass_iqama == "" || this.form.id_pass_iqama == null || this.form.id_pass_iqama == 0) {
        $('.validation_id_pass_iqama').html(this.$t('filed_is_required'));
        $('.validation_id_pass_iqama').slideDown();
        this.errors = 1;
      }
      else{
        var val = this.form.id_pass_iqama;
        if(this.form.nationality == 194){
          var x=this.validateSAID(this.form.id_pass_iqama);
          if(x == -1 ){
            $('.validation_id_pass_iqama').html(this.$t('invalid_id'));
            $('.validation_id_pass_iqama').slideDown();
            this.errors = 1;
          }
          else{
            $('.validation_id_pass_iqama').slideUp();
          }
        }
        else if(this.form.country_of_residence == 194 && res ==-1){
          if( /^[0-9]+$/.test(val)){
            if (val.length === 10 && val.toString().indexOf("2") == 0) {
                $('.validation_id_pass_iqama').slideUp();
            }
            else{
              $('.validation_id_pass_iqama').html(this.$t('id_20_digit'));
              $('.validation_id_pass_iqama').slideDown();
              this.errors = 1;
            }
          }
          else{
            $('.validation_id_pass_iqama').html(this.$t('enter_only_number'));
            $('.validation_id_pass_iqama').slideDown();
            this.errors = 1;
          }
        }
        else{
          if (val.length < 7 || val.length > 10){
            $('.validation_id_pass_iqama').html(this.$t('id_25_digit'));
            $('.validation_id_pass_iqama').slideDown();
            this.errors = 1;
          }
        }
      }
      if(this.errors == 0){
        this.$store.state.guest.title = this.form.title;
        this.$store.state.guest.first_name = this.form.first_name;
        this.$store.state.guest.last_name = this.form.last_name;
        this.$store.state.guest.gender = this.form.gender;
        this.$store.state.guest.country_of_residence = this.form.country_of_residence;
        this.$store.state.guest.nationality = this.form.nationality;
        this.$store.state.guest.id_pass_iqama = this.form.id_pass_iqama;
        this.$store.state.guest.full_name = this.form.full_name;
        this.$store.state.guest.passport_type = this.form.passport_type;
        this.$store.state.guest.place_of_birth = this.form.place_of_birth;
        this.$store.state.guest.date_of_birth = this.form.date_of_birth;
        this.$router.push({ path: '/registration-guest/step3' });
      }
      else{
        $('.alert_error').removeClass('h');
        setTimeout(function () {
            $('.alert_error').addClass("h");;
        }, 5000);
      }
    }
  }
}
</script>
