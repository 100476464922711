<template>
  <div class="mt100 c pb50 mb50">
    <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
      <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
      <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }} {{ $t('as_media_staff') }}</div>
      <div class="mt10 l w100">
        <div class="bg-prograss-bar prograss-bar">
          <div class="prograss-bar ov ra w100 g_nm bg_orange"></div>
        </div>
        <div class="mt10 c-blue">{{ $t('step2') }}</div>
        <div class="mt10 b">{{ $t('basic_registration') }}</div>
        <form class="pv10" @submit.prevent="submit_form">
          <!-- START ID -->
          <div v-if="$store.state.media_staff_user.nationality ==194 || $store.state.media_staff_user.country_of_residence ==194"></div>
          <div v-else class="mt15" >
            <label v-if="$store.state.no_visa_countries.indexOf($store.state.media_staff_user.nationality) != -1" class="label_fields">{{$t('id_number')}} *</label>
            <label v-else-if="$store.state.media_staff_user.country_of_residence == 194 && $store.state.no_visa_countries.indexOf($store.state.media_staff_user.nationality) == -1" class="label_fields">{{$t('iqama')}} *</label>
            <label v-else class="label_fields">{{$t('passport_number')}} *</label>
            <input v-if="$store.state.media_staff_user.nationality ==194" :disabled="view_only_all == 1" class="input_fields" v-model="form.id_pass_iqama" type="text" maxlength="10"/>
            <input v-else-if="$store.state.media_staff_user.country_of_residence == 194 && $store.state.no_visa_countries.indexOf($store.state.media_staff_user.nationality) == -1" :disabled="view_only_all == 1" class="input_fields" v-model="form.id_pass_iqama" type="text" maxlength="10"/>
            <input v-else :disabled="view_only_all == 1" class="input_fields" v-model="form.id_pass_iqama" type="text" maxlength="10"/>
            <div class="validation_id_pass_iqama validation_warning"></div>
          </div>
          <!-- CLOSE ID -->
          <!-- <div class="mt15" v-if="($store.state.media_staff_user.nationality) != 194 && $store.state.media_staff_user.country_of_residence != 194"> -->
          <!-- START PASSPORT TYPE AND EXPIRY DATE -->
          <div v-if="$store.state.no_visa_countries.indexOf($store.state.media_staff_user.nationality) != -1 || $store.state.media_staff_user.country_of_residence == 194"></div>
          <tbl v-else>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{$t('passport_type')}} *</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.passport_type" :options="passport_type"/>
                <div class="validatePassportType validation_warning"></div>
              </div>
            </cl>
            <cl class="vt colSep"></cl>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{$t('pass_expire_date')}} *</label>
                <input  :disabled="view_only_all == 1"  class="input_fields select_input expiry_date" placeholder="" v-model="form.passport_expiry_date" min="2023-09-09" type="date"/>
                <div class="validatePassportExpiryDate validation_warning"></div>
              </div>
            </cl>
          </tbl>
          <!-- CLOSE PASSPORT TYPE AND EXPIRY DATE -->
          <!-- START WORKPLACE -->
          <div v-if="this.$store.state.media_staff_user.nationality != 194 && this.$store.state.media_staff_user.country_of_residence != 194" class="mt15">
            <label class="label_fields">{{$t('work_place')}} *</label>
            <input :disabled="view_only_all == 1" class="input_fields" v-model="form.work_place" type="text"/>
            <div class="validate_work_place validation_warning"></div>
          </div>
          <!-- CLOSE WORKPLACE -->
          <!-- START JOBTITLE -->
          <div v-if="this.$store.state.media_staff_user.nationality != 194 && this.$store.state.media_staff_user.country_of_residence != 194" class="mt15">
            <label class="label_fields">{{$t('job_title')}} *</label>
            <input :disabled="view_only_all == 1" class="input_fields" v-model="form.job_title" type="text"/>
            <div class="validate_job_title validation_warning"></div>
          </div>
          <!-- CLOSE JOBTITLE -->
          <!-- START PHONE -->
          <!-- <div class="mt15">
            <label class="label_fields mb8">{{ $t('phone') }}</label>
            <div class="pv4"></div>
              <MazPhoneNumberInput :disabled="view_only_all == 1" v-model="form.phone" fetch-country="true" :default-phone-number="form.phone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results = $event" :success="results?.isValid" style="direction:ltr !important;"/>
            <div class="validation_phone validation_warning"></div>
          </div> -->
          <!-- CLOSE PHONE -->
          <!-- START PHONE -->
        <div class="mt15">
          <label class="label_fields mb8">{{ $t('mobile_phone') }} *</label>
          <div class="pv4"></div>
            <MazPhoneNumberInput :disabled="view_only_all == 1" v-model="form.phone" :default-phone-number="form.phone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results = $event" :success="results?.isValid" style="direction:ltr !important"/>
          <div class="validation_phone validation_warning"></div>
        </div>
        <!-- CLOSE PHONE -->
        <!-- START PHONE -->
        <div v-if="this.$store.state.media_staff_user.nationality != 194 && this.$store.state.media_staff_user.country_of_residence != 194" class="mt15">
          <label class="label_fields mb8">{{ $t('telephone') }}</label>
          <div class="pv4"></div>
            <MazPhoneNumberInput :disabled="view_only_all == 1" v-model="form.telephone" :default-phone-number="form.telephone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results2 = $event" :success="results2?.isValid" style="direction:ltr !important"/>
        </div>
          <!-- START EMAIL -->
          <div class="mt15 mb15">
            <label class="label_fields">{{ $t('email_address') }}</label>
            <input disabled  class="input_fields" v-model="form.email" v-bind:placeholder="$t('email_address')" type="email"/>
            <div class="validation_email validation_warning"></div>
          </div>
          <!-- CLOSE EMAIL -->
          <!-- START ROLE -->
          <div v-if="this.$store.state.media_staff_user.nationality != 194 && this.$store.state.media_staff_user.country_of_residence != 194" class="mt15">
              <label class="label_fields">{{ $t('job') }}</label>
              <input disabled  class="input_fields" v-model="form.jobfield" v-bind:placeholder="$t('job')" type="text" readonly/>
              <div class="validation_job validation_warning"></div>
            </div>
            <!-- CLOSE ROLE -->
            <div v-if="this.$store.state.media_staff_user.nationality != 194 && this.$store.state.media_staff_user.country_of_residence != 194" class="mt15">
              <label class="label_fields">{{$t('gender')}}</label>
              <br>
              <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="form.gender" value="male">{{ $t('male') }}
              <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="form.gender" value="female">{{ $t('female') }}
              <div class="validation_gender validation_warning"></div>
            </div>
            <div v-if="this.$store.state.media_staff_user.nationality != 194 && this.$store.state.media_staff_user.country_of_residence != 194" class="mt15">
              <tbl_nm>
                <label class="pointer">
                  <cl class="w5">
                    <div class="bg-blue p25 round100" style="width:30px;">
                      <span v-if="form.personalimage == '' || form.personalimage == null " class="cc mdi mdi-image-outline fs20 cw"></span>
                      <img v-else :src='$store.state.baseURLImage+form.personalimage' style="width:50px; height:50px;" class="cc round100 cw" />
                    </div>
                  </cl>
                  <cl class="colSep"></cl>
                  <cl class="wa" style="max-width:250px;">
                    <label class="label_fields">{{ $t('personalphoto') }} *</label>
                      <input :disabled="view_only_all == 1" type="file" id="fileaa" ref="fileaa"  @change="uploadImagepersonal()" accept="image/*" hidden/>
                      <div class="fs12 nw oe image_textpersonal">
                        {{ $t('image_text') }}
                      </div>
                  </cl>
                </label>
                  <cl class="colSep"></cl>
                  <cl class="w5 r" style="min-width:25px;">
                    <span class="mdi mdi-image-multiple-outline c-blue fs20"></span>
                    <span class="mdi mdi-information-outline pointer c-blue fs20" @click="showpop_image = 1"></span>
                  </cl>
                </tbl_nm>
              <div class="validate_logopersonal validation_warning"></div>
            </div>

            <div v-if="this.$store.state.media_staff_user.nationality != 194 && this.$store.state.media_staff_user.country_of_residence != 194" class="mt15">
                <tbl_nm>
                <label class="pointer">
                  <cl class="w5">
                    <div class="bg-blue p25 round100" style="width:30px;">
                      <span v-if="form.passportimage == '' || form.passportimage == null " class="cc mdi mdi-image-outline fs20 cw"></span>
                      <img v-else :src='$store.state.baseURLImage+form.passportimage' style="width:50px; height:50px;" class="cc round100 cw" />
                    </div>
                  </cl>
                  <cl class="colSep"></cl>
                  <cl class="wa" style="max-width:250px;">
                    <label v-if="$store.state.no_visa_countries.indexOf(form.nationality) != -1" class="label_fields">{{$t('IDphoto')}} *</label>
                    <label v-else class="label_fields">{{$t('passportphoto')}} *</label>
                    <input :disabled="view_only_all == 1" type="file" id="file" ref="file"  @change="uploadImage()" accept="image/*" hidden/>
                      <div class="fs12 nw oe image_text">
                        {{ $t('image_text') }}
                      </div>
                  </cl>
                </label>
                  <cl class="colSep"></cl>
                  <cl class="w5 r" style="min-width:25px;">
                    <span class="mdi mdi-image-multiple-outline c-blue fs20"></span>
                    <span class="mdi mdi-information-outline pointer c-blue fs20" @click="showpop_image = 1"></span>
                  </cl>
                </tbl_nm>
              <div class="validate_logopassport validation_warning"></div>
            </div>
            <div class="mt15">
              <label class="label_fields">{{ $t('media_company') }}</label>
              <input :disabled="view_only_all == 1"  class="input_fields" v-model="form.media_company" v-bind:placeholder="$t('media_company')" type="text"/>
              <div class="validation_media_company validation_warning"></div>
            </div>
            <div class="mt20 r">
            <button v-if="view_only_all == 0" @click="submit()" class="next_btn ph20 ml20">
              <div v-if="disabled_submit" class="mt10 mb10 ml5 mr5 rotatingLoader" ></div>
              <span v-if="!disabled_submit" class="cw lh40 fs18" >{{$t('next')}}</span>
              <!-- <span v-if="$i18n.locale == 'en' && !disabled_submit" class="cw mdi mdi-arrow-right lh40 fs30"></span>
              <span v-if="$i18n.locale == 'ar' && !disabled_submit" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="popup_descimage round2x l" v-if="showpop_image == 1" style="z-index:9999999999;">
    <div class="popupdesc_img_close ov20 pointer la mr20 ba" @click="showpop_image = 0">{{$t('close')}}</div>
    <h2>{{$t('photo_specs')}}</h2>
    <p>{{$t('photo_specs_title')}}</p>
    <ul class="popdesc_ul_list">
      <li>{{$t('photo_specs_li_1')}}</li>
      <li>{{$t('photo_specs_li_2')}}</li>
      <li>{{$t('photo_specs_li_3')}}</li>
      <li>{{$t('photo_specs_li_4')}}</li>
      <li>{{$t('photo_specs_li_5')}}</li>
      <li>{{$t('photo_specs_li_6')}}</li>
      <li>{{$t('photo_specs_li_7')}}</li>
      <li>{{$t('photo_specs_li_8')}}</li>
      <li>{{$t('photo_specs_li_9')}}</li>
      <li>{{$t('photo_specs_li_10')}}</li>
      <li>{{$t('photo_specs_li_11')}}</li>
      <li>{{$t('photo_specs_li_12')}}</li>
      <li>{{$t('photo_specs_li_13')}}</li>
    </ul>
  </div>
</template>
<script>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import { ref } from 'vue';
const results = ref();
const results2 = ref();
export default {
  name: 'step2mediastaff',
    components: { MazPhoneNumberInput },

  data() {
    return {
      view_only_all: 0,
      errors:0,
      showpop_image:0,
      disabled_submit: false,
      logo_image: (this.$store.state.baseURLuploadImage),
      logo_imagepassport: (this.$store.state.baseURLuploadImage),
      main_logo_image: (this.$store.state.baseURLuploadImage),
      main_logo_imagepassport: (this.$store.state.baseURLuploadImage),
      form: {
        id_pass_iqama:"",
        phone:"",
        personalimage: "",
        passportimage: "",
        email:"",
        confirmemail:"",
        gender:'',
        jobfield:"",
        passport_type:"",
        work_place:'',
        job_title:'',
        telephone:"",
        passport_expiry_date:'',
        media_company:"",


      }
    }
  },
  computed:{
    passport_type(){
      if(this.$i18n.locale == 'ar'){
          return this.$store.state.ar_passport_type;
      }
      return this.$store.state.en_passport_type;
    }
  },
  methods: {
    validatedata(formname,validatefieldclass, text) {
    if (formname == "" || formname == null || formname == 0) {
        console.log(formname);
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        this.errors = 1;
        } else {
        $(validatefieldclass).slideUp();
        }
    },
    validateSAID(id) {
      id = id.trim();
      if (isNaN(parseInt(id))) {
        return -1;
      }
      if (id.length !== 10) {
        return -1;
      }
      var type = id.substr(0, 1);
      if (type !== '2' && type !== '1') {
        return -1;
      }
      var sum = 0;
      for (var i = 0; i < 10; i++) {
        if (i % 2 === 0) {
          var ZFOdd = String('00' + String(Number(id.substr(i, 1)) * 2)).slice(-2);
          sum += Number(ZFOdd.substr(0, 1)) + Number(ZFOdd.substr(1, 1));
        }
        else {
          sum += Number(id.substr(i, 1));
        }
      }
      return (sum % 10 !== 0) ? -1 : type;
    },
    uploadImagepersonal() {
      const uploadurl = this.$store.state.baseURLuploadImage + "upload_image.php";
      this.file = this.$refs.fileaa.files[0];
      $('.image_textpersonal').html(this.file.name)
      let formData = new FormData();
      var logoname = Date.now();
      var path_title="personal";
      logoname=path_title+"_"+logoname +""+Math.floor(Math.random() * 10000);
      formData.append('file', this.file);
      formData.append('image_name', logoname);
      formData.append('path', path_title);
      var self = this;
      this.axios.post(uploadurl, formData,{headers: {'Content-Type': 'multipart/form-data'}}).then(function (response) {
        if(response.data == 0){
          alert('File not uploaded.');
          // console.log(response.data);
          $(".validate_logopersonal").html("Problem with uploading the Image");
          $(".validate_logopersonal").slideDown();
            self.errors = 1;
        }else if(response.data == 1){
          $(".validate_logopersonal").html("Image size exceeds 4MB");
          $(".validate_logopersonal").slideDown();
            self.errors = 1;
        }else if(response.data == 2){
          $(".validate_logopersonal").html("Upload valid images Only");
          $(".validate_logopersonal").slideDown();
            self.errors = 1;
        }else{
            self.errors = 0;
              $(".validate_logopersonal").slideUp();
          // console.log(response.data);
          self.form.personalimage=response.data;
          console.log(self.form.personalimage);
          self.logo_image=self.main_logo_image+"images/"+response.data;
          // alert('File uploaded successfully.');
        }
      })
      .catch(function (error) {
        // console.log(error);
        });
    },

    uploadImage() {
      const uploadurl = this.$store.state.baseURLuploadImage + "upload_image.php";
      this.file = this.$refs.file.files[0];
      $('.image_text').html(this.file.name)
      let formData = new FormData();
      var logoname = Date.now();
      var path_title="passport";
      logoname=path_title+"_"+logoname +""+Math.floor(Math.random() * 10000);
      formData.append('file', this.file);
      formData.append('image_name', logoname);
      formData.append('path', path_title);
      var self = this;
      this.axios.post(uploadurl, formData,{headers: {'Content-Type': 'multipart/form-data'}}).then(function (response) {
        if(response.data == 0){
          alert('File not uploaded.');
          // console.log(response.data);
          $(".validate_logo").html("Problem with uploading the Image");
          $(".validate_logo").slideDown();
            self.errors = 1;
        }else if(response.data == 1){
          $(".validate_logo").html("Image size exceeds 4MB");
          $(".validate_logo").slideDown();
            self.errors = 1;
        }else if(response.data == 2){
          $(".validate_logo").html("Upload valid images Only");
          $(".validate_logo").slideDown();
            self.errors = 1;
        }else{
            self.errors = 0;
              $(".validate_logo").slideUp();
          // console.log(response.data);
          self.form.passportimage=response.data;
          self.logo_imagepassport=self.main_logo_imagepassport+"images/"+response.data;
          // alert('File uploaded successfully.');
        }
      })
      .catch(function (error) {
        // console.log(error);
        });
    },
    submit() {
      if(this.disabled_submit == false){
        $('.next_btn').addClass('disabled_btn');
        this.disabled_submit=true;
        this.$emit('submit', this.form);
        this.errors=0;
        if (this.form.phone == "" || this.form.phone == null || this.form.phone == 0) {
          $(".validation_phone").html(this.$t('filed_is_required'));
          $(".validation_phone").slideDown();
          this.errors = 1;
          console.log("eror3");
        }
        else {
          if( !this.results.isValid){
            this.errors = 1;
            $(".validation_phone").html(this.$t('phone_not_valid'));
            $(".validation_phone").slideDown();
          }
          else{
            $(".validation_phone").slideUp();
          }
        }

        var res = this.$store.state.no_visa_countries.indexOf(this.$store.state.media_staff_user.nationality);
        if(res == -1 && this.$store.state.media_staff_user.country_of_residence !=194){
          this.validatedata(this.form.passport_type,".validatePassportType", this.$t('passport_type_is_required'));
          this.validatedata(this.form.passport_expiry_date,".validatePassportExpiryDate", this.$t('passport_expiry_is_required'));
        }

        if(this.$store.state.media_staff_user.nationality != 194 && this.$store.state.media_staff_user.country_of_residence !=194){
          this.validatedata(this.form.work_place,".validate_work_place", this.$t('work_place_is_required'));
          this.validatedata(this.form.job_title,".validate_job_title", this.$t('job_title_is_required'));
          if (this.form.passportimage == "" || this.form.passportimage == null || this.form.passportimage == 0) {
            $(".validate_logo").html(this.$t('personal_image_is_required'));
            $(".validate_logo").slideDown();
            this.errors = 1;
          } else {
            $(".validate_logo").slideUp();
          }
          if (this.form.personalimage == "" || this.form.personalimage == null || this.form.personalimage == 0) {
            $(".validate_logopersonal").html(this.$t('photo_is_required'));
            $(".validate_logopersonal").slideDown();
            this.errors = 1;
          } else {
            $(".validate_logopersonal").slideUp();
          }
        this.validatedata(this.form.gender,".validation_gender", this.$t('gender_is_required'));
        if (this.form.id_pass_iqama == "" || this.form.id_pass_iqama == null || this.form.id_pass_iqama == 0) {
          $('.validation_id_pass_iqama').html(this.$t('filed_is_required'));
          $('.validation_id_pass_iqama').slideDown();
          this.errors = 1;
        }
        else{
          var res = this.$store.state.no_visa_countries.indexOf(this.$store.state.media_staff_user.nationality);
          var val = this.form.id_pass_iqama;
          if(this.$store.state.media_staff_user.nationality == 194){
            var x=this.validateSAID(this.form.id_pass_iqama);
            if(x == -1 ){
              $('.validation_id_pass_iqama').html(this.$t('invalid_id'));
              $('.validation_id_pass_iqama').slideDown();
              this.errors = 1;
            }
            else{
              $('.validation_id_pass_iqama').slideUp();
            }
          }
          else if(this.$store.state.media_staff_user.country_of_residence == 194 && res ==-1){
            if( /^[0-9]+$/.test(val)){
              if (val.length === 10 && val.toString().indexOf("2") == 0) {
                  $('.validation_id_pass_iqama').slideUp();
              }
              else{
                $('.validation_id_pass_iqama').html(this.$t('id_20_digit'));
                $('.validation_id_pass_iqama').slideDown();
                this.errors = 1;
              }
            }
            else{
              $('.validation_id_pass_iqama').html(this.$t('enter_only_number'));
              $('.validation_id_pass_iqama').slideDown();
              this.errors = 1;
            }
          }else{
            if (val.length > 10 || val.length < 7){
              $('.validation_id_pass_iqama').html(this.$t('id_25_digit'));
              $('.validation_id_pass_iqama').slideDown();
              this.errors = 1;
            }
          }
        }
        }
        this.validatedata(this.form.email,".validation_email", this.$t('email_is_required'));
        this.validatedata(this.form.media_company,".validation_media_company", this.$t('media_company_is_required'));

        if(this.errors==0){
          this.$store.state.media_staff_user.id_pass_iqama=this.form.id_pass_iqama;
          this.$store.state.media_staff_user.phone=this.form.phone;
          this.$store.state.media_staff_user.email=this.form.email;
          this.$store.state.media_staff_user.gender=this.form.gender;
          this.$store.state.media_staff_user.media_company=this.form.media_company;
          this.$store.state.media_staff_user.job =this.form.jobfield;
          this.$store.state.media_staff_user.passport_type=this.form.passport_type;
          this.$store.state.visa.type_of_user = "media_staff";
          this.$store.state.visa.user_token_key = this.$store.state.media_staff_user.token_key;
          this.$store.state.visa.nationality = this.$store.state.media_staff_user.nationality;
          this.$store.state.visa.country_of_residence = this.$store.state.media_staff_user.country_of_residence;
          this.$store.state.visa.userID =this.$store.state.media_staff_user.id;
          this.$store.state.visa.gender = this.$store.state.media_staff_user.gender;
          this.$store.state.visa.full_name = this.$store.state.media_staff_user.full_name;
          this.$store.state.visa.passport_expiry_date = this.$store.state.media_staff_user.passport_expiry_date;
          this.$store.state.visa.date_of_birth = this.$store.state.media_staff_user.date_of_birth;
          this.$store.state.visa.place_of_birth = this.$store.state.media_staff_user.place_of_birth;
          this.$store.state.visa.passport_type = this.$store.state.media_staff_user.passport_type;
          this.axios.post(this.$store.state.baseURL + "check-phone", {
            token_key: this.$store.state.media_staff_user.token_key,
            phone:this.form.phone
          })
          .then((response) => {
            if(response.data.success == true){
              var res = this.$store.state.no_visa_countries.indexOf(this.$store.state.media_staff_user.nationality);
              if(this.form.telephone){}else{this.form.telephone=null;}
              if( this.$store.state.media_staff_user.country_of_residence == 194 || res != -1){
                this.axios.post(this.$store.state.baseURL + "add-staff-media", {
                  mediaa_id: this.$store.state.media_staff_user.mediaa_id,
                  title: this.$store.state.media_staff_user.title,
                  first_name: this.$store.state.media_staff_user.first_name,
                  last_name: this.$store.state.media_staff_user.last_name,
                  full_name: this.$store.state.media_staff_user.full_name,
                  place_of_birth: this.$store.state.media_staff_user.place_of_birth,
                  date_of_birth: this.$store.state.media_staff_user.date_of_birth,
                  address: this.$store.state.media_staff_user.address,
                  company_name:this.$store.state.media_staff_user.media_company,
                  qualification: this.$store.state.media_staff_user.qualification,
                  nationality: this.$store.state.media_staff_user.nationality,
                  country_of_residence: this.$store.state.media_staff_user.country_of_residence,
                  email: this.$store.state.media_staff_user.email,
                  work_place: this.form.work_place,
                  job_title: this.form.job_title,
                  personal_photo: this.form.personalimage,
                  passport_image: this.form.passportimage,
                  telephone:this.form.telephone,
                  passport_type: this.$store.state.media_staff_user.passport_type,
                  gender: this.$store.state.media_staff_user.gender,
                  phone: this.$store.state.media_staff_user.phone,
                  job: this.$store.state.media_staff_user.job,
                  passport_expiry_date: this.form.passport_expiry_date,
                  id_pass_iqama: this.$store.state.media_staff_user.id_pass_iqama,
                  passport_expiry_date: this.form.passport_expiry_date,
                  linked_in: this.$store.state.media_staff_user.linked_in  ,
                  twitter: this.$store.state.media_staff_user.twitter  ,
                  facebook: this.$store.state.media_staff_user.facebook  ,
                  instagram: this.$store.state.media_staff_user.instagram  ,
                  snapchat: this.$store.state.media_staff_user.snapchat  ,
                  token_key: this.$store.state.media_staff_user.token_key,
                  status:"",
                  visa_status: "not required",
                  flight_status: "not required",
                  hotel_status: "not required",
                }).then((response) => {
                  if(response.data.success == true){
                    this.$router.push({ path: "/registration/thankyou" });
                  }
                  else{
                    this.$router.push({ path: '/registration/failed' });
                  }
                }).catch((e) => {
                  $('.next_btn').removeClass('disabled_btn');
                  this.disabled_submit=false;
                })
              }
              else{
                this.axios.post(this.$store.state.baseURL + "add-staff-media", {
                  mediaa_id: this.$store.state.media_staff_user.mediaa_id,
                  title: this.$store.state.media_staff_user.title,
                  first_name: this.$store.state.media_staff_user.first_name,
                  last_name: this.$store.state.media_staff_user.last_name,
                  full_name: this.$store.state.media_staff_user.full_name,
                  place_of_birth: this.$store.state.media_staff_user.place_of_birth,
                  date_of_birth: this.$store.state.media_staff_user.date_of_birth,
                  address: this.$store.state.media_staff_user.address,
                  company_name:this.$store.state.media_staff_user.media_company,
                  qualification: this.$store.state.media_staff_user.qualification,
                  nationality: this.$store.state.media_staff_user.nationality,
                  country_of_residence: this.$store.state.media_staff_user.country_of_residence,
                  email: this.$store.state.media_staff_user.email,
                  work_place: this.form.work_place,
                  job_title: this.form.job_title,
                  personal_photo: this.form.personalimage,
                  passport_image: this.form.passportimage,
                  telephone:this.form.telephone,
                  passport_type: this.$store.state.media_staff_user.passport_type,
                  gender: this.$store.state.media_staff_user.gender,
                  phone: this.$store.state.media_staff_user.phone,
                  job: this.$store.state.media_staff_user.job,
                  // job: "dasd",
                  passport_expiry_date: this.form.passport_expiry_date,
                  id_pass_iqama: this.$store.state.media_staff_user.id_pass_iqama,
                  passport_expiry_date: this.form.passport_expiry_date,
                  linked_in: this.$store.state.media_staff_user.linked_in  ,
                  twitter: this.$store.state.media_staff_user.twitter  ,
                  facebook: this.$store.state.media_staff_user.facebook  ,
                  instagram: this.$store.state.media_staff_user.instagram  ,
                  snapchat: this.$store.state.media_staff_user.snapchat  ,
                  token_key: this.$store.state.media_staff_user.token_key,
                  status:"",
                  visa_status: "new",
                  flight_status: "new",
                  hotel_status: "new",
                }).then((response) => {
                  this.$store.state.loader = 0;
                  this.$store.state.media_staff_user.token_key;
                  this.disabled_submit=false;
                  if(response.data.success == true){
                    if (response.data.data.visa_status != "new") {
                      this.$router.push({ path: "/registration/thankyou" });
                    } else {
                      this.$router.push({ path: "/registration/visa/step1" });
                    }
                  }
                  else{
                    this.$router.push({ path: '/registration/failed' });
                  }
                }).catch((e) => {
                  $('.next_btn').removeClass('disabled_btn');
                  this.disabled_submit=false;
                });
              }
            }
            else{
              $(".validation_phone").html(this.$t('duplicated_phone'));
              $(".validation_phone").slideDown();
              $('.alert_error').removeClass('h');
              setTimeout(function () {
                $('.alert_error').addClass("h");;
              }, 5000);
              $('.next_btn').removeClass('disabled_btn');
              this.disabled_submit=false;
            }
          })
          .catch((e) => {});
        }
        else{
          $('.next_btn').removeClass('disabled_btn');
          this.disabled_submit=false;
          $('.alert_error').removeClass('h');
          setTimeout(function () {
            $('.alert_error').addClass("h");
          }, 5000);
        }
      }

  }},
  created() {
    console.log(this.$store.state.media_staff_user);
    this.$store.state.loader = 1;
    this.form.jobfield=this.$store.state.media_staff_user.job ;
    this.form.media_company= this.$store.state.media_staff_user.company_name
    if (Object.keys(this.$store.state.media_staff_user).length === 0) {
      // alert('Data is Missing! Please Use the Link sent to your Email!');
    }
    else {
      if(this.$store.state.is_view_only == 1){
        this.view_only_all=1;
      }
      var objectrespons=this.$store.state.media_staff_user;
      var objectresponsecuritycheck=this.$store.state.media_staff_user_secuirty;
      if(objectresponsecuritycheck == null){
      }else{
        this.form.personalimage= objectresponsecuritycheck.personal_photo;
        this.form.passportimage= objectresponsecuritycheck.passport_image;
        this.form.passport_type=objectresponsecuritycheck.passport_type;
        this.form.telephone=objectresponsecuritycheck.telephone;
        this.form.work_place=objectresponsecuritycheck.work_place;
        this.form.job_title = objectresponsecuritycheck.job_title;
        this.form.passport_expiry_date = objectresponsecuritycheck.passport_expiry_date;
        this.logo_image=this.logo_image+"images/"+ objectresponsecuritycheck.personal_photo;
        this.logo_imagepassport=this.logo_imagepassport+"images/"+objectresponsecuritycheck.passport_image;
      }
      this.form.gender=objectrespons.gender;
      this.form.id_pass_iqama=objectrespons.id_pass_iqama;
      this.form.email= this.$store.state.media_staff_user.email,
      this.form.jobfield=objectrespons.job;
      this.form.phone=objectrespons.phone;
      this.$store.state.loader = 0;
    }
  },
}
</script>
