<template>
<div class="mt100 c mb50">
  <div class="pv20 g_nm round2x ph40 c w33 md_w60 m_w75 m_m_w100 bgw sh10">
    <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
    <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }} {{ $t('as_vip_staff') }}</div>
    <div class="mt10 l w100">
      <div class="bg-prograss-bar prograss-bar">
        <div class="prograss-bar ov ra w100 g_nm bg_orange"></div>
      </div>
      <div class="mt10 c-blue">{{ $t('step2') }}</div>
      <div class="mt10 b">{{ $t('basic_registration') }}</div>
      <form class="pv10" @submit.prevent="submit_form">
        <!-- START GENDER -->
        <div class="mt15">
          <label class="label_fields">{{$t('gender')}}</label>
          <br>
          <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="form.gender" value="male">Male
          <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="form.gender" value="female">Female
          <div class="validation_gender validation_warning"></div>
        </div>
        <!-- CLOSE GENDER -->
        <!-- START PHONE -->
        <div class="mt15">
          <label class="label_fields mb8">{{ $t('mobile_phone') }} *</label>
          <div class="pv4"></div>
          <MazPhoneNumberInput v-model="form.phone" :default-phone-number="form.phone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results = $event" :success="results?.isValid"
            style="direction:ltr !important;" />
          <div class="validatePhoneNumber validation_warning"></div>
        </div>
        <!-- CLOSE PHONE -->
        <!-- START TELEPHONE -->
        <div class="mt15">
          <label class="label_fields mb8">{{ $t('telephone') }}</label>
          <div class="pv4"></div>
          <MazPhoneNumberInput v-model="form.telephone" :default-phone-number="form.telephone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results2 = $event" :success="results2?.isValid"
            style="direction:ltr !important" />
        </div>
        <!-- CLOSE TELEPHONE -->
        <!-- START QUALIFICATION -->
        <div class="mt15">
          <label class="label_fields">{{$t('qualification')}} *</label>
          <Select2 :disabled="view_only_all == 1" v-model="form.qualification" :options="qualification_list" />
          <div class="validate_qualification validation_warning"></div>
        </div>
        <!-- CLOSE QUALIFICATION -->
        <!-- START WORKPLACE -->
        <div class="mt15">
          <label class="label_fields">{{$t('work_place')}} *</label>
          <input :disabled="view_only_all == 1" class="input_fields" v-model="form.work_place" type="text" />
          <div class="validate_work_place validation_warning"></div>
        </div>
        <!-- CLOSE WORKPLACE -->
        <!-- START JOBTITLE -->
        <div class="mt15">
          <label class="label_fields">{{$t('job_title')}} *</label>
          <input :disabled="view_only_all == 1" class="input_fields" v-model="form.job_title" type="text" />
          <div class="validate_job_title validation_warning"></div>
        </div>
        <!-- CLOSE JOBTITLE -->
        <!-- START ADDRESS -->
        <div class="mt15">
          <label class="label_fields">{{$t('address')}} *</label>
          <input :disabled="view_only_all == 1" class="input_fields" v-model="form.address" type="text" />
          <div class="validate_address validation_warning"></div>
        </div>
        <!-- CLOSE ADDRESS -->
        <!-- START FACEBOOK -->
        <div class="mt15">
          <label class="label_fields">{{$t('do_you_have_facebook')}}</label>
          <br>
          <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveFacebook" value="1">{{ $t('yes') }}
          <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveFacebook" value="0">{{ $t('no') }}
        </div>
        <div v-if="HaveFacebook == '1'" class="mt10">
          <input :disabled="view_only_all == 1" class="sm ph10 input_fields" v-model="form.facebook" v-bind:placeholder="$t('facebook')" type="text" />
          <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue mdi mdi-facebook"></span></div>
          <div class="validate_facebook validation_warning"></div>
        </div>
        <!-- CLOSE FACEBOOK -->
        <!-- START TWITTER -->
        <div class="mt15">
          <label class="label_fields">{{$t('do_you_have_twitter')}}</label>
          <br>
          <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveTwitter" value="1">{{ $t('yes') }}
          <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveTwitter" value="0">{{ $t('no') }}
          <div class="validateGender validation_warning"></div>
        </div>
        <div v-if="HaveTwitter == '1'" class="mt10">
          <input :disabled="view_only_all == 1" class="sm ph10 input_fields" v-model="form.twitter" v-bind:placeholder="$t('twitter')" type="text" />
          <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue icon-x-twitter"></span></div>
          <div class="validate_twitter validation_warning"></div>
        </div>
        <div class="mt15">
          <label class="label_fields">{{ $t('category') }}</label>
          <select :disabled="view_only_all == 1" class="input_fields select_input" v-model="form.category" style="direction:ltr !important">
            <option value="0" selected disabled>{{ $t('select_category') }}</option>
            <option v-for="category_item in category_list" :key="category_item.id" :value="category_item.id">
              {{ category_item.title }}
            </option>
          </select>
          <div class="validation_category validation_warning"></div>
        </div>
        <!-- CLOSE TWITTER -->
        <div class="mt20 r">
          <button v-if="$store.state.is_view_only == 0" @click="submit" class="next_btn ph20 ml20">
            <div v-if="disabled_submit" class="mt10 mb10 ml5 mr5 rotatingLoader"></div>
            <span v-if="!disabled_submit" class="cw lh40 fs18" >{{$t('next')}}</span>
            <!-- <span v-if="$i18n.locale == 'en' && !disabled_submit" class="cw mdi mdi-arrow-right lh40 fs30"></span>
            <span v-if="$i18n.locale == 'ar' && !disabled_submit" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>
<script>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import {
  ref
} from 'vue';
const results = ref();
export default {
  name: 'step2vip',
  components: {
    MazPhoneNumberInput
  },

  data() {
    return {
      errors: 0,
      disabled_submit: false,
      view_only_all: 0,
      category_list: null,
      ar_qualification: [{
          id: 1,
          text: 'ثانوي'
        },
        {
          id: 2,
          text: 'بكالوريس'
        },
        {
          id: 3,
          text: 'ماجستير'
        },
        {
          id: 4,
          text: 'دكتوراه'
        },
      ],
      en_qualification: [{
          id: 1,
          text: 'Secondary'
        },
        {
          id: 2,
          text: 'Bachelor\'s degree'
        },
        {
          id: 3,
          text: 'Master\'s'
        },
        {
          id: 4,
          text: 'Ph.D'
        },
      ],
      HaveFacebook: 0,
      HaveTwitter: 0,
      form: {
        gender: "",
        phone: "",
        telephone: "",
        qualification: "",
        work_place: "",
        job_title: "",
        category: "",
        address: "",
        facebook: "",
        twitter: "",
        address: "",
      }
    }
  },
  computed: {
    qualification_list() {
      if (this.$i18n.locale == 'ar') {
        return this.ar_qualification
      }
      return this.en_qualification
    }
  },
  methods: {
    validatedata(formname, validatefieldclass, text) {
      if (formname == "" || formname == null || formname == 0) {
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        this.errors = 1;
      } else {
        $(validatefieldclass).slideUp();
      }
    },
    submit() {
      if (this.disabled_submit == false) {
        $('.next_btn').addClass('disabled_btn');
        this.disabled_submit = true;
        this.errors = 0;
        this.$emit('submit', this.form);
        if (this.form.gender == "" || this.form.gender == null || this.form.gender == 0) {
          $(".validation_gender").html(this.$t('gender_is_required'));
          $(".validation_gender").slideDown();
          //console.log("eerorr1");
          this.errors = 1;
        } else {
          $(".validation_gender").slideUp();

        }

        if (this.form.phone == "" || this.form.phone == null || this.form.phone == 0) {
          $(".validatePhoneNumber").html(this.$t('filed_is_required'));
          $(".validatePhoneNumber").slideDown();
          //console.log("eerorr2");
          this.errors = 1;
        } else {
          if (!this.results.isValid) {
            this.errors = 1;
            $(".validatePhoneNumber").html(this.$t('phone_not_valid'));
            $(".validatePhoneNumber").slideDown();
            //  console.log("eerorr3");
          } else {
            //  console.log("eerorr4");
            $(".validatePhoneNumber").slideUp();
          }
        }
        if (this.HaveFacebook == '1') {
          this.validatedata(this.form.facebook, ".validate_facebook", this.$t('filed_is_required'));
        }
        if (this.HaveTwitter == '1') {
          this.validatedata(this.form.twitter, ".validate_twitter", this.$t('filed_is_required'));
        }
        this.validatedata(this.form.category, ".validation_category", this.$t('category_is_required'));
        this.validatedata(this.form.qualification, ".validate_qualification", this.$t('filed_is_required'));
        this.validatedata(this.form.work_place, ".validate_work_place", this.$t('filed_is_required'));
        this.validatedata(this.form.job_title, ".validate_job_title", this.$t('filed_is_required'));
        this.validatedata(this.form.address, ".validate_address", this.$t('filed_is_required'));
        //  console.log('validation error = '+ this.errors)
        if (this.errors == 0) {
          this.$store.state.visa.type_of_user = "vip_staff";
          this.$store.state.visa.user_token_key = this.$store.state.vip_user.token_key;
          this.$store.state.visa.nationality = this.$store.state.vip_user.nationality;
          this.$store.state.visa.country_of_residence = this.$store.state.vip_user.country_of_residence,
            this.$store.state.visa.userID = this.$store.state.vip_user.id;
          this.$store.state.visa.gender = this.$store.state.vip_user.gender;
          if (this.form.telephone) {} else {
            this.form.telephone = null;
          }
          if (this.form.twitter) {} else {
            this.form.twitter = null;
          }
          if (this.form.facebook) {} else {
            this.form.facebook = null;
          }
          this.$store.state.vip_user.facebook = this.form.facebook;
          this.$store.state.vip_user.twitter = this.form.twitter;
          this.$store.state.vip_user.telephone = this.form.telephone;
          this.$store.state.vip_user.phone = this.form.phone;
          this.$store.state.vip_user.qualification = this.form.qualification;
          this.$store.state.vip_user.work_place = this.form.work_place;
          this.$store.state.vip_user.job_title = this.form.job_title;
          this.$store.state.vip_user.address = this.form.address;
          this.$store.state.vip_user.gender = this.form.gender;
          this.$store.state.vip_user.category = this.form.category;
          var res = this.$store.state.no_visa_countries.indexOf(this.$store.state.vip_user.nationality);
          this.axios.post(this.$store.state.baseURL + "check-phone", {
              token_key: "biban",
              phone: this.form.phone
            })
            .then((response) => {
              if (response.data.success == true) {

                if (this.$store.state.vip_user.country_of_residence == 194 || res != -1) {
                  this.axios.post(this.$store.state.baseURL + "update-vip-staff-info", {
                      gender: this.$store.state.vip_user.gender,
                      phone: this.$store.state.vip_user.phone,
                      telephone: this.$store.state.vip_user.telephone,
                      qualification: this.$store.state.vip_user.qualification,
                      work_place: this.$store.state.vip_user.work_place,
                      job_title: this.$store.state.vip_user.job_title,
                      address: this.$store.state.vip_user.address,
                      facebook: this.$store.state.vip_user.facebook,
                      twitter: this.$store.state.vip_user.twitter,
                      category: this.$store.state.vip_user.category,
                      title: this.$store.state.vip_user.title,
                      first_name: this.$store.state.vip_user.first_name,
                      last_name: this.$store.state.vip_user.last_name,
                      nationality: this.$store.state.vip_user.nationality,
                      country_of_residence: this.$store.state.vip_user.country_of_residence,
                      email: this.$store.state.vip_user.email,
                      id_pass_iqama: this.$store.state.vip_user.id_pass_iqama,
                      full_name: this.$store.state.vip_user.full_name,
                      personal_photo: this.$store.state.vip_user.personal_photo,
                      passport_image: this.$store.state.vip_user.passport_image,
                      place_of_birth: this.$store.state.vip_user.place_of_birth,
                      date_of_birth: this.$store.state.vip_user.date_of_birth,
                      passport_type: this.$store.state.vip_user.passport_type,
                      passport_expiry_date: this.$store.state.vip_user.passport_expiry_date,
                      token_key: this.$store.state.vip_user.token_key,
                      status: "",
                      visa_status: "not required",
                      flight_status: "not required",
                      hotel_status: "not required",
                    })
                    .then((response) => {
                      $('.next_btn').removeClass('disabled_btn');
                      this.disabled_submit = false;
                      this.$router.push({
                        path: "/registration/thankyou"
                      });
                    })
                    .catch((e) => {});
                } else {
                  this.axios.post(this.$store.state.baseURL + "update-vip-staff-info", {
                      gender: this.$store.state.vip_user.gender,
                      phone: this.$store.state.vip_user.phone,
                      telephone: this.$store.state.vip_user.telephone,
                      qualification: this.$store.state.vip_user.qualification,
                      work_place: this.$store.state.vip_user.work_place,
                      job_title: this.$store.state.vip_user.job_title,
                      address: this.$store.state.vip_user.address,
                      facebook: this.$store.state.vip_user.facebook,
                      twitter: this.$store.state.vip_user.twitter,
                      category: this.$store.state.vip_user.category,
                      title: this.$store.state.vip_user.title,
                      first_name: this.$store.state.vip_user.first_name,
                      last_name: this.$store.state.vip_user.last_name,
                      nationality: this.$store.state.vip_user.nationality,
                      country_of_residence: this.$store.state.vip_user.country_of_residence,
                      email: this.$store.state.vip_user.email,
                      id_pass_iqama: this.$store.state.vip_user.id_pass_iqama,
                      full_name: this.$store.state.vip_user.full_name,
                      personal_photo: this.$store.state.vip_user.personal_photo,
                      passport_image: this.$store.state.vip_user.passport_image,
                      place_of_birth: this.$store.state.vip_user.place_of_birth,
                      date_of_birth: this.$store.state.vip_user.date_of_birth,
                      passport_type: this.$store.state.vip_user.passport_type,
                      passport_expiry_date: this.$store.state.vip_user.passport_expiry_date,
                      token_key: this.$store.state.vip_user.token_key,
                      status: "",
                      visa_status: "new",
                      flight_status: "new",
                      hotel_status: "new",
                    })
                    .then((response) => {
                      $('.next_btn').removeClass('disabled_btn');
                      this.disabled_submit = false;
                      if (response.data.data.visa_status != "new") {
                        this.$router.push({
                          path: "/registration/thankyou"
                        });
                      } else {
                        this.$router.push({
                          path: "/registration/visa/step1"
                        });
                      }
                    })
                    .catch((e) => {});
                }
              } else {
                $('.validatePhoneNumber').html(this.$t('duplicated_phone'));
                $('.validatePhoneNumber').slideDown();
                $('.alert_error').removeClass('h');
                setTimeout(function() {
                  $('.alert_error').addClass("h");;
                }, 5000);
              }
            })
            .catch((e) => {});
        } else {
          $('.alert_error').removeClass('h');
          setTimeout(function() {
            $('.alert_error').addClass("h");;
          }, 5000);
          $('.next_btn').removeClass('disabled_btn');
          this.disabled_submit = false;
        }
      }
    }
  },
  created() {
    this.$store.state.loader = 1;
    if (this.$store.state.vip_user.token_key == null || this.$store.state.vip_user.token_key == "") {
      this.$router.push({
        path: '/registration/invalid-token'
      });
    }
    if (this.$store.state.is_view_only == 1) {
      this.view_only_all = 1;
    }
    this.axios.get(this.$store.state.baseURL + 'get-all-categories', {}).then(response => {
        var objectrespons = response.data.data;
        this.category_list = objectrespons;
      })
      .catch(e => {})
    var objectresponsecuritycheck = this.$store.state.vip_user.datasecurity;
    if (objectresponsecuritycheck != null) {
      this.form.telephone = objectresponsecuritycheck.telephone;
      this.form.qualification = objectresponsecuritycheck.qualification;
      this.form.work_place = objectresponsecuritycheck.work_place;
      this.form.job_title = objectresponsecuritycheck.job_title;
      this.form.address = objectresponsecuritycheck.address;
      this.form.facebook = objectresponsecuritycheck.facebook;
      this.form.twitter = objectresponsecuritycheck.twitter;
    }
    if (this.form.facebook) {
      this.HaveFacebook = 1;
    }
    if (this.form.twitter) {
      this.HaveTwitter = 1;
    }
    this.form.phone = this.$store.state.vip_user.phone;
    this.form.gender = this.$store.state.vip_user.gender;
    this.$store.state.loader = 0;
  },

}
</script>
