<template>
  <div class="c mt150">
    <img class="mt150 g_nm" src="@/assets/img/thankyou.png"/>
    <div class="b">{{ $t('registration_completed') }}</div>
    <!-- <div class="fs13 mt5">{{ $t('see_you_at_biban')}}</div> -->
    <div class="fs13 mt5">{{ $t('proccessing_reg')}}</div>


  </div> 
</template>
<script>
export default {
    name: 'guest2',
    data(){
        return {

        }
    },
    methods:{

    },
    created() {

    },

}
</script>
