<template>
  <div class="fs14 bg-gray oh ltr" style="min-height:100vh">
    <div v-if="$store.state.loader" class="pf ov bgb40 overlaydiv"  style="z-index:999999999">
      <div class="cc">
        <div class="fs20 b cw mb20">{{$t('loading')}}</div>
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <div class="ov pf ba pv5 sh20 bgw ltr" style="z-index:999999999">
      <div class=" pf ov ba h alert_error" style="z-index:999999999999">
        <div class="mt20 g_nm sh20 bgw ph15 pv8 round2x" style="border:2px solid red">
          {{$t('error_in_felids')}}
        </div>
      </div>
      <div class="flexi l">
        <div class="fs0">
          <div class="g_nm w50 vm l">
            <img src="../assets/img/Biban-logo-10.svg" alt="profile_image" style="height:50px;" />
          </div>
          <div class="g_nm w50 fs0 r vm">
            <div class="g_nm vm ph8 fs14">
              <div class="g_nm brdr round2x ph2 pointer pv4 lang-main-div"  @click="showLangDiv()">
                <span class="vm mt2 mdi mdi-web c-orange fs20"></span>
                <span class="vm ph4">{{ $t('lang') }}</span>
                <span class="vm mt2 mdi arrow-span mdi-chevron-down fs20 c-orange"></span>
              </div>
              <div class="ov lang-select  ba brdr round2x pv4 c bgw sh20 mt40" hidden>
                <div class="bb pointer"  @click="changeLocale('en')">English</div>
                <div class="pointer"  @click="changeLocale('ar')">عربي</div>
              </div>
            </div>
            <div class="g_nm vm pointer">
              <span class="mdi mdi-apps fs30 c-orange"></span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="m_h ov ba la mt70 mr_70" style="right:-70px;">
      <img src="../assets/img/rightclip.png">
    </div>
    <div class="m_h ov ba ra" style="left:-130px; top:75vh;">
      <img src="../assets/img/leftclip.png">
    </div>

      <router-view class="mt100 mt50 pb100" style="margin-top:50px; margin-bottom:50px;"></router-view>
    <div class="mt80 bgb70">
      <div class="flexi pv10 c cw fs0">
        <div class="c g_nm ph5 vm"><a href="https://www.monshaat.gov.sa"> <img class="g_nm" src="../assets/img/f_logo.svg" style="width:60px;"/></a></div>
        <div class="c g_nm ph5 vm"><a href="https://www.vision2030.gov.sa"> <img class="g_nm" src="../assets/img/2023.svg" style="width:60px;"/></a></div>
        <div class="fs14 ph8 vm g_nm">{{ $t('all_right_1') }}  {{ $t('all_right_2') }}  &#169;	{{ new Date().getFullYear()}} </div>
      </div>
    </div>

      <!-- <div class="ov ta bg-footer " style="z-index:999999999">
        <div class="flexi pv10 cw"> {{ $t('all_right_1') }} {{ new Date().getFullYear()}}<span class="ml30"></span> {{ $t('all_right_2') }}</div>
        <div class="flexi pv10 c cw fs0">
            <div class="c">
              <img class="g_nm" src="../assets/img/logo_monshaat.png"/>
            </div>
            <div class="fs14">
                {{ $t('all_right_1') }} {{ new Date().getFullYear()}}  - {{ $t('all_right_2') }}
            </div>
          </div>
      </div> -->


  </div>
</template>
<script>
// import $ from "jquery";
export default {
  name: "Mainlayout",
  data() {
    return {
      showLangBool: 0,
    }
  },
  methods: {
    showLangDiv(){
      if($('.arrow-span').hasClass('mdi-chevron-up')){
        $('.arrow-span').removeClass('mdi-chevron-up');
        $('.lang-select').slideUp();
      }
      else{
        $('.arrow-span').addClass('mdi-chevron-up');
        $('.lang-select').slideDown();

      }
    },
    changeLocale(locale) {
      console.log('add');
      this.$i18n.locale = locale;
      $('.arrow-span').removeClass('mdi-chevron-up');
      $('.lang-select').slideUp();
      if(locale == 'ar'){
        $('head').append('<link rel="stylesheet" href="/rtl_style.css" class="ar_style">');console.log('add');
      }else{
        $('.ar_style').remove();
        console.log('removed');
      }
    }
}
};
</script>
