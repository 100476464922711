<template>
  <div class="fs14 flexi big ph0 bg_calendar oh ltr" style="min-height: 100vh">
    <div v-if="$store.state.loader" class="pf ov bgb40 overlaydiv" style="z-index: 999999999" >
      <div class="cc">
        <div class="fs20 b cw mb20">{{ $t("loading") }}</div>
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <div class="ov pf ba pv5 sh20 bgw ltr elevation-0" style="z-index: 999999999" >
      <div class="pf ov ba h alert_error" style="z-index: 999999999999">
        <div class="mt20 g_nm sh20 bgw ph15 pv8 round2x" style="border: 2px solid red" >
          {{ $t("error_in_felids") }}
        </div>
      </div>
      <div class="flexi big ph80 md_ph20 m_ph15 c">
        <tbl_nm>
          <cl class="l" style="width: 90px">
            <router-link :to="{ name: 'HomeWebsite' }">
              <img class="pointer" src="../assets/img/biban-logo.png" alt="profile_image" />
            </router-link>
          </cl>
          <cl class="m_h fs0 c">
            <div class="g_nm ph2"><div class="nav-bar-item nav_about_biban_div pv2 ph2 fs14" @click="goto('about_biban')" >{{ $t("about_biban") }}</div></div>
            <div v-if="config_list.display_main_page_agenda_section == 'show'" class="g_nm ph2"><div class="nav-bar-item nav_calendar_div pv2 ph2 fs14" :class="{ active: $route.name.includes('calendar') }" @click="goto('calendar')" >{{ $t("calendar") }}</div></div>
            <div v-if="config_list.display_main_page_speakers_section == 'show'" class="g_nm ph2"><div class="nav-bar-item nav_speaker_div pv2 ph2 fs14" :class="{ active: $route.name.includes('speaker') }" @click="goto('speaker')" >{{ $t("speakers") }}</div></div>
            <div v-if="config_list.display_main_page_doors_section == 'show'" class="g_nm ph2"><div class="nav-bar-item nav_door_div pv2 ph2 fs14" :class="{ active: $route.name.includes('door') }" @click="goto('door')" >{{ $t("doors") }}</div></div>
            <div v-if="config_list.display_main_page_partners_section == 'show'" class="g_nm ph2"><div class="nav-bar-item nav_partner_div pv2 ph2 fs14" :class="{ active: $route.name.includes('partner') }" @click="goto('partner')" >{{ $t("partners") }}</div></div>
            <div v-if="config_list.display_main_page_exhibitors_section == 'show'" class="g_nm ph2"><div class="nav-bar-item nav_exhibitor_div pv2 ph2 fs14" :class="{ active: $route.name.includes('exhibitor') }" @click="goto('exhibitor')" >{{ $t("exhibitors") }}</div></div>
            <div v-if="config_list.display_main_page_ewc_section == 'show'" class="g_nm ph2"><div class="nav-bar-item nav_ewc_div pv2 ph2 fs14" :class="{ active: $route.name.includes('ewc') }" @click="goto('ewc')" >{{ $t("leading_businesses") }}</div></div>
            <div v-if=" config_list.display_main_page_live_streaming_section == 'show' " class="g_nm ph2"><div class="nav-bar-item nav_live_streaming_div pv2 ph2 fs14" :class="{ active: $route.name.includes('live_streaming') }" @click="goto('live_streaming')" >{{ $t("live_streaming") }}</div></div>
            <div v-if="$i18n.locale == 'en'" class="g_nm ph2"><div class="nav-bar-item nav_visit_saudi_div pv2 ph2 fs14" :class="{ active: $route.name.includes('visit-saudi') }" ><router-link :to="{ name: 'visit-saudi' }">Visit Saudi</router-link></div></div>
          </cl>
          <cl class="colSep"></cl>
          <cl class="r w10" style="min-width:200px;">
            <div class="g_nm vm m_h ph2">
              <span class="pointer mdi mdi-menu sub_menu_btn c-blue fs30"></span>
            </div>
            <div class="g_nm vm ph2 fs14">
              <div class="g_nm ph2 pointer pv4 lang-main-div" @click="showLangDiv()" >
                <span v-if="$route.fullPath != '/visit-saudi'" class="vm ml1 mr1 mdi mdi-web c_blue fs24 pointer"></span>
                <span class="vm ph2 nav-gray fs16">{{ $t("lang") }}</span>
                <span v-if="$route.fullPath != '/visit-saudi'" class="vm mt2 mdi arrow-span mdi-chevron-down fs20 c-nav-gray" ></span>
              </div>
              <div class="ov lang-select ba brdr round2x pv4 c bgw sh20 mt40" hidden>
                <div class="bb pointer" @click="changeLocale('en')">
                  EN
                </div>
                <div class="pointer" @click="changeLocale('ar')">AR</div>
              </div>
            </div>
            <div class="h m_sib vm cm">
              <span class="pointer mdi mdi-apps menu_btn c-nav-gray fs30" ></span>
              <div class="ov la ba mt50 brdr sh20 nw h menu_div c bgw">
                <div class="menu_nav_item nav_about_biban_div pv10 ph20 pointer bb" @click="goto('about_biban')" >{{ $t("about_biban") }}</div>
                <div v-if="config_list.display_main_page_agenda_section == 'show'" class="menu_nav_item nav_calendar_div pv10 ph20 pointer bb" :class="{ active: $route.name.includes('calendar') }" @click="goto('calendar')" >{{ $t("calendar") }}</div>
                <div v-if=" config_list.display_main_page_speakers_section == 'show' " class="menu_nav_item nav_speaker_div pv10 ph20 pointer bb" :class="{ active: $route.name.includes('speaker') }" @click="goto('speaker')" >{{ $t("speakers") }}</div>
                <div v-if="config_list.display_main_page_doors_section == 'show'" class="menu_nav_item nav_door_div pv10 ph20 pointer bb" :class="{ active: $route.name.includes('door') }" @click="goto('door')" >{{ $t("doors") }}</div>
                <div v-if=" config_list.display_main_page_partners_section == 'show' " class="menu_nav_item nav_partner_div pv10 ph20 pointer bb" :class="{ active: $route.name.includes('partner') }" @click="goto('partner')" >{{ $t("partners") }}</div>
                <div v-if=" config_list.display_main_page_exhibitors_section == 'show' " class="menu_nav_item nav_exhibitor_div pv10 ph20 pointer bb" :class="{ active: $route.name.includes('exhibitor') }" @click="goto('exhibitor')" >{{ $t("exhibitors") }}</div>
                <div v-if="config_list.display_main_page_ewc_section == 'show'" class="menu_nav_item nav_ewc_div pv10 ph20 pointer bb" :class="{ active: $route.name.includes('ecw') }" @click="goto('ewc')" >{{ $t("leading_businesses") }}</div>
                <div v-if=" config_list.display_main_page_live_streaming_section == 'show' " class="menu_nav_item nav_live_streaming_div pv10 ph20 pointer" :class="{ active: $route.name.includes('live_streaming') }" @click="goto('#live_streaming')" >{{ $t("live_streaming") }}</div>
                <div v-if="$i18n.locale == 'en'" class="menu_nav_item nav_visit_saudi_div pv10 bb ph20 pointer" :class="{ active: $route.name.includes('visit-saudi') }" ><router-link :to="{ name: 'visit-saudi' }">Visit Saudi</router-link></div>
                <div class="sub_menu_nav_item  pointer pv10 ph20 pointer bb">{{$t('mendia_center')}}</div>
                <router-link :to="{ name: 'workshops'}" ><div class="sub_menu_nav_item  pointer pv10 ph20 pointer bb">{{$t('workshops')}}</div></router-link>
                <router-link :to="{ name: 'opportunities'}" ><div class="sub_menu_nav_item  pointer pv10 ph20 pointer bb">{{$t('oppurtuinites')}}</div></router-link>
                <div class="sub_menu_nav_item  pointer pv10 ph20 pointer bb">{{$t('monshaat')}}</div>
                <div class="sub_menu_nav_item  pointer pv10 ph20 pointer bb">{{$t('faq')}}</div>
                <div class="sub_menu_nav_item  pointer pv10 ph20 pointer bb">{{$t('supporting_centers')}}</div>
                <div class="fs0 mt8 pv10 bb">
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://twitter.com/BibanGlobal?s=20&t=AiJHRuSZaIsyEIgQGqASRQ">
                      <div class="new_bg_blue" style="padding:12px; border-radius:4px;"><span class="cc icon-x-twitter cw"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.facebook.com/profile.php?id=100088582235109">
                      <div class="new_bg_blue" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-facebook cw"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.youtube.com/@bibanglobal8184/featured">
                      <div class="new_bg_blue" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-youtube cw"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.instagram.com/bibanglobal/">
                      <div class="new_bg_blue" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-instagram cw"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.linkedin.com/company/bibanglobal/">
                      <div class="new_bg_blue" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-linkedin cw"></span></div>
                    </a>
                  </div>
                </div>
                <div class="sub_menu_nav_item pv10 c pointer bb">
                  <button :name="$t('contact_us')" class="contact_us round2x pointer c-nav-gray pv2 ph4" @click="goto('map_contact')" >
                    {{ $t("contact_us") }}
                  </button>
                </div>
              </div>
            </div>
            <button :name="$t('contact_us')" class="m_h contact_us round2x ml2 mr2 pointer c-nav-gray pv2 ph4" @click="goto('map_contact')" >
              {{ $t("contact_us") }}
            </button>
            <!--  <button class=" m_h downlaod_btn round2x sbtn pointer cw ph10 pv4">{{$t('download_app')}}</button> -->
          </cl>
        </tbl_nm>
        <div class="ch c h ba sub_menu_div pv30 g_nm mt6 bgw sh20 pv20 ph20 m_ph10">
          <tbl>
            <cl class="w70 vt">
              <tbl>
                <cl class="w50">
                  <div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('mendia_center')}}</div>
                  <div class="bb h m_sb mt2"></div>
                  <router-link :to="{ name: 'workshops'}" ><div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('workshops')}}</div></router-link>
                  <div class="bb h m_sb mt2"></div>
                  <router-link :to="{ name: 'opportunities'}" ><div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('oppurtuinites')}}</div></router-link>
                  <div class="bb h m_sb mt2"></div>
                </cl>
                <cl class="w50">
                  <div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('supporting_centers')}}</div>
                  <div class="bb h m_sb mt2"></div>
                  <div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('monshaat')}}</div>
                  <div class="bb h m_sb mt2"></div>
                  <router-link :to="{ name: 'faq'}" ><div class="sub_menu_nav_item round2x pointer ph8 pv4">{{$t('faq')}}</div></router-link>
                  <div class="bb h m_sb mt2"></div>

                </cl>
              </tbl>
            </cl>
            <cl class="colSep m_h"></cl>
            <cl class="w30 nw vb m_mt4">
              <button :name="$t('contact_us')" class="contact_us round2x pointer c-nav-gray pv2 ph4" @click="goto('map_contact')" >
                {{ $t("contact_us") }}
              </button>
              <div class="fs0 mt8">
                <div class="g_nm ph2 vm fs18">
                  <a href="https://twitter.com/BibanGlobal?s=20&t=AiJHRuSZaIsyEIgQGqASRQ">
                    <div class="new_bg_blue" style="padding:12px; border-radius:4px;"><span class="cc icon-x-twitter cw"></span></div>
                  </a>
                </div>
                <div class="g_nm ph2 vm fs18">
                  <a href="https://www.facebook.com/profile.php?id=100088582235109">
                    <div class="new_bg_blue" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-facebook cw"></span></div>
                  </a>
                </div>
                <div class="g_nm ph2 vm fs18">
                  <a href="https://www.youtube.com/@bibanglobal8184/featured">
                    <div class="new_bg_blue" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-youtube cw"></span></div>
                  </a>
                </div>
                <div class="g_nm ph2 vm fs18">
                  <a href="https://www.instagram.com/bibanglobal/">
                    <div class="new_bg_blue" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-instagram cw"></span></div>
                  </a>
                </div>
                <div class="g_nm ph2 vm fs18">
                  <a href="https://www.linkedin.com/company/bibanglobal/">
                    <div class="new_bg_blue" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-linkedin cw"></span></div>
                  </a>
                </div>
              </div>
            </cl>
          </tbl>
        </div>
      </div>
    </div>
    <router-view class=""></router-view>
    <div class="" style="background-color: #4d4d4d">
      <div class="flexi big ph80 md_ph50 m_ph20 m_m_ph10 pv10 c cw">
        <div class="fs0">
          <div class="g_nm w50 l vm">
            <!--<a href="https://www.vision2030.gov.sa"><div class="con g_nm p40" :style="{backgroundImage: 'url('+require('../assets/img/2023.svg')+')'}"></div></a>-->
          </div>
          <div class="g_nm w50 r vm">
            <router-link :to="{ name: 'HomeWebsite' }">
              <div class="con g_nm p40" :style="{backgroundImage:'url(' + require('../assets/img/logo-02.svg') + ')',}"></div>
            </router-link>
          </div>
        </div>
        <tbl class="l mt10">
          <cl class="vt w50">
            <p class="fs20 b">{{ getStaticContent("footer_main_title") }}</p>
            <p class="">{{ getStaticContent("footer_main_paragraph") }}</p>
          </cl>
          <cl class="colSep pv10"></cl>
          <cl class="vt c w16">
            <div class="g l"></div>
          </cl>
          <cl class="colSep pv10"></cl>
          <cl class="vt w16 c nw">
            <div class="g l">
              <div class="mt10">{{ getStaticContent("footer_contact_title") }}</div>
              <!-- <div><span class="ml5 mr5 mdi mdi-map-marker"></span>{{$t('text2')}}</div> -->
              <div class="mt10">
                <span class="ml5 mr5 mdi mdi-email"></span>{{ getStaticContent("footer_email") }}
              </div>
              <div class="mt10">
                <p>{{ getStaticContent("footer_local_call_title") }}</p>
                <span class="ml5 mr5 mdi mdi-phone"></span>
                <span>{{ getStaticContent("footer_local_call_number") }}</span>
              </div>
              <div class="">
                <p>{{ getStaticContent("footer_international_call_title") }}</p>
                <span class="ml5 mr5 mdi mdi-phone"></span>
                <span>{{ getStaticContent("footer_international_call_number")}}</span>
              </div>
            </div>
          </cl>
          <cl class="colSep pv10"></cl>
          <cl class="vb w16 r m_c">
            <div class="r m_c">
              <div class="g_nm c" style="width: 80px">
                <!-- {{$t('all_right_1')}} -->
              </div>
            </div>
            <!-- <a href="https://www.monshaat.gov.sa"> <img class="g_nm" src="../assets/img/mounshaat.png" style="width:80px;"/></a> -->
          </cl>
        </tbl>
        <tbl class="mt10">
          <cl class="w50">
            <div class="ph10 vm fs0">
              <!-- <div class="vm g ph10 m_pv10 fs14 l m_c">{{$t('download_app')}}</div>
              <div class="vm g_nm ph5"><button :name="$t('download_biban_app_for_android')"><img src="../assets/img/google_play.png" style="height:38px;" :alt="$t('download_biban_app_for_android')"/></button></div>
              <div class="vm g_nm ph5"><button :name="$t('download_biban_app_for_ios')"><img src="../assets/img/app_store.png" style="height:38px;" :alt="$t('download_biban_app_for_ios')"/></button></div>-->
            </div>
          </cl>
          <cl class="colSep pv10"></cl>
          <!--  <cl class="w50 r m_c">#Biban_2023 | #بيبان_2023</cl> -->
        </tbl>
        <div class="pt2 mt10 bgw"></div>
        <div class="fs14 p10 vm g_nm">
          <span>{{ getStaticContent("footer_powered_by_text") }}</span>
          {{ new Date().getFullYear() }}
        </div>
        <!-- <div class="c g_nm ph5 vm"><a href="https://www.monshaat.gov.sa"> <img class="g_nm" src="../assets/img/f_logo.svg" style="width:60px;"/></a></div>
        <div class="c g_nm ph5 vm"><a href="https://www.vision2030.gov.sa"> <img class="g_nm" src="../assets/img/2023.svg" style="width:60px;"/></a></div>
        <div class="fs14 ph8 vm g_nm">{{ $t('all_right_1') }} {{ new Date().getFullYear()}} &#8211;  {{ $t('all_right_2') }}</div> -->
      </div>
    </div>
  </div>
</template>
<script>
// import $ from "jquery";
export default {
  name: "Mainlayout",
  data() {
    return {
      showLangBool: 0,
      footer_list: null,
      config_list: [],
    };
  },
  mounted() {
    $(".menu_btn").click(function () {
      $(".menu_div").toggleClass("h");
      $(".sub_menu_div").addClass('h')
    });
    $(".menu_nav_item").click(function () {
      $(".menu_div").toggleClass("h");
    });
    $(".sub_menu_btn").click(function () {
      $(".sub_menu_div").toggleClass("h");
      $(".menu_div").addClass('h')
    });
    $(".sub_menu_nav_item").click(function () {
      $(".sub_menu_div").toggleClass("h");
    });
  },

  created() {
    this.axios
      .get(this.$store.state.baseURL + "static_content/configuration", {})
      .then((response) => {
        var data = response.data.data;
        var x;
        data.forEach((element) => {
          x = element.short_code;
          this.config_list[x] = element.body;
        });
      })
      .catch((e) => {});
      if(this.$route.fullPath == '/visit-saudi'){
        this.changeLocale("en")
      }
      if (this.$i18n.locale == "ar") {
        this.axios
          .get(this.$store.state.baseURL + "static_content/footer?lang=ar", {})
          .then((response) => {
            this.footer_list = response.data.data;
          })
          .catch((e) => {});
      } else {
        this.getdata(this.$i18n.locale);
      }
      $(document).click(function() {
        var container_menu = $(".menu_div");
        var container_sub = $(".sub_menu_div");
        var sub_btn =$('.sub_menu_btn')
        var main_btn =$('.menu_btn')
        // if (!container_menu.is(event.target) && !container_menu.has(event.target).length) {
        if (!container_menu.is(event.target) && !main_btn.is(event.target)) {
            // if($(".menu_div").hasClass('h')){
              $(".menu_div").addClass('h');
            // }
        }
        if (!container_sub.is(event.target) && !sub_btn.is(event.target)) {
          // if($(".sub_menu_div").hasClass('h')){
            $(".sub_menu_div").addClass('h')
          // }
        }
    });
  },
  watch: {
    "$i18n.locale": function (newVal) {
      this.getdata(newVal);
    },
  },
  methods: {
    getdata: function (lang) {
      // GET FOOTER
      this.axios
        .get(
          this.$store.state.baseURL + "static_content/footer?lang=" + lang,
          {}
        )
        .then((response) => {
          this.footer_list = response.data.data;
        })
        .catch((e) => {});
    },
    showLangDiv() {
      if(this.$route.fullPath == '/visit-saudi'){
      }
      else{
        if ($(".arrow-span").hasClass("mdi-chevron-up")) {
        $(".arrow-span").removeClass("mdi-chevron-up");
        $(".lang-select").slideUp();
        } else {
          $(".arrow-span").addClass("mdi-chevron-up");
          $(".lang-select").slideDown();
        }
      }
    },
    getStaticContent(key) {
      if (this.footer_list) {
        return this.footer_list.find((item) => item.short_code == key)?.body;
      }
      return "";
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      $("html").attr("lang", "ar");
      $(".arrow-span").removeClass("mdi-chevron-up");
      $(".lang-select").slideUp();
      if (locale == "ar") {
        $("head").append(
          '<link rel="stylesheet" href="/rtl_style.css" class="ar_style">'
        );
        // console.log("add");
        $("#title").html("بيبان 2024 وجهة عالمية للفرص");
      } else {
        $("html").attr("lang", "en");
        $(".ar_style").remove();
        $("#title").html("BIBAN 2024 Global Destination for Opportunities");
      }
    },
    goto(id) {
      if (this.$route.fullPath == "/") {
        var x = $("#" + id).offset().top - 70;
        $("html, body").animate(
          {
            scrollTop: x,
          },
          300
        );
      } else {
        var xname = "/";
        var idid = id.replace(/\s/g, "");
        this.$router.push({ path: xname, query: { t: idid } });
      }
    },
  },
};
</script>
