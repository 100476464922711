<template>
  <div class="c mt150">
    <img class="mt150 g_nm" src="@/assets/img/thankyou.png"/>
    <div class="b">{{ $t('registration_completed') }}</div>
    <!-- <div class="fs13 mt5">{{ $t('see_you_at_biban')}}</div> -->
    <div class="fs13 mt5">{{ $t('proccessing_reg')}}</div>


  </div>


  <!-- <div class=" relative pt-50p"> -->
    <!-- <div class="bg-[#ffffff5d] rounded-xl w-1/2 mx-auto pt-5 pb-5 formboxax">
      <div class="w-[70%] mx-auto bg-white rounded-md relative flex items-center">

      </div>
      <div class="thankyoumsg">Thank You for Registration</div>
    </div> -->
  <!-- </div> -->
</template>
<script>
export default {
  name: 'Thankyou',
  data(){
    return {

    }
  },
  methods:{

  },
  created() {
  this.$store.state.showloader_registration = 0;
  },
}
</script>
