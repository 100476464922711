<template>
<div class="mt100 ltr c mb50">
  <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
    <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
    <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }}  {{$t('as_speaker')}}</div>
    <div class="mt10 l w100">
      <div class="bg-prograss-bar prograss-bar">
        <div class="prograss-bar ov ra w100 g_nm bg_orange"></div>
      </div>
      <div class="mt10 c-blue">{{ $t('step3') }}</div>
      <div class="mt10 b">{{ $t('career_info') }}</div>
      <form class="pv10" @submit.prevent="submit_form">
        <!-- START ROLE -->
        <div class="mt15">
          <label class="label_fields">{{ $t('job_role') }} *</label>
            <Select2 :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" v-model="form.job" :options="role_list" />
          <div class="validation_job validation_warning"></div>
        </div>
        <div class="mt15">
          <label class="label_fields">{{ $t('bio') }}</label>
          <textarea :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="input_fields" style="resize: vertical" row="20" v-model="form.bio" v-bind:placeholder="$t('bio')" type="text"></textarea>
          <div class="validation_bio validation_warning"></div>
        </div>
        <!-- CLOSE BIO -->
        <!-- START PART OF PARTICIPATION -->
        <div class="mt15">
          <label class="label_fields">{{ $t('part_of_participation') }}</label>
          <textarea :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="input_fields" style="resize: vertical" row="20" v-model="form.part_of_participation" v-bind:placeholder="$t('part_of_participation')" type="text"></textarea>
          <div class="validation_part_of_participation validation_warning"></div>
        </div>
        <div class="mt15">
          <label class="label_fields">{{$t('work_place')}} *</label>
          <input :disabled="view_only_all == 1" class="input_fields" v-model="form.work_place" type="text"/>
          <div class="validate_work_place validation_warning"></div>
        </div>
        <!-- CLOSE WORKPLACE -->
        <!-- START JOBTITLE -->
        <div class="mt15">
          <label class="label_fields">{{$t('job_title')}} *</label>
          <input :disabled="view_only_all == 1" class="input_fields" v-model="form.job_title" type="text"/>
          <div class="validate_job_title validation_warning"></div>
        </div>
        <!-- CLOSE ROLE -->
        <div class="mt20 r">
          <button v-if="$store.state.is_view_only == 0" @click="submit" class="next_btn ph20 ml20">
            <div v-if="disabled_submit" class="mt10 mb10 ml5 mr5 rotatingLoader" ></div>
            <span v-if="!disabled_submit" class="cw lh40 fs18" >{{$t('next')}}</span>
            <!-- <span v-if="$i18n.locale == 'en' && !disabled_submit" class="cw mdi mdi-arrow-right lh40 fs30"></span>
            <span v-if="$i18n.locale == 'ar' && !disabled_submit" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'step3speaker',
  data() {
    return {
      disabled_submit:false,
      view_only_all: 0,
      errors: 0,
      form: {
        job: "",
        bio: "",
        part_of_participation:"",
        work_place:'',
        job_title:'',
      }
    }
  },
  created() {
    this.$store.state.loader = 1;
    if (Object.keys(this.$store.state.speaker_user).length === 0) {

      this.$router.push({ path: '/registration/failed' });
    }
    else {
      this.axios.post(this.$store.state.baseURL + "getindustries", {}).then((response) => {
        var xobjectrespons = response.data.data;
        xobjectrespons.forEach(element => {
          var x ={
              id: element.id,
              text: element.title
          }
          this.industries_list.push(x);
      });
      }).catch((e) => {
        console.log(e);
      });
      if (this.$store.state.is_view_only == 1) {
        this.view_only_all = 1;
      }
      var objectrespons = this.$store.state.speaker_user;
      var objectresponsecuritycheck = this.$store.state.speaker_user.datasecurity
        if(objectresponsecuritycheck == null){

        }else{
          this.form.work_place = objectresponsecuritycheck.work_place;
          this.form.job_title = objectresponsecuritycheck.job_title;
        }
      this.form.job = objectrespons.job;
      this.form.part_of_participation=objectrespons.part_of_participation;
      this.form.bio = objectrespons.bio;
    //  console.log(objectrespons);

      this.$store.state.loader = 0;
    }
  },
  methods: {
    validatedata(formname,validatefieldclass, text) {
      if (formname == "" || formname == null || formname == 0) {
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        this.errors = 1;
      }
      else{
        $(validatefieldclass).slideUp();
      }
    },
    submit() {
      if(this.disabled_submit == false){
        $('.next_btn').addClass('disabled_btn');
        this.disabled_submit=true;
        this.$emit('submit', this.form);
        this.errors = 0;
        this.validatedata(this.form.job,".validation_job", this.$t('filed_is_required'));
        this.validatedata(this.form.work_place,".validation_work_place", this.$t('work_place_is_required'));
        this.validatedata(this.form.job_title,".validation_job_title", this.$t('job_title_is_required'));
        if(this.errors == 0){
          this.$store.state.speaker_user.job = this.form.job;
          this.$store.state.speaker_user.bio = this.form.bio;
          this.$store.state.speaker_user.part_of_participation = this.form.part_of_participation;
          this.$store.state.speaker_user.work_place = this.form.work_place;
          this.$store.state.speaker_user.job_title = this.form.job_title;
          this.$store.state.visa.type_of_user ="speaker";
          this.$store.state.visa.user_token_key =this.$store.state.speaker_user.token_key;
          this.$store.state.visa.nationality = this.$store.state.speaker_user.nationality;
          this.$store.state.visa.country_of_residence =this.$store.state.speaker_user.country_of_residence,
          this.$store.state.visa.userID =this.$store.state.speaker_user.id;
          var res = this.$store.state.no_visa_countries.indexOf(this.$store.state.speaker_user.nationality);
          if( this.$store.state.speaker_user.country_of_residence == 194 || res != -1){
            this.axios.post(this.$store.state.baseURL + "updatespeaker", {
            user_id: this.$store.state.speaker_user.user_id,
            title: this.$store.state.speaker_user.title,
            first_name: this.$store.state.speaker_user.first_name,
            last_name: this.$store.state.speaker_user.last_name,
            nationality: this.$store.state.speaker_user.nationality,
            country_of_residence: this.$store.state.speaker_user.country_of_residence,
            full_name: this.$store.state.speaker_user.full_name,
            personal_photo: this.$store.state.speaker_user.personal_photo,
            passport_image: this.$store.state.speaker_user.passport_image,
            passport_type: this.$store.state.speaker_user.passport_type,
            telephone: this.$store.state.speaker_user.telephone,
            qualification: this.$store.state.speaker_user.qualification,
            address: this.$store.state.speaker_user.address,
            passport_expiry_date: this.$store.state.speaker_user.passport_expiry_date,
            place_of_birth: this.$store.state.speaker_user.place_of_birth,
            date_of_birth: this.$store.state.speaker_user.date_of_birth,
            email: this.$store.state.speaker_user.email,
            phone: this.$store.state.speaker_user.phone,
            gender: this.$store.state.speaker_user.gender,
            job: this.$store.state.speaker_user.job,
            work_place: this.$store.state.speaker_user.work_place,
            bio: this.$store.state.speaker_user.bio,
            job_title: this.$store.state.speaker_user.job_title,
            part_of_participation:this.$store.state.speaker_user.part_of_participation,
            linked_in: this.$store.state.speaker_user.linked_in,
            facebook: this.$store.state.speaker_user.facebook,
            instagram: this.$store.state.speaker_user.instagram,
            twitter: this.$store.state.speaker_user.twitter,
            snapchat: this.$store.state.speaker_user.snapchat,
            // photo: this.$store.state.speaker_user.photo,
            token_key: this.$store.state.speaker_user.token_key,
            id_pass_iqama: this.$store.state.speaker_user.id_pass_iqama,
            company_name:this.$store.state.speaker_user.company_name,
            status:"",
            visa_status: "not required",
            flight_status: "not required",
            hotel_status: "not required",
          }).then((response) => {
            if(response.data.success == true){
              this.$router.push({ path: "/registration/thankyou" });
            }
            else{
                // alert("wong")
              this.$router.push({ path: '/registration/failed' });
            }
          }) .catch((e) => {
            $('.next_btn').removeClass('disabled_btn');
            this.disabled_submit=false;
          })
          }
          else{

            this.axios.post(this.$store.state.baseURL + "updatespeaker", {
            user_id: this.$store.state.speaker_user.user_id,
            title: this.$store.state.speaker_user.title,
            first_name: this.$store.state.speaker_user.first_name,
            last_name: this.$store.state.speaker_user.last_name,
            nationality: this.$store.state.speaker_user.nationality,
            country_of_residence: this.$store.state.speaker_user.country_of_residence,
            full_name: this.$store.state.speaker_user.full_name,
            personal_photo: this.$store.state.speaker_user.personal_photo,
            passport_image: this.$store.state.speaker_user.passport_image,
            passport_type: this.$store.state.speaker_user.passport_type,
            telephone: this.$store.state.speaker_user.telephone,
            qualification: this.$store.state.speaker_user.qualification,
            address: this.$store.state.speaker_user.address,
            passport_expiry_date: this.$store.state.speaker_user.passport_expiry_date,
            place_of_birth: this.$store.state.speaker_user.place_of_birth,
            date_of_birth: this.$store.state.speaker_user.date_of_birth,
            email: this.$store.state.speaker_user.email,
            phone: this.$store.state.speaker_user.phone,
            gender: this.$store.state.speaker_user.gender,
            job: this.$store.state.speaker_user.job,
            work_place: this.$store.state.speaker_user.work_place,
            bio: this.$store.state.speaker_user.bio,
            job_title: this.$store.state.speaker_user.job_title,
            part_of_participation:this.$store.state.speaker_user.part_of_participation,
            linked_in: this.$store.state.speaker_user.linked_in,
            facebook: this.$store.state.speaker_user.facebook,
            instagram: this.$store.state.speaker_user.instagram,
            twitter: this.$store.state.speaker_user.twitter,
            snapchat: this.$store.state.speaker_user.snapchat,
          //  photo: this.$store.state.speaker_user.photo,
            token_key: this.$store.state.speaker_user.token_key,
            id_pass_iqama: this.$store.state.speaker_user.id_pass_iqama,
            part_of_participation:this.$store.state.speaker_user.part_of_participation,
            company_name:this.$store.state.speaker_user.company_name,
            personal_photo: this.$store.state.speaker_user.personal_photo,
            passport_image: this.$store.state.speaker_user.passport_image,
            status:"",
            visa_status: "new",
            flight_status: "new",
            hotel_status: "new",
          }).then((response) => {
              this.$store.state.loader = 0;
              this.$store.state.media_staff_user.token_key;
              this.disabled_submit=false;
              if(response.data.success == true){
                if (response.data.data.visa_status != "new") {
                  this.$router.push({ path: "/registration/thankyou" });
                } else {
                  // alert("wong")
                  this.$router.push({ path: "/registration/visa/step1" });
                }
              }
              else{
                this.$router.push({ path: '/registration/failed' });
              }
            }).catch((e) => {
              $('.next_btn').removeClass('disabled_btn');
              this.disabled_submit=false;
              console.log(e);
            });
          }
        }
        else{
          $('.alert_error').removeClass('h');
          setTimeout(function () {
            $('.alert_error').addClass("h");;
          }, 5000);
          $('.next_btn').removeClass('disabled_btn');
          this.disabled_submit=false;
        }
      }
    }
  },
  computed:{
    role_list(){
      if(this.$i18n.locale == 'ar'){
        return this.$store.state.ar_role_list
      }
      return this.$store.state.en_role_list
    }
  },

}
</script>
