<template>
<div class="mt100 ltr c mb50" v-if="token_is_valide == 1">
  <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
    <img class="g_nm" src="@/assets/img/biban-logo.png"  alt="profile_image" />
    <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }} {{$t('as_vip')}}</div>
    <div class="mt10 l w100">
      <div class="bg-prograss-bar prograss-bar">
        <div class="prograss-bar ov ra w66 g_nm bg_orange"></div>
      </div>
      <div class="mt10 c-blue">{{ $t('step2') }}</div>
      <div class="mt10 b">{{ $t('basic_registration') }}</div>
      <form class="pv10" @submit.prevent="submit_form">
        <!-- START PHONE -->
        <div class="mt15">
          <label class="label_fields mb8">{{ $t('mobile_phone') }} *</label>
          <div class="pv4"></div>
          <MazPhoneNumberInput :disabled="view_only_all == 1" v-model="form.phone" :default-phone-number="form.phone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results = $event" :success="results?.isValid"
            style="direction:ltr !important;" />
          <div class="validatePhone validation_warning"></div>
        </div>
        <!-- CLOSE PHONE -->
        <!-- START TELEPHONE -->
        <div class="mt15">
          <label class="label_fields mb8">{{ $t('telephone') }}</label>
          <div class="pv4"></div>
          <MazPhoneNumberInput :disabled="view_only_all == 1" v-model="form.telephone" :default-phone-number="form.telephone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results2 = $event"
            :success="results2?.isValid" style="direction:ltr !important" />
        </div>
        <!-- CLOSE TELEPHONE -->
        <!-- START DATA AND PLACE OF BIRTH -->
        <tbl>
          <cl class="vt w50">
            <div class="mt15">
              <label class="label_fields">{{$t('place_of_birth')}} *</label>
              <Select2 :disabled="view_only_all == 1" v-model="form.place_of_birth" :options="countries_list" />
              <div class="validatePlaceOfBirth validation_warning"></div>
            </div>
          </cl>
          <cl class="colSep"></cl>
          <cl class="vt w50">
            <div class="mt15">
              <label class="label_fields">{{$t('date_of_birth')}} *</label>
              <input :disabled="view_only_all == 1" class="input_fields" placeholder="" v-model="form.date_of_birth" max="2009-03-01" type="date" />
              <div class="validateDateOfdBirth validation_warning"></div>
            </div>
          </cl>
        </tbl>
        <!-- CLOSE DATA AND PLACE OF BIRTH -->
        <!-- START QUALIFICATION -->
        <div class="mt15">
          <label class="label_fields">{{$t('qualification')}} *</label>
          <Select2 :disabled="view_only_all == 1  && this.$store.state.come_form_geenrated_link==0" v-model="form.qualification" :options="qualification_list" />
          <div class="validate_qualification validation_warning"></div>
        </div>
        <!-- CLOSE QUALIFICATION -->
        <!-- START WORKPLACE -->
        <div class="mt15">
          <label class="label_fields">{{$t('work_place')}} *</label>
          <input :disabled="view_only_all == 1" class="input_fields" v-model="form.work_place" type="text" />
          <div class="validate_work_place validation_warning"></div>
        </div>
        <!-- CLOSE WORKPLACE -->
        <!-- START JOBTITLE -->
        <div class="mt15">
          <label class="label_fields">{{$t('job_title')}} *</label>
          <input :disabled="view_only_all == 1" class="input_fields" v-model="form.job_title" type="text" />
          <div class="validate_job_title validation_warning"></div>
        </div>
        <!-- CLOSE JOBTITLE -->
        <!-- START ADDRESS -->
        <div class="mt15">
          <label class="label_fields">{{$t('address')}} *</label>
          <input :disabled="view_only_all == 1" class="input_fields" v-model="form.address" type="text" />
          <div class="validate_address validation_warning"></div>
        </div>
        <!-- CLOSE ADDRESS -->
        <div class="mt15">
          <label class="label_fields">{{ $t('category') }}</label>
          <Select2 :disabled="view_only_all == 1" v-model="form.category" :options="category_list" />
          <div class="validation_category validation_warning"></div>
        </div>
        <div class="mt20 r">
          <button :disabled="disabled_submit"  @click="submit" class="next_btn ph20 ml20">
            <div v-if="disabled_submit" class="mt10 mb10 ml5 mr5 rotatingLoader"></div>
            <span v-if="!disabled_submit" class="cw lh40 fs18" >{{$t('next')}}</span>
            <!-- <span v-if="$i18n.locale == 'en' && !disabled_submit" class="cw mdi mdi-arrow-right lh40 fs30"></span>
            <span v-if="$i18n.locale == 'ar' && !disabled_submit" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>
<script>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import {
  ref
} from 'vue';
const results = ref();
export default {
  name: 'step2vip',
  components: {
    MazPhoneNumberInput
  },
  data() {
    return {
      category_list: null,
      view_only_all: 0,
      errors: 0,
      token_is_valide: 1,
      HaveFacebook: 0,
      HaveTwitter: 0,
      ar_countries_list:[],
      en_countries_list:[],
      ar_cat_list:[],
      en_cat_list:[],
      disabled_submit: false,
      form: {
        phone: "",
        telephone: "",
        category: "",
        place_of_birth: "",
        date_of_birth: "",
        qualification: "",
        work_place: "",
        job_title: "",
        address: "",
        facebook: "",
        twitter: "",
      }
    }
  },
  created() {
    this.$store.state.loader=1;
    this.axios.post(this.$store.state.baseURL + "getcountries", {}).then((response) => {
        var objectrespons = response.data.data;
        objectrespons.forEach(element => {
          var ar_y = {
            id: element.id,
            text: element.country_arName
          }
          var en_y = {
            id: element.id,
            text: element.country_enName
          }
          this.ar_countries_list.push(ar_y);
          this.en_countries_list.push(en_y);
        });
        this.$store.state.loader=0;
      })
      .catch((e) => {this.$store.state.loader=0;});
    this.axios.get(this.$store.state.baseURL + 'get-all-categories', {}).then(response => {
        var objectrespons = response.data.data;
        objectrespons.forEach(element => {
          var en_y  = {
            id: element.id,
            text: element.title
          }
          var ar_y = {
            id: element.id,
            text: element.title_ar
          }
          this.ar_cat_list.push(ar_y);
          this.en_cat_list.push(en_y);
        });
      })
      .catch(e => {})
      console.log(this.$store.state.vip_user);
    var objectresponsecuritycheck = this.$store.state.vip_user.datasecurity;
    if (objectresponsecuritycheck != null) {
      this.form.telephone = objectresponsecuritycheck.telephone;
      this.form.place_of_birth = objectresponsecuritycheck.place_of_birth;
      this.form.date_of_birth = moment(objectresponsecuritycheck.date_of_birth).format("YYYY-MM-DD");
      this.form.qualification = objectresponsecuritycheck.qualification;
      this.form.work_place = objectresponsecuritycheck.work_place;
      this.form.job_title = objectresponsecuritycheck.job_title;
      this.form.address = objectresponsecuritycheck.address;
      this.form.facebook = objectresponsecuritycheck.facebook;
      this.form.address = objectresponsecuritycheck.address;
    }
    if (this.form.facebook) {
      this.HaveFacebook = 1;
    }
    if (this.form.twitter) {
      this.HaveTwitter = 1;
    }
    this.form.phone = this.$store.state.vip_user.phone;
    this.form.category = this.$store.state.vip_user.category;
    if (this.$store.state.is_view_only == 1) {
      this.view_only_all = 1;
    }
  },
  computed: {
    countries_list() {
      if (this.$i18n.locale == 'ar') {
        return this.ar_countries_list
      }
      return this.en_countries_list
    },
    qualification_list() {
      if (this.$i18n.locale == 'ar') {
        return this.$store.state.ar_qualification
      }
      return this.$store.state.en_qualification
    },
    category_list(){
      if (this.$i18n.locale == 'ar') {
        return this.ar_cat_list
      }
      return this.en_cat_list
    }
  },
  methods: {
    validatedata(formname, validatefieldclass, text) {
      if (formname == "" || formname == null || formname == 0) {
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        this.errors = 1;
        // console.log(validatefieldclass);
      } else {
        $(validatefieldclass).slideUp();
      }
    },
    submit() {

      if (this.disabled_submit == false) {
        $('.next_btn').addClass('disabled_btn');
        this.disabled_submit = true;
        this.errors = 0;
        $('.next_btn').addClass('disabled_btn');
        this.disabled_submit = true;
        this.$emit('submit', this.form);
        this.errors = 0;
        if (this.form.phone == "" || this.form.phone == null || this.form.phone == 0) {
          $(".validatePhone").html(this.$t('filed_is_required'));
          $(".validatePhone").slideDown();
          this.errors = 1;
        } else {
          if (!this.results.isValid) {
            this.errors = 1;
            $(".validatePhone").html(this.$t('phone_not_valid'));
            $(".validatePhone").slideDown();
          } else {
            $(".validatePhone").slideUp();
          }
        }
        this.validatedata(this.form.category, ".validation_category", this.$t('category_is_required'));
        this.validatedata(this.form.place_of_birth, ".validatePlaceOfBirth", this.$t('place_of_birth_is_required'));
        this.validatedata(this.form.date_of_birth, ".validateDateOfdBirth", this.$t('date_of_birth_is_required'));
        this.validatedata(this.form.qualification, ".validate_qualification", this.$t('qualification_is_required'));
        this.validatedata(this.form.work_place, ".validate_work_place", this.$t('work_place_is_required'));
        this.validatedata(this.form.job_title, ".validate_job_title", this.$t('job_title_is_required'));
        this.validatedata(this.form.address, ".validate_address", this.$t('address_is_required'));
        if (this.HaveFacebook == '1') {
          this.validatedata(this.form.facebook, ".validate_facebook", this.$t('facebook_is_required'));
        }
        if (this.HaveTwitter == '1') {
          this.validatedata(this.form.twitter, ".validate_twitter", this.$t('twitter_is_required'));
        }
        if (this.errors == 0) {
          if (this.form.facebook) {} else {
            this.form.facebook = null;
          }
          if (this.form.telephone) {} else {
            this.form.telephone = null;
          }
          if (this.form.twitter) {} else {
            this.form.twitter = null;
          }
          this.$store.state.vip_user.phone = this.form.phone;
          this.$store.state.vip_user.telephone = this.form.telephone;
          this.$store.state.vip_user.category = this.form.category;
          this.$store.state.vip_user.place_of_birth = this.form.place_of_birth;
          this.$store.state.vip_user.date_of_birth = this.form.date_of_birth;
          this.$store.state.vip_user.qualification = this.form.qualification;
          this.$store.state.vip_user.work_place = this.form.work_place;
          this.$store.state.vip_user.job_title = this.form.job_title;
          this.$store.state.vip_user.address = this.form.address;
          this.$store.state.vip_user.facebook = this.form.facebook;
          this.$store.state.vip_user.twitter = this.form.twitter;

          if (this.$store.state.is_view_only == 0) {
            this.$store.state.visa.type_of_user = "vip";
            this.$store.state.visa.user_token_key = this.$store.state.vip_user_token;
            this.$store.state.visa.nationality = this.$store.state.vip_user.nationality;
            this.$store.state.visa.country_of_residence = this.$store.state.vip_user.country_of_residence,
            this.$store.state.visa.userID = this.$store.state.vip_user.id;
            this.$store.state.visa.gender = this.$store.state.vip_user.gender;
            this.axios.post(this.$store.state.baseURL + "check-phone", {
                token_key: this.$store.state.vip_user.token_key,
                phone: this.form.phone
              })
              .then((response) => {
                if (response.data.success == true) {
                  var res = this.$store.state.no_visa_countries.indexOf(this.$store.state.vip_user.nationality);
                  if (this.$store.state.vip_user.country_of_residence == 194 || res != -1) {
                    this.axios.post(this.$store.state.baseURL + "update-vip-info", {
                        passport_expiry_date: this.$store.state.vip_user.passport_expiry_date,
                        title: this.$store.state.vip_user.title,
                        first_name: this.$store.state.vip_user.first_name,
                        last_name: this.$store.state.vip_user.last_name,
                        gender: this.$store.state.vip_user.gender,
                        email: this.$store.state.vip_user.email,
                        full_name: this.$store.state.vip_user.full_name,
                        personal_photo: this.$store.state.vip_user.personal_photo,
                        passport_image: this.$store.state.vip_user.passport_image,
                        passport_type: this.$store.state.vip_user.passport_type,
                        token_key: this.$store.state.vip_user_token,
                        nationality: this.$store.state.vip_user.nationality,
                        country_of_residence: this.$store.state.vip_user.country_of_residence,
                        id_pass_iqama: this.$store.state.vip_user.id_pass_iqama,
                        place_of_birth: this.$store.state.vip_user.place_of_birth,
                        date_of_birth: this.$store.state.vip_user.date_of_birth,
                        qualification: this.$store.state.vip_user.qualification,
                        phone: this.$store.state.vip_user.phone,
                        category: this.$store.state.vip_user.category,
                        work_place: this.$store.state.vip_user.work_place,
                        job_title: this.$store.state.vip_user.job_title,
                        address: this.$store.state.vip_user.address,
                        facebook: this.$store.state.vip_user.facebook,
                        twitter: this.$store.state.vip_user.twitter,
                        telephone: this.$store.state.vip_user.telephone,
                        status: "",
                        visa_status: "not required",
                        flight_status: "not required",
                        hotel_status: "not required",
                      })
                      .then((response) => {
                        if(response.data.success == false){
                          console.log(this.$store.state.vip_user);
                          $('.alert_error').removeClass('h');
                          setTimeout(function() {
                            $('.alert_error').addClass("h");;
                          }, 5000);
                            $('.next_btn').removeClass('disabled_btn');
                            this.disabled_submit = false;
                        }else{
                          $('.next_btn').removeClass('disabled_btn');
                          this.disabled_submit = false;
                          this.$router.push({
                            path: "/registration/vip/step3"
                          });
                        }
                      })
                      .catch((e) => {});
                  } else {
                    this.axios.post(this.$store.state.baseURL + "update-vip-info", {
                        passport_expiry_date: this.$store.state.vip_user.passport_expiry_date,
                        title: this.$store.state.vip_user.title,
                        first_name: this.$store.state.vip_user.first_name,
                        last_name: this.$store.state.vip_user.last_name,
                        gender: this.$store.state.vip_user.gender,
                        email: this.$store.state.vip_user.email,
                        full_name: this.$store.state.vip_user.full_name,
                        personal_photo: this.$store.state.vip_user.personal_photo,
                        passport_image: this.$store.state.vip_user.passport_image,
                        passport_type: this.$store.state.vip_user.passport_type,
                        token_key: this.$store.state.vip_user_token,
                        nationality: this.$store.state.vip_user.nationality,
                        country_of_residence: this.$store.state.vip_user.country_of_residence,
                        id_pass_iqama: this.$store.state.vip_user.id_pass_iqama,
                        place_of_birth: this.$store.state.vip_user.place_of_birth,
                        date_of_birth: this.$store.state.vip_user.date_of_birth,
                        qualification: this.$store.state.vip_user.qualification,
                        phone: this.$store.state.vip_user.phone,
                        category: this.$store.state.vip_user.category,
                        work_place: this.$store.state.vip_user.work_place,
                        job_title: this.$store.state.vip_user.job_title,
                        address: this.$store.state.vip_user.address,
                        facebook: this.$store.state.vip_user.facebook,
                        twitter: this.$store.state.vip_user.twitter,
                        telephone: this.$store.state.vip_user.telephone,
                        status: "",
                        visa_status: "new",
                        flight_status: "new",
                        hotel_status: "new",
                      })
                      .then((response) => {

                        if(response.data.success == false){
                          console.log(this.$store.state.vip_user);
                          $('.alert_error').removeClass('h');
                          setTimeout(function() {
                            $('.alert_error').addClass("h");;
                          }, 5000);
                            $('.next_btn').removeClass('disabled_btn');
                            this.disabled_submit = false;
                        }else{
                          $('.next_btn').removeClass('disabled_btn');
                          this.disabled_submit = false;
                          this.$router.push({
                            path: "/registration/vip/step3"
                          });
                        }

                      })
                      .catch((e) => {});
                  }
                } else {
                  $(".validatePhone").html(this.$t('duplicated_phone'));
                  $(".validatePhone").slideDown();
                  $('.alert_error').removeClass('h');
                  setTimeout(function() {
                    $('.alert_error').addClass("h");;
                  }, 5000);
                  $('.next_btn').removeClass('disabled_btn');
                  this.disabled_submit = false;
                }
              })
              .catch((e) => {});
          } else {
            $('.next_btn').removeClass('disabled_btn');
            this.disabled_submit = false;
            this.$router.push({
              path: "/registration/vip/step3"
            });
          }
        } else {
          $('.next_btn').removeClass('disabled_btn');
          this.disabled_submit = false;
        }
      }
    },
  }
}
</script>
