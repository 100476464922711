import {
  createRouter,
  createWebHistory
} from 'vue-router'
// import Registration from '../views/registration/registrationView.vue'
import ThankYou from '../views/registration/thankyou.vue'

import GuestStep1View from '../views/registration/guestRegistration/Step1View.vue'
import GuestStep3View from '../views/registration/guestRegistration/Step3View.vue'
import GuestStep2 from '../views/registration/guestRegistration/Step2View.vue'
import MediaStep1 from '../views/registration/media/Step1View.vue'
import MediaStep2 from '../views/registration/media/Step2View.vue'
import SpeakerStep1 from '../views/registration/speaker/Step1View.vue'
import SpeakerStep2 from '../views/registration/speaker/Step2View.vue'
import SpeakerStep3 from '../views/registration/speaker/Step3View.vue'
import SpeakerStep4 from '../views/registration/speaker/Step4View.vue'
import SpeakerStep5 from '../views/registration/speaker/Step5View.vue'
import SpeakerStep6 from '../views/registration/speaker/Step6View.vue'
import SpeakerThankyou from '../views/registration/speaker/thankyou.vue'
import SpeakerVisa from '../views/registration/speaker/Visa.vue'
import SpeakerVisaGCC from '../views/registration/speaker/Visa_gcc.vue'

// import Visa1 from '../views/registration/visa/Visa1.vue'
// import Visa2 from '../views/registration/visa/Visa2.vue'
// import Visa3 from '../views/registration/visa/Visa3.vue'
import Guest1 from '../views/registration/guest/Guest1.vue'
import Guest2 from '../views/registration/guest/Guest2.vue'
import Guest3 from '../views/registration/guest/Guest3.vue'
import Guest4 from '../views/registration/guest/Guest4.vue'
import Guest5 from '../views/registration/guest/Guest5.vue'
import Guest6 from '../views/registration/guest/Guest6.vue'
import GuestVisa from '../views/registration/guest/Visa.vue'
import GuestVisaGCC from '../views/registration/guest/Visa_gcc.vue'
import GuestThankyou from '../views/registration/guest/thankyou.vue'
import MediaStep1Staff from '../views/registration/media/StaffStep1View.vue'
import MediaStep2Staff from '../views/registration/media/StaffStep2View.vue'
import GuestStep4 from '../views/registration/guestRegistration/Step4View.vue'
import GuestStep5 from '../views/registration/guestRegistration/Step5View.vue'
import GuestStep6 from '../views/registration/guestRegistration/Step6View.vue'
import ExhGuestVisa from '../views/registration/guestRegistration/Visa.vue'
import ExhGuestVisaGCC from '../views/registration/guestRegistration/Visa_gcc.vue'
import LinkMedia from '../views/registration/media/LinkMedia.vue'
import LinkExhibitor from '../views/registration/LinkExhibitor.vue'
import LinkNotAvailable from '../views/registration/LinkNotAvailable.vue'
import VIPStep1 from '../views/registration/VIP/Step1View.vue'
import VIPStep2 from '../views/registration/VIP/Step2View.vue'
import VIPStep3 from '../views/registration/VIP/Step3View.vue'
import VIPStep4 from '../views/registration/VIP/Step4View.vue'
import VIPStep5 from '../views/registration/VIP/Step5View.vue'
import VIPStep6 from '../views/registration/VIP/Step6View.vue'
import VipThankyou from '../views/registration/VIP/thankyou.vue'
import VipVisa from '../views/registration/VIP/Visa.vue'
import VipVisaGCC from '../views/registration/VIP/Visa_gcc.vue'
import VipStep1Staff from '../views/registration/VIP/StaffStep1View.vue'
import VipStep2Staff from '../views/registration/VIP/StaffStep2View.vue'
import MSTAFFStep4 from '../views/registration/media/Step4View.vue'
import MSTAFFStep5 from '../views/registration/media/Step5View.vue'
import MSTAFFStep6 from '../views/registration/media/Step6View.vue'
import MVisa from '../views/registration/media/Visa.vue'
import MVisaGCC from '../views/registration/media/Visa_gcc.vue'
import VisaStep1 from '../views/registration/visa/Step1.vue'
import VisaStep2 from '../views/registration/visa/Step2.vue'
import VisaStep3 from '../views/registration/visa/Step3.vue'
import Visa from '../views/registration/visa/Visa.vue'
import VisaGcc from '../views/registration/visa/VisaGcc.vue'
import RegFailed from '../views/registration/regfailed.vue'
import InvaliedToken from '../views/registration/InvalidToken.vue'




const routes = [
// EXIHIBITOR REGISTRATION ROUTES
{
  path: '/registration/step1',
  name: 'step1',
  component: () => import('../views/registration/Step1View.vue'),
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/step2',
  name: 'step2',
  component: () => import('../views/registration/Step2View.vue'),
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/step3',
  name: 'step3',
  component: () => import('../views/registration/Step3View.vue'),
  meta: {
    layout: 'mainlayout'
  }
},















{
  path: '/media-center',
  name: 'MediaCenter',
  component: () => import('../views/web/media-center/media-center-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/discover-biban',
  name: 'DiscoverBiban',
  component: () => import('../views/web/wizard/wizard-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/news',
  name: 'News',
  component: () => import('../views/web/news/news-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/guidelines',
  name: 'Guidelines',
  component: () => import('../views/web/guidelines/guidelines-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/download-app',
  name: 'Downloadapp',
  component:()=>import('../views/web/directory/directory-index.vue') ,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/directory',
  name: 'Directory',
  component:()=>import('../views/web/directory/directory-index.vue') ,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/app',
  name: 'App',
  component:()=>import('../views/web/directory/directory-index.vue') ,
  meta: {
    layout: 'WebLayout'
  }
},

{
  path: '/gallery',
  name: 'Gallery',
  component: () => import('../views/web/gallery/gallery-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},


{
  path: '/news-item/',
  name: 'OneNews',
  component:()=> import('../views/web/news-item/news-item-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/',
  name: 'NewHomeWebsite',
  component: () => import('../views/web/home/home-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/sur/',
  name: 'SurveyPage',
  component: () => import('../views/survey/survey-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/survey/thankyou',
  name: 'SurveyThankyou',
  component: ()=>import('../views/survey/thankyou.vue'),
  meta: {
    layout: 'WebLayout'
  },
},
{
  path: '/survey/failed',
  name: 'SurveyFailed',
  component: ()=>import('../views/survey/failed.vue'),
  meta: {
    layout: 'WebLayout'
  },
},
{
  path: '/speakers',
  name: 'NewSpeakersWeb',
  component: () =>import('../views/web/speakers/speakers-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/sme',
  name: 'NewSMEWeb',
  component: () =>import('../views/web/sme/sme-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/aboutMonshaat',
  name: 'about-monshaat',
  component: () => import('../views/web/about/about-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/exhibitors',
  name: 'NewExhibitorsWeb',
  component: ()=>import('../views/web/exhibitors/exhibitors-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/ewc',
  name: 'NewEwc',
  component: ()=> import('../views/web/ewc/ewc-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/agenda',
  name: 'Agenda',
  component: () => import('../views/web/agenda/agenda-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/opportunities',
  name: 'Opportunities',
  component: ()=>import('../views/web/opportunities/opportunities-index.vue') ,
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},

{
  path: '/side-event',
  name: 'SideEventWeb',
  component:()=>import('../views/web/side-event/side-event-index.vue')  ,
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/side-event/option',
  name: 'SideEvent',
  component:()=>import('../views/web/side-event/side-event-index.vue')  ,
  meta: {
    layout: 'WebLayout'
  }
},

{
  path: '/visit-saudi',
  name: 'visit-saudi',
  component:()=> import('../views/web/visit-saudi/visit-saudi-index.vue'),
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/faq',
  name: 'faq',
  component:()=> import('../views/web/faq/faq-index.vue') ,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/workshops',
  name: 'workshops',
  component:()=> import('../views/web/workshops/workshops-index.vue'),
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/door/',
  name: 'door',
  component:()=> import('../views/web/door/door-index.vue'),
  params: true,
  meta: {
    layout: 'WebLayout'
  }
},
// {
//   path: '/registration/visitor/step1%20',
//   redirect: '/registration/visitor/step1'
// },
// {
//   path: '/registration/test/visitor/step1',
//   redirect: '/registration/visitor/step1'
// },
{
  path: '/registration/visitor/step1',
  name: 'VisitorStep1',
  component:()=>import('../views/registration/visitor/step1.vue'),
  meta: {
    layout: 'WebLayout'
  }
},

{
  path: '/registration/visitor/step2',
  name: 'VisitorStep2',
  component:()=>import('../views/registration/visitor/step2.vue'),
  meta: {
    layout: 'WebLayout'
  }
},

{
  path: '/registration/visitor/thankyou',
  name: 'VisitorThankyou',
  component: ()=>import('../views/registration/visitor/thankyou.vue'),

  meta: {
    layout: 'WebLayout'
  },
},
{
  path: '/registration/flight/option',
  name: 'FlightOption',
  component:()=>import('../views/registration/flight/flightOption.vue') ,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/registration/flight/thank-you',
  name: 'MessageThankyou',
  component:()=>import('../views/registration/flight/thankyou_2.vue'),
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/registration/flight/thankyou',
  name: 'OptionThankyou',
  component:()=>import('../views/registration/flight/thankyou_1.vue') ,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/registration/flight/selected',
  name: 'FlightProcessing',
  component:()=>import('../views/registration/flight/option_selected.vue') ,
  meta: {
    layout: 'WebLayout'
  }
},

{
  path: '/certificate',
  name: 'Certificate',
  component:()=>import('../views/web/certificate/Certificate.vue'),
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/certificate-trainer',
  name: 'T_Certificate',
  component:()=>import('../views/web/certificate/t_Certificate.vue'),
  meta: {
    layout: 'WebLayout'
  }
},

{
  path: '/visitor-badge',
  name: 'VisitorBadge',
  component:()=>import('../views/registration/badge/VisitorBadge.vue'),
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/E-badge',
  name: 'EBadge',
  component:()=>import('../views/registration/badge/VisitorBadge.vue'),
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/plane-badge',
  name: 'PlaneBadge',
  component:()=>import('../views/registration/badge/PlaneBadge.vue'),
  meta: {
    layout: 'WebLayout'
  }
},


{
  path: '/side-event/thankyou',
  name: 'SideThankyou',
  component:()=>import('../views/web/side-event/thankyou.vue')  ,
  meta: {
    layout: 'WebLayout'
  }
},
{
  path: '/resources/catalogue/',
  name: 'Catalogue',
  component:()=>import('../views/web/catalogue/catalogue-index.vue')  ,
  meta: {
    layout: 'WebLayout'
  }
},

{
  path: '/registration-link-media',
  name: 'link-media',
  component: LinkMedia,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/failed',
  name: 'regfailed',
  component: RegFailed,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/invalid-token',
  name: 'InvalidToken',
  component: InvaliedToken,
  meta: {
    layout: 'mainlayout'
  }
},
/*
{
  path: '/',
  name: 'landing',
  component: HomeView,
  meta: {
    // layout: 'HomeLayout'
  }
},
*/
{
  path: '/registration-link-exhibitor',
  name: 'LinkExhibitor',
  component: LinkExhibitor,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration-link-expire',
  name: 'LinkNotAvailable',
  component: LinkNotAvailable,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},

{
  path: '/registration/speaker/step1',
  name: 'step1speaker',
  component: SpeakerStep1,
  meta: {
    layout: 'mainlayout'
  }
},

{
  path: '/registration/speaker/step2',
  name: 'step2speaker',
  component: SpeakerStep2,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/speaker/step3',
  name: 'step3speaker',
  component: SpeakerStep3,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/speaker/step4',
  name: 'step4speaker',
  component: SpeakerStep4,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/speaker/step5',
  name: 'step5speaker',
  component: SpeakerStep5,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/speaker/step6',
  name: 'step6speaker',
  component: SpeakerStep6,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/speaker/thankyou',
  name: 'thankyouspeaker',
  component: SpeakerThankyou,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/speaker/visa',
  name: 'visaspeaker',
  component: SpeakerVisa,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/speaker/gcc-visa',
  name: 'visagccspeaker',
  component: SpeakerVisaGCC,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/vip/step1',
  name: 'step1vip',
  component: VIPStep1,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/vip/step2',
  name: 'step2vip',
  component: VIPStep2,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/vip/step3',
  name: 'step3vip',
  component: VIPStep3,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/vip/step4',
  name: 'step4vip',
  component: VIPStep4,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/vip/step5',
  name: 'step5vip',
  component: VIPStep5,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/vip/step6',
  name: 'step6vip',
  component: VIPStep6,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/vip/thankyou',
  name: 'thankyouvip',
  component: VipThankyou,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/vip/visa',
  name: 'visavip',
  component: VipVisa,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/vip/gcc-visa',
  name: 'visagccvip',
  component: VipVisaGCC,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/vip/staff/step1',
  name: 'step1vipStaff',
  component: VipStep1Staff,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/vip/staff/step2',
  name: 'step2vipStaff',
  component: VipStep2Staff,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/media/step1',
  name: 'step1media',
  component: MediaStep1,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/media/step2',
  name: 'step2media',
  component: MediaStep2,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/media/staff/step1',
  name: 'step1mediaStaff',
  component: MediaStep1Staff,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/media/staff/step2',
  name: 'step2mediaStaff',
  component: MediaStep2Staff,
  meta: {
    layout: 'mainlayout'
  }
},
// NEW ROUTS
{
  path: '/registration/media/step3',
  name: 'step4media',
  component: MSTAFFStep4,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/media/step4',
  name: 'step5media',
  component: MSTAFFStep5,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/media/step5',
  name: 'step6media',
  component: MSTAFFStep6,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/media/visa',
  name: 'visamedia',
  component: MVisa,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/media/visa_gcc',
  name: 'visagccmedia',
  component: MVisaGCC,
  meta: {
    layout: 'mainlayout'
  }
},


// {
//   path: '/about',
//   name: 'about',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
//   meta: {
//     layout: 'mainlayout'
//   }
// },
// {
//   path: '/registration',
//   name: 'registration',
//   component: Registration,
//   // params: true,
//   meta: {
//     layout: 'mainlayout'
//   }
// },

{
  path: '/registration-guest/step1',
  name: 'guestStep1',
  component: GuestStep1View,
  // params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration-guest/step2',
  name: 'GuestStep2',
  component: GuestStep2,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration-guest/step3',
  name: 'guestStep3',
  component: GuestStep3View,
  // params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration-guest/step4',
  name: 'guestStep4',
  component: GuestStep4,
  // params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration-guest/step5',
  name: 'guestStep5',
  component: GuestStep5,
  // params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration-guest/step6',
  name: 'guestStep6',
  component: GuestStep6,
  // params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration-guest/visa',
  name: 'ExhGuestVisa',
  component: ExhGuestVisa,
  // params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration-guest/visa_gcc',
  name: 'ExhGuestVisaGCC',
  component: ExhGuestVisaGCC,
  // params: true,
  meta: {
    layout: 'mainlayout'
  }
},
// {
//   path: '/visa1',
//   name: 'Visa1',
//   component: Visa1,
//   meta: {
//     layout: 'mainlayout'
//   }
// },
// {
//   path: '/visa2',
//   name: 'Visa2',
//   component: Visa2,
//   meta: {
//     layout: 'mainlayout'
//   }
// },
// {
//   path: '/visa3',
//   name: 'Visa3',
//   component: Visa3,
//   meta: {
//     layout: 'mainlayout'
//   }
// },
{
  path: '/registration/thankyou',
  name: 'thankyou',
  component: ThankYou,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/guest1',
  name: 'Guest1',
  component: Guest1,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/guest2',
  name: 'Guest2',
  component: Guest2,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/guest3',
  name: 'Guest3',
  component: Guest3,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/guest4',
  name: 'Guest4',
  component: Guest4,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/guest5',
  name: 'Guest5',
  component: Guest5,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/guest6',
  name: 'Guest6',
  component: Guest6,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/guest/visa',
  name: 'GuestVisa',
  component: GuestVisa,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/guest/visa_gcc',
  name: 'GuestVisaGCC',
  component: GuestVisaGCC,
  params: true,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/guest-thankyou',
  name: 'GuestThankyou',
  component: GuestThankyou,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/visa/step1',
  name: 'VisaStep1',
  component: VisaStep1,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/visa/step2',
  name: 'VisaStep2',
  component: VisaStep2,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/visa/step3',
  name: 'VisaStep3',
  component: VisaStep3,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/visa/Visa',
  name: 'Visa',
  component: Visa,
  meta: {
    layout: 'mainlayout'
  }
},
{
  path: '/registration/visa/VisaGcc',
  name: 'VisaGcc',
  component: VisaGcc,
  meta: {
    layout: 'mainlayout'
  }
},
//Visitor



]

const router = createRouter({
  //  history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
})





export default router
