<template>
  <component :is="layout" />
</template>
<script>
import Mainlayout from '@/layouts/Mainlayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import HomeLayout from '@/layouts/HomeLayout.vue';

export default {
  name: "App",

  data() {
    return {
      layout: null,
    };
  },
  created(){
  if($('html').attr('lang') == 'ar'){
    $('#title').html('بيبان 24 وجهة عالمية للفرص')
  }
  else{
    $('#title').html('BIBAN 24 Global Destination for Opportunities')
  }
// $('#title').html(this.$t('web_title'));
  },
  components: {
    Mainlayout,
    EmptyLayout
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = "EmptyLayout"
      }
    },
  },
};
</script>
