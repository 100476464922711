<template>
  <div class="mt100 c mb100">
    <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
      <img class="g_nm" src="@/assets/img/biban-logo.png"  alt="profile_image" />
      <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }}  {{ $t('as_exh_staff') }}</div>
      <div class="mt10 l w100">
        <div class="bg-prograss-bar prograss-bar">
          <div class="prograss-bar ov ra w100 g_nm bg_orange"></div>
        </div>
        <div class="mt10 c-blue">{{ $t('step3') }}</div>
        <div class="mt10 b">{{ $t('basic_registration') }}</div>
        <form class="pv10" @submit.prevent="submit_form">
          <!-- START QUALIFICATION -->
          <div class="mt15">
            <label class="label_fields">{{$t('qualification')}} *</label>
            <Select2 :disabled="view_only_all == 1" v-model="form.qualification" :options="qualification_list"/>
            <div class="validate_qualification validation_warning"></div>
          </div>
          <!-- CLOSE QUALIFICATION -->
          <!-- START WORKPLACE -->
          <div class="mt15">
            <label class="label_fields">{{$t('work_place')}} *</label>
            <input :disabled="view_only_all == 1" class="input_fields" v-model="form.work_place" type="text"/>
            <div class="validate_work_place validation_warning"></div>
          </div>
          <!-- CLOSE WORKPLACE -->
          <!-- START JOBTITLE -->
          <div class="mt15">
            <label class="label_fields">{{$t('job_title')}} *</label>
            <input :disabled="view_only_all == 1" class="input_fields" v-model="form.job_title" type="text"/>
            <div class="validate_job_title validation_warning"></div>
          </div>
          <!-- CLOSE JOBTITLE -->
          <!-- START ADDRESS -->
          <div class="mt15">
            <label class="label_fields">{{$t('address')}} *</label>
            <input :disabled="view_only_all == 1" class="input_fields" v-model="form.address" type="text"/>
            <div class="validate_address validation_warning"></div>
          </div>
          <!-- CLOSE ADDRESS -->
          <!-- START FACEBOOK -->
          <div class="mt15">
              <label class="label_fields">{{$t('do_you_have_facebook')}}</label>
              <br>
              <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveFacebook"  value="1">{{ $t('yes') }}
              <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveFacebook" value="0">{{ $t('no') }}
          </div>
          <div v-if="HaveFacebook == '1'" class="mt10">
            <input :disabled="view_only_all == 1" class="sm ph10 input_fields" v-model="form.facebook" v-bind:placeholder="$t('facebook')" type="text" />
            <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue mdi mdi-facebook"></span></div>
              <div class="validate_facebook validation_warning"></div>
          </div>
          <!-- CLOSE FACEBOOK -->
          <!-- START TWITTER -->
          <div class="mt15">
              <label class="label_fields">{{$t('do_you_have_twitter')}}</label>
              <br>
              <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveTwitter"  value="1">{{ $t('yes') }}
              <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="HaveTwitter" value="0">{{ $t('no') }}
              <div class="validateGender validation_warning"></div>
          </div>
          <div v-if="HaveTwitter == '1'" class="mt10">
            <input :disabled="view_only_all == 1" class="sm ph10 input_fields" v-model="form.twitter" v-bind:placeholder="$t('twitter')" type="text" />
            <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue icon-x-twitter"></span></div>
              <div class="validate_twitter validation_warning"></div>
          </div>
          <!-- CLOSE TWITTER -->
          <!-- START PASSPORT EXPIRE DATE -->
          <div v-if="$store.state.no_visa_countries.indexOf($store.state.guest.nationality) != -1 ">
            <label class="label_fields">{{$t('id_expiry_date')}} *</label>
            <input class="input_fields select_input expiry_date" placeholder="" v-model="form.passport_expiry_date" min="2023-04-01" type="date"/>
            <div class="validatePassportExpiryDate validation_warning"></div>
          </div>
          <div v-else-if="$store.state.no_visa_countries.indexOf($store.state.guest.nationality) == -1  && $store.state.guest.country_of_residence == 194">
            <label class="label_fields">{{$t('iqama_expiry_date')}} *</label>
            <input class="input_fields select_input expiry_date" placeholder="" v-model="form.passport_expiry_date" min="2023-04-01" type="date"/>
            <div class="validatePassportExpiryDate validation_warning"></div>
          </div>
          <div v-else class="mt15">
            <label class="label_fields">{{$t('pass_expire_date')}} *</label>
            <input class="input_fields select_input expiry_date" placeholder="" v-model="form.passport_expiry_date" min="2023-09-09" type="date"/>
            <div class="validatePassportExpiryDate validation_warning"></div>
          </div>
          <!-- CLOSE PASSPORT EXPIRE DATE -->
          <!-- START ROLE -->
          <div class="mt15">
            <label class="label_fields">{{ $t('role') }} * </label>
            <Select2 :disabled="view_only_all == 1" v-model="form.role" :options="role_list" />
            <div class="validaterole validation_warning"></div>
          </div>
          <!-- CLOSE ROLE -->
          <!-- START PERSONAL PHOTO -->
          <div class="mt15">
            <label class="pointer">
              <tbl_nm>
                <cl class="w5">
                  <div class="bg-blue p25 round100" style="width:30px;">
                    <span v-if="form.personalimage == '' || form.personalimage == null " class="cc mdi mdi-image-outline fs20 cw"></span>
                    <img v-else :src='logo_image' style="width:50px; height:50px;" class="cc round100 cw" />
                  </div>
                </cl>
                <cl class="colSep"></cl>
                <cl class="wa" style="max-width:250px;">
                  <label class="label_fields">{{ $t('personalphoto') }} *</label>
                    <input :disabled="view_only_all == 1" type="file" id="fileaa" ref="fileaa"  @change="uploadImagepersonal()" accept="image/*" hidden/>
                    <div class="fs12 nw oe image_textpersonal">
                      {{ $t('image_text') }}
                    </div>
                </cl>
                <cl class="colSep"></cl>
                <cl class="w5 r" style="min-width:25px;">
                  <span class="mdi mdi-image-multiple-outline c-blue fs20"></span>
                  <span class="mdi mdi-information-outline pointer c-blue fs20" @click="showpop_image = 1"></span>
                </cl>
              </tbl_nm>
            </label>
            <div class="validate_logopersonal validation_warning"></div>
          </div>
          <!-- CLOSE PERSONAL PHOTO -->
          <!-- START PASSPORT PHOTO -->
          <div class="mt15">
              <tbl_nm>
              <label class="pointer">
                <cl class="w5">
                  <div class="bg-blue p25 round100" style="width:30px;">
                    <span v-if="form.passportimage == '' || form.passportimage == null " class="cc mdi mdi-image-outline fs20 cw"></span>
                    <img v-else :src='logo_imagepassport' style="width:50px; height:50px;" class="cc round100 cw" />
                  </div>
                </cl>
                <cl class="colSep"></cl>
                <cl class="wa" style="max-width:250px;">
                  <label v-if="$store.state.no_visa_countries.indexOf($store.state.guest.nationality) != -1" class="label_fields">{{$t('IDphoto')}} *</label>
                  <label v-else-if="$store.state.guest.country_of_residence == 194" class="label_fields">{{$t('iqamaphoto')}} *</label>
                  <label v-else class="label_fields">{{$t('passportphoto')}} *</label>
                  <input :disabled="view_only_all == 1" type="file" id="file" ref="file"  @change="uploadImage()" accept="image/*" hidden/>
                  <div class="fs12 nw oe image_text">
                    {{ $t('image_text') }}
                  </div>
                </cl>
                </label>
                <cl class="colSep"></cl>
                <cl class="w5 r" style="min-width:25px;">
                  <span class="mdi mdi-image-multiple-outline c-blue fs20"></span>
                  <span class="mdi mdi-information-outline pointer c-blue fs20" @click="showpop_image = 1"></span>
                </cl>
              </tbl_nm>
            <div class="validate_logopassport validation_warning"></div>
          </div>
          <!-- CLOSE PASSPORT PHOTO -->
          <div class="mt20 r">
            <button v-if="$store.state.is_view_only == 0" @click="submit" class="next_btn ph20 ml20">
              <div v-if="disabled_submit" class="mt10 mb10 ml5 mr5 rotatingLoader" ></div>
              <span v-if="!disabled_submit" class="cw lh40 fs18" >{{$t('next')}}</span>
              <!-- <span v-if="$i18n.locale == 'en' && !disabled_submit" class="cw mdi mdi-arrow-right lh40 fs30"></span>
              <span v-if="$i18n.locale == 'ar' && !disabled_submit" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="popup_descimage round2x l" v-if="showpop_image == 1" style="z-index:9999999999;">
    <div class="popupdesc_img_close ov20 pointer la mr20 ba" @click="showpop_image = 0">{{$t('close')}}</div>
    <h2>{{$t('photo_specs')}}</h2>
    <p>{{$t('photo_specs_title')}}</p>
    <ul class="popdesc_ul_list">
      <li>{{$t('photo_specs_li_1')}}</li>
      <li>{{$t('photo_specs_li_2')}}</li>
      <li>{{$t('photo_specs_li_3')}}</li>
      <li>{{$t('photo_specs_li_4')}}</li>
      <li>{{$t('photo_specs_li_5')}}</li>
      <li>{{$t('photo_specs_li_6')}}</li>
      <li>{{$t('photo_specs_li_7')}}</li>
      <li>{{$t('photo_specs_li_8')}}</li>
      <li>{{$t('photo_specs_li_9')}}</li>
      <li>{{$t('photo_specs_li_10')}}</li>
      <li>{{$t('photo_specs_li_11')}}</li>
      <li>{{$t('photo_specs_li_12')}}</li>
      <li>{{$t('photo_specs_li_13')}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "guestStep3",
  data() {
    return {
      disabled_submit: false,
      showpop_image:0,
      errors: 0,
      industry_other: false,
      view_only_all: 0,
      industries_list: null,
      HaveFacebook:'0',
      HaveTwitter:'0',
      ar_qualification:[
        {id:1,text:'ثانوي'},
        {id:2,text:'بكالوريس'},
        {id:3,text:'ماجستير'},
        {id:4,text:'دكتوراه'},
      ],
      en_qualification:[
        {id:1,text:'Secondary'},
        {id:2,text:'Bachelor\'s degree'},
        {id:3,text:'Master\'s'},
        {id:4,text:'Ph.D'},
      ],
      logo_image: (this.$store.state.baseURLuploadImage),
      logo_imagepassport: (this.$store.state.baseURLuploadImage),
      main_logo_image: (this.$store.state.baseURLuploadImage),
      main_logo_imagepassport: (this.$store.state.baseURLuploadImage),
      form: {
        personalimage: "",
        passportimage: "",
        role: "",
        qualification:'',
        work_place:'',
        job_title:'',
        address:'',
        facebook:'',
        twitter:'',
        passport_expiry_date:'',

      },
    };
  },
  created() {
    if(this.$store.state.exh_guest_token_key == null || this.$store.state.exh_guest_token_key == ""){
      this.$router.push({ path: '/registration/failed' });
    }
    if(this.$store.state.is_view_only == 1){
      this.view_only_all=1;
    }
    this.form.role=this.$store.state.saved_guest_data.job;
    var objectresponsecuritycheck=this.$store.state.saved_guest_data.datasecurity;
    if(objectresponsecuritycheck == null){
    }
    else{
      this.form.qualification = objectresponsecuritycheck.qualification;
      this.form.work_place = objectresponsecuritycheck.work_place;
      this.form.job_title = objectresponsecuritycheck.job_title;
      this.form.address = objectresponsecuritycheck.address;
      this.form.facebook = objectresponsecuritycheck.facebook;
      this.form.twitter = objectresponsecuritycheck.twitter;
      this.form.passport_expiry_date = objectresponsecuritycheck.passport_expiry_date;
      this.form.personalimage= objectresponsecuritycheck.personal_photo;
      this.form.passportimage= objectresponsecuritycheck.passport_image;
      this.logo_image=this.logo_image+"images/"+ objectresponsecuritycheck.personal_photo;
      this.logo_imagepassport=this.logo_imagepassport+"images/"+objectresponsecuritycheck.passport_image;
      this.$store.state.security_check_data=objectresponsecuritycheck;
    }
    if(this.form.facebook){
      this.HaveFacebook = 1;
    }
    if(this.form.twitter){
      this.HaveTwitter = 1;
    }
  },
  computed:{
    qualification_list(){
      if(this.$i18n.locale == 'ar'){
          return this.ar_qualification
      }
      return this.en_qualification
    },
    role_list(){
      if(this.$i18n.locale == 'ar'){
        return this.$store.state.ar_role_list
      }
      return this.$store.state.en_role_list
    }
  },
  methods: {
    validatedata(formname,validatefieldclass, text) {
      if (formname == "" || formname == null || formname == 0) {
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        this.errors = 1;
      }
      else{
        $(validatefieldclass).slideUp();
      }
    },
    uploadImagepersonal() {
      const uploadurl = this.$store.state.baseURLuploadImage + "upload_image.php";
      this.file = this.$refs.fileaa.files[0];
      $('.image_textpersonal').html(this.file.name)
      let formData = new FormData();
      var logoname = Date.now();
      var path_title="personal";
      logoname=path_title+"_"+logoname +""+Math.floor(Math.random() * 10000);
      formData.append('file', this.file);
      formData.append('image_name', logoname);
      formData.append('path', path_title);
      var self = this;
      this.axios.post(uploadurl, formData,{headers: {'Content-Type': 'multipart/form-data'}}).then(function (response) {
        if(response.data == 0){
          alert('File not uploaded.');
          // console.log(response.data);
          $(".validate_logopersonal").html("Problem with uploading the Image");
          $(".validate_logopersonal").slideDown();
            self.errors = 1;
        }else if(response.data == 1){
          $(".validate_logopersonal").html("Image size exceeds 4MB");
          $(".validate_logopersonal").slideDown();
            self.errors = 1;
        }else if(response.data == 2){
          $(".validate_logopersonal").html("Upload valid images Only");
          $(".validate_logopersonal").slideDown();
            self.errors = 1;
        }else{
            self.errors = 0;
              $(".validate_logopersonal").slideUp();
          // console.log(response.data);
          self.form.personalimage=response.data;
          console.log(self.form.personalimage);
          self.logo_image=self.main_logo_image+"images/"+response.data;
          // alert('File uploaded successfully.');
        }
      })
      .catch(function (error) {
        // console.log(error);
        });
    },

    uploadImage() {
      const uploadurl = this.$store.state.baseURLuploadImage + "upload_image.php";
      this.file = this.$refs.file.files[0];
      $('.image_text').html(this.file.name)
      let formData = new FormData();
      var logoname = Date.now();
      var path_title="passport";
      logoname=path_title+"_"+logoname +""+Math.floor(Math.random() * 10000);
      formData.append('file', this.file);
      formData.append('image_name', logoname);
      formData.append('path', path_title);
      var self = this;
      this.axios.post(uploadurl, formData,{headers: {'Content-Type': 'multipart/form-data'}}).then(function (response) {
        if(response.data == 0){
          alert('File not uploaded.');
          // console.log(response.data);
          $(".validate_logo").html("Problem with uploading the Image");
          $(".validate_logo").slideDown();
            self.errors = 1;
        }else if(response.data == 1){
          $(".validate_logo").html("Image size exceeds 4MB");
          $(".validate_logo").slideDown();
            self.errors = 1;
        }else if(response.data == 2){
          $(".validate_logo").html("Upload valid images Only");
          $(".validate_logo").slideDown();
            self.errors = 1;
        }else{
            self.errors = 0;
              $(".validate_logo").slideUp();
          // console.log(response.data);
          self.form.passportimage=response.data;
          self.logo_imagepassport=self.main_logo_imagepassport+"images/"+response.data;
          // alert('File uploaded successfully.');
        }
      })
      .catch(function (error) {
        // console.log(error);
        });
    },


    submit() {
      if(this.disabled_submit == false){
        $('.next_btn').addClass('disabled_btn');
        this.disabled_submit=true;
        this.errors = 0;
        this.$emit("submit", this.form);
        if (this.form.personalimage == "" || this.form.personalimage == null || this.form.personalimage == 0) {
          $(".validate_logopersonal").html(this.$t('personal_image_is_required'));
          $(".validate_logopersonal").slideDown();
          this.errors = 1;
        }
        else {
          $(".validate_logopersonal").slideUp();
        }
        if (this.form.passportimage == "" || this.form.passportimage == null || this.form.passportimage == 0) {
          $(".validate_logo").html(this.$t('photo_is_required'));
          $(".validate_logo").slideDown();
          this.errors = 1;
        }
        else {
          $(".validate_logo").slideUp();
        }


        this.validatedata(this.form.role,".validaterole", this.$t('filed_is_required'));
        this.validatedata(this.form.qualification,".validate_qualification", this.$t('qualification_is_required'));
        this.validatedata(this.form.work_place,".validate_work_place", this.$t('work_place_is_required'));
        this.validatedata(this.form.job_title,".validate_job_title", this.$t('job_title_is_required'));
        this.validatedata(this.form.address,".validate_address", this.$t('address_is_required'));
        if(this.HaveFacebook == '1'){
          this.validatedata(this.form.facebook,".validate_facebook", this.$t('facebook_is_required'));
        }
        if(this.HaveTwitter == '1'){
          this.validatedata(this.form.twitter,".validate_twitter", this.$t('twitter_is_required'));
        }
        var res = this.$store.state.no_visa_countries.indexOf(this.$store.state.guest.nationality);
        if(res != -1){
          this.validatedata(this.form.passport_expiry_date,".validatePassportExpiryDate", this.$t('id_expiry_is_required'));
        }
        else if(res == -1 && this.$store.state.guest.country_of_residence == 194){
          this.validatedata(this.form.passport_expiry_date,".validatePassportExpiryDate", this.$t('iqama_expiry_is_required'));
        }
        // if(res == -1 && this.$store.state.country_of_residence !=194){
        else{
          this.validatedata(this.form.passport_expiry_date,".validatePassportExpiryDate", this.$t('passport_expiry_is_required'));
        }
        if(this.errors == 0){
          this.$store.state.guest.role=this.form.role;
          this.$store.state.visa.type_of_user ="exh_staff";
          this.$store.state.visa.user_token_key =this.$store.state.exh_guest_token_key;
          this.$store.state.visa.nationality = this.$store.state.guest.nationality;
          this.$store.state.visa.gender = this.$store.state.guest.gender;
          this.$store.state.visa.country_of_residence = this.$store.state.guest.country_of_residence;
          this.$store.state.visa.userID =this.$store.state.saved_guest_data.id;
          this.$store.state.visa.full_name = this.$store.state.saved_guest_data.full_name;
          this.$store.state.visa.date_of_birth = this.$store.state.saved_guest_data.date_of_birth;
          this.$store.state.visa.place_of_birth = this.$store.state.saved_guest_data.place_of_birth;
          this.$store.state.visa.passport_type = this.$store.state.saved_guest_data.passport_type;
          var res = this.$store.state.no_visa_countries.indexOf(this.$store.state.guest.nationality);
          if(res == -1 && this.$store.state.country_of_residence !=194){
            this.$store.state.visa.passport_expiry_date = this.$store.state.saved_guest_data.passport_expiry_date;
          }
        //  alert(res);
          //alert(this.$store.state.guest.nationality);
          if( this.$store.state.guest.country_of_residence == 194 || res != -1){
            if(this.form.twitter){}else{
              this.form.twitter=null;
            }
            if(this.form.facebook){}else{
              this.form.facebook=null;
            }
            this.axios.post(this.$store.state.baseURL + "add-staff-guest", {
                email: this.$store.state.saved_guest_data.email,
                phone: this.$store.state.guest.phone,
                telephone: this.$store.state.guest.telephone,
                title:this.$store.state.guest.title,
                first_name:this.$store.state.guest.first_name,
                last_name:this.$store.state.guest.last_name,
                nationality: this.$store.state.guest.nationality,
                country_of_residence: this.$store.state.guest.country_of_residence,
                id_pass_iqama: this.$store.state.guest.id_pass_iqama,
                gender: this.$store.state.guest.gender,
                full_name: this.$store.state.guest.full_name,
                passport_type: this.$store.state.guest.passport_type,
                place_of_birth: this.$store.state.guest.place_of_birth,
                date_of_birth: this.$store.state.guest.date_of_birth,
                job: this.$store.state.guest.role,
                qualification: this.form.qualification,
                work_place: this.form.work_place,
                job_title: this.form.job_title,
                address: this.form.address,
                facebook: this.form.facebook,
                twitter: this.form.twitter,
                personal_photo: this.form.personalimage,
                passport_image: this.form.passportimage,
                passport_expiry_date: this.form.passport_expiry_date,
                company_exhibitor_id: this.$store.state.saved_guest_data.company_exhibitor_id,
                token_key: this.$store.state.exh_guest_token_key,
                status:"",
                visa_status: "not required",
                flight_status: "not required",
                hotel_status: "not required",
              })
              .then((response) => {
                if(response.data.success == true){
                  this.$router.push({ path: "/registration/thankyou" });
                }
                else{
                  this.$router.push({ path: '/registration/failed' });
                }

              })
              .catch((e) => {});
          }
          else{
            if(this.form.twitter){}else{
              this.form.twitter=null;
            }
            if(this.form.facebook){}else{
              this.form.facebook=null;
            }
            this.axios.post(this.$store.state.baseURL + "add-staff-guest", {
                email: this.$store.state.saved_guest_data.email,
                phone: this.$store.state.guest.phone,
                telephone: this.$store.state.guest.telephone,
                title:this.$store.state.guest.title,
                first_name:this.$store.state.guest.first_name,
                last_name:this.$store.state.guest.last_name,
                nationality: this.$store.state.guest.nationality,
                country_of_residence: this.$store.state.guest.country_of_residence,
                id_pass_iqama: this.$store.state.guest.id_pass_iqama,
                gender: this.$store.state.guest.gender,
                full_name: this.$store.state.guest.full_name,
                passport_type: this.$store.state.guest.passport_type,
                place_of_birth: this.$store.state.guest.place_of_birth,
                date_of_birth: this.$store.state.guest.date_of_birth,
                job: this.$store.state.guest.role,
                qualification: this.form.qualification,
                job_title: this.form.job_title,
                work_place: this.form.work_place,
                address: this.form.address,
                facebook: this.form.facebook,
                twitter: this.form.twitter,
                personal_photo: this.form.personalimage,
                passport_image: this.form.passportimage,
                passport_expiry_date: this.form.passport_expiry_date,
                company_exhibitor_id: this.$store.state.saved_guest_data.company_exhibitor_id,
                token_key: this.$store.state.exh_guest_token_key,
                status:"",
                visa_status: "new",
                flight_status: "new",
                hotel_status: "new",
              })
              .then((response) => {
                this.$store.state.guest.id = response.data.data.id;
                // console.log(response.data.data);
                if(response.data.success == true){
                  if (response.data.data.visa_status != "new") {
                    this.$router.push({ path: "/registration/thankyou" });
                  } else {
                    this.$router.push({ path: "/registration/visa/step1" });
                  }
                }
                else{
                  this.$router.push({ path: '/registration/failed' });
                }
              })
              .catch((e) => {
              // console.log(e);
            });
          }
        }
        else{
          $('.alert_error').removeClass('h');
          setTimeout(function () {
            $('.alert_error').addClass("h");;
          }, 5000);
          $('.next_btn').removeClass('disabled_btn');
          this.disabled_submit=false;
        }
      }
    },
  },
};
</script>
