<template>
  <div class="c mt150">
    <div class="mt100 m_mt0 g_nm ph50 bgw round3x sh20">
      <div class="ov ba p50 con ch tx_50 ty__50" :style="{backgroundImage: 'url('+require('@/assets/img/visaimg.png')+')'}" style="top:-50px"></div>
      <div class="b mt60 ">{{ $t('do_you_want') }}</div>
      <div class="mt20 mb20 c">
        <button @click="gotofunc(1)" class="prev_btn pv5 ph30">{{ $t('no')}}</button>
        <button @click="gotofunc(0)" class="next_btn pv5 ph30 cw ml20">{{ $t('yes')}}</button>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'guestStep5',
  data() {
    return {

    }
  },
  methods: {
    gotofunc(goto) {
      if(goto ==1){
        this.$router.push({path: '/registration/thankyou'});
      }else{
        this.$router.push({path: '/registration/guest6'});
      }

    }
  },
}
</script>
