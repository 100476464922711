<template>
<div class="mt100 c pb50 mb50">
  <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
    <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
    <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }} {{$t('as_media')}}</div>
    <div class="mt10 l w100">
      <div class="bg-prograss-bar prograss-bar">
        <div class="prograss-bar ov ra w100 g_nm bg_orange"></div>
      </div>
      <div class="mt10 c-blue">{{ $t('step2') }}</div>
      <div class="mt10 b">{{ $t('team_member') }} {{this.$store.state.media_user.member_nb}}</div>
    </div> 
    <form class="pv10" @submit.prevent="submit_form">
      <div class="mt10" v-for="(entry, index) in listofstaff" :key="index">
        <div v-if="entry.borni == 'new' || entry.borni == 'old' || entry.borni == 'rejected'" class="brdr fs0 p10 nw">
          <div class="g_nm vm p20 brdr-blue round100" @click="show_member_btn(index)" style="min-width:40px"><span class="cc mdi mdi_show_team mdi-chevron-down c-blue fs20"></span></div>
          <div class="g_nm vm w80 l ph10 fs14">{{entry.first_name}}</div>
          <div class="g_nm vm">
            <span v-if="entry.security_status =='approved' " class="mdi mdi-check-bold fs20" style="color:green"></span>
            <span v-else-if="entry.security_status =='rejected' " class="mdi mdi-close-thick fs20" style="color:red"></span>
            <span v-else-if="entry.status == 'pending' || entry.borni == 'new' " class="mdi mdi-trash-can-outline c-orange fs20" @click='delete_staff_memeber(index)'></span>
            <span v-else-if="entry.status != 'pending'" class="mdi mdi-progress-helper c-orange fs20"></span>
          </div>
          <div class="l fs14 team_div h">
            <div class="b mt10">{{ $t('name')}}</div>
            <div class=""> {{entry.first_name}}</div>
            <div class="b mt10">{{ $t('email')}}</div>
            <div class="">{{entry.email}}</div>
            <div class="mt10 b">{{ $t('job') }}</div>
            <div class="">{{entry.job}}</div>
          </div>
        </div>
        
      </div>
      <div class="mt10">
        <div v-if="can_add_member" class="brdr p10">
          <div class="l">
            <div class="g_nm vm p20 round100 bg_orange pointer" @click="add_memebr_div=true">
              <span class="cc mdi mdi-plus add-btn fs20 cw"></span>
            </div>
            <div class="g_nm ph10 vm">{{ $t('add_new_member') }}</div>
          </div>
          <div v-if="add_memebr_div" class="l">
            <!-- START NAME -->
            <div class="mt15">
              <label class="label_fields">{{ $t('first_name') }}</label>
              <input class="input_fields" v-bind:placeholder="$t('name')" v-model="member_first_name" type="text" />
              <div class="validate_name validation_warning"></div>
            </div>
            <!-- CLOSE NAME -->
            <!-- START EMAIL -->
            <div class="mt15">
              <label class="label_fields">{{ $t('email') }}</label>
              <input class="input_fields" v-bind:placeholder="$t('email')" v-model="member_email" type="text" />
              <div class="validate_email validation_warning"></div>
              <div class="validate_duplicated_email validation_warning"></div>
            </div>
            <!-- CLOSE EMAIL -->
            <!-- START JOB -->
            <div class="mt15">
              <label class="label_fields">{{ $t('job') }}</label>
              <input class="input_fields" v-bind:placeholder="$t('job')" v-model="member_job" type="text" />
              <div class="validate_job validation_warning"></div>
            </div>
            <!-- CLOSE JOB -->
            <button class="next_btn mt10 pv5 ph10 cw" @click="add_new_staff_memeber()">{{ $t('add') }}</button>
            <button class="next_btn ml20 mt10 pv5 ph10 cw" @click="add_memebr_div=false">{{ $t('cancel') }}</button>
          </div>
        </div>
          <div class="mt10 validate_numbers validation_warning"></div>
          <div class="mt10 validate_duplicat_email validation_warning"></div>
          
        <div class="mt20 r">
          <!-- <button @click="this.$router.push({path:'/registration/media/step1?t=' + this.$store.state.exh_token_key,})" class="vm prev_btn ph20">
            <span v-if="$i18n.locale == 'ar'" class="mdi mdi-arrow-right lh40 fs30"></span>
            <span v-if="$i18n.locale == 'en'" class="mdi mdi-arrow-left lh40 fs30"></span>
          </button> -->
          <button  @click="submit" class="next_btn cw pointer ph20 vm  ml20">
            <div v-if="disabled_submit" class="mt10 mb10 ml5 mr5 rotatingLoader" ></div>
            <div v-if="!disabled_submit"  class="pv10  cw">{{$t('registrate_now')}}</div>
          </button>
        </div>
      </div>


    </form>
  </div>
</div>
</template>
<script>
export default {
  name: 'step2media',
  data() {
    return {
      disabled_submit:false,
      can_add_member:true,
      add_memebr_div: false,
      listofstaff: [], 
      lenghtofliststaffmemeber: 0,
      member_first_name: "",
      member_email: "",
      member_job: "",
      showtable_member_staff: 0,
      buttonsubmitflag: 0,
      counter:0,
    }
  },
  created() {
    this.$store.state.loader=1;
    if (Object.keys(this.$store.state.media_user).length === 0) {
      // alert('Data is Missing! Please Use the Link sent to your Email!');
      this.$router.push({ path: '/registration/failed' });
    }
    else {
      this.axios.post(this.$store.state.baseURL + 'get-specific-media-team', {
        'token_key': this.$store.state.media_user_token
      })
      .then(response => {
        var objectrespons = response.data.data[0].mediaateams;
        objectrespons.forEach(object => {
          if(object.security_status == "rejected"){
            object.borni="rejected";
            this.listofstaff.push(object);
          }
          else{
            object.borni="old";
            this.listofstaff.push(object);
          }
        });
        if ((this.listofstaff).length > 0) {
          this.buttonsubmitflag = 1;
          this.showtable_member_staff = 1;
          this.checklimit();
        }
        this.$store.state.loader=0;
      })
      .catch(e => {this.$store.state.loader=0;})
    }
  },
  methods: {
    show_member_btn(x) {
      $(".team_div:eq(" + x + ")").toggleClass('h');
      if ($(".mdi_show_team:eq(" + x + ")").hasClass('mdi-chevron-up')) {
        $(".mdi_show_team:eq(" + x + ")").removeClass('mdi-chevron-up')
      }
      else {
        $(".mdi_show_team:eq(" + x + ")").addClass('mdi-chevron-up')
      }
    },
    add_new_staff_memeber() {
      var xxxxvalidation = 0;
      if ((this.member_first_name == "" || this.member_first_name == null || this.member_first_name == 0)) {
        $('.validate_name').html(this.$t('filed_is_required'));
        xxxxvalidation = 1;
        $('.validate_name').slideDown();
      }
      else {
        $('.validate_name').slideUp();
      }

      if ((this.member_email == "" || this.member_email == null || this.member_email == 0)) {
        $('.validate_email').html(this.$t('email_is_required'));
        $('.validate_email').slideDown();
          xxxxvalidation = 1;
      }
      else {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(this.member_email)) {
          $('.validate_email').slideUp();
          var result = this.listofstaff.filter(x => (x.email).toLowerCase() === (this.member_email).toLowerCase() );
          if( result === undefined || result.length == 0){
            $('.validate_email').slideUp();
          }
          else{
            //   $('.validate_email').html(this.$t('duplicated_email'));
            //   xxxxvalidation = 1;
            // $('.validate_email').slideDown();
          }
        } else {
          $('.validate_email').html(this.$t('invalid_email'));
          xxxxvalidation = 1;
          $('.validate_email').slideDown();
        }
      }
      if ((this.member_job == "" || this.member_job == null || this.member_job == 0)) {
        $('.validate_job').html(this.$t('filed_is_required'));
        xxxxvalidation = 1;
        $('.validate_job').slideDown();
      }
      else {
        $('.validate_job').slideUp();
      }
      
      if (xxxxvalidation == 0) {
        this.showtable_member_staff = 1;
        this.listofstaff.push({
          first_name: this.member_first_name,
          email: this.member_email,
          job: this.member_job,
          borni:"new",
        });
        this.buttonsubmitflag = 1;
        this.member_first_name = "";
        this.member_email = "";
        this.member_job = "";
        this.add_memebr_div = false;
        this.checklimit();
      }
    },
    delete_staff_memeber(index) {
      var result = this.listofstaff[index];
      if(result.borni == 'new'){
        this.listofstaff.splice(index, 1);
      }
      else{
        this.listofstaff[index].borni="delete";
      }
      this.checklimit();
    },
    checklimit(){
      this.counter=0;
      this.listofstaff.forEach(object => {
        if(object.borni == "new" || object.borni == "old"){
          this.counter++;
        }
      });
      if(this.counter < this.$store.state.media_user.member_nb){
        this.can_add_member=true;
      }
      else{
        this.can_add_member=false;
      }
    },
    submit() {
      if(this.disabled_submit == false){
        $('.next_btn').addClass('disabled_btn');
        this.disabled_submit=true;
        this.$emit('submit', this.form);
        console.log(this.listofstaff);
        if(this.listofstaff.length == 0){
          $('.alert_error').removeClass('h');
          setTimeout(function () {
            $('.alert_error').addClass("h");
          }, 5000);
          $('.validate_numbers').html(this.$t('at_least_one_member'));
          $('.validate_numbers').slideDown();
          $('.next_btn').removeClass('disabled_btn');
          this.disabled_submit=false;
        }
        else{
          $('.validate_numbers').slideUp();
          this.axios.post(this.$store.state.baseURL + "addmediateam", {
            arraylist: this.listofstaff,
            mediaa_id: this.$store.state.media_user.id,
          }).then((response) => {
            console.log(response);
            if(response.data.success == false && response.data.status == 2){
              // alert(1);
              $('.alert_error').removeClass('h');
            setTimeout(function () {
              $('.alert_error').addClass("h");
            }, 5000);
              var html = this.$t('some_email_are_duplicated') + " " +response.data.email;
              $('.validate_duplicat_email').html(html);
              $('.validate_duplicat_email').slideDown();
              $('.next_btn').removeClass('disabled_btn');
              this.disabled_submit=false;
            }
            else{
              this.$router.push({path: "/registration/thankyou"});
              $('.validate_duplicat_email').slideUp();
              $('.next_btn').removeClass('disabled_btn');
              this.disabled_submit=false;
            }
          }).catch((e) => {
            $('.next_btn').removeClass('disabled_btn');
            this.disabled_submit=false;
              console.log(e);
          });
        }
      }
    },
  },
  
}
</script>
