<template>
<div class="mt100 ltr c mb50">
    <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
      <img class="g_nm" src="@/assets/img/biban-logo.png"  alt="profile_image" />
      <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }} {{ $t('as_guest') }}</div>
      <div class="mt10 l w100">
        <div class="bg-prograss-bar prograss-bar"><div class="prograss-bar ov ra w66 g_nm bg_orange"></div></div>
        <div class="mt10 c-blue">{{ $t('step2') }}</div>
        <div class="mt10 b">{{ $t('contact_info') }}</div>
        <form class="pv10" @submit.prevent="submit_form">
          <!-- START PHONE -->
          <div class="mt15">
            <label class="label_fields mb8">{{ $t('mobile_phone') }} *</label>
            <div class="pv4"></div>
                <MazPhoneNumberInput :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" v-model="form.phonenumber" :default-phone-number="form.phonenumber" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results = $event" :success="results?.isValid" style="direction:ltr !important;"/>
            <div class="validatePhoneNumber validation_warning"></div>
          </div>
          <!-- CLOSE PHONE -->
          <!-- START TELEPHONE -->
          <div class="mt15">
            <label class="label_fields mb8">{{ $t('telephone') }}</label>
            <div class="pv4"></div>
              <MazPhoneNumberInput :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" v-model="form.telephone" :default-phone-number="form.telephone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results2 = $event" :success="results2?.isValid" style="direction:ltr !important"/>
          </div>
          <!-- CLOSE TELEPHONE -->
          <!-- START QUALIFICATION -->
          <div class="mt15">
            <label class="label_fields">{{$t('qualification')}} *</label>
            <Select2 :disabled="view_only_all == 1  && this.$store.state.come_form_geenrated_link==0" v-model="form.qualification" :options="qualification_list"/>
            <div class="validate_qualification validation_warning"></div>
          </div>
          <!-- CLOSE QUALIFICATION -->
          <!-- START WORKPLACE -->
          <div class="mt15">
            <label class="label_fields">{{$t('work_place')}} *</label>
            <input :disabled="view_only_all == 1" class="input_fields" v-model="form.work_place" type="text"/>
            <div class="validate_work_place validation_warning"></div>
          </div>
          <!-- CLOSE WORKPLACE -->
          <!-- START JOBTITLE -->
          <div class="mt15">
            <label class="label_fields">{{$t('job_title')}} *</label>
            <input :disabled="view_only_all == 1" class="input_fields" v-model="form.job_title" type="text"/>
            <div class="validate_job_title validation_warning"></div>
          </div>
          <!-- CLOSE JOBTITLE -->
          <!-- START ADDRESS -->
          <div class="mt15">
            <label class="label_fields">{{$t('address')}} *</label>
            <input :disabled="view_only_all == 1" class="input_fields" v-model="form.address" type="text"/>
            <div class="validate_address validation_warning"></div>
          </div>
          <!-- CLOSE ADDRESS -->
          <!-- START EMAIL -->
          <div class="mt15 mb15">
            <label class="label_fields">{{ $t('email_address') }} *</label>
            <input :disabled="this.$store.state.come_form_geenrated_link==0" class="input_fields" v-model="form.emailaddress" v-bind:placeholder="$t('email_address')" type="email"/>
            <div class="validateEmail validation_warning"></div>
          </div>
          <!-- CLOSE EMAIL -->
          <div class="mt20 r">
            <button @click="submit()" class="next_btn ph20 ml20">
              <span class="cw lh40 fs18" >{{$t('next')}}</span>
              <!-- <span v-if="$i18n.locale == 'en'" class="cw mdi mdi-arrow-right lh40 fs30"></span>
              <span v-if="$i18n.locale == 'ar'" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
            </button>
          </div>
        </form>
      </div>
    </div>
</div>
</template>
<script>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import { ref } from 'vue';
const results = ref();
export default {
  name: 'guest2',
    components: { MazPhoneNumberInput },

  data() {
    return {
      errors: 0,
      view_only_all: 0,
      ar_qualification:[
        {id:1,text:'ثانوي'},
        {id:2,text:'بكالوريس'},
        {id:3,text:'ماجستير'},
        {id:4,text:'دكتوراه'},
      ],
      en_qualification:[
        {id:1,text:'Secondary'},
        {id:2,text:'Bachelor\'s degree'},
        {id:3,text:'Master\'s'},
        {id:4,text:'Ph.D'},
      ],
      form: {
        phonenumber: "",
        emailaddress: "",
        telephone:"",
        qualification:'',
        work_place:'',
        job_title:'',
        address:'',
      }
    }
  },
  computed:{
    qualification_list(){
      if(this.$i18n.locale == 'ar'){
          return this.ar_qualification
      }
      return this.en_qualification
    }
  },
  methods: {
    validatedata(formname,validatefieldclass, text) {
      if (formname == "" || formname == null || formname == 0) {
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        this.errors = 1;
        console.log(validatefieldclass);
        }
      else {
        $(validatefieldclass).slideUp();
      }
    },
    submit() {
      this.errors = 0;
      this.$emit("submit", this.form);
      if (this.form.phonenumber == "" || this.form.phonenumber == null || this.form.phonenumber == 0) {
        $(".validatePhoneNumber").html(this.$t('filed_is_required'));
        $(".validatePhoneNumber").slideDown();
        this.errors = 1;
      } else {
        if( !this.results.isValid){
          this.errors = 1;
          $(".validatePhoneNumber").html(this.$t('phone_not_valid'));
          $(".validatePhoneNumber").slideDown();
        }
        else{
          $(".validatePhoneNumber").slideUp();
        }
      }
      this.validatedata(this.form.emailaddress,".validateEmail", this.$t('email_is_required'));
      this.validatedata(this.form.qualification,".validate_qualification", this.$t('qualification_is_required'));
      this.validatedata(this.form.work_place,".validate_work_place", this.$t('work_palce_is_required'));
      this.validatedata(this.form.job_title,".validate_job_title", this.$t('job_title_is_required'));
      this.validatedata(this.form.address,".validate_address", this.$t('address_is_required'));
      if (this.errors == 0) {
        if(this.form.telephone){}else{this.form.telephone=null;}
        this.$store.state.normal_guest.telephone = this.form.telephone;
        this.$store.state.normal_guest.phonenumber = this.form.phonenumber;
        this.$store.state.normal_guest.emailaddress = this.form.emailaddress;
        this.$store.state.normal_guest.qualification = this.form.qualification;
        this.$store.state.normal_guest.work_place = this.form.work_place;
        this.$store.state.normal_guest.job_title = this.form.job_title;
        this.$store.state.normal_guest.address = this.form.address;
        this.axios.post(this.$store.state.baseURL + "check-phone", {
          token_key: this.$store.state.normal_guest.token_key,
          phone:this.form.phonenumber
        })
        .then((response) => {
          if(response.data.success == true){
            this.$router.push({path: '/registration/guest3'})
          }
          else{
            $(".validatePhoneNumber").html(this.$t('duplicated_phone'));
            $(".validatePhoneNumber").slideDown();
            $('.alert_error').removeClass('h');
            setTimeout(function () {
              $('.alert_error').addClass("h");;
            }, 5000);
          }
        })
        .catch((e) => {});

      }
      else{
        $('.alert_error').removeClass('h');
        setTimeout(function () {
          $('.alert_error').addClass("h");
        }, 5000);
      }
    }
  },
  created() {
    if(this.$store.state.normal_guest.token_key == null || this.$store.state.normal_guest.token_key == ""){
      this.$router.push({ path: '/registration/invalid-token' });
    }
    var objectresponsecuritycheck=this.$store.state.normal_guest.datasecurity;
    if(objectresponsecuritycheck != null){
      this.form.telephone = objectresponsecuritycheck.telephone;
      this.form.qualification = objectresponsecuritycheck.qualification;
      this.form.work_place = objectresponsecuritycheck.work_place ;
      this.form.job_title = objectresponsecuritycheck.job_title ;
      this.form.address = objectresponsecuritycheck.address ;
      // this.form.facebook = objectresponsecuritycheck.facebook;
      // this.form.address = objectresponsecuritycheck.address;
    }
    this.form.phonenumber=this.$store.state.normal_guest.phone;
    this.form.emailaddress=this.$store.state.normal_guest.email;
    if (this.$store.state.is_view_only == 1) {
      this.view_only_all = 1;
    }
  },
}
</script>
