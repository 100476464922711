<template>
<div class="mt100 c mb100">
  <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
    <img class="g_nm" src="@/assets/img/biban-logo.png"  alt="profile_image" />
    <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }} {{ $t('as_exh_staff') }}</div>
    <div class="mt10 l w100">
      <div class="bg-prograss-bar prograss-bar">
        <div class="prograss-bar ov ra w33 g_nm bg_orange"></div>
      </div>
      <div class="mt10 c-blue">{{ $t('step1') }}</div>
      <div class="mt10 b">{{ $t('basic_registration') }}</div>
      <form class="pv10" @submit.prevent="submit_form">
        <!-- START LEAGL NAME -->
        <div class="mt15">
          <label class="label_fields">{{ $t('company_name') }} *</label>
          <input class="input_fields" v-model="form.company_name" type="text" disabled />
        </div>
        <!-- CLOSE LEAGL NAME -->
        <!-- START PHONE -->
        <div class="mt15">
          <label class="label_fields mb8">{{ $t('mobile_phone') }} *</label>
          <div class="pv4"></div>
            <MazPhoneNumberInput :disabled="view_only_all == 1" v-model="form.phone" :default-phone-number="form.phone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results = $event" :success="results?.isValid" style="direction:ltr !important"/>
          <div class="validation_phone validation_warning"></div>
        </div>
        <!-- CLOSE PHONE -->
        <!-- START PHONE -->
        <div class="mt15">
          <label class="label_fields mb8">{{ $t('telephone') }}</label>
          <div class="pv4"></div>
            <MazPhoneNumberInput :disabled="view_only_all == 1" v-model="form.telephone" :default-phone-number="form.telephone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results2 = $event" :success="results2?.isValid" style="direction:ltr !important"/>
        </div>
        <!-- CLOSE PHONE -->
        <!-- START EMAIL -->
        <div class="mt15">
          <label class="label_fields mb8">{{ $t('email') }} *</label>
          <input  disabled class="input_fields" type="text" id="" v-bind:placeholder="$t('email')" v-model="form.email" />
          <div class="validation_email validation_warning"></div>
        </div>
          <div class="fs12 c mt20">{{ $t('privacy_inforamtion') }}</div>
        <!-- CLOSE EMAIL -->
        <div class="mt20 r">
          <button @click="submit" class="next_btn ph20">
            <span class="cw lh40 fs18" >{{$t('next')}}</span>
            <!-- <span v-if="$i18n.locale == 'en'" class="cw mdi mdi-arrow-right lh40 fs30"></span>
            <span v-if="$i18n.locale == 'ar'" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import { ref } from 'vue';
const results = ref();
const results2 = ref();
export default {
  name: "guestStep1",
    components: { MazPhoneNumberInput },
  data() {
    return {
      errors: 0,
      token_is_valide: 1,
      view_only_all: 0,
      form: {
        company_name: "",
        email: "",
        phone: "",
        telephone:""
      },
    };
  },
  created() {
    this.$store.state.loader=1;
    var tokennuser = this.$route.query.t;
    var defaultlanguage = this.$route.query.lang;
    if (defaultlanguage == "" || defaultlanguage == null) {
    }else{
      if(defaultlanguage == "ar" || defaultlanguage == "en"){
        this.$i18n.locale = defaultlanguage;
        if(defaultlanguage == 'ar'){
          $('head').append('<link rel="stylesheet" href="/rtl_style.css" class="ar_style">');
        }
        else{
          $('.ar_style').remove();
        }
      }
    }
    if(tokennuser == "" || tokennuser == null) {
        tokennuser = this.$store.state.exh_guest_token_key;
    }
    this.$store.state.exh_guest_token_key = tokennuser;
    this.axios.post(this.$store.state.baseURL + "get-staff", {
      token_key: tokennuser,
    })
    .then((response) => {
      if(response.data.data == null){
          this.token_is_valide=0;
          this.$store.state.loader=0;
        //  console.log('no resp');
        this.$router.push({ path: '/registration/invalid-token' });
      }
      else{
          var objectrespons = response.data.data;
          var objectresponssecurity = response.data.datasecurity;
          this.form.company_name = response.data.ex_name[0];
          this.form.email = objectrespons.email;
          this.form.phone = objectrespons.phone;
          if(response.data.datasecurity !=null){
            this.form.telephone = objectresponssecurity.telephone;
          }
          this.$store.state.saved_guest_data = objectrespons;
          this.$store.state.saved_guest_data.datasecurity = objectresponssecurity;
          this.$store.state.saved_guest_data.ex_name = response.data.ex_name[0];
          if(objectrespons.status == "pending" || objectrespons.status == "new"){
            this.view_only_all=0;
            this.$store.state.is_view_only=0;
          }
          else{
            this.view_only_all=1;
            this.$store.state.is_view_only=1;
          }
          this.$store.state.loader=0;
      }
    })
    .catch((e) => {
      this.$store.state.loader=0;
      this.$router.push({ path: '/registration/invalid-token' });
      this.token_is_valide=0;
    });

  },
  methods: {
    submit() {
      this.errors = 0;
      this.$emit("submit", this.form);
      if (this.form.phone == "" || this.form.phone == null || this.form.phone == 0) {
        $(".validation_phone").html(this.$t('phone_is_required'));
        $(".validation_phone").slideDown();
        this.errors = 1;
      } else {
        if( !this.results.isValid){
          this.errors = 1;
          $(".validation_phone").html(this.$t('phone_not_valid'));
          $(".validation_phone").slideDown();
        }
        else{
          $(".validation_phone").slideUp();
        }
      }
      if (this.form.email == "" || this.form.email == null || this.form.email == 0) {
        $(".validation_email").html(this.$t('email_is_required'));
        $(".validation_email").slideDown();
        this.errors = 1;
      } else {
          $(".validation_email").slideUp();
      }
      if (this.errors == 0) {
        this.$store.state.guest.phone = this.form.phone;
        this.$store.state.guest.email = this.form.email;
        if(this.form.telephone){
        }
        else{
          this.form.telephone=null;
        }
        this.$store.state.guest.telephone = this.form.telephone;
        this.axios.post(this.$store.state.baseURL + "check-phone", {
          token_key: this.$store.state.exh_guest_token_key,
          phone:this.form.phone
        })
        .then((response) => {
          if(response.data.success == true){
            this.$router.push({path: "/registration-guest/step2"});
          }
          else{
            $(".validation_phone").html(this.$t('duplicated_phone'));
            $(".validation_phone").slideDown();
            $('.alert_error').removeClass('h');
            setTimeout(function () {
              $('.alert_error').addClass("h");;
            }, 5000);
          }
        })
        .catch((e) => {});
      }
      else{
        $('.alert_error').removeClass('h');
        setTimeout(function () {
          $('.alert_error').addClass("h");;
        }, 5000);
      }
    },
  },
};
</script>
