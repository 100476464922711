<template>
  <div class="fs14 flexi big ph0 bg_calendar oh ltr" style="min-height: 100vh">
    <div
      v-if="$store.state.loader"
      class="pf ov bgb40 overlaydiv"
      style="z-index: 999999999"
    >
      <div class="cc">
        <div class="fs20 b cw mb20">{{ $t("loading") }}</div>
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <div class="ov pf ba pv5 sh20 bgw ltr" style="z-index: 999999999">
      <div class="pf ov ba h alert_error" style="z-index: 999999999999">
        <div
          class="mt20 g_nm sh20 bgw ph15 pv8 round2x"
          style="border: 2px solid red"
        >
          {{ $t("error_in_felids") }}
        </div>
      </div>
      <div class="flexi big ph80 md_ph50 m_ph20 c">
<!--        <tbl_nm style="direction:ltr !important">-->
        <tbl_nm  >
          <cl class="" style="width: 119px">
            <router-link :to="{ name: 'NewHomeWebsite' }">
              <img
                class="pointer"
                src="../assets/img/Biban-logo-10.svg"
                alt="profile_image"
              />
            </router-link>
          </cl>
          <cl class="colSep"></cl>
          <cl class="r">
            <div class="g_nm vm ph8 fs14">
              <div
                class="g_nm ph2 pointer pv4 lang-main-div"
                @click="showLangDiv()"
              >
                <span class="vm ml2 mr2 mdi mdi-web c_blue fs24 pointer"></span>
                <span class="vm ph4 nav-gray">{{ $t("lang") }}</span>
                <span
                  class="vm mt2 mdi arrow-span mdi-chevron-down fs20 c-nav-gray"
                ></span>
              </div>
              <div
                class="ov lang-select ba brdr round2x pv4 c bgw sh20 mt40"
                hidden
              >
                <div class="bb pointer" @click="changeLocale('en')">
                  English
                </div>
                <div class="pointer" @click="changeLocale('ar')">عربي</div>
              </div>
            </div>
            <div class="h m_sib vm cm">
              <span
                class="pointer mdi mdi-apps menu_btn c-nav-gray fs30"
              ></span>
              <div class="ov la ba mt50 brdr sh20 nw h menu_div c bgw">
                <div
                  class="menu_nav_item nav_about_biban_div pv10 ph20 pointer bb"
                  @click="goto('about_biban')"
                >
                  {{ $t("about_biban") }}
                </div>
                <div
                  class="menu_nav_item nav_door_div pv10 ph20 pointer bb"
                  :class="{ active: $route.name.includes('door') }"
                  @click="goto('door')"
                >
                  {{ $t("doors") }}
                </div>
                <div
                  class="menu_nav_item nav_exhibitors_div pv10 ph20 pointer bb"
                  :class="{ active: $route.name.includes('exhibitor') }"
                  @click="goto('exhibitor')"
                >
                  {{ $t("exhibitors") }}
                </div>
                <div
                  class="menu_nav_item nav_ewc_div pv10 ph20 pointer bb"
                  :class="{ active: $route.name.includes('ecw') }"
                  @click="goto('ecw')"
                >
                  {{ $t("leading_businesses") }}
                </div>
              </div>
            </div>
          </cl>
        </tbl_nm>
      </div>
    </div>
    <!-- <img class="mt100" src="../assets/img/pattern2.svg" style="width: 100%" /> -->
    <router-view class=""></router-view>
    <!-- <div class="ov ta bgb70">
      <div class="flexi pv10 c cw fs0">
        <div class="c g_nm ph5 vm">
          <a href="https://www.monshaat.gov.sa">
            <img
              class="g_nm"
              src="../assets/img/f_logo.svg"
              style="width: 60px"
          /></a>
        </div>
        <div class="c g_nm ph5 vm">
          <a href="https://www.vision2030.gov.sa">
            <img class="g_nm" src="../assets/img/2023.svg" style="width: 60px"
          /></a>
        </div>
        <div class="fs14 ph8 vm g_nm">
          {{ $t("all_right_1") }} {{ $t("all_right_2") }} &#169;
          {{ new Date().getFullYear() }}
        </div>
      </div>
    </div> -->
  <div class="bg_gray cb">
      <div class="flexi big ph80 md_ph50 m_ph20 m_m_ph10 pv10 web_c_dark_grey">
        <tbl>
          <cl class="vt l">
            <tbl>
              <cl class="w60 vt">
                <p class="fs20 b c_navy">{{ getStaticContent("footer_main_title") }}</p>
                <p class="">{{ getStaticContent("footer_main_paragraph") }}</p>
              </cl>
              <cl class="colSep20"></cl>
              <cl class="w40 vt">
                <div class="mt10 c_navy">{{ getStaticContent("footer_contact_title") }}</div>
<!--                <p>{{ $t('location_of_event') }}</p>-->
                <div class="mt5">{{ getStaticContent("footer_email") }}</div>
                <div class="mt20">
<!--                  <p class="c_navy">{{$t('calling_numbers')}}</p>-->
                  <span>{{$t('inside_ksa')}}</span><div class="ph4 g_nm"></div><span>{{ getStaticContent("footer_local_call_number") }}</span>
                </div>
<!--                <div class="">-->
<!--                  <span>{{$t('outside_ksa')}}</span><div class="ph4 g_nm"></div><span>{{ getStaticContent("footer_international_call_number")}}</span>-->
<!--                </div>-->
              </cl>
            </tbl>
            <div class="fs0">
              <div class="g_nm vt">
                <p class="fs14 c_navy">{{$t('follow_us')}}</p>
                <div class="mt5 l">
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://twitter.com/BibanGlobal?s=20&t=AiJHRuSZaIsyEIgQGqASRQ">
                      <div class="brdr" style="padding:12px; border-radius:4px;"><span class="cc icon-x-twitter" style="line-height:24px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.facebook.com/profile.php?id=100088582235109">
                      <div class="brdr" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-facebook" style="line-height:24px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.youtube.com/@bibanglobal8184/featured">
                      <div class="brdr" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-youtube" style="line-height:24px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.instagram.com/bibanglobal/">
                      <div class="brdr" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-instagram" style="line-height:24px;"></span></div>
                    </a>
                  </div>
                  <div class="g_nm ph2 vm fs18">
                    <a href="https://www.linkedin.com/company/bibanglobal/">
                      <div class="brdr" style="padding:12px; border-radius:4px;"><span class="cc mdi mdi-linkedin" style="line-height:24px;"></span></div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="g_nm ph10"></div>
              <!-- <div class="g_nm m_m_w100 m_m_mt10 vt">
                <p class="fs14 c_navy">{{$t('download_app')}}</p>
                <img class="g_nm mt5" style="height:26px;" src="@/assets/img/web2/google_paly.svg"/>
                <img class="g_nm mt5" :class="[($i18n.locale == 'ar') ?  'mr10' : 'ml10' ]" style="height:26px;" src="@/assets/img/web2/app_store.svg"/>
              </div> -->
            </div>
          </cl>
          <cl class="vt colSep"></cl>
          <cl class="vt m_l fs14 pb100 m_pb0" style="width:100px;">
            <router-link :to="{ name: 'NewHomeWebsite' }">
              <div class="con g_nm p50" :style="{backgroundImage:'url(' + require('@/assets/img/Biban-logo-10.svg') + ')',}"></div>
            </router-link>
            <div class="ov ta m_pr m_sib" style="max-height:80px">
              <div class="con g_nm p50" :style="{backgroundImage:'url(' + require('@/assets/img/monshaat_logo-01.svg') + ')',}">
                <a href="https://www.monshaat.gov.sa" target="_blank"></a>
              </div>
            </div>
          </cl>
        </tbl>
      </div>
      <div class="pt1 mt10 bgb30"></div>
        <div class="fs14 p10 vm g_nm web_c_dark_grey">
          <span>{{ getStaticContent("footer_powered_by_text") }}</span>
          {{ new Date().getFullYear() }}
        </div>
    </div>
  </div>
</template>
<script>
// import $ from "jquery";
export default {
  name: "Mainlayout",
  data() {
    return {
      showLangBool: 0,
      footer_list: null,
    };
  },
  mounted() {
    $(".menu_btn").click(function () {
      $(".menu_div").toggleClass("h");
    });
    $(".menu_nav_item").click(function () {
      $(".menu_div").toggleClass("h");
    });
  },
  created() {
    if (this.$i18n.locale == "ar") {
      this.axios
        .get(this.$store.state.baseURL + "static_content/footer?lang=ar", {})
        .then((response) => {
          this.footer_list = response.data.data;
        })
        .catch((e) => {});
    } else {
      this.getdata(this.$i18n.locale);
    }
  },
  watch: {
    "$i18n.locale": function (newVal) {
      this.getdata(newVal);
    },
  },
  methods: {
    getdata: function (lang) {
      // GET FOOTER
      this.axios
        .get(
          this.$store.state.baseURL + "static_content/footer?lang=" + lang,
          {}
        )
        .then((response) => {
          this.footer_list = response.data.data;
        })
        .catch((e) => {});
    },
    showLangDiv() {
      if ($(".arrow-span").hasClass("mdi-chevron-up")) {
        $(".arrow-span").removeClass("mdi-chevron-up");
        $(".lang-select").slideUp();
      } else {
        $(".arrow-span").addClass("mdi-chevron-up");
        $(".lang-select").slideDown();
      }
    },
    getStaticContent(key) {
      if (this.footer_list) {
        return this.footer_list.find((item) => item.short_code == key)?.body;
      }
      return "";
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      $("html").attr("lang", "ar");
      $(".arrow-span").removeClass("mdi-chevron-up");
      $(".lang-select").slideUp();
      if (locale == "ar") {
        $("head").append(
          '<link rel="stylesheet" href="/rtl_style.css" class="ar_style">'
        );
        console.log("add");
      } else {
        $("html").attr("lang", "en");
        $(".ar_style").remove();
      }
    },
  },
};
</script>
