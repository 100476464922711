<template>
  <div class="mt100 c pb50 mb50">
    <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
      <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
      <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }}  {{ $t('as_media_staff') }}</div>
      <div class="mt10 l w100">
        <div class="bg-prograss-bar prograss-bar">
          <div class="prograss-bar ov ra w50 g_nm bg_orange"></div>
        </div>
        <div class="mt10 c-blue">{{ $t('step1') }}</div>
        <div class="mt10 b">{{ $t('basic_registration') }}</div>
        <form class="pv10" @submit.prevent="submit_form">
          <!-- START TITLE -->
          <div class="mt15">
            <label class="label_fields">{{ $t('title') }} *</label>
            <Select2 :disabled="view_only_all == 1" v-model="form.title" :options="title_list"/>
            <div class="validation_title validation_warning"></div>
          </div>
          <!-- CLOSE TITLE -->
          <!-- START FIRST & LAST NAME -->
          <tbl>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{ $t('first_name') }} *</label>
                <input :disabled="view_only_all == 1" class="input_fields" v-model="form.firstname" v-bind:placeholder="$t('first_name')" type="text"/>
                <div class="validation_first_name validation_warning"></div>
              </div>
            </cl>
            <cl class="colSep pv10"></cl>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{ $t('last_name') }} *</label>
                <input :disabled="view_only_all == 1" class="input_fields" v-model="form.lastname" v-bind:placeholder="$t('last_name')" type="text" />
                <div class="validation_last_name validation_warning"></div>
              </div>
            </cl>
          </tbl>
          <!-- CLOSE FIRST & LAST NAME -->
          <!-- START NATIONLAITY AND COR -->
          <tbl>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{ $t('nationality') }} *</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.nationality" :options="nationality_list"  />
                <div class="validatenation validation_warning"></div>
              </div>
            </cl>
            <cl v-if="this.form.nationality != 194" class="colSep pv10"></cl>
            <cl v-if="this.form.nationality != 194" class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{ $t('country_of') }} *</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.countryofresidence" :options="countries_list"  />
                <div class="validation_countryofresidence validation_warning"></div>
              </div>
            </cl>
          </tbl>
          <!-- CLOSE NATIONLAITY AND COR -->
          <!-- START FULL NAME -->
          <div v-if="this.form.nationality != 194 && this.form.countryofresidence != 194" class="mt15">
            <label v-if="f|| $store.state.no_visa_countries.indexOf(form.nationality) != -1" class="label_fields">{{$t('full_name_as_id')}} *</label>
            <label v-else class="label_fields">{{$t('full_name_as_passport')}} *</label>
            <input :disabled="view_only_all == 1" class="input_fields" v-model="form.full_name" type="text"/>
            <div class="validateFullName validation_warning"></div>
          </div>
          <!-- CLOSE FULL NAME -->
          <!-- START DATE & PLACE OF BIRTH -->
          <tbl v-if="this.form.nationality != 194 && this.form.countryofresidence != 194">
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{$t('place_of_birth')}} *</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.place_of_birth" :options="countries_list"/>
                <div class="validatePlaceOfBirth validation_warning"></div>
              </div>
            </cl>
            <cl class="colSep pv10"></cl>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{$t('date_of_birth')}} *</label>
                <input :disabled="view_only_all == 1" class="input_fields" placeholder="" v-model="form.date_of_birth" max="2009-03-01" type="date"/>
                <div class="validateDateOfdBirth validation_warning"></div>
              </div>
            </cl>
          </tbl>
          <!-- CLOSE DATE & PLACE OF BIRTH -->
          <!-- START ADDRESS -->
          <div v-if="this.form.nationality != 194 && this.form.countryofresidence != 194" class="mt15">
            <label class="label_fields">{{$t('address')}} *</label>
            <input :disabled="view_only_all == 1" class="input_fields" v-model="form.address" type="text"/>
            <div class="validate_address validation_warning"></div>
          </div>
          <!-- CLOSE ADDRESS -->
          <!-- START QUALIFICATION -->
          <div v-if="this.form.nationality != 194 && this.form.countryofresidence != 194" class="mt15">
            <label class="label_fields">{{$t('qualification')}} *</label>
            <Select2 :disabled="view_only_all == 1" v-model="form.qualification" :options="qualification_list"/>

            <div class="validate_qualification validation_warning"></div>
          </div>
          <!-- CLOSE QUALIFICATION -->
          <div class="pv10"></div>
          <div v-if="this.form.nationality != 194 && this.form.countryofresidence != 194">
            <label class="mt15 label_fields">{{ $t('socail_media') }}</label>
            <div class="mt10">
              <input :disabled="view_only_all == 1" class="sm ph10 input_fields" v-model="form.linked_in" v-bind:placeholder="$t('linked_in')" type="text" />
              <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue mdi mdi-linkedin"></span></div>
            </div>
            <div class="mt10">
              <input :disabled="view_only_all == 1" class="sm ph10 input_fields" v-model="form.twitter" v-bind:placeholder="$t('twitter')" type="text" />
              <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue icon-x-twitter"></span></div>
            </div>
            <div class="mt10">
              <input :disabled="view_only_all == 1" class="sm ph10 input_fields" v-model="form.facebook" v-bind:placeholder="$t('facebook')" type="text" />
              <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue mdi mdi-facebook"></span></div>
            </div>
            <div class="mt10">
              <input :disabled="view_only_all == 1" class="sm ph10 input_fields" v-model="form.instagram" v-bind:placeholder="$t('instagram')" type="text" />
              <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue mdi mdi-instagram"></span></div>
            </div>
            <div class="mt10">
              <input :disabled="view_only_all == 1" class="sm ph10 input_fields" v-model="form.snapchat" v-bind:placeholder="$t('snapchat')" type="text" />
              <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue mdi mdi-snapchat"></span></div>
            </div>
          </div>
          <div class="mt20 r">
            <button @click="submit" class="next_btn ph20">
              <span class="cw lh40 fs18" >{{$t('next')}}</span>
              <!-- <span v-if="$i18n.locale == 'en'" class="cw mdi mdi-arrow-right lh40 fs30"></span>
              <span v-if="$i18n.locale == 'ar'" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'step1mediaStaff',
  data() {
    return {
      ar_nationality_list:[],
      en_nationality_list:[],
      ar_countries_list: [],
      en_countries_list: [],
      view_only_all: 0,
      ar_qualification:[
        {id:1,text:'ثانوي'},
        {id:2,text:'بكالوريس'},
        {id:3,text:'ماجستير'},
        {id:4,text:'دكتوراه'},
      ],
      en_qualification:[
        {id:1,text:'Secondary'},
        {id:2,text:'Bachelor\'s degree'},
        {id:3,text:'Master\'s'},
        {id:4,text:'Ph.D'},
      ],
      form: {
        title: "",

        firstname: "",
        lastname: "",
        nationality: "",
        full_name:"",
        place_of_birth:'',
        qualification:'',
        date_of_birth:'',
        address:'',
        countryofresidence: "",
        linked_in: "",
        twitter: "",
        facebook: "",
        instagram: "",
        snapchat: "",
      }
    }
  },
  created() {
    this.$store.state.loader=1;
    var tokennuser = this.$route.query.t;
    if (tokennuser == "" || tokennuser == null) {
      tokennuser = this.$store.state.media_staff_user_token;
    }
    this.$store.state.media_staff_user_token = tokennuser;
    this.axios.post(this.$store.state.baseURL + "getcountries", {}).then((response) => {
        var objectrespons = response.data.data;
        objectrespons.forEach(element => {
          var en_x ={
              id: element.id,
              text: element.country_enName
            }
            var ar_x ={
              id: element.id,
              text: element.country_arName
            }
            var en_y ={
              id: element.id,
              text: element.country_enNationality
            }

            var ar_y ={
              id: element.id,
              text: element.country_arNationality
            }
          this.ar_nationality_list.push(ar_y);
          this.en_nationality_list.push(en_y);
          this.ar_countries_list.push(ar_x);
          this.en_countries_list.push(en_x);
        });
      })
      .catch((e) => {
        console.log(e);
      });
    this.axios.post(this.$store.state.baseURL+'get-staff-media', {
        'token_key': tokennuser
      })
      .then(response => {
        if(response.data.data == null){
          this.token_is_valide=0;
          this.$store.state.loader=0;
          this.$router.push({ path: '/registration/invalid-token' });
          }else{
            var objectrespons=response.data.data;
            var objectresponsecuritycheck=response.data.datasecurity;
            console.log(objectresponsecuritycheck);
            if(objectresponsecuritycheck == null){
            }else{
              this.form.full_name=objectresponsecuritycheck.full_name;
              this.form.address=objectresponsecuritycheck.address;
              this.form.place_of_birth=objectresponsecuritycheck.place_of_birth;
              this.form.date_of_birth=objectresponsecuritycheck.date_of_birth;
              this.form.qualification = objectresponsecuritycheck.qualification;
              this.form.twitter = objectresponsecuritycheck.twitter;
              this.form.facebook = objectresponsecuritycheck.facebook;
            }

            this.$store.state.guest.id=objectrespons.id;
            this.form.title=objectrespons.title;
            this.form.firstname=objectrespons.first_name;
            this.form.lastname=objectrespons.last_name;
            this.form.nationality=objectrespons.nationality;
            this.form.linked_in = objectrespons.linked_in;
            this.form.instagram = objectrespons.instagram;
            this.form.snapchat = objectrespons.snapchat;
            this.form.countryofresidence=objectrespons.country_of_residence;
            this.$store.state.media_staff_user=objectrespons;
            this.$store.state.media_staff_user_secuirty=objectresponsecuritycheck;
            this.$store.state.visa_guest_type="media_staff";
            this.$store.state.showloader_registration =0;
            if(objectrespons.status == "pending" || objectrespons.status == "new" || 1==1){
            // if(objectrespons.status == "pending"){
              this.view_only_all=0;
              this.$store.state.is_view_only=0;
            }
            else{
              this.view_only_all=1;
              this.$store.state.is_view_only=1;
            }
              this.$store.state.loader=0;
          }

          // if(objectrespons.form_status == 0){
          //   this.view_only_all=1;
          //   this.$store.state.is_view_only=1;
          // }
          // console.log(this.$store.state.media_staff_user);
      })
      .catch(e => {
        this.$store.state.loader=0;
        this.$router.push({ path: '/registration/failed' });
        console.log(e);
      })
  },
  computed:{
    countries_list(){
      if(this.$i18n.locale == 'ar'){
          return this.ar_countries_list
      }
      return this.en_countries_list
    },
    nationality_list(){
      if(this.$i18n.locale == 'ar'){
          return this.ar_nationality_list
      }
      return this.en_nationality_list
    },
    qualification_list(){
      if(this.$i18n.locale == 'ar'){
          return this.ar_qualification
      }
      return this.en_qualification
    },
    title_list(){
      if(this.$i18n.locale == 'ar'){
          return this.$store.state.ar_title_list
      }
      return this.$store.state.en_title_list
    }
  },
  methods: {
    validatedata(formname,validatefieldclass, text) {
    if (formname == "" || formname == null || formname == 0) {
        console.log(formname);
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        this.errors = 1;
          alert(validatefieldclass);
        } else {
        $(validatefieldclass).slideUp();
        }
    },
    submit() {
      this.$emit('submit', this.form);
      this.errors=0;
      // alert(this.form.nationality );
      // alert(this.form.nationality != 194);
      // alert(this.form.countryofresidence !=194)
      // alert((this.form.nationality != 194 && this.form.countryofresidence !=194))

      if(this.form.nationality != 194 && this.form.countryofresidence !=194){
        this.validatedata(this.form.full_name,".validateFullName", this.$t('full_name_is_required'));
        this.validatedata(this.form.place_of_birth,".validatePlaceOfBirth", this.$t('place_of_birth_is_required'));
        this.validatedata(this.form.date_of_birth,".validateDateOfdBirth", this.$t('date_of_bitrh_is_required'));
        this.validatedata(this.form.address,".validate_address", this.$t('address_is_required'));
        this.validatedata(this.form.qualification,".validate_qualification", this.$t('qualification"_is_required'));
      }
      this.validatedata(this.form.title,".validation_title", this.$t('title_is_required'));
      this.validatedata(this.form.firstname,".validation_first_name", this.$t('first_name_is_required'));
      this.validatedata(this.form.lastname,".validation_last_name", this.$t('last_name_is_required'));
      this.validatedata(this.form.nationality,".validatenation", this.$t('nationality_is_required_is_required'));
      if(this.form.nationality != 194){
        this.validatedata(this.form.countryofresidence,".validation_countryofresidence", this.$t('countryofresidence_is_required_is_required'));
      }

      if(this.errors==0){
        this.$store.state.media_staff_user.title=this.form.title;
        this.$store.state.media_staff_user.first_name=this.form.firstname;
        this.$store.state.media_staff_user.last_name=this.form.lastname;
        this.$store.state.media_staff_user.full_name=this.form.full_name;
        this.$store.state.media_staff_user.place_of_birth=this.form.place_of_birth;
        this.$store.state.media_staff_user.date_of_birth=this.form.date_of_birth;
        this.$store.state.media_staff_user.address=this.form.address;
        this.$store.state.media_staff_user.nationality=this.form.nationality;
        this.$store.state.media_staff_user.country_of_residence =this.form.countryofresidence;
        this.$store.state.media_staff_user.qualification=this.form.qualification;
        this.$store.state.media_staff_user.linked_in = this.form.linked_in;
        this.$store.state.media_staff_user.twitter = this.form.twitter;
        this.$store.state.media_staff_user.facebook = this.form.facebook;
        this.$store.state.media_staff_user.instagram = this.form.instagram;
        this.$store.state.media_staff_user.snapchat = this.form.snapchat;
        this.$router.push({
          path: '/registration/media/staff/step2'
        });
      }
      else{
        $('.alert_error').removeClass('h');
        setTimeout(function () {
          $('.alert_error').addClass("h");
        }, 5000);
        console.log(this.errors);
      }

    }
  },

}
</script>
