<template>
<div class="c mt150">
    <div class=" mt100 m_mt0 g_nm ph20 bgw round3x sh20">
      <div class="ov ba p50 con ch tx_50 ty__50" :style="{backgroundImage: 'url('+require('@/assets/img/ccg.png')+')'}" style="top:-50px"></div>
      <div class="b mt60 ">{{ $t('are_you_gcc') }}</div>
      <div class="mt20 mb20 c">
        <button @click="gotofunc(0)" class="prev_btn pv5 ph30">{{ $t('no')}}</button>
        <button @click="gotofunc(1)" class="next_btn pv5 ph30 cw ml20">{{ $t('yes')}}</button>
      </div>
    </div>
</div>

</template>
<script>
export default {
  name: 'guestStep6',
  data() {
    return {

    }
  },
  methods: {
    gotofunc(goto) {
      if(goto ==1){
        this.$router.push({path: '/registration/media/visa_gcc'});
      }else{
        this.$router.push({path: '/registration/media/visa'});
      }

    }
  },
  created() {
    // this.$store.state.showloader_registration = 1;
    // if (Object.keys(this.$store.state.speaker_user).length === 0) {
    //   alert('Data is Missing! Please Use the Link sent to your Email!');
    // } else {
    //   this.$store.state.visa_guest_type='speaker_staff';
    //   this.$store.state.showloader_registration = 0;
    // }
  },
}
</script>
