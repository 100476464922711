<template>
<div v-if="loader" class="pf ov bgb40 overlaydiv z2000" >
    <div class="cc">
      <div class="fs20 b cw mb20">{{$t('loading')}}</div>
      <div class="lds-dual-ring"></div>
    </div>
  </div>
</template>
<script>
export default {
   name: 'LinkMedia',
  data() {
    return {
      loader:false,
    }
  },

  created() {
    this.loader =1;
    var slugg = this.$route.query.slug;
  //  var tokennuser = this.$route.query.token_key;
  //  if (slugg == "" || slugg == null || tokennuser == "" || tokennuser == null) {
    if (slugg == "" || slugg == null ) {
      this.$router.push({ path: '/registration-link-expire' });
    }
    this.axios.post(this.$store.state.baseURL+'media-link', {
      'slug':slugg

    })
    .then(response => {
      // console.log(response);
      var objectrespons=response.data.data;
      var message=response.data.message;
      if(message == "available"){
        this.$store.state.come_form_geenrated_link=1;
        this.$router.push({ path: '/registration/media/step1', query: { t: objectrespons.token_key } })
      }
      else{
        this.$router.push({ path: '/registration-link-expire' });
      }
    })
    .catch(e => {
      console.log(e);
    })

  },
}

</script>
<style Scoped>
.overlaydiv{
  margin: 0px !important;
  padding: 0px !important;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
