<template>
  <div class="mt100 pb50 ltr c mb50">
    <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
      <img class="g_nm" src="@/assets/img/biban-logo.png"  alt="profile_image" />
      <div class="mt10 c-blue">
        {{ $t("welcome_to_registrate") }} {{ $t("as_guest") }}
      </div>
      <div class="mt10 l w100">
        <div class="bg-prograss-bar prograss-bar">
          <div class="prograss-bar ov ra w33 g_nm bg_orange"></div>
        </div>
        <div class="mt10 c-blue">{{ $t("step1") }}</div>
        <div class="mt10 b">{{ $t("basic_registration") }}</div>
        <form class="pv10" @submit.prevent="submit_form">
          <!-- START TITLE -->
          <div class="mt15">
            <label class="label_fields">{{ $t("title") }} *</label>
            <Select2 :disabled="view_only_all == 1" v-model="form.title" :options="title_list" />
            <div class="validateTitle validation_warning"></div>
          </div>
          <!-- CLOSE TITLE -->
          <!-- START NAME -->
          <tbl>
            <cl class="vm w50">
              <div class="mt15">
                <label class="label_fields">{{ $t("first_name") }} *</label>
                <input :disabled="view_only_all == 1" class="input_fields" v-model="form.first_name" v-bind:placeholder="$t('first_name')" type="text"/>
                <div class="validateFirstName validation_warning"></div>
              </div>
            </cl>
            <cl class="colSep pv10"></cl>
            <cl class="vm w50">
              <div class="mt15">
                <label class="label_fields">{{ $t("last_name") }} *</label>
                <input :disabled="view_only_all == 1" class="input_fields" v-model="form.last_name" v-bind:placeholder="$t('last_name')" type="text"/>
                <div class="validateLastName validation_warning"></div>
              </div>
            </cl>
          </tbl>
          <!-- CLOSE NAME -->
          <!-- START NATIONALITY AND COR -->
          <tbl>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{ $t("nationality") }} *</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.nationality" :options="nationality_list" />
                <div class="validatenation validation_warning"></div>
              </div>
            </cl>
            <cl class="colSep"></cl>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{ $t("country_of") }} *</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.country_of_residence" :options="countries_list" />
                <div class="validateCountryOfResidence validation_warning"></div>
              </div>
            </cl>
          </tbl>
          <!-- CLOSE NATIONALITY AND COR -->
          <!-- START FULL NAME -->
          <div class="mt15">
          <label v-if=" $store.state.no_visa_countries.indexOf(form.nationality) != -1 " class="label_fields" >{{ $t("full_name_as_id") }} *</label>
            <label v-else-if="form.country_of_residence == 194" class="label_fields" >{{ $t("full_name_as_iqama") }} *</label >
            <label v-else class="label_fields" >{{ $t("full_name_as_passport") }} *</label >
            <input :disabled="view_only_all == 1" class="input_fields" v-model="form.full_name" type="text" />
            <div class="validateFullName validation_warning"></div>
          </div>
          <!-- CLOSE FULL NAME -->
          <!-- START DATA AND PLACE OF BIRTH -->
          <tbl>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{ $t("place_of_birth") }} *</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.place_of_birth" :options="countries_list" />
                <div class="validatePlaceOfBirth validation_warning"></div>
              </div>
            </cl>
            <cl class="colSep"></cl>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{ $t("date_of_birth") }} *</label>
                <input :disabled="view_only_all == 1" class="input_fields" placeholder="" v-model="form.date_of_birth" max="2009-03-01" type="date" />
                <div class="validateDateOfdBirth validation_warning"></div>
              </div>
            </cl>
          </tbl>
          <!-- CLOSE DATA AND PLACE OF BIRTH -->
          <!-- START GENDER -->
          <div class="mt15">
            <label class="label_fields">{{ $t("gender") }} *</label>
            <br />
            <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="form.gender" value="male" />{{ $t("male") }}
            <input :disabled="view_only_all == 1" type="radio" class="mt8 ml10 mr10" v-model="form.gender" value="female" />{{ $t("female") }}
            <div class="validateGender validation_warning"></div>
          </div>
          <!-- CLOSE GENDER -->
          <!-- START PERSONAL IMAGE -->
          <div class="mt15">
            <tbl_nm>
              <label class="pointer">
                <cl class="w5">
                  <div class="bg-blue p25 round100" style="width: 30px">
                    <span v-if=" form.personalimage == '' || form.personalimage == null " class="cc mdi mdi-image-outline fs20 cw" ></span>
                    <img v-else :src="logo_image" style="width: 50px; height: 50px" class="cc round100 cw" />
                  </div>
                </cl>
                <cl class="colSep"></cl>
                <cl class="wa" style="max-width: 250px">
                  <label class="label_fields" >{{ $t("personalphoto") }} *</label >
                  <input :disabled="view_only_all == 1" type="file" id="fileaa" ref="fileaa" @change="uploadImagepersonal()" accept="image/*" hidden />
                  <div class="fs12 nw oe image_textpersonal">
                    {{ $t("image_text") }}
                  </div>
                </cl>
              </label>
              <cl class="colSep"></cl>
              <cl class="w5 r" style="min-width: 25px">
                <span class="mdi mdi-image-multiple-outline c-blue fs20"></span>
                <span class="mdi mdi-information-outline pointer c-blue fs20" @click="showpop_image = 1" ></span>
              </cl>
            </tbl_nm>
            <div class="validate_logopersonal validation_warning"></div>
          </div>
          <!-- CLOSE PERSONAL IMAGE -->
          <!-- START PASSPORT IMAGE -->
          <div class="mt15">
            <tbl_nm>
              <label class="pointer">
                <cl class="w5">
                  <div class="bg-blue p25 round100" style="width: 30px">
                    <span v-if=" form.passportimage == '' || form.passportimage == null " class="cc mdi mdi-image-outline fs20 cw" ></span>
                    <img v-else :src="logo_imagepassport" style="width: 50px; height: 50px" class="cc round100 cw" />
                  </div>
                </cl>
                <cl class="colSep"></cl>
                <cl class="wa" style="max-width: 250px">
                  <label v-if=" form.nationality == 194 || $store.state.no_visa_countries.indexOf( form.nationality ) != -1 " class="label_fields" >{{ $t("IDphoto") }} *</label >
                  <label v-else-if="form.country_of_residence == 194" class="label_fields" >{{ $t("iqamaphoto") }} *</label >
                  <label v-else class="label_fields" >{{ $t("passportphoto") }} *</label >
                  <input :disabled="view_only_all == 1" type="file" id="file" ref="file" @change="uploadImage()" accept="image/*" hidden />
                  <div class="fs12 nw oe image_text">
                    {{ $t("image_text") }}
                  </div>
                </cl>
              </label>
              <cl class="colSep"></cl>
              <cl class="w5 r" style="min-width: 25px">
                <span class="mdi mdi-image-multiple-outline c-blue fs20"></span>
                <span class="mdi mdi-information-outline pointer c-blue fs20" @click="showpop_image = 1" ></span>
              </cl>
            </tbl_nm>
            <div class="validate_logopassport validation_warning"></div>
          </div>
          <!-- CLOSE PASSPORT IMAGE -->
          <!-- START PASSPORT TYPE AND EXP DATE -->
          <!-- {{ $store.state.no_visa_countries.indexOf(form.nationality) }} -->
          <div v-if="$store.state.no_visa_countries.indexOf(form.nationality) != -1">
            <div class="mt15">
              <label class="label_fields">{{$t('id_expiry_date')}} *</label>
              <input :disabled="view_only_all == 1" class="input_fields select_input expiry_date" placeholder="" v-model="form.passport_expiry_date" min="2023-04-01" type="date"/>
              <div class="validatePassportExpiryDate validation_warning"></div>
            </div>
          </div>
          <div v-else-if="$store.state.no_visa_countries.indexOf(form.nationality) == -1 && form.country_of_residence == 194">
            <div class="mt15">
              <label class="label_fields">{{$t('iqama_expiry_date')}} *</label>
              <input :disabled="view_only_all == 1" class="input_fields select_input expiry_date" placeholder="" v-model="form.passport_expiry_date" min="2023-04-01" type="date"/>
              <div class="validatePassportExpiryDate validation_warning"></div>
            </div>
          </div>
          <tbl v-else>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields">{{ $t("passport_type") }} *</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.passport_type" :options="passport_type" />
                <div class="validatePassportType validation_warning"></div>
              </div>
            </cl>
            <cl class="colSep"></cl>
            <cl class="vt w50">
              <div class="mt15">
                <label class="label_fields" >{{ $t("pass_expire_date") }} *</label >
                <input :disabled="view_only_all == 1" class="input_fields select_input expiry_date" placeholder="" v-model="form.passport_expiry_date" min="2023-09-09" type="date" />
                <div class="validatePassportExpiryDate validation_warning" ></div>
              </div>
            </cl>
          </tbl>
          <!-- CLOSE PASSPORT TYPE AND EXP DATE -->
          <div class="mt15">
            <label v-if=" form.nationality == 194 || $store.state.no_visa_countries.indexOf(form.nationality) != -1 " class="label_fields" >{{ $t("id_number") }} *</label >
            <label v-else-if=" form.country_of_residence == 194 && $store.state.no_visa_countries.indexOf(form.nationality) == -1 " class="label_fields" >{{ $t("iqama") }} *</label >
            <label v-else class="label_fields" >{{ $t("passport_number") }} *</label >
            <input v-if="form.nationality == 194" :disabled="view_only_all == 1" class="input_fields" v-model="form.id_pass_iqama" type="text" maxlength="10" />
            <input v-else-if=" form.country_of_residence == 194 && $store.state.no_visa_countries.indexOf(form.nationality) == -1 " :disabled="view_only_all == 1" class="input_fields" v-model="form.id_pass_iqama" type="text" maxlength="10" />
            <input v-else :disabled="view_only_all == 1" class="input_fields" v-model="form.id_pass_iqama" type="text" maxlength="10" />
            <div class="validation_id_pass_iqama validation_warning"></div>
          </div>
          <div class="mt20 r">
            <button @click="submit()" class="next_btn ph20">
              <span class="cw lh40 fs18" >{{$t('next')}}</span>
              <!-- <span v-if="$i18n.locale == 'ar'" class="cw mdi mdi-arrow-left lh40 fs30" ></span> -->
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="popup_descimage round2x l" v-if="showpop_image == 1" style="z-index: 9999999999" >
    <div class="popupdesc_img_close ov20 pointer la mr20 ba" @click="showpop_image = 0" >
      {{ $t("close") }}
    </div>
    <h2>{{ $t("photo_specs") }}</h2>
    <p>{{ $t("photo_specs_title") }}</p>
    <ul class="popdesc_ul_list">
      <li>{{ $t("photo_specs_li_1") }}</li>
      <li>{{ $t("photo_specs_li_2") }}</li>
      <li>{{ $t("photo_specs_li_3") }}</li>
      <li>{{ $t("photo_specs_li_4") }}</li>
      <li>{{ $t("photo_specs_li_5") }}</li>
      <li>{{ $t("photo_specs_li_6") }}</li>
      <li>{{ $t("photo_specs_li_7") }}</li>
      <li>{{ $t("photo_specs_li_8") }}</li>
      <li>{{ $t("photo_specs_li_9") }}</li>
      <li>{{ $t("photo_specs_li_10") }}</li>
      <li>{{ $t("photo_specs_li_11") }}</li>
      <li>{{ $t("photo_specs_li_12") }}</li>
      <li>{{ $t("photo_specs_li_13") }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "guest1",
  data() {
    return {
      errors: 0,
      showpop_image: 0,
      view_only_all: 0,
      token_is_valide: 1,
      ar_nationality_list: [],
      en_nationality_list: [],
      ar_countries_list: [],
      en_countries_list: [],
      main_logo_image: this.$store.state.baseURLuploadImage,
      main_logo_imagepassport: this.$store.state.baseURLuploadImage,
      form: {
        first_name: "",
        last_name: "",
        title: "",
        nationality: "",
        country_of_residence: "",
        gender: "",
        full_name: "",
        id_pass_iqama: "",
        personalimage: "",
        passportimage: "",

        place_of_birth: "",
        date_of_birth: "",
        passport_type: "",
        passport_expiry_date: "",
      },
    };
  },
  created() {
    this.$store.state.loader = 1;
    var tokennuser = this.$route.query.t;
    var defaultlanguage = this.$route.query.lang;
    if (defaultlanguage == "" || defaultlanguage == null) {
    } else {
      if (defaultlanguage == "ar" || defaultlanguage == "en") {
        this.$i18n.locale = defaultlanguage;
        if (defaultlanguage == "ar") {
          $("head").append(
            '<link rel="stylesheet" href="/rtl_style.css" class="ar_style">'
          );
        } else {
          $(".ar_style").remove();
        }
      }
    }
    if (tokennuser == "" || tokennuser == null) {
      tokennuser = this.$store.state.normal_guest_token_key;
    }
    this.$store.state.normal_guest_token_key = tokennuser;
    this.axios
      .post(this.$store.state.baseURL + "getcountries", {})
      .then((response) => {
        var objectrespons = response.data.data;
        objectrespons.forEach((element) => {
          var ar_x = {
            id: element.id,
            text: element.country_arNationality,
          };
          var en_x = {
            id: element.id,
            text: element.country_enNationality,
          };
          var ar_y = {
            id: element.id,
            text: element.country_arName,
          };
          var en_y = {
            id: element.id,
            text: element.country_enName,
          };
          this.ar_nationality_list.push(ar_x);
          this.en_nationality_list.push(en_x);
          this.ar_countries_list.push(ar_y);
          this.en_countries_list.push(en_y);
        });
      })
      .catch((e) => {});
    this.axios
      .post(this.$store.state.baseURL + "get-guest", {
        token_key: tokennuser,
      })
      .then((response) => {
        if (response.data.data == null) {
          this.token_is_valide = 0;
          this.$store.state.loader = 0;
          this.$router.push({ path: "/registration/invalid-token" });
        } else {
          var objectrespons = response.data.data;
          var objectresponsecuritycheck = response.data.datasecurity;
          this.$store.state.normal_guest = objectrespons;
          this.$store.state.normal_guest.datasecurity =
            objectresponsecuritycheck;
          this.form.title = objectrespons.title;
          this.form.first_name = objectrespons.first_name;
          this.form.last_name = objectrespons.last_name;
          this.form.gender = objectrespons.gender;
          this.form.nationality = objectrespons.nationality;
          this.form.country_of_residence = objectrespons.country_of_residence;
          this.form.id_pass_iqama = objectrespons.id_pass_iqama;
          if (objectresponsecuritycheck != null) {
            this.form.full_name = objectresponsecuritycheck.full_name;
            this.form.personalimage = objectresponsecuritycheck.personal_photo;
            this.form.passportimage = objectresponsecuritycheck.passport_image;
            this.form.place_of_birth = objectresponsecuritycheck.place_of_birth;
            this.form.date_of_birth = objectresponsecuritycheck.date_of_birth;
            this.form.passport_type = objectresponsecuritycheck.passport_type;
            this.form.passport_expiry_date =
              objectresponsecuritycheck.passport_expiry_date;
          }
          if (
            objectrespons.status == "pending" ||
            objectrespons.status == "new"
          ) {
            this.view_only_all = 0;
            this.$store.state.is_view_only = 0;
          } else {
            this.view_only_all = 1;
            this.$store.state.is_view_only = 1;
          }
          this.$store.state.loader = 0;
        }
      })
      .catch((e) => {
        this.$store.state.loader = 0;
        this.$router.push({ path: "/registration/failed" });
        this.token_is_valide = 0;
      });
  },
  computed: {
    countries_list() {
      if (this.$i18n.locale == "ar") {
        return this.ar_countries_list;
      }
      return this.en_countries_list;
    },
    nationality_list() {
      if (this.$i18n.locale == "ar") {
        return this.ar_nationality_list;
      }
      return this.en_nationality_list;
    },
    title_list() {
      if (this.$i18n.locale == "ar") {
        return this.$store.state.ar_title_list;
      }
      return this.$store.state.en_title_list;
    },
    passport_type() {
      if (this.$i18n.locale == "ar") {
        return this.$store.state.ar_passport_type;
      }
      return this.$store.state.en_passport_type;
    },
  },
  methods: {
    validateSAID(id) {
      id = id.trim();
      if (isNaN(parseInt(id))) {
        return -1;
      }
      if (id.length !== 10) {
        return -1;
      }
      var type = id.substr(0, 1);
      if (type !== "2" && type !== "1") {
        return -1;
      }
      var sum = 0;
      for (var i = 0; i < 10; i++) {
        if (i % 2 === 0) {
          var ZFOdd = String("00" + String(Number(id.substr(i, 1)) * 2)).slice(
            -2
          );
          sum += Number(ZFOdd.substr(0, 1)) + Number(ZFOdd.substr(1, 1));
        } else {
          sum += Number(id.substr(i, 1));
        }
      }
      return sum % 10 !== 0 ? -1 : type;
    },
    uploadImagepersonal() {
      const uploadurl =
        this.$store.state.baseURLuploadImage + "upload_image.php";
      this.file = this.$refs.fileaa.files[0];
      $(".image_textpersonal").html(this.file.name);
      let formData = new FormData();
      var logoname = Date.now();
      var path_title = "personal";
      logoname =
        path_title + "_" + logoname + "" + Math.floor(Math.random() * 10000);
      formData.append("file", this.file);
      formData.append("image_name", logoname);
      formData.append("path", path_title);
      var self = this;
      this.axios
        .post(uploadurl, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          if (response.data == 0) {
            alert("File not uploaded.");
            // console.log(response.data);
            $(".validate_logopersonal").html(
              "Problem with uploading the Image"
            );
            $(".validate_logopersonal").slideDown();
            self.errors = 1;
          } else if (response.data == 1) {
            $(".validate_logopersonal").html("Image size exceeds 4MB");
            $(".validate_logopersonal").slideDown();
            self.errors = 1;
          } else if (response.data == 2) {
            $(".validate_logopersonal").html("Upload valid images Only");
            $(".validate_logopersonal").slideDown();
            self.errors = 1;
          } else {
            self.errors = 0;
            $(".validate_logopersonal").slideUp();
            // console.log(response.data);
            self.form.personalimage = response.data;
            console.log(self.form.personalimage);
            self.logo_image = self.main_logo_image + "images/" + response.data;
            // alert('File uploaded successfully.');
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    uploadImage() {
      const uploadurl =
        this.$store.state.baseURLuploadImage + "upload_image.php";
      this.file = this.$refs.file.files[0];
      $(".image_text").html(this.file.name);
      let formData = new FormData();
      var logoname = Date.now();
      var path_title = "passport";
      logoname =
        path_title + "_" + logoname + "" + Math.floor(Math.random() * 10000);
      formData.append("file", this.file);
      formData.append("image_name", logoname);
      formData.append("path", path_title);
      var self = this;
      this.axios
        .post(uploadurl, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          if (response.data == 0) {
            alert("File not uploaded.");
            // console.log(response.data);
            $(".validate_logo").html("Problem with uploading the Image");
            $(".validate_logo").slideDown();
            self.errors = 1;
          } else if (response.data == 1) {
            $(".validate_logo").html("Image size exceeds 4MB");
            $(".validate_logo").slideDown();
            self.errors = 1;
          } else if (response.data == 2) {
            $(".validate_logo").html("Upload valid images Only");
            $(".validate_logo").slideDown();
            self.errors = 1;
          } else {
            self.errors = 0;
            $(".validate_logo").slideUp();
            // console.log(response.data);
            self.form.passportimage = response.data;
            self.logo_imagepassport =
              self.main_logo_imagepassport + "images/" + response.data;
            // alert('File uploaded successfully.');
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    validatedata(formname, validatefieldclass, text) {
      if (formname == "" || formname == null || formname == 0) {
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        this.errors = 1;
        // console.log(validatefieldclass);
      } else {
        $(validatefieldclass).slideUp();
      }
    },
    submit() {
      this.errors = 0;
      this.$emit("submit", this.form);
      this.validatedata(this.form.first_name, ".validateFirstName", this.$t("first_name_is_required"));
      this.validatedata(this.form.last_name, ".validateLastName", this.$t("last_name_is_required"));
      this.validatedata(this.form.title, ".validateTitle", this.$t("title_is_required"));
      this.validatedata(this.form.nationality, ".validatenation", this.$t("nationality_is_required"));
      this.validatedata(this.form.country_of_residence, ".validateCountryOfResidence", this.$t("countryofresidence_is_required"));
      this.validatedata(this.form.full_name, ".validateFullName", this.$t("full_name_is_required"));
      this.validatedata(this.form.personalimage, ".validate_logopersonal", this.$t("personal_image_is_required"));
      this.validatedata(this.form.passportimage, ".validate_logopassport", this.$t("photo_is_required"));
      this.validatedata(this.form.place_of_birth, ".validatePlaceOfBirth", this.$t("place_of_birth_is_required"));
      this.validatedata(this.form.date_of_birth, ".validateDateOfdBirth", this.$t("date_of_birth_is_required"));
      this.validatedata(this.form.gender, ".validateGender", this.$t("gender_is_required"));
      var res = this.$store.state.no_visa_countries.indexOf(this.form.nationality);
      if (res == -1 && this.form.country_of_residence != 194) {
        this.validatedata(this.form.passport_type,".validatePassportType",this.$t("passport_type_is_required"));
      }
      if(res != -1){
        this.validatedata(this.form.passport_expiry_date,".validatePassportExpiryDate", this.$t('id_expiry_is_required'));
      }
      else if(res == -1 && this.form.country_of_residence == 194){
        this.validatedata(this.form.passport_expiry_date,".validatePassportExpiryDate", this.$t('iqama_expiry_is_required'));
      }
      else{
        this.validatedata(this.form.passport_expiry_date,".validatePassportExpiryDate",this.$t("passport_expiry_is_required"));
      }

      if ( this.form.id_pass_iqama == "" || this.form.id_pass_iqama == null || this.form.id_pass_iqama == 0 ) {
        $(".validation_id_pass_iqama").html(this.$t("filed_is_required"));
        $(".validation_id_pass_iqama").slideDown();
        this.errors = 1;
      } else {
        var val = this.form.id_pass_iqama;
        if (this.form.nationality == 194) {
          var x = this.validateSAID(this.form.id_pass_iqama);
          if (x == -1) {
            $(".validation_id_pass_iqama").html(this.$t("invalid_id"));
            $(".validation_id_pass_iqama").slideDown();
            this.errors = 1;
          } else {
            $(".validation_id_pass_iqama").slideUp();
          }
        } else if (this.form.country_of_residence == 194 && res == -1) {
          if (/^[0-9]+$/.test(val)) {
            if (val.length === 10 && val.toString().indexOf("2") == 0) {
              $(".validation_id_pass_iqama").slideUp();
            } else {
              $(".validation_id_pass_iqama").html(this.$t("id_20_digit"));
              $(".validation_id_pass_iqama").slideDown();
              this.errors = 1;
            }
          } else {
            $(".validation_id_pass_iqama").html(this.$t("enter_only_number"));
            $(".validation_id_pass_iqama").slideDown();
            this.errors = 1;
          }
        } else {
          if (val.length < 7 || val.length > 10) {
            $(".validation_id_pass_iqama").html(this.$t("id_25_digit"));
            $(".validation_id_pass_iqama").slideDown();
            this.errors = 1;
          }
        }
      }
      if (this.errors == 0) {
        this.$store.state.normal_guest.first_name = this.form.first_name;
        this.$store.state.normal_guest.last_name = this.form.last_name;
        this.$store.state.normal_guest.title = this.form.title;
        this.$store.state.normal_guest.nationality = this.form.nationality;
        this.$store.state.normal_guest.country_of_residence =
          this.form.country_of_residence;
        this.$store.state.normal_guest.gender = this.form.gender;
        this.$store.state.normal_guest.id_pass_iqama = this.form.id_pass_iqama;
        this.$store.state.normal_guest.full_name = this.form.full_name;
        this.$store.state.normal_guest.personal_photo = this.form.personalimage;
        this.$store.state.normal_guest.passport_image = this.form.passportimage;
        this.$store.state.normal_guest.place_of_birth =
          this.form.place_of_birth;
        this.$store.state.normal_guest.date_of_birth = this.form.date_of_birth;
        this.$store.state.normal_guest.passport_type = this.form.passport_type;
        this.$store.state.normal_guest.passport_expiry_date =
          this.form.passport_expiry_date;

        this.$router.push({
          path: "/registration/guest2",
        });
      } else {
        $(".alert_error").removeClass("h");
        setTimeout(function () {
          $(".alert_error").addClass("h");
        }, 5000);
      }
    },
  },
};
</script>
