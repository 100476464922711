<template>
<div class="c mt150">
    <div class=" mt100 m_mt0 g_nm ph20 bgw round3x sh20">
      <div class="ov ba p50 con ch tx_50 ty__50" :style="{backgroundImage: 'url('+require('@/assets/img/visaimg.png')+')'}" style="top:-50px"></div>
      <div class="b mt60 ">{{ $t('do_you_want') }}</div>
      <div class="mt20 mb20 c">
        <button @click="gotofunc(0)" class="prev_btn pv5 ph30 ">{{ $t('no')}}</button>
        <button @click="gotofunc(1)" class="next_btn pv5 ph30 cw ml20">{{ $t('yes')}}</button>
      </div>
    </div>
</div>


<!-- 
<div class=" relative bg-white pt-50p">
  <div class="bg-[#ffffff5d] rounded-xl w-1/2 mx-auto pt-12 pb-5 formboxax">
    <div class="mediaregistertitle mt-5 text-center">Speaker Registration</div><br><br>
    <div class="w-[70%] mx-auto bg-white rounded-md relative flex items-center">
      <div class="w-[100%] bg-[#0F5A75] h-2 rounded-md"></div>
      <div class="flex justify-between absolute w-full">
        <div class=" bg-[#0F5A75]  text-[#fff] pb-1 text-center w-1/5 rounded-xl font-bold">25%</div>
        <div class="bg-[#0F5A75] text-[#ffffff] pb-1 text-center w-1/5 rounded-xl font-bold ">50%</div>
        <div class="bg-[#0F5A75] text-[#ffffff] pb-1 text-center w-1/5 rounded-xl font-bold ">75%</div>
        <div class="bg-[#0F5A75] text-[#ffffff] pb-1 text-center w-1/5 rounded-xl font-bold ">100%</div>
      </div>
    </div>
    <div class="mediaregistertitle mt-12  mx-auto ">

    </div>

      <div class="flex justify-end mt-5 btnsubmitstepmob">
        <button @click="gotofunc(1)" >
          Yes
        </button>
        <button @click="gotofunc(0)" >
          No
        </button>
      </div>

  </div>
</div> -->
</template>
<script>
export default {
  name: 'step5speaker',
  data() {
    return {

    }
  },
  methods: {
    gotofunc(goto) {
      if(goto ==1){
        this.$router.push({path: '/registration/speaker/step6'});
      }else{
        this.$router.push({path: '/registration/speaker/thankyou'});
      }

    }
  },
  created() {
    this.$store.state.showloader_registration = 1;
    if (Object.keys(this.$store.state.speaker_user).length === 0) {
      alert('Data is Missing! Please Use the Link sent to your Email!');
    } else {
      this.$store.state.showloader_registration = 0;
    }
  },
}
</script>
