<template>

<div class="mt100 c mb50">
<div class="pv20 g_nm round2x ph40 c w50 md_w66 m_w75 m_m_w100 bgw sh10">
<img class="g_nm" src="@/assets/img/biban-logo.png"  alt="profile_image" />
<div class="mt10 c-blue">{{ $t('welcome_to_registrate') }}</div>
<div class="mt10 l w100">
<div class="bg-prograss-bar prograss-bar">
    <div class="prograss-bar ov ra w100 g_nm bg_orange"></div>
</div>
<div class="mt10 b">{{ $t('visa_requirement') }}</div>
<form class="pv10" @submit.prevent="submit_form">
    <!-- START NAME & PASS NUMBER-->
    <tbl>
        <cl class="vt w50">
        <div class="mt15">
            <label class="label_fields">{{ $t('full_name') }} *</label>
            <input class="input_fields" v-model="form.full_name" v-bind:placeholder="$t('full_name')" type="text"/>
            <div class="validatefullname validation_warning"></div>
        </div>
        </cl>
        <cl class="colSep pv10"></cl>
        <cl class="vt w50">
            <div class="mt15">
                <label class="label_fields">{{ $t('passport_number') }} *</label>
                <input class="input_fields" id="passport_number" v-model="form.passport_number" v-bind:placeholder="$t('passport_number')" type="text" maxlength="10"/>
                <div class="validatePassportNumber validation_warning"></div>
            </div>
        </cl>
    </tbl>
    <!-- CLOSE NAME & PASS NUMBER-->
    <!-- START image -->
    <div class="mt15 w50 md_w66 m_w80 m_m_w100">
        <tbl_nm>
            <label class="pointer">
                <cl class="w10">
                    <div class="bg-blue p25 round100" style="min-width:30px;">
                        <span v-if="form.personal_image == '' || form.personal_image == null " class="cc mdi mdi-image-outline fs30 cw"></span>
                        <img v-else :src='logo_image' class="cc round100 fs30 cw imageforupload" style="width:50px; height:50px;" />
                    </div>
                </cl>
                <cl class="colSep"></cl>
                <cl class="wa" style="max-width:250px;">
                    <label class="label_fields">{{ $t('personal_photo') }}</label>
                    <!-- <label class="pointer"> -->
                        <input type="file" ref="personal_image" id="personal_image" @change="uploadImage()" accept="image/*" hidden />
                        <div class="fs12 nw oe image_text">
                            {{ $t('image_text') }}
                        </div>
                    <!-- </label> -->
                    <div class="fs12 nw oe">
                        {{ $t('image_condition') }}
                    </div>
                </cl>
            </label>
            <cl class="colSep"></cl>
            <cl class="w5 r" style="min-width:25px;">
                <span class="mdi mdi-image-multiple-outline c-blue fs20"></span>
                <span class="mdi mdi-information-outline pointer c-blue fs20" @click="showpop_image = 1"></span>
            </cl>
        </tbl_nm>
        <div class="validatPassportPhoto validation_warning"></div>
    </div>
    <!-- CLOSE image -->
    <!-- START -->
    <tbl>
        <cl class="vt w50">
            <div class="mt15">
                <label class="label_fields">{{$t('passport_type')}} *</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.passport_type" :options="passport_type"/>

                <!-- <select class="input_fields select_input" v-model="form.passport_type" >
                    <option value="Ordinary Passport" selected>Ordinary Passport</option>
                    <option value="Official Passport">Official Passport</option>
                    <option value="Diplomatic Passport">Diplomatic Passport</option>
                    <option value="Emergency Travel Document">Emergency Travel Document</option>
                </select> -->
            </div>
        </cl>
        <cl class="colSep pv10"></cl>
        <cl class="vt w50">
            <div class="mt15">
                <label class="label_fields">{{$t('pass_expire_date')}} *</label>
                <input class="input_fields select_input expiry_date" placeholder="" v-model="form.passport_expiry_date" min="2023-09-09" type="date"/>
                <div class="validatePassportExpiryDate validation_warning"></div>
            </div>
        </cl>
    </tbl>
    <!-- CLOSE -->
    <!-- START -->
    <tbl>
        <cl class="vt w50">
            <div class="mt15">
                <label class="label_fields">{{$t('place_of_birth')}} *</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.place_of_birth" :options="countries_list"/>
                <div class="validatePlaceOfBirth validation_warning"></div>
            </div>
        </cl>
        <cl class="colSep pv10"></cl>
        <cl class="vt w50">
            <div class="mt15">
                <label class="label_fields">{{$t('date_of_birth')}} *</label>
                <input class="input_fields" placeholder="" v-model="form.date_of_birth" max="2009-03-01" type="date"/>
                <div class="validateDateOfdBirth validation_warning"></div>
            </div>
        </cl>
    </tbl>
    <!-- CLOSE -->
    <!-- START -->
    <tbl>
        <cl class="vt w50">
            <div class="mt15">
                <label class="label_fields">{{$t('nationality')}} *</label>
                <Select2 disabled v-model="form.nationality" :options="nationality_list"/>
                <div class="validateNation validation_warning"></div>
            </div>
        </cl>
        <cl class="colSep pv10"></cl>
        <cl class="w50 vt" v-if="anothernathos == 0">
            <div class="mt15">
                <label class="label_fields">{{$t('have_another_nationality')}}</label>
                <select class="input_fields select_input" v-model="anothernathos">
                        <option value="0" selected>{{$t('no')}}</option>
                    <option value="1">{{$t('yes')}}</option>
                </select>
            </div>
        </cl>
        <cl class="w50 vt" v-if="anothernathos == 1">
            <div class="mt15">
                <label class="label_fields">{{$t('prev_nationality')}}</label>
                <Select2 v-model="form.previous_nationality" :options="nationality_list"/>
                <div class="validateprePrevNation validation_warning"></div>
            </div>
        </cl>
    </tbl>
    <!-- CLOSE -->
    <!-- START -->
    <tbl>
        <cl class="vt w50">
            <div class="mt15">
                <label class="label_fields">{{$t('country_comming_from')}}</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.country_comming_from" :options="countries_list"/>
                <div class="validateCountryCommingFrom validation_warning"></div>
            </div>
        </cl>
        <cl class="colSep pv10"></cl>
        <cl class="vt w50">
            <div class="mt15">
                <label class="label_fields">{{$t('country_of')}} *</label>
                <Select2 disabled v-model="form.country_of_residence" :options="countries_list"/>
                <div class="validateCountryOfResidence validation_warning"></div>
            </div>
        </cl>
    </tbl>
    <!-- CLOSE -->
    <div class="mt15">
      <label class="">{{$t('place_of_issue')}} *</label>
      <input class="input_fields select_input" placeholder="" v-model="form.place_of_issue" type="text" maxlength="50"/>
      <div class="validatePlaceOfIssue validation_warning"></div>
    </div>
    <!-- START -->
    <tbl>
        <cl class="vt w50">
            <div class="mt15">
                <label class="label_fields">{{$t('pass_issue_date')}} *</label>
                <input class="input_fields issue_date" placeholder="" v-model="form.passport_issue_date" type="date"/>
                <div class="validateIssuancedate validation_warning"></div>
            </div>
            <div class="mt15">
                <label class="label_fields">{{$t('religion')}} *</label>
                <select class="input_fields select_input" v-model="form.religion" >
                    <option value="muslim">{{$t('muslim')}}</option>
                    <option value="not muslim">{{$t('not_muslim')}}</option>
                </select>
                <div class="validateReligion validation_warning"></div>
            </div>
        </cl>
        <cl class="colSep pv10"></cl>
        <cl class="vt w50">
            <div class="mt15">
                <label class="label_fields">{{$t('issuance_country')}} *</label>
                <Select2 :disabled="view_only_all == 1" v-model="form.issuance_country" :options="countries_list"/>
                <!-- <select class="input_fields select_input" v-model="form.issuance_country">
                    <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enName">{{country_item.country_enName}}</option>
                </select> -->
                <div class="validateIssuanceCountry validation_warning"></div>
            </div>
        </cl>
    </tbl>
    <div class="fs12 c mt20">{{ $t('privacy_inforamtion') }}</div>
    <!-- CLOSE -->
    <!-- <div class="mt15">
        <label class="label_fields">{{$t('gender')}} *</label>
        <br>
        <input type="radio" class="mt8 ml10 mr10" v-model="form.gender" value="male">Male
        <input type="radio" class="mt8 ml10 mr10" v-model="form.gender" value="female">Female
    </div> -->
    <div class="mt20 r">
        <button @click="submit" class="next_btn ph20  cw">
            <div v-if="disabled_submit" class="mt10 mb10 ml5 mr5 rotatingLoader" ></div>
            <div v-if="!disabled_submit"  class="pv10 cw">{{$t('registrate_now')}}</div>
        </button>
    </div>
</form>
</div>
</div>
<div class="popup_descimage round2x l" v-if="showpop_image == 1" style="z-index:9999999999;">
<div class="popupdesc_img_close ov20 pointer la mr20 ba" @click="showpop_image = 0">{{$t('close')}}</div>
<h2>{{$t('photo_specs')}}</h2>
<p>{{$t('photo_specs_title')}}</p>
<ul class="popdesc_ul_list">
    <li>{{$t('photo_specs_li_1')}}</li>
    <li>{{$t('photo_specs_li_2')}}</li>
    <li>{{$t('photo_specs_li_3')}}</li>
    <li>{{$t('photo_specs_li_4')}}</li>
    <li>{{$t('photo_specs_li_5')}}</li>
    <li>{{$t('photo_specs_li_6')}}</li>
    <li>{{$t('photo_specs_li_7')}}</li>
    <li>{{$t('photo_specs_li_8')}}</li>
    <li>{{$t('photo_specs_li_9')}}</li>
    <li>{{$t('photo_specs_li_10')}}</li>
    <li>{{$t('photo_specs_li_11')}}</li>
    <li>{{$t('photo_specs_li_12')}}</li>
    <li>{{$t('photo_specs_li_13')}}</li>
</ul>
</div>
</div>

</template>
<script>
export default {
  name: 'Visa3',
  data(){
    return {
      anothernathos :0,
      disabled_submit: false,
      // countries_list: [],
      // nationality_list:[],
      en_countries_list:[],
      ar_countries_list:[],
      en_nationality_list:[],
      ar_nationality_list:[],
      personal_photo_name:'',
      logo_image: (this.$store.state.baseURLuploadImage),
      main_logo_image: (this.$store.state.baseURLuploadImage),
      showpop_image:0,
      visa_guest_type:this.$store.state.visa_guest_type,
      form:{
        full_name:'', //done
        passport_number:'', //done
        passport_type:'Ordinary Passport', //done
        issuance_country:'',
        passport_issue_date:'',
        date_of_birth:'',
        nationality:'',
        place_of_birth:'',
        place_of_issue:'',
        previous_nationality:'',
        country_of_residence:'',
        personal_image:"",
        passport_expiry_date:'',
        religion:'',
        gender:'',
        country_comming_from:"",
      },
    }
  },
  computed:{
    countries_list(){
      if(this.$i18n.locale == 'ar'){
        return this.ar_countries_list
      }
      return this.en_countries_list
    },
    nationality_list(){
      if(this.$i18n.locale == 'ar'){
        return this.ar_nationality_list
      }
      return this.en_nationality_list
    },
    passport_type(){
      if(this.$i18n.locale == 'ar'){
          return this.$store.state.ar_passport_type;
      }
      return this.$store.state.en_passport_type;
    }
  },
  created(){
    this.$store.state.loader = 1;
    this.axios.post(this.$store.state.baseURL+'getcountries', {
    }).then(response => {
      var objectrespons=response.data.data;
      objectrespons.forEach(element => {
      var ar_x ={
          id: element.id,
          text: element.country_arNationality
      }
      var en_x ={
          id: element.id,
          text: element.country_enNationality
      }

      var ar_y ={
          id: element.id,
          text: element.country_arName
      }
      var en_y ={
          id: element.id,
          text: element.country_enName
      }
      this.ar_nationality_list.push(ar_x);
      this.en_nationality_list.push(en_x);
      this.ar_countries_list.push(ar_y);
      this.en_countries_list.push(en_y);
    });
      this.$store.state.loader = 0;
    }).catch(e => {})
    this.form.nationality= this.$store.state.visa.nationality;
    this.form.country_of_residence= this.$store.state.visa.country_of_residence;
    this.form.full_name = this.$store.state.visa.full_name;
    this.form.passport_type = this.$store.state.visa.passport_type;
    this.form.date_of_birth = this.$store.state.visa.date_of_birth;
    this.form.place_of_birth = this.$store.state.visa.place_of_birth;
    this.form.passport_expiry_date = this.$store.state.visa.passport_expiry_date;
  },
  mounted(){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;
    $('.issue_date').attr("max", today);
  },
  methods:{
    uploadImage() {
    const uploadurl = this.$store.state.baseURLuploadImage + "upload_image.php";
    this.file = this.$refs.personal_image.files[0];
    $('.image_text').html(this.file.name)
    let formData = new FormData();
    var logoname = Date.now();
    var path_title="visa";
    logoname=path_title+"_"+logoname +""+Math.floor(Math.random() * 10000);
    formData.append('file', this.file);
    formData.append('image_name', logoname);
    formData.append('path', path_title);
    var self = this;
    this.axios.post(uploadurl, formData,{headers: {'Content-Type': 'multipart/form-data'}}).then(function (response) {
    if(response.data == 0){
    // console.log(response.data);
    $(".validatPassportPhoto").html("Problem with uploading the Image");
    $(".validatPassportPhoto").slideDown();
      self.errors = 1;
    }else if(response.data == 1){
    $(".validatPassportPhoto").html("Image size exceeds 4MB");
    $(".validatPassportPhoto").slideDown();
      self.errors = 1;
    }else if(response.data == 2){
    $(".validatPassportPhoto").html("Upload valid images Only");
    $(".validatPassportPhoto").slideDown();
    self.errors = 1;
    }else{
    self.errors = 0;
    $(".validatPassportPhoto").slideUp();
    self.form.personal_image = response.data;
    self.logo_image = self.main_logo_image + "images/" + response.data;
    // alert('File uploaded successfully.');
    }
    }).catch(function (error) {});
    },
    validatedata(formname,validatefieldclass, text) {
      if (formname == "" || formname == null || formname == 0) {
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        this.errors = 1;
      }
      else{
        $(validatefieldclass).slideUp();
      }
    },
    submit(){
      $('.next_btn').addClass('disabled_btn');
      this.disabled_submit=true;
      this.errors=0;
      this.$emit('submit', this.form);
      this.validatedata(this.form.full_name,".validatefullname", this.$t('filed_is_required'));
      // this.validatedata(this.form.passport_number,".validatePassportNumber", this.$t('filed_is_required'));
      if(this.form.passport_number == "" || this.form.passport_number == null || this.form.passport_number == 0){
        $('.validatePassportNumber').html(this.$t('filed_is_required'));
        $('.validatePassportNumber').slideDown();
        this.errors = 1;
      }
      else{
        var val =this.form.passport_number.length;
        if(val >10 || val < 7){
          $('.validatePassportNumber').html(this.$t('id_25_digit'));
          $('.validatePassportNumber').slideDown();
          this.errors = 1;
        }
        else{
            $('.validatePassportNumber').slideUp();
        }
      }
      this.validatedata(this.form.passport_type,".validatePassportType", this.$t('filed_is_required'));
      this.validatedata(this.form.issuance_country,".validateIssuanceCountry", this.$t('filed_is_required'));
      this.validatedata(this.form.passport_issue_date,".validateIssuancedate", this.$t('filed_is_required'));
      this.validatedata(this.form.date_of_birth,".validateDateOfdBirth", this.$t('filed_is_required'));
      this.validatedata(this.form.nationality,".validateNation", this.$t('filed_is_required'));
      this.validatedata(this.form.place_of_birth,".validatePlaceOfBirth", this.$t('filed_is_required'));
      this.validatedata(this.form.country_of_residence,".validateCountryOfResidence", this.$t('filed_is_required'));
      this.validatedata(this.form.passport_expiry_date,".validatePassportExpiryDate", this.$t('filed_is_required'));
      this.validatedata(this.form.country_comming_from,".validateCountryCommingFrom", this.$t('filed_is_required'));
      this.validatedata(this.form.place_of_issue,".validatePlaceOfIssue", this.$t('filed_is_required'));

      this.validatedata(this.form.religion,".validateReligion", this.$t('filed_is_required'));
      if(this.anothernathos == 1){
        this.validatedata(this.form.previous_nationality,".validateprePrevNation", this.$t('filed_is_required'));
      }
      else{
        $('.validateprePrevNation').slideUp();
      }
      if(this.$refs.personal_image.files.length == 0){
        $('.validatPassportPhoto').html(this.$t('personal_image_is_required'));
        $('.validatPassportPhoto').slideDown();
        this.errors=1;
      }
      else{
        $('.validatPassportPhoto').slideUp();
      }
      if(this.errors == 0){
        this.axios.post(this.$store.state.baseURL + "add-staff-visa-info", {
          "user_id":this.$store.state.visa.userID,
          "full_name": this.form.full_name,
          "passport_number": this.form.passport_number,
          "passport_type": this.form.passport_type,
          "issuance_country": this.form.issuance_country,
          "issue_date": this.form.passport_issue_date,
          "date_of_birth": this.form.date_of_birth,
          "place_of_birth": this.form.place_of_birth,
          "place_of_issue": this.form.place_of_issue,
          "expiry_date": this.form.passport_expiry_date,
          "religion": this.form.religion,
          "gender": this.$store.state.visa.gender,
          "personal_photo": this.form.personal_image,
          "country_of_residence": this.form.country_of_residence,
          "nationality": this.form.nationality,
          "current_nationality": this.form.nationality,
          "previous_nationality": this.form.previous_nationality,
          "country_coming_from":this.form.country_comming_from,
          "user_token_key":this.$store.state.visa.user_token_key,
          "type_of_user":this.$store.state.visa.type_of_user,
          "visa_type":"visa_international",
          // "visa_status":"",
          })
          .then((response) => {
            this.axios.post(this.$store.state.baseURL + "update-visa-status", {
              type_of_user:this.$store.state.visa.type_of_user,
              user_token_key: this.$store.state.visa.user_token_key,
              visa_status: "new",
              flight_status: "new",
              hotel_status: "new",
            }).then((response) => {
              this.$router.push({path: '/registration/thankyou'});
            }).catch((e) => {});
          })
          .catch((e) => {
              $('.next_btn').removeClass('disabled_btn');
              this.disabled_submit=false;
          });
      }
      else{
        $('.next_btn').removeClass('disabled_btn');
        this.disabled_submit=false;
        // console.log('error 1');
      }
    }
  }
}
</script>
