<template>
<div class="mt100 ltr c mb50">
  <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
    <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
    <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }} {{$t('as_speaker')}}</div>
    <div class="mt10 l w100">
      <div class="bg-prograss-bar prograss-bar">
        <div class="prograss-bar ov ra w66 g_nm bg_orange"></div>
      </div>
      <div class="mt10 c-blue">{{ $t('step2') }}</div>
      <div class="mt10 b">{{ $t('contact_info') }}</div>
      <form class="pv10" @submit.prevent="submit_form">
        <!-- START PHONE -->
        <div class="mt15">
          <label class="label_fields mb8">{{ $t('phone') }} *</label>
          <div class="pv4"></div>
            <MazPhoneNumberInput :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" v-model="form.phone" :default-phone-number="form.phone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results = $event" :success="results?.isValid" style="direction:ltr !important;"/>
            <!-- <input :disabled="view_only_all == 1" class="input_fields" type="text" id="mobile_code" v-bind:placeholder="$t('phone')" required v-model="form.phone" /> -->
          <div class="validation_phone validation_warning"></div>
        </div>
        <div class="mt15">
          <label class="label_fields mb8">{{ $t('telephone') }}</label>
          <div class="pv4"></div>
            <MazPhoneNumberInput :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" v-model="form.telephone" :default-phone-number="form.telephone" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results2 = $event" :success="results2?.isValid" style="direction:ltr !important"/>
        </div>
        <!-- START PHONE -->
        <!-- START EMAIL -->
        <div class="mt15 mb15">
          <label class="label_fields">{{ $t('email_address') }} *</label>
          <input disabled class="input_fields" v-model="form.email" v-bind:placeholder="$t('email_address')" type="email" />
          <div class="validation_email validation_warning"></div>
        </div>
        <div class="mt15">
          <label class="label_fields">{{$t('qualification')}} *</label>
          <Select2 :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" v-model="form.qualification" :options="qualification_list"/>
          <div class="validate_qualification validation_warning"></div>
        </div>

        <!-- CLOSE QUALIFICATION -->
        <!-- START WORKPLACE -->

        <!-- CLOSE JOBTITLE -->
        <!-- START ADDRESS -->
        <div class="mt15">
          <label class="label_fields">{{$t('address')}} *</label>
          <input :disabled="view_only_all == 1" class="input_fields" v-model="form.address" type="text"/>
          <div class="validate_address validation_warning"></div>
        </div>
        <!-- CLOSE ADDRESS -->
        <!-- START PASSPORT TYPE & EXPIRY DATE -->
        <div v-if="$store.state.no_visa_countries.indexOf($store.state.speaker_user.nationality) != -1">
          <div class="mt15">
            <label class="label_fields">{{$t('id_expiry_date')}} *</label>
            <input :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="input_fields select_input expiry_date" placeholder="" v-model="form.passport_expiry_date" min="2023-04-01" type="date"/>
            <div class="validatePassportExpiryDate validation_warning"></div>
          </div>
        </div>
        <div v-else-if="$store.state.no_visa_countries.indexOf($store.state.speaker_user.nationality) == -1 && $store.state.speaker_user.country_of_residence == 194">
          <div class="mt15">
            <label class="label_fields">{{$t('iqama_expiry_date')}} *</label>
            <input :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="input_fields select_input expiry_date" placeholder="" v-model="form.passport_expiry_date" min="2023-04-01" type="date"/>
            <div class="validatePassportExpiryDate validation_warning"></div>
          </div>
        </div>
        <tbl v-else>
          <cl class="vt w50">
            <div class="mt15" >
              <label class="label_fields">{{$t('passport_type')}} *</label>
              <Select2 :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" v-model="form.passport_type" :options="passport_type"/>
              <div class="validatePassportType validation_warning"></div>
            </div>
          </cl>
          <cl class="vt colSep"></cl>
          <cl class="vt w50">
            <div class="mt15">
              <label class="label_fields">{{$t('pass_expire_date')}} *</label>
              <input :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="input_fields select_input expiry_date" placeholder="" v-model="form.passport_expiry_date" min="2023-09-09" type="date"/>
              <div class="validatePassportExpiryDate validation_warning"></div>
            </div>
          </cl>
        </tbl>
        <!-- CLSOE PASSPORT TYPE & EXPIRY DATE -->

        <!-- START SM -->
        <label class="label_fields">{{ $t('socail_media') }}</label>
        <div class="mt10">
          <input :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="sm ph10 input_fields" v-model="form.linked_in" v-bind:placeholder="$t('linked_in')" type="text" />
          <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue mdi mdi-linkedin"></span></div>
        </div>
        <div class="mt10">
          <input :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="sm ph10 input_fields" v-model="form.twitter" v-bind:placeholder="$t('twitter')" type="text" />
          <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue icon-x-twitter"></span></div>
        </div>
        <div class="mt10">
          <input :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="sm ph10 input_fields" v-model="form.facebook" v-bind:placeholder="$t('facebook')" type="text" />
          <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue mdi mdi-facebook"></span></div>
        </div>
        <div class="mt10">
          <input :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="sm ph10 input_fields" v-model="form.instagram" v-bind:placeholder="$t('instagram')" type="text" />
          <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue mdi mdi-instagram"></span></div>
        </div>
        <div class="mt10">
          <input :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="sm ph10 input_fields" v-model="form.snapchat" v-bind:placeholder="$t('snapchat')" type="text" />
          <div class="ov ml10 ra ba fs12 round100 mt10" style="padding:13px; background-color:#c8c8c8c8"><span class="cc c-blue mdi mdi-snapchat"></span></div>
        </div>
        <div class="mt20 r">
          <button @click="submit()" class="next_btn ph20 ml20">
            <span class="cw lh40 fs18" >{{$t('next')}}</span>
            <!-- <span v-if="$i18n.locale == 'en'" class="cw mdi mdi-arrow-right lh40 fs30"></span>
            <span v-if="$i18n.locale == 'ar'" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>
<script>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import { ref } from 'vue';
const results = ref();
export default {
  name: 'step2speaker',
    components: { MazPhoneNumberInput },

  data() {
    return {
      errors: 0,
      view_only_all:0,
      ar_qualification:[
        {id:1,text:'ثانوي'},
        {id:2,text:'بكالوريس'},
        {id:3,text:'ماجستير'},
        {id:4,text:'دكتوراه'},
      ],
      en_qualification:[
        {id:1,text:'Secondary'},
        {id:2,text:'Bachelor\'s degree'},
        {id:3,text:'Master\'s'},
        {id:4,text:'Ph.D'},
      ],
      form: {
        phone: "",
        telephone: "",

        passport_type:'',
        qualification:'',

        address:'',
        passport_expiry_date:'',

        email: "",
        linked_in: "",
        twitter: "",
        facebook: "",
        instagram: "",
        snapchat: "",
      }
    }
  },
  methods: {
    validatedata(formname,validatefieldclass, text) {
      if (formname == "" || formname == null || formname == 0) {
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        this.errors = 1;
      }
      else{
        $(validatefieldclass).slideUp();
      }
    },
    submit() {
      this.$emit('submit', this.form);
      this.errors = 0;
      if (this.form.phone == "" || this.form.phone == null || this.form.phone == 0) {
        $(".validation_phone").html(this.$t('phone_is_required'));
        $(".validation_phone").slideDown();
        this.errors = 1;
      } else {
        if( !this.results.isValid){
          this.errors = 1;
          $(".validation_phone").html(this.$t('phone_not_valid'));
          $(".validation_phone").slideDown();
        }
        else{
          $(".validation_phone").slideUp();
        }
      }
      this.validatedata(this.form.email,".validation_email", this.$t('email_is_required'));
      this.validatedata(this.form.qualification,".validation_qualification", this.$t('qualification_is_required'));
      this.validatedata(this.form.address,".validation_address", this.$t('address_is_required'));

      var res = this.$store.state.no_visa_countries.indexOf(this.$store.state.speaker_user.nationality);
      if(res != -1){
        this.validatedata(this.form.passport_expiry_date,".validation_passport_expiry_date", this.$t('id_expiry_is_required'));
      }
      else if(res == -1 && this.$store.state.speaker_user.country_of_residence == 194){
        this.validatedata(this.form.passport_expiry_date,".validation_passport_expiry_date", this.$t('iqama_expiry_is_required'));
      }
      else{
        this.validatedata(this.form.passport_expiry_date,".validation_passport_expiry_date", this.$t('passport_expiry_is_required'));
      }
      if(res == -1 && this.$store.state.speaker_user.country_of_residence !=194){
        this.validatedata(this.form.passport_type,".validatePassportType", this.$t('passport_type_is_required'));
      }
      if (this.errors == 1) {
      }
      else {
        if(this.form.facebook){}
        else{this.form.facebook=null;}
        if(this.form.telephone){}
        else{this.form.telephone=null;}
        this.$store.state.speaker_user.phone = this.form.phone;
        this.$store.state.speaker_user.email = this.form.email;
        this.$store.state.speaker_user.linked_in = this.form.linked_in;
        this.$store.state.speaker_user.twitter = this.form.twitter;
        this.$store.state.speaker_user.facebook = this.form.facebook;
        this.$store.state.speaker_user.instagram = this.form.instagram;
        this.$store.state.speaker_user.telephone = this.form.telephone;
        this.$store.state.speaker_user.snapchat = this.form.snapchat;
        this.$store.state.speaker_user.passport_type = this.form.passport_type;
        this.$store.state.speaker_user.address = this.form.address;
        this.$store.state.speaker_user.passport_expiry_date = this.form.passport_expiry_date;
        this.$store.state.speaker_user.qualification = this.form.qualification;
        this.axios.post(this.$store.state.baseURL + "check-phone", {
          token_key: this.$store.state.speaker_user_token,
          phone:this.form.phone
        })
        .then((response) => {
          if(response.data.success == true){
            this.$router.push({path: '/registration/speaker/step3'});
          }
          else{
            $(".validation_phone").html(this.$t('duplicated_phone'));
            $(".validation_phone").slideDown();
            $('.alert_error').removeClass('h');
            setTimeout(function () {
              $('.alert_error').addClass("h");;
            }, 5000);
          }
        })
        .catch((e) => {});

      }

    }
  },
  computed:{
    qualification_list(){
      if(this.$i18n.locale == 'ar'){
          return this.ar_qualification
      }
      return this.en_qualification
    },
    passport_type(){
      if(this.$i18n.locale == 'ar'){
          return this.$store.state.ar_passport_type;
      }
      return this.$store.state.en_passport_type;
    }
  },
  created() {
    this.$store.state.showloader_registration = 1;
    if (Object.keys(this.$store.state.speaker_user).length === 0) {

      this.$router.push({ path: '/registration/failed' });
    } else {

      var objectrespons = this.$store.state.speaker_user;
      var objectresponsecuritycheck = this.$store.state.speaker_user.datasecurity
      if(this.$store.state.is_view_only == 1){
        this.view_only_all=1;
      }
  //    console.log(objectresponsecuritycheck);
      if(objectresponsecuritycheck == null){
        //console.log("not null objectresponsecuritycheck");

      }else{
        this.form.facebook = objectresponsecuritycheck.facebook;
        this.form.telephone = objectresponsecuritycheck.telephone;
        this.form.passport_type = objectresponsecuritycheck.passport_type;
        this.form.qualification = objectresponsecuritycheck.qualification;
        this.form.address = objectresponsecuritycheck.address;
        this.form.passport_expiry_date = objectresponsecuritycheck.passport_expiry_date;
      }
      this.form.phone = objectrespons.phone;
      this.form.email = objectrespons.email;
      this.form.linked_in = objectrespons.linked_in;
      this.form.twitter = objectrespons.twitter;

      this.form.instagram = objectrespons.instagram;
      this.form.snapchat = objectrespons.snapchat;

    //  this.form.passport_type=this.$store.state.saved_guest_data.passport_type;
      this.$store.state.showloader_registration = 0;
    }
  },
}
</script>
