<template>
    <div class="mt100 c mb50">
        <div class="pv20 g_nm round2x ph40 c w50 md_w66 m_w75 m_m_w100 bgw sh10">
            <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
            <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }}</div>
            <div class="mt10 l w100">
                <div class="bg-prograss-bar prograss-bar">
                    <div class="prograss-bar ov ra w100 g_nm bg_orange"></div>
                </div>
                <div class="mt10 b">{{ $t('visa_requirement') }}</div>
                <form class="pv10" @submit.prevent="submit_form">
                    <!-- START NAME & PASS NUMBER-->
                    <tbl>
                        <cl class="vt w50">
                        <div class="mt15">
                            <label class="label_fields">{{ $t('full_name') }} *</label>
                            <input class="input_fields" v-model="form.full_name" v-bind:placeholder="$t('full_name')" type="text"/>
                            <div class="validatefullname validation_warning"></div>
                        </div>
                        </cl>
                        <cl class="colSep pv10"></cl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{ $t('passport_number') }} *</label>
                                <input class="input_fields" id="passport_number" v-model="form.passport_number" v-bind:placeholder="$t('passport_number')" type="text"/>
                                <div class="validatePassportNumber validation_warning"></div>
                            </div>
                        </cl>
                    </tbl>
                    <!-- CLOSE NAME & PASS NUMBER-->
                    <!-- START image -->
                    <div class="mt15 w50 md_w66 m_w80 m_m_w100">
                        <tbl_nm>
                            <cl class="w10">
                                <div class="bg-blue p25 round100" style="min-width:30px;">
                                    <span v-if="form.personal_image == '' || form.personal_image == null " class="cc mdi mdi-image-outline fs30 cw"></span>
                                    <img v-else :src='logo_image' class="cc round100 fs30 cw imageforupload" style="width:50px; height:50px;" />
                                </div>
                            </cl>
                            <cl class="colSep"></cl>
                            <cl class="wa" style="max-width:250px;">
                                <label class="label_fields">{{ $t('personal_photo') }}</label>
                                <label class="pointer">
                                    <input type="file" ref="personal_image" id="personal_image" @change="uploadImage()" accept="image/*" hidden />
                                    <div class="fs12 nw oe image_text">
                                        {{ $t('image_text') }}
                                    </div>
                                </label>
                                <div class="fs12 nw oe">
                                    {{ $t('image_condition') }}
                                </div>
                            </cl>
                            <cl class="colSep"></cl>
                            <cl class="w5 r" style="min-width:25px;">
                                <span class="mdi mdi-image-multiple-outline c-blue fs20"></span>
                                <span class="mdi mdi-information-outline c-blue fs20" @click="showpop_image = 1"></span>
                            </cl>
                        </tbl_nm>
                        <div class="validatPassportPhoto validation_warning"></div>
                    </div>
                    <!-- CLOSE image -->
                    <!-- START -->
                    <tbl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{$t('passport_type')}} *</label>
                                <select class="input_fields select_input" v-model="form.passport_type" >
                                    <option value="Ordinary Passport" selected>Ordinary Passport</option>
                                    <option value="Official Passport">Official Passport</option>
                                    <option value="Diplomatic Passport">Diplomatic Passport</option>
                                    <option value="Emergency Travel Document">Emergency Travel Document</option>
                                </select>
                            </div>
                        </cl>
                        <cl class="colSep pv10"></cl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{$t('issuance_country')}} *</label>
                                <Select2 :disabled="view_only_all == 1" v-model="form.issuance_country" :options="countries_list"/>
                                <!-- <select class="input_fields select_input" v-model="form.issuance_country">
                                    <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enName">{{country_item.country_enName}}</option>
                                </select> -->
                                <div class="validateIssuanceCountry validation_warning"></div>
                            </div>
                        </cl>
                    </tbl>
                    <!-- CLOSE -->
                    <!-- START -->
                    <tbl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{$t('pass_issue_date')}} *</label>
                                <input class="input_fields" placeholder="" v-model="form.passport_issue_date" type="date"/>
                                <div class="validateIssuancedate validation_warning"></div>
                            </div>
                        </cl>
                        <cl class="colSep pv10"></cl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{$t('date_of_birth')}} *</label>
                                <input class="input_fields" placeholder="" v-model="form.date_of_birth" type="date"/>
                                <div class="validateDateOfdBirth validation_warning"></div>
                            </div>
                        </cl>
                    </tbl>
                    <!-- CLOSE -->
                    <!-- START -->
                    <!-- <tbl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{$t('nationality')}} *</label>
                                <select class="input_fields select_input" disabled v-model="form.nationality">
                                    <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enNationality">{{country_item.country_enNationality}}</option>
                                </select>
                                <div class="validateNation validation_warning"></div>
                            </div>
                        </cl>
                        <cl class="colSep pv10"></cl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{$t('place_of_birth')}} *</label>
                                <select class="input_fields select_input" v-model="form.place_of_birth">
                                    <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enName">{{country_item.country_enName}}</option>
                                </select>
                                <div class="validatePlaceOfBirth validation_warning"></div>
                            </div>
                        </cl>
                    </tbl> -->
                    <tbl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{$t('nationality')}} *</label>
                                <Select2 disabled v-model="form.nationality" :options="nationality_list"/>
                                <!-- <select class="input_fields select_input" disabled v-model="form.nationality">
                                    <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enNationality">{{country_item.country_enNationality}}</option>
                                </select> -->
                                <div class="validateNation validation_warning"></div>
                            </div>
                        </cl>
                        <cl class="colSep pv10"></cl>
                        <cl class="w50 vt" v-if="anothernathos == 0">
                            <div class="mt15">
                                <label class="label_fields">{{$t('have_another_nationality')}}</label>
                                <select class="input_fields select_input" v-model="anothernathos">
                                        <option value="0" selected>{{$t('no')}}</option>
                                    <option value="1">{{$t('yes')}}</option>
                                </select>
                            </div>
                        </cl>
                        <cl class="w50 vt" v-if="anothernathos == 1">
                            <div class="mt15">
                                <label class="label_fields">{{$t('prev_nationality')}}</label>
                                <Select2 v-model="form.previous_nationality" :options="nationality_list"/>
                                <!-- <select class="input_fields select_input" v-model="form.previous_nationality">
                                    <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enNationality">{{country_item.country_enNationality}}</option>
                                </select> -->
                                <div class="validatepreNation validation_warning"></div>
                            </div>
                        </cl>
                    </tbl>
                    <tbl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{$t('place_of_birth')}} *</label>
                                <Select2 :disabled="view_only_all == 1" v-model="form.place_of_birth" :options="countries_list"/>
                                <!-- <select class="input_fields select_input" v-model="form.place_of_birth">
                                    <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enName">{{country_item.country_enName}}</option>
                                </select> -->
                                <div class="validatePlaceOfBirth validation_warning"></div>
                            </div>
                        </cl>
                        <cl class="colSep pv10"></cl>

                        <cl class="w50"></cl>
                    </tbl>
                    <!-- CLOSE -->
                    <!-- START -->
                    <tbl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <!-- <label class="">{{$t('place_of_issue')}} *</label> -->
                                <label class="label_fields">{{$t('country_comming_from')}}</label>
                                <Select2 :disabled="view_only_all == 1" v-model="form.country_comming_from" :options="countries_list"/>
                                <!-- <select class="input_fields select_input" v-model="form.place_of_issue">
                                    <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enName">{{country_item.country_enName}}</option>
                                </select> -->
                                <div class="validateCountryCommingFrom validation_warning"></div>
                                <!-- <div class="validatePlaceOfIssue validation_warning"></div> -->
                            </div>
                        </cl>
                        <cl class="colSep pv10"></cl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{$t('country_of')}} *</label>
                                <Select2 disabled v-model="form.country_of_residence" :options="countries_list"/>
                                <!-- <select class="input_fields select_input" disabled v-model="form.country_of_residence" >
                                    <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enName">{{country_item.country_enName}}</option>
                                </select> -->
                                <div class="validateCountryOfResidence validation_warning"></div>
                            </div>
                        </cl>
                    </tbl>
                    <!-- CLOSE -->
                    <!-- START -->
                    <tbl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{$t('pass_expire_date')}} *</label>
                                <input class="input_fields select_input" placeholder="" v-model="form.passport_expiry_date" type="date"/>
                                <div class="validatePassportExpiryDate validation_warning"></div>
                            </div>
                        </cl>
                        <cl class="colSep pv10"></cl>
                        <cl class="vt w50">
                            <div class="mt15">
                                <label class="label_fields">{{$t('religion')}} *</label>
                                <select class="input_fields select_input" v-model="form.religion" >
                                    <option value="muslim" selected>Muslim</option>
                                    <option value="christian">Christian</option>
                                    <option value="other">Other</option>
                                </select>
                                <div class="validateReligion validation_warning"></div>
                            </div>
                        </cl> 
                    </tbl>
                    <!-- CLOSE -->
                    <div class="mt15">
                        <label class="label_fields">{{$t('gender')}} *</label>
                        <br>
                        <input type="radio" class="mt8 ml10 mr10" v-model="form.gender" checked value="male">Male
                        <input type="radio" class="mt8 ml10 mr10" v-model="form.gender" value="female">Female
                    </div>
                    <div class="mt20 r">
                        <button @click="submit()" class="next_btn ph20 pv15 cw">{{$t('registrate_now')}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
<div class="popup_descimage" v-if="showpop_image == 1">
<div class="popupdesc_img_close" @click="showpop_image = 0">close</div>
<h2>Photo specs</h2>
<p>The specifications for a Saudi Arabia passport photo are as follows:</p>
<ul class="popdesc_ul_list">
<li>Required photo size: 4.5 cm in height and 3.5 cm in width</li>
<li>Colored pictures are necessary. Not black and white.</li>
<li>Head position: keep your head straight and face forward</li>
<li>Must not be older than 6 months</li>
<li>Background: white</li>
<li>Smile: no</li>
<li>Eyes: clearly visible</li>
<li>Blurry photos are rejected</li>
<li>Glasses: acceptable under certain conditions</li>
<li>Headwear: allowed for religious purposes</li>
<li>Resolution (pixels): at least 600 dpi</li>
<li>Dress code: clothes must be in contrast with the background. Do not wear white clothes</li>
<li>Beard: permitted if it represents your everyday likeness</li>
</ul>
</div>
</template>
<script>
export default {
    name: 'Visa3',
    data(){
        return {
            anothernathos :0,
            countries_list: [],
            nationality_list:[],
            personal_photo_name:'',
            logo_image: (this.$store.state.baseURLuploadImage),
            main_logo_image: (this.$store.state.baseURLuploadImage),
            showpop_image:0,
            visa_guest_type:this.$store.state.visa_guest_type,
            form:{
                full_name:'', //done
                passport_number:'', //done
                passport_type:'Ordinary Passport', //done
                issuance_country:'',
                passport_issue_date:'',
                date_of_birth:'',
                nationality:'',
                place_of_birth:'',
                place_of_issue:'',
                previous_nationality:'',
                country_of_residence:'',
                personal_image:"",
                passport_expiry_date:'',
                religion:'muslim',
                gender:'male',
                country_comming_from:"",
            },
        }
    },
    created(){
        this.$store.state.showloader_registration = 1;
        this.axios.post(this.$store.state.baseURL+'getcountries', {
        }).then(response => {
            var objectrespons=response.data.data;
            objectrespons.forEach(element => {
                this.nationality_list.push(element.country_enNationality);
                this.countries_list.push(element.country_enName);
            });
            this.$store.state.showloader_registration = 0;
        })
        .catch(e => {
            console.log(e);
        })
        console.log("normal_guest" , this.$store.state.normal_guest);
        this.form.nationality=this.$store.state.normal_guest.nationality
        this.form.country_of_residence=this.$store.state.normal_guest.country_of_residence
        console.log(this.$store.state.normal_guest.country_of_residence);
        // this.form.country_of_residence=
        // console.log(this.$store.state.saved_guest_data);
        // console.log(this.$store.state.guest);
        // this.form.nationality=this.$store.state.guest.nationality;
        // this.form.issuance_country=this.$store.state.guest.nationality;
        // this.form.issuance_country=this.$store.state.guest.nationality;
        // this.form.country_of_residence=this.$store.state.guest.country_of_residence;
        // this.form.place_of_birth=this.$store.state.guest.country_of_residence;
        // this.form.place_of_issue=this.$store.state.guest.country_of_residence;
        // this.form.passport_number=this.$store.state.guest.id_iqama_passport;
        // this.form.gender=this.$store.state.guest.gender
    },
    methods:{
        uploadImage() {
            const uploadurl = this.$store.state.baseURLuploadImage + "upload_image.php";
            this.file = this.$refs.personal_image.files[0];
            $('.image_text').html(this.file.name)
            let formData = new FormData();
            var logoname = Date.now();
            var path_title="visa";
            logoname=path_title+"_"+logoname +""+Math.floor(Math.random() * 10000);
            formData.append('file', this.file);
            formData.append('image_name', logoname);
            formData.append('path', path_title);
            var self = this;
            this.axios.post(uploadurl, formData,{headers: {'Content-Type': 'multipart/form-data'}}).then(function (response) {
                if(response.data == 0){
                alert('File not uploaded.');
                console.log(response.data);
                }else{
                console.log(response.data);
                self.form.personal_image = response.data;
                self.logo_image = self.main_logo_image + "images/visa/" + response.data;
                // alert('File uploaded successfully.');
                }
            }).catch(function (error) {console.log(error);});
        },
        submit(){
            this.errors=0;
            this.$emit('submit', this.form);

            if(this.form.full_name == null ){
                $('.validatefullname').html('The full name  is required');
                $('.validatefullname').slideDown();
                this.errors=1;
                console.log('error here1');
            }else{
                $('.validatefullname').slideUp();
            }

            if(this.form.passport_number == "" || this.form.passport_number == null ){
                $('.validatePassportNumber').html('The passport number  is required');
                $('.validatePassportNumber').slideDown();
                this.errors=1;
                console.log('error here2');
            }else{
                $('.validatePassportNumber').slideUp();
            }
            if(this.form.passport_type == "" || this.form.passport_type == null ){
                $('.validatePassportType').html('The passport type  is required');
                $('.validatePassportType').slideDown();
                this.errors=1;
                console.log('error here3');
            }else{
                $('.validatePassportType').slideUp();
            }
            if(this.form.issuance_country == "" || this.form.issuance_country == null ){
                $('.validateIssuanceCountry').html('The passport issuance country is required');
                $('.validateIssuanceCountry').slideDown();
                this.errors=1;
                console.log('error here4');
            }else{
                $('.validateIssuanceCountry').slideUp();
            }
            if(this.form.passport_issue_date == "" || this.form.passport_issue_date == null ){
                $('.validateIssuancedate').html('The passport issuance date is required');
                $('.validateIssuancedate').slideDown();
                this.errors=1;
                console.log('error here5');
            }else{
                $('.validateIssuancedate').slideUp();
            }
            if(this.form.date_of_birth == "" || this.form.date_of_birth == null ){
                $('.validateDateOfdBirth').html('The date of birth is required');
                $('.validateDateOfdBirth').slideDown();
                this.errors=1;
                console.log('error here6');
            }else{
                $('.validateDateOfdBirth').slideUp();
            }
            if(this.form.nationality == "" || this.form.nationality == null ){
                $('.validateNation').html('The passport nationality is required');
                $('.validateNation').slideDown();
                this.errors=1;
                console.log('error here7');
            }else{
                $('.validateNation').slideUp();
            }
            if(this.form.place_of_birth == "" || this.form.place_of_birth == null ){
                $('.validatePlaceOfBirth').html('The passport place of birth is required');
                $('.validatePlaceOfBirth').slideDown();
                this.errors=1;
                console.log('error here8');
            }else{
                $('.validatePlaceOfBirth').slideUp();
            }
            // if(this.form.place_of_issue == "" || this.form.place_of_issue == null ){
            //     $('.validatePlaceOfIssue').html('The passport place of issue is required');
            //     $('.validatePlaceOfIssue').slideDown();
            //     this.errors=1;
            //     console.log('error here9');
            // }else{
            //     $('.validatePlaceOfIssue').slideUp();
            // }
            if(this.form.country_of_residence == "" || this.form.country_of_residence == null ){
                $('.validateCountryOfResidence').html('The passport counrty of residence is required');
                $('.validateCountryOfResidence').slideDown();
                this.errors=1;
                console.log('error here10');
            }else{
                $('.validateCountryOfResidence').slideUp();
            }
            if(this.form.passport_expiry_date == "" || this.form.passport_expiry_date == null ){
                $('.validatePassportExpiryDate').html('The passport expiry date is required');
                $('.validatePassportExpiryDate').slideDown();
                this.errors=1;
                console.log('error here11');
            }else{
                $('.validatePassportExpiryDate').slideUp();
            }
            if(this.form.religion == "" || this.form.religion == null ){
                $('.validateReligion').html('The passport religion is required');
                $('.validateReligion').slideDown();
                this.errors=1;
                console.log('error here12');
            }else{
                $('.validateReligion').slideUp();
            }
            if(this.form.gender == "" || this.form.gender == null ){
                $('.validateGender').html('The passport gender is required');
                $('.validateGender').slideDown();
                this.errors=1;
                console.log('error here13');
            }else{
                $('.validateGender').slideUp();
            }
            if(this.form.country_comming_from == "" || this.form.country_comming_from == null ){
                $('.validateCountryCommingFrom').html(this.$t('c_coming_from_is_required'));
                $('.validateCountryCommingFrom').slideDown();
                this.errors=1;
                console.log('error here13');
            }else{
                $('.validateCountryCommingFrom').slideUp();
            }
            if(this.$refs.personal_image.files.length == 0){
                $('.validatPassportPhoto').html(this.$t('personal_image_is_required'));
                $('.validatPassportPhoto').slideDown();
                this.errors=1;
            }else{
                $('.validatPassportPhoto').slideUp();
            }




            
            if(this.errors == 0){
                this.axios.post(this.$store.state.baseURL + "add-staff-visa-info", {
                    "user_id":this.$store.state.normal_guest.id,
                    "full_name": this.form.full_name,
                    "passport_number": this.form.passport_number,
                    "passport_type": this.form.passport_type,
                    "issuance_country": this.form.issuance_country,
                    "issue_date": this.form.passport_issue_date,
                    "date_of_birth": this.form.date_of_birth,
                    "place_of_birth": this.form.place_of_birth,
                    "place_of_issue": this.form.place_of_issue,
                    "expiry_date": this.form.passport_expiry_date,
                    "religion": this.form.religion,
                    "gender": this.form.gender,
                    "personal_photo": this.form.personal_image,
                    "country_of_residence": this.form.country_of_residence,
                    "nationality": this.form.nationality,
                    "current_nationality": this.form.nationality,
                    "previous_nationality": this.form.previous_nationality,
                    // "type": this.visa_guest_type,
                    "user_token_key":this.$store.state.normal_guest.token_key,
                    // "type_of_user":"visa_international",
                    "country_comming_from":this.form.country_comming_from,
                    "type_of_user":"guest",
                    "visa_type":"visa_international",
                    })
                    .then((response) => {
                    console.log(response);
                        this.$router.push({path: '/registration/guest-thankyou'});

                    })
                    .catch((e) => {
                    console.log(e);
                });
            }else{
                console.log('error 1');
            }
        }
    }
}
</script>
