import { createApp } from 'vue'
import App from './App.vue'
import 'maz-ui/css/main.css'
import './assets/css/bea.css'
import './assets/css/style.css'
import './assets/css/mdi.css'
import router from './router'
import store from './store'
import axios from 'axios'
import Mainlayout from "./layouts/Mainlayout.vue";
import EmptyLayout from "./layouts/EmptyLayout.vue";
import HomeLayout from "./layouts/HomeLayout.vue";
import HomeLayout2 from "./layouts/HomeLayout2.vue";
import FlightLayout from "./layouts/FlightLayout.vue";
import WebLayout from "./layouts/WebLayout.vue";
import VueAxios from 'vue-axios';
import VueI18n from "vue-i18n";
import i18n from './i18n';
import VueQRCodeComponent from 'vue-qrcode-component';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import Select2 from 'vue3-select2-component';
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

//import { library } from '@fortawesome/fontawesome-svg-core'
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//import { faUserHeadset } from '@fortawesome/pro-duotone-svg-icons';
//import { faStore } from '@fortawesome/pro-regular-svg-icons';
// import VueHtmlToPaper from 'vue-html-to-paper';
import { jsPDF } from "jspdf";

//library.add( faUsers , faUsersRays , faTrademark , faCity , faUserTie , faUserHeadset , faStore , faUsersBetweenLines , faFilter)

const app = createApp(App).use(store).use(router);
app.use(i18n);
app.use(jsPDF);

app.use(MazPhoneNumberInput);
app.component('mainlayout', Mainlayout);
app.component('HomeLayout', HomeLayout);
app.component('WebLayout', WebLayout);
app.component('FlightLayout', FlightLayout);
app.component('Select2', Select2);

app.component('qr-code', VueQRCodeComponent);
//app.component('font-awesome-icon', FontAwesomeIcon);

// Vue.component('font-awesome-icon', FontAwesomeIcon)
// app.use(VueHtmlToPaper, options);


app.use(VueAwesomePaginate)
// var x_tokern;
// async function getAPITOkEn(){
// //  axios.post('https://devbiban.com/oauth/token',
//   axios.post('https://bibanglobal.sa/oauth/token',
//   {
//       client_id:6,
//       client_secret:'7zlrxYQPQRfkuKsuNFJLS0KeuYhGZqnrY3DPOl9Q',
//       scopes:'',
//       grant_type:'client_credentials',
//   },
//   ).then((res) => {
//     x_token=res.data.data.access_token;
//     // return xaxzcxs;
//   }).catch((e) => {});
// }
//   function setAxios(){
//     axios.defaults.headers.common['Authorization'] = `Bearer ` + x_tokern,
//       app.use(VueAxios, axios)
//       app.mount('#app')
//   };
//   getAPITOkEn().then(
//     setAxios()
//   );

    // axios.defaults.headers.common['Authorization'] = `Bearer ` + "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2IiwianRpIjoiMGFkNjcyY2RiNzRiYjQwZTliNzdmNjZiYzg0OGZkYTEyMzI3ODNiNjlhZDEzOWVlMWY1MDgwYjJjZTlhMGI2MWU2NDZkM2VmODA5MTllNDQiLCJpYXQiOjE2NzQxMzg5NzAuNzk4NzMyOTk1OTg2OTM4NDc2NTYyNSwibmJmIjoxNjc0MTM4OTcwLjc5ODczODAwMjc3NzA5OTYwOTM3NSwiZXhwIjoxNzA1Njc0OTcwLjc0Nzk2Nzk1ODQ1MDMxNzM4MjgxMjUsInN1YiI6IiIsInNjb3BlcyI6W119.Kdk0_B9eNxMH36OGPX8QLujCrW-VG0SMIGT6PZ_5alkftlcfkhZydj-GaggOGZbc80uVhL7lR0Z54T13K_twuml7zMrLn5a0rzaIZC4uMINB5RrK4eYk1jpASYqehpuxnKBkkNv66UBw55WRrj9kiKuW5iCZC6QHTQuhVJncwIBFDZVVHthOfncJKflVfuigNtZSChnFHIcpNm6XTNRexfskKRPFA1cpYahxaRYszCwRosIOjRUo7kIzvUTASiXvyerQQDptZJ56T0Fd4zLHFC5tZ5TGm-Iac_kKBiSfqe9f59menAzlVLeKIMKEjYbE4k9PaSRrGCYw5gSSo7mfuxQyQHEUIwYppuLcWeC1Iy3FVrNUuFq7JWf5-lWl0kQcEnBIx6lu2lJIRIFQclKAhMSZ7ebIrjhOdd5TSnqtv_h5lPhw_1nmVH28rvr7D99g7WPRC1yBz5dl4BSWhcnqziVet42WsDYVNNDbS-8bEdc_EgKpj91VaM86CRUcksdwAm7wXDyzFvdAHRmwjmwinCsveFtWu27xwFwZg9GbDdSmCMwvi5vEUQJCK-abOIALrNfLEkFGq_j6bEPFuX6fR_xbFLx6Xrnt446-hsFp-boCx3Nuopi03YZj7YfO6d0Rk5n66say-cUMFy6q8YM8LTarRgXfKRTLHeZAwntcGaE";
    app.use(VueAxios, axios)
    // (async () => {
    //     const getAPITOkEnz = await getAPITOkEn(); //API promises
    //       axios.defaults.headers.common['Authorization'] = `Bearer ` + getAPITOkEnz;
    //       app.use(VueAxios, axios)
    //       app.mount('#app')
    // })();
    app.mount('#app')
