<template>

      <router-view ></router-view>

</template>

<script>
export default {
  name: "EmptyLayout",
  components: {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>
