<template>
  <div class="mt100 c mb50">
    <div class="pv20 g_nm round2x ph40 c w33 md_w60 m_w75 m_m_w100 bgw sh10">
      <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
      <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }} {{$t('as_vip')}}</div>
      <div class="mt10 l w100">
        <div class="bg-prograss-bar prograss-bar">
          <div class="prograss-bar ov ra w100 g_nm bg_orange"></div>
        </div>
        <div class="mt10 c-blue">{{ $t('step3') }}</div>
        <div class="mt10 b">{{ $t('companion') }} {{this.$store.state.vip_user.member_nb}}</div>
      </div>
      <form class="pv10" @submit.prevent="submit_form">
        <div class="mt10" v-for="(entry, index) in listofstaff" :key="index">
          <div v-if="entry.borni == 'new' || entry.borni == 'old' || entry.borni == 'rejected'" class="brdr fs0 p10 nw">
            <div class="g_nm vm w8 p20 brdr-blue round100" @click="show_member_btn(index)" style="min-width:40px"><span class="cc mdi mdi_show_team mdi-chevron-down c-blue fs20"></span></div>
            <div class="g_nm vm w80 l ph10 fs14">{{entry.first_name}}</div>
            <div class="g_nm vm">
              <span v-if="entry.security_status =='approved' " class="mdi mdi-check-bold fs20" style="color:green"></span>
              <span v-else-if="entry.security_status =='rejected' " class="mdi mdi-close-thick fs20" style="color:red"></span>
              <span v-else-if="entry.status == 'pending' || entry.borni == 'new' " class="mdi mdi-trash-can-outline c-orange fs20" @click='delete_staff_memeber(index)'></span>
              <span v-else-if="entry.status != 'pending'" class="mdi mdi-progress-helper c-orange fs20"></span>

            </div>
            <!-- <div class="g_nm vm"><span class="mdi mdi-trash-can-outline c-orange fs20" @click='delete_staff_memeber(index)'></span></div> -->
            <div class="l fs14 team_div h">
              <div class="b mt10">{{ $t('name')}}</div>
              <div class=""> {{entry.first_name}}</div>
              <div class="b mt10">{{ $t('email')}}</div>
              <div class="">{{entry.email}}</div>
            </div>
          </div>
        </div>
        <div  class="mt10">
          <div v-if="can_add_member" class="brdr p10">
            <div class="l">
              <div class="g_nm vm p20 round100 bg_orange pointer" @click="add_memebr_div=true">
                <span class="cc mdi mdi-plus add-btn fs20 cw"></span>
              </div>
              <div class="g_nm ph10 vm">{{ $t('add_new_companion') }}</div>
            </div>
            <div v-if="add_memebr_div" class="l">
              <!-- START NAME -->
              <div class="mt15">
                <label class="label_fields">{{ $t('name') }} *</label>
                <input class="input_fields" v-bind:placeholder="$t('name')" v-model="member_name" type="text" />
                  <div class="validate_name validation_warning"></div>
                <!-- <div class="validate_name validation_warning">Name is required</div> -->
              </div>
              <!-- CLOSE NAME -->
              <!-- START EMAIL -->
              <div class="mt15">
                <label class="label_fields">{{ $t('email') }} *</label>
                <input class="input_fields" v-bind:placeholder="$t('email')" v-model="member_email" type="text" />
                <div class="validate_email validation_warning"></div>
                <div class="validate_duplicated_email validation_warning"></div>

                <!-- <div class="validate_name validation_warning">Name is required</div> -->
              </div>
              <!-- CLOSE EMAIL -->
              <!-- START JOB -->
              <button class="next_btn mt10 pv5 ph10 cw" @click="add_new_staff_memeber()">{{ $t('add') }}</button>
              <button class="next_btn ml20 mt10 pv5 ph10 cw" @click="add_memebr_div=false">{{ $t('cancel') }}</button>
            </div>
          </div>
          <div class="mt10 validate_numbers validation_warning"></div>
          <div class="mt20 r">
            <!-- <button @click="this.$router.push({path:'/registration/step1' , query: {t: this.$store.state.exh_token_key} })" class="vm prev_btn ph20">
              <span v-if="$i18n.locale == 'ar'" class="mdi mdi-arrow-right fs30 lh40"></span>
              <span v-if="$i18n.locale == 'en'" class="mdi mdi-arrow-left fs30 lh40"></span>
            </button> -->
            <button  @click="submit" class="next_btn cw ph20 vm  ml20">
              <span v-if="!disabled_submit" class="cw lh40 fs18" >{{$t('next')}}</span>
              <div v-if="disabled_submit" class="mt10 mb10 ml5 mr5 rotatingLoader" ></div>
              <!-- <div v-if="!disabled_submit"  class="pv10  cw">{{$t('registrate_now')}}</div> -->
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "Step3View",
  data() {
    return {
      disabled_submit:false,
      can_add_member:true,
      add_memebr_div: false,
      listofstaff: [],
      member_name: "",
      member_email: "",
      showtable_member_staff: 0,
      buttonsubmitflag: 0,
      counter:0,
    };
  },

  created() {
    this.$store.state.loader=0;
    this.axios.post(this.$store.state.baseURL + 'get-vip-staff-info', {
      'token_key': this.$store.state.vip_user_token
    })
    .then(response => {
      var objectresponsstuff = response.data.data[0].vipteams;
      objectresponsstuff.forEach(object => {
        if(object.security_status == "rejected"){
            object.borni="rejected";
            this.listofstaff.push(object);
          }
          else{
            object.borni="old";
            this.listofstaff.push(object);
          }
      });
      if ((this.listofstaff).length > 0) {
        this.showtable_member_staff = 1;
        this.buttonsubmitflag = 1;
        this.checklimit();
      }
      this.$store.state.loader=0;
      if(this.$store.state.is_view_only == 1){
        this.view_only_all=1;
      }
    })
    .catch(e => {})
  },

  methods: {
    show_member_btn(x) {
      $(".team_div:eq(" + x + ")").toggleClass('h');
      if ($(".mdi_show_team:eq(" + x + ")").hasClass('mdi-chevron-up')) {
        $(".mdi_show_team:eq(" + x + ")").removeClass('mdi-chevron-up')
      } else {
        $(".mdi_show_team:eq(" + x + ")").addClass('mdi-chevron-up')
      }
    },
    add_new_staff_memeber() {
      var xxxxvalidation = 0;
      this.counter=0;
      if ((this.member_name == "" || this.member_name == null || this.member_name == 0)) {
        $('.validate_name').html(this.$t('filed_is_required'));
        xxxxvalidation = 1;
        $('.validate_name').slideDown();
      }
      else {
        $('.validate_name').slideUp();
      }

      if ((this.member_email == "" || this.member_email == null || this.member_email == 0)) {
        $('.validate_email').html(this.$t('filed_is_required'));
        $('.validate_email').slideDown();
          xxxxvalidation = 1;
      }
      else {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(this.member_email)) {
          $('.validate_email').slideUp();
          var result = this.listofstaff.filter(x => (x.email).toLowerCase() === (this.member_email).toLowerCase() );
          if( result === undefined || result.length == 0){
            $('.validate_email').slideUp();
          }
          else{
            //   $('.validate_email').html(this.$t('duplicated_email'));
            //   xxxxvalidation = 1;
            // $('.validate_email').slideDown();
          }
        } else {
          $('.validate_email').html(this.$t('invalid_email'));
          xxxxvalidation = 1;
          $('.validate_email').slideDown();
        }
      }

      if (xxxxvalidation == 0) {
        this.showtable_member_staff = 1;
        this.listofstaff.push({
          first_name: this.member_name,
          email: this.member_email,
          borni:"new",
        });
        this.buttonsubmitflag = 1;
        this.member_name = "";
        this.member_email = "";
        this.add_memebr_div = false;
        this.checklimit();
      }
    },
    delete_staff_memeber(index) {
      var result = this.listofstaff[index];
      if(result.borni == 'new'){
        this.listofstaff.splice(index, 1);
      }
      else{
        this.listofstaff[index].borni="delete";
      }
      this.checklimit();
    },
    checklimit(){
      this.counter=0;
      this.listofstaff.forEach(object => {
        if(object.borni != "delete"){
          this.counter++;
        }
      });
      if(this.counter < this.$store.state.vip_user.member_nb){
        this.can_add_member=true;
      }
      else{
        this.can_add_member=false;
      }
    },
    submit() {
      if(this.disabled_submit == false){
        this.errors=0;
        $('.next_btn').addClass('disabled_btn');
        this.disabled_submit=true;
        this.axios.post(this.$store.state.baseURL + "add-vip-staff", {
          arraylist: this.listofstaff,
          vip_id: this.$store.state.vip_user.id,
        }).then((response) => {
          $('.next_btn').removeClass('disabled_btn');
          this.disabled_submit=false;
          var res = this.$store.state.no_visa_countries.indexOf(this.$store.state.vip_user.nationality);
          console.log(response);
          if(response.data.success == false){
            if(response.data.message == "duplicated email"){
              $('.validate_numbers').html(this.$t('duplicated_email') +' '+ response.data.email);
              $('.validate_numbers').slideDown();
                this.disabled_submit=false;
                  $('.next_btn').removeClass('disabled_btn');
            }
          }else{
            console.log(this.$store.state.vip_user.country_of_residence +" , "+  res)
            if( this.$store.state.vip_user.country_of_residence == 194 || res != -1){
              this.$router.push({path: '/registration/thankyou'});
            }else{
              if(this.$store.state.vip_user.status == "new" || this.$store.state.vip_user.status == "pending"){
                this.$router.push({ path: "/registration/visa/step1" });
              }else{
                this.$router.push({path: '/registration/thankyou'});
              }
            }
          }

        }).catch((e) => {});
      }
    },
  },
};
</script>
