<template>
    <div class="mt100 c mb50">
        <div class="pv20 g_nm round2x ph40 c w80 md_w100 bgw sh10">
            <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
            <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }}</div>
            <div class="mt10 l w100">
                <div class="c">
                    <div class="bg-prograss-bar g w50 m_w100 prograss-bar">
                        <div class="prograss-bar ov ra w100 g_nm bg_orange"></div>
                    </div>
                </div>
                <div class="mt10 b">{{ $t('visa_requirement') }}</div>
                <form class="pv10" @submit.prevent="submit_form">
                  <div class="label_fields">Name in English</div>
                  <tbl>
                    <cl class="vt w25">
                      <div class="mt8">
                        <input class="input_fields" placeholder="First name" v-model="form.first_name" type="text" />
                        <div class="validatefirst_name validation_warning"></div>
                      </div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w25">
                      <div class="mt8">
                        <input class="input_fields" placeholder="Father name" v-model="form.father_name" type="text" />
                        <div class="validatefather_name validation_warning"></div>
                      </div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w25">
                      <div class="mt8">
                        <input class="input_fields" placeholder="Grand father name" v-model="form.grand_father_name" type="text" />
                        <div class="validategrand_father_name validation_warning"></div>
                      </div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w25">
                      <div class="mt8">
                        <input class="input_fields" placeholder="Last Name" v-model="form.last_name" type="text" />
                        <div class="validatelast_name validation_warning"></div>
                      </div>
                    </cl>
                  </tbl>
                  <div class="mt15"></div>
                  <div class="label_fields r">الإسم</div>
                  <tbl class="rtl r">
                    <cl class="vt w25">
                      <div class="">
                        <input class="input_fields" placeholder="الإسم الأول" v-model="form.ar_first_name" type="text" />
                        <div class="validatearfirst_name validation_warning"></div>
                      </div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w25">
                      <div class="">
                        <input class="input_fields" placeholder="الأب" v-model="form.ar_father_name" type="text" />
                        <div class="validatearfather_name validation_warning"></div>
                      </div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w25">
                      <div class="">
                        <input class="input_fields" placeholder="الجد" v-model="form.ar_grand_father_name" type="text" />
                        <div class="validateargrand_father_name validation_warning"></div>
                      </div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w25">
                      <div class="">
                        <input class="input_fields" placeholder="إسم العائلة" v-model="form.ar_last_name" type="text" />
                        <div class="validatearlast_name validation_warning"></div>
                      </div>
                    </cl>
                  </tbl>
                  <!-- START image -->
                  <div class="mt15 w50 md_w66 m_w80 m_m_w100">
                    <tbl_nm>
                      <cl class="w10">
                        <div class="bg-blue p25 round100" style="min-width:30px;">
                          <span v-if="form.personal_image == '' || form.personal_image == null " class="cc mdi mdi-image-outline fs30 cw"></span>
                          <img v-else :src='logo_image' class="cc round100 fs30 cw imageforupload" style="width:50px; height:50px;" />

                        </div>
                      </cl>
                      <cl class="colSep"></cl>
                      <cl class="wa" style="max-width:250px;">
                        <label class="label_fields">{{ $t('personal_photo') }}</label>
                        <label class="pointer">
                          <input type="file" ref="personal_image" id="personal_image" @change="uploadImage()" accept="image/*" hidden />
                          <div class="fs12 nw oe image_text">
                            {{ $t('image_text') }}
                          </div>
                        </label>
                        <div class="fs12 nw oe">
                          {{ $t('image_condition') }}
                        </div>
                      </cl>
                      <cl class="colSep"></cl>
                      <cl class="w5 r" style="min-width:25px;">
                        <span class="mdi mdi-image-multiple-outline c-blue fs20"></span>
                          <span class="mdi mdi-information-outline c-blue fs20" @click="showpop_image = 1"></span>
                      </cl>
                    </tbl_nm>
                    <div class="validatPassportPhoto validation_warning"></div>
                  </div>
                  <!-- CLOSE image -->
                  <tbl class="mt15">
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('recant_nationality')}}</label>
                      <Select2 disabled v-model="form.current_nationality" :options="nationality_list"/>
                      <!-- <select class="input_fields select_input" v-model="form.current_nationality">
                        <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enNationality">{{country_item.country_enNationality}}</option>
                      </select> -->
                      <div class="validateNation validation_warning"></div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w50" v-if="anothernathos == 0">
                    <label class="label_fields">{{$t('have_another_nationality')}}</label>
                    <select class="input_fields select_input" v-model="anothernathos">
                      <option value="0" selected>{{$t('no')}}</option>
                      <option value="1">{{$t('yes')}}</option>
                    </select>
                    <div class="validatepreNation validation_warning"></div>
                    </cl>
                    <cl class="vt w50" v-if="anothernathos == 1">
                      <label class="label_fields">{{$t('prev_nationality')}}</label>
                      <Select2 v-model="form.previous_nationality" :options="nationality_list"/>
                      <!-- <select class="input_fields select_input" v-model="form.previous_nationality">
                        <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enNationality">{{country_item.country_enNationality}}</option>
                      </select> -->
                      <div class="validatepreNation validation_warning"></div>
                    </cl>
                  </tbl>
                  <tbl class="mt15">
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('passport_number')}}</label>
                      <input class="input_fields" id="passport_number" placeholder="Passport number" v-model="form.passport_number" type="text" />
                      <div class="validatePassportNumber validation_warning"></div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('passport_type')}}</label>
                      <select class="input_fields select_input" v-model="form.passport_type">
                        <option value="Ordinary Passport" selected>Ordinary Passport</option>
                        <option value="Official Passport">Official Passport</option>
                        <option value="Diplomatic Passport">Diplomatic Passport</option>
                        <option value="Emergency Travel Document">Emergency Travel Document</option>
                      </select>
                      <div class="validatePassportType validation_warning"></div>
                    </cl>
                  </tbl>

                  <tbl class="mt15">
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('issuance_country')}}</label>
                      <Select2 :disabled="view_only_all == 1" v-model="form.issuance_country" :options="countries_list"/>
                      <!-- <select class="input_fields select_input" v-model="form.issuance_country">
                        <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enName">{{country_item.country_enName}}</option>
                      </select> -->
                      <div class="validateIssuanceCountry validation_warning"></div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('pass_issue_date')}}</label>
                      <input class="input_fields select_input" placeholder="" v-model="form.passport_issue_date" type="date" />
                      <div class="validateIssuancedate validation_warning"></div>
                    </cl>
                  </tbl>
                  <tbl class="mt15">
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('pass_expire_date')}}</label>
                      <input class="input_fields" placeholder="" v-model="form.passport_expiry_date" type="date" />
                      <div class="validatePassportExpiryDate validation_warning"></div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('place_of_birth')}}</label>
                      <Select2 :disabled="view_only_all == 1" v-model="form.place_of_birth" :options="countries_list"/>
                      <!-- <select class="input_fields select_input" v-model="form.place_of_birth">
                        <option v-for="country_item in countries_list" :key="country_item.id" :value="country_item.country_enName">{{country_item.country_enName}}</option>
                      </select> -->
                      <div class="validatePlaceOfBirth validation_warning"></div>
                    </cl>
                  </tbl>
                  <tbl class="mt15">
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('social_situation')}}</label>
                      <select class="input_fields select_input" v-model="form.social_situation">
                        <option value="single" selected>{{$t('single')}}</option>
                        <option value="married">{{$t('married')}}</option>
                        <option value="divorced">{{$t('divorced')}}</option>
                      </select>
                      <div class="validatesocial_situation validation_warning"></div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('country_comming_from')}}</label>
                      <Select2 :disabled="view_only_all == 1" v-model="form.country_comming_from" :options="countries_list"/>
                      <div class="validateCountryCommingFrom validation_warning"></div>
                    </cl>
                  </tbl>


                  <tbl class="mt15">
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('job')}}</label>
                      <input class="input_fields" v-model="form.job" type="text" />
                      <div class="validatear_job validation_warning"></div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('address')}}</label>
                      <input class="input_fields" v-model="form.address" type="text" />
                      <div class="validateaddress validation_warning"></div>
                    </cl>
                  </tbl>

                  <tbl class="mt15">
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('expected_entry_date')}}</label>
                      <input class="input_fields" placeholder="" v-model="form.expected_entry_date" type="date" />
                      <div class="validateexpected_entry_date validation_warning"></div>
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('contact_point')}}</label>
                      <input class="input_fields" v-model="form.contact_point" type="text" />
                      <div class="validatecontactpoint validation_warning"></div>
                    </cl>
                  </tbl>
                  <tbl class="mt15">
                    <cl class="vt w50">
                      <label class="label_fields">{{$t('gender')}}</label>
                      <br>
                      <input type="radio" class="mt8 ml10 mr10" v-model="form.gender" checked value="male">Male
                      <input type="radio" class="mt8 ml10 mr10" v-model="form.gender" value="female">Female
                    </cl>
                    <cl class="colSep pv10"></cl>
                    <cl class="w50 vt"></cl>

                  </tbl>
  <div class="fs12 c mt20">{{ $t('privacy_inforamtion') }}</div>

                  <div class="mt20 r">
                    <button @click="submit" class="next_btn ph20 pv15 cw">{{$t('registrate_now')}}</button>
                  </div>
                </form>
            </div>
        </div>
    </div>
    <div class="popup_descimage" v-if="showpop_image == 1">
      <div class="popupdesc_img_close" @click="showpop_image = 0">close</div>
    <h2>Photo specs</h2>
    <p>The specifications for a Saudi Arabia passport photo are as follows:</p>
    <ul class="popdesc_ul_list">
    <li>Required photo size: 4.5 cm in height and 3.5 cm in width</li>
    <li>Colored pictures are necessary. Not black and white.</li>
    <li>Head position: keep your head straight and face forward</li>
    <li>Must not be older than 6 months</li>
    <li>Background: white</li>
    <li>Smile: no</li>
    <li>Eyes: clearly visible</li>
    <li>Blurry photos are rejected</li>
    <li>Glasses: acceptable under certain conditions</li>
    <li>Headwear: allowed for religious purposes</li>
    <li>Resolution (pixels): at least 600 dpi</li>
    <li>Dress code: clothes must be in contrast with the background. Do not wear white clothes</li>
    <li>Beard: permitted if it represents your everyday likeness</li>
    </ul>
    </div>
</template>
<script>
export default {
    name: 'Visa3',
    data(){
        return {
            countries_list: [],
            nationality_list:[],
            personal_photo_name:'',
            logo_image: (this.$store.state.baseURLuploadImage),
            main_logo_image: (this.$store.state.baseURLuploadImage),
            visa_guest_type: this.$store.state.visa_guest_type,
            anothernathos:0,
            showpop_image:0,
            form:{
              first_name:"",
              father_name:"",
              grand_father_name:"",
              last_name:"",
              ar_first_name:"",
              ar_father_name:"",
              ar_grand_father_name:"",
              ar_last_name:"",
              personal_image:"",
              current_nationality:"",
              previous_nationality:"",
              passport_number:"",
              passport_type:"",
              issuance_country:"",
              passport_issue_date:"",
              passport_expiry_date:"",
              country_comming_from:"",
              place_of_birth:"",
              date_of_birth:"",
              social_situation:"",
              gender:"",
              job:"",
              address:"",
              contact_point:"",
              expected_entry_date:"",
              entry_number:"90",
              length_of_stay:"90",
              umrah:"",
              past_travel:"",
            },
        }
    },
    created(){
        this.$store.state.showloader_registration = 1;
        this.axios.post(this.$store.state.baseURL+'getcountries', {
        }).then(response => {
            var objectrespons=response.data.data;
            objectrespons.forEach(element => {
                this.nationality_list.push(element.country_enNationality);
                this.countries_list.push(element.country_enName);
            });
        })
        .catch(e => {
            console.log(e);
        })
        this.form.current_nationality=this.$store.state.guest.nationality

    },
    methods:{
      uploadImage() {
        const uploadurl = this.$store.state.baseURLuploadImage + "upload_image.php";
        this.file = this.$refs.personal_image.files[0];
        $('.image_text').html(this.file.name)
         let formData = new FormData();
         var logoname = Date.now();
         var path_title="visa";
         logoname=path_title+"_"+logoname +""+Math.floor(Math.random() * 10000);
         formData.append('file', this.file);
         formData.append('image_name', logoname);
         formData.append('path', path_title);
         var self = this;
         this.axios.post(uploadurl, formData,{headers: {'Content-Type': 'multipart/form-data'}}).then(function (response) {
            if(response.data == 0){
               alert('File not uploaded.');
               $(".validatPassportPhoto").html("Problem with uploading the Image");
               $(".validatPassportPhoto").slideDown();
                 self.errors = 1;
             }else if(response.data == 1){
               $(".validatPassportPhoto").html("Image size exceeds 4MB");
               $(".validatPassportPhoto").slideDown();
                 self.errors = 1;
             }else if(response.data == 2){
               $(".validatPassportPhoto").html("Upload valid images Only");
               $(".validatPassportPhoto").slideDown();
                 self.errors = 1;
             }else{
                 self.errors = 0;
                   $(".validatPassportPhoto").slideUp();
              self.form.personal_image = response.data;
              self.logo_image = self.main_logo_image + "images/visa/" + response.data;
              // alert('File uploaded successfully.');
            }
         }).catch(function (error) {console.log(error);});
      },
      validatedata(formname,validatefieldclass) {
        if (formname == "" || formname == null || formname == 0) {
          $("."+validatefieldclass).html("Field is required");
          $("."+validatefieldclass).slideDown();
          this.errors = 1;
        } else {
          $("."+validatefieldclass).slideUp();
        }
      },
        submit(){

            this.errors=0;
            this.$emit('submit', this.form);
            this.validatedata(this.form.first_name,"validatefirst_name");
            this.validatedata(this.form.father_name,"validatefather_name");
            this.validatedata(this.form.grand_father_name,"validategrand_father_name");
            this.validatedata(this.form.last_name,"validatelast_name");
            this.validatedata(this.form.ar_first_name,"validatearfirst_name");
            this.validatedata(this.form.ar_father_name,"validatearfather_name");
            this.validatedata(this.form.ar_grand_father_name,"validateargrand_father_name");
            this.validatedata(this.form.ar_last_name,"validatearlast_name");
            this.validatedata(this.form.current_nationality,"validateNation");
            // this.validatedata(this.form.previous_nationality,"validatepreNation");
            this.validatedata(this.form.passport_number,"validatePassportNumber");
            this.validatedata(this.form.passport_type,"validatePassportType");
            this.validatedata(this.form.issuance_country,"validateIssuanceCountry");
            this.validatedata(this.form.passport_issue_date,"validateIssuancedate");
            this.validatedata(this.form.passport_expiry_date,"validatePassportExpiryDate");
            this.validatedata(this.form.place_of_birth,"validatePlaceOfBirth");
            this.validatedata(this.form.social_situation,"validatesocial_situation");
            this.validatedata(this.form.job,"validatear_job");
            this.validatedata(this.form.address,"validateaddress");
            this.validatedata(this.form.expected_entry_date,"validateexpected_entry_date");
            this.validatedata(this.form.entry_number,"validateentry_number");
            this.validatedata(this.form.length_of_stay,"validatelenghtstay");
            this.validatedata(this.form.contact_point,"validatecontactpoint");
            this.validatedata(this.form.country_comming_from,"validateCountryCommingFrom");
            if(this.$refs.personal_image.files.length == 0){
              $('.validatPassportPhoto').html(this.$t('personal_image_is_required'));
              $('.validatPassportPhoto').slideDown();
              this.errors=1;
            }else{
              $('.validatPassportPhoto').slideUp();
            }

            if(this.errors ==0){
                this.axios.post(this.$store.state.baseURL + "add-staff-visa-info", {
                    "user_id":this.$store.state.guest.id,
                    "first_name": this.form.first_name,
                    "father_name": this.form.father_name,
                    "grand_father_name": this.form.grand_father_name,
                    "last_name": this.form.last_name,
                    "ar_first_name": this.form.ar_first_name,
                    "ar_father_name": this.form.ar_father_name,
                    "ar_grand_father_name": this.form.ar_grand_father_name,
                    "ar_last_name": this.form.ar_last_name,
                    "personal_photo": this.form.personal_image,
                    "nationality": this.form.current_nationality,
                    "current_nationality": this.form.current_nationality,
                    "previous_nationality": this.form.previous_nationality,
                    "passport_number": this.form.passport_number,
                    "passport_type": this.form.passport_type,
                    "issuance_country": this.form.issuance_country,
                    "issue_date": this.form.passport_issue_date,
                    "date_of_birth": this.form.date_of_birth,
                    "place_of_birth": this.form.place_of_birth,
                    "social_situation": this.form.social_situation,
                    "job": this.form.job,
                    "expiry_date": this.form.passport_expiry_date,
                    "contact_point": this.form.contact_point,
                    "gender": this.form.gender,
                    "residence_saudi_address": this.form.address,
                    "expected_entry_date": this.form.expected_entry_date,
                    "entry_number": this.form.entry_number,
                    "length_of_stay": this.form.length_of_stay,
                    "umrah": this.form.umrah,
                    "past_travel": this.form.past_travel,
                    "country_coming_from":this.form.country_comming_from,

                    "visa_type":"visa_gcc",
                    // "type": this.visa_guest_type,
                    "type_of_user":'exh_staff',

                      "user_token_key":this.$store.state.exh_guest_token_key
                    })
                    .then((response) => {
                    console.log(response);
                    console.log("visa data sent");
                        this.$router.push({ path: "/registration/thankyou" });
                    })
                    .catch((e) => {
                    console.log(e);
                });
            }else{
                console.log('error 1');
            }
        }
    }
}
</script>
