<template>
  <div class="mt100 c mb100" v-if="token_is_valide == 1">
    <div class="pv20 g_nm round2x ph40 c w40 md_w60 m_w75 m_m_w100 bgw sh10">
    <img class="g_nm" src="@/assets/img/biban-logo.png"   alt="profile_image" />
      <div class="mt10 c-blue">{{ $t('welcome_to_registrate') }} {{$t('as_media')}}</div>
      <div class="mt10 l w100">
        <div class="bg-prograss-bar prograss-bar">
          <div class="prograss-bar ov ra w50 g_nm bg_orange"></div>
        </div>
        <div class="mt10 c-blue">{{ $t('step1') }}</div>
        <div class="mt10 b">{{ $t('basic_registration') }}</div>
        <form class="pv10" @submit.prevent="submit_form">
          <!-- START LEAGL NAME -->
            <div class="mt15">
              <label class="label_fields">{{ $t('legal_name') }} *</label>
              <input :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="input_fields" v-model="form.legal_name" v-bind:placeholder="$t('legal_name')" type="text"/>
              <div class="validation_legal_name validation_warning"></div>
            </div>
          <!-- CLOSE LEAGL NAME -->
          <!-- START CONTACT NAME -->
            <div class="mt15">
              <label class="label_fields">{{ $t('contact_name') }} *</label>
              <input  :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" class="input_fields" v-model="form.contactname" v-bind:placeholder="$t('contact_name')" type="text"/>
              <div class="validation_contactname validation_warning"></div>
            </div>
          <!-- CLOSE CONTACT NAME -->
          <div class="mt15">
            <label class="label_fields mb8">{{ $t('phone') }} *</label>
            <div class="pv4"></div>
              <MazPhoneNumberInput :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" v-model="form.phonenumber" fetch-country="true" :default-phone-number="form.phonenumber" show-code-on-list :preferred-countries="['SA']" :ignored-countries="['IL']" @update="results = $event" :success="results?.isValid" style="direction:ltr !important;"/>
              <!-- <input :disabled="view_only_all == 1" class="input_fields" type="text" id="mobile_code"  v-bind:placeholder="$t('phone')" v-model="form.phonenumber" /> -->
            <div class="validate_phone validation_warning"></div>
          </div>
          <div class="mt15">
            <label class="label_fields">{{ $t('headquarter_country') }} *</label>
            <Select2 :disabled="view_only_all == 1 && this.$store.state.come_form_geenrated_link==0" v-model="form.nationality" :options="countries_list" />
            <!-- <select :disabled="view_only_all == 1" class="input_fields select_input" v-model="form.country">
              <option value="" selected disabled>{{ $t('select_nationality') }}</option>
              <option  v-for="country_item in countries_list" :key="country_item.country_enName" :value="country_item.country_enName">
                {{ country_item.country_enName }}
              </option>
            </select> -->
            <div class="validatecountry validation_warning"></div>
          </div>
          <!-- START EMAIL -->
          <div class="mt15">
              <label class="label_fields">{{ $t('email') }} *</label>
              <input :disabled="this.$store.state.come_form_geenrated_link==0" class="input_fields" v-model="form.emailaddress" v-bind:placeholder="$t('email')" type="email"/>
              <div class="validation_email validation_warning"></div>
          </div>
          <!-- CLOSE EMAIL -->
          <div class="mt20 r">
              <button @click="submit" class="next_btn ph20">
                <div v-if="disabled_submit" class="mt10 mb10 ml5 mr5 rotatingLoader" ></div>
                <span v-if="!disabled_submit" class="cw lh40 fs18" >{{$t('next')}}</span>
                <!-- <span v-if="$i18n.locale == 'en' && !disabled_submit" class="cw mdi mdi-arrow-right lh40 fs30"></span>
                <span v-if="$i18n.locale == 'ar' && !disabled_submit" class="cw mdi mdi-arrow-left lh40 fs30"></span> -->
              </button>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import { ref } from 'vue';
const results = ref();

export default {
  name: 'step1media',
  components: { MazPhoneNumberInput },
  data() {
    return {
      ar_countries_list:[],
      en_countries_list:[],
      token_is_valide: 1,
      errors:0,
      disabled_submit:false,
      view_only_all: 0,
      form: {
        legal_name: "",
        contactname: "",
        phonenumber: "",
        nationality: "",
        emailaddress: "",
        confirmemailaddress: ""
      }
    }
  },
  created() {
    this.$store.state.loader = 1;
    var tokennuser = this.$route.query.t;
    if (tokennuser == "" || tokennuser == null) {
      tokennuser = this.$store.state.media_user_token;
    }
    var defaultlanguage = this.$route.query.lang;
    if (defaultlanguage == "" || defaultlanguage == null) {
    }else{
      if(defaultlanguage == "ar" || defaultlanguage == "en"){
        this.$i18n.locale = defaultlanguage;
        if(defaultlanguage == 'ar'){
          $('head').append('<link rel="stylesheet" href="/rtl_style.css" class="ar_style">');
        }
        else{
          $('.ar_style').remove();
        }
      }
    }
    this.$store.state.media_user_token = tokennuser;
    this.axios.post(this.$store.state.baseURL + "getcountries", {}).then((response) => {
      var objectrespons = response.data.data;
      objectrespons.forEach(element => {
        var ar_y ={
          id: element.id,
          text: element.country_arName
        }
        var en_y ={
          id: element.id,
          text: element.country_enName
        }
        this.ar_countries_list.push(ar_y);
        this.en_countries_list.push(en_y);
      });
    }).catch((e) => {
      console.log(e);
    });
    this.axios.post(this.$store.state.baseURL + 'getmedia', {
        'token_key': tokennuser
      }).then(response => {
        if(response.data.data == null){
          this.token_is_valide=0;
          this.$store.state.token_is_valide=0;
          this.$store.state.loader = 0;
          this.$router.push({ path: '/registration/invalid-token' });
        }
        var objectrespons = response.data.data;
        console.log(objectrespons);
        this.form.legal_name = objectrespons.legal_name;
        this.form.contactname = objectrespons.owner_name;
        this.form.phonenumber = objectrespons.phone;
        this.form.nationality = objectrespons.nationality;
        this.form.emailaddress = objectrespons.owner_email;
        this.form.confirmemailaddress = objectrespons.owner_email;
        this.$store.state.media_user = objectrespons;
        if(objectrespons.status == "pending" || objectrespons.status == "new"){
            this.view_only_all=0;
            this.$store.state.is_view_only=0;
          }
          else{
            this.view_only_all=1;
            this.$store.state.is_view_only=1;
          }
          this.$store.state.loader = 0;
        if(objectrespons.member_nb != null &&  objectrespons.member_nb != "" && objectrespons.member_nb != undefined){
          this.$store.state.media_user.member_nb = objectrespons.member_nb
        }
        else{
          this.$store.state.media_user.member_nb = 40;
        }
      }).catch(e => {
        this.$store.state.loader=0;
        this.$router.push({ path: '/registration/failed' });
        console.log(e);
      })

  },
  computed:{
    countries_list(){
      if(this.$i18n.locale == 'ar'){
          return this.ar_countries_list
      }
      return this.en_countries_list
    },
  },
  methods: {
    submit() {
      $('.next_btn').addClass('disabled_btn');
      this.disabled_submit=true;
      this.$emit('submit', this.form);
      this.errors = 0;
      if (this.form.legal_name == "" || this.form.legal_name == null || this.form.legal_name == 0) {
          $(".validation_legal_name").html(this.$t('legal_name_is_required'));
          $(".validation_legal_name").slideDown();
          this.errors = 1;
      } else {
          $(".validation_legal_name").slideUp();
      }
      if (this.form.contactname == "" || this.form.contactname == null || this.form.contactname == 0) {
          $(".validation_contactname").html(this.$t('contactname_is_required'));
          $(".validation_contactname").slideDown();
          this.errors = 1;
      } else {
          $(".validation_contactname").slideUp();
      }
      if (this.form.phonenumber == "" || this.form.phonenumber == null || this.form.phonenumber == 0) {
        $(".validate_phone").html(this.$t('phone_required'));
        $(".validate_phone").slideDown();
        this.errors = 1;
      } else {
        if( !this.results.isValid){
          this.errors = 1;
          $(".validate_phone").html(this.$t('phone_not_valid'));
          $(".validate_phone").slideDown();
        }
        else{
          $(".validate_phone").slideUp();
        }
      }
      if (this.form.nationality == "" || this.form.nationality == null || this.form.nationality == 0) {
        this.errors = 1;
        $(".validatecountry").html(this.$t('headquarter_is_required'));
        $(".validatecountry").slideDown();
      } else {
        $(".validatecountry").slideUp();
      }
      if (this.form.emailaddress == "" || this.form.emailaddress == null || this.form.emailaddress == 0) {
        this.errors = 1;
        $(".validation_email").html(this.$t('email_is_required'));
        $(".validation_email").slideDown();
      } else {
        $(".validation_email").slideUp();
      }
      if(this.errors == 0){
        this.$store.state.showloader_registration = 1;
        this.$store.state.media_user.legal_name = this.form.legal_name;
        this.$store.state.media_user.owner_name = this.form.contactname;
        this.$store.state.media_user.phone = this.form.phonenumber;
        this.$store.state.media_user.nationality = this.form.nationality;
        this.$store.state.media_user.owner_email = this.form.emailaddress;
        if (this.$store.state.is_view_only == 1) {
          $('.next_btn').addClass('disabled_btn');
              this.disabled_submit=false;
          this.$router.push({
            path: '/registration/media/step2'
          });
        }else{
        this.axios.post(this.$store.state.baseURL + "updatemedia", {
            user_id: this.$store.state.media_user.user_id,
            legal_name: this.$store.state.media_user.legal_name,
            owner_name: this.$store.state.media_user.owner_name,
            owner_email: this.$store.state.media_user.owner_email,
            nationality: this.$store.state.media_user.nationality,
            phone: this.$store.state.media_user.phone,
            token_key: this.$store.state.media_user.token_key,
            status:"",
            form_status:1,
          })
          .then((response) => {
            this.$store.state.showloader_registration = 0;
            $('.next_btn').removeClass('disabled_btn');
              this.disabled_submit=false;
            this.$router.push({
              path: '/registration/media/step2'
            });
          })
          .catch((e) => {
            console.log(e);
            $('.next_btn').removeClass('disabled_btn');
              this.disabled_submit=false;
          });
          }
      }
      else{
        $('.next_btn').removeClass('disabled_btn');
              this.disabled_submit=false;
        $('.alert_error').removeClass('h');
        setTimeout(function () {
          $('.alert_error').addClass("h");
        }, 5000);
      }
    }
  },

}
</script>
